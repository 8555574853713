import React, { Component } from 'react';
import Tabulator from "tabulator-tables"; //import Tabulator library

import { cleanUpSingleTabulatorColumn, setLocalStorageValueByParameter, toggleLoader } from '../../class/common';
import { ALL_WIDGETS, API_URL, BUTTON_TYPE, BUTTON_VARIANT, CALCULATED_COLUMNS as CALC_COLS, DIALOG_SIZE, FORMULA_ELEMENT_TYPE, FormatTypes, INFO_MESSAGE, PS_MAPPING, ROW_STATUS, SCENARIO_STATUS, SIZES, STAGING_SECTIONS, STAGING_SECTIONS_TITLES } from '../../class/constants';
import { convertPxToViewport, convertViewportToPx, formatValString } from '../../class/formatting';
import { alertAndLogError, toggleEnableHeaderNext, translateFormulaToString } from '../../class/jqueries';
import { capitaliseFirstLetterAfterChar, copyObjectValues, deepCompareObjects, getTranslationFile, parseBoolean, tryParse } from '../../class/utils';
import Button from '../../newComponents/Button';
import Modal from '../../newComponents/Modal';
import { getTableButton, getTableIcon } from '../../newComponents/tabulatorComponents';
import { getCalculatedColumns } from './CommonRequests';
import CalculatedColMapping from './GLAccountList.js';
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from '../../class/networkUtils.js';
import { formatValHTML } from '../../class/format.js';


const baseUrl = process.env.REACT_APP_BASE_URL;
const $ = require('jquery');
const lang = getTranslationFile();
const tableId = "calculated-columns-table";

const columns = [
    {
        title: CALC_COLS.TITLES.ID,
        field: CALC_COLS.FIELDS.ID,
        visible: false,
    },
    {
        title: CALC_COLS.TITLES.ADD,
        field: CALC_COLS.FIELDS.ADD,
        headerSort: false,
        width: convertViewportToPx(150),
    },
    {
        title: CALC_COLS.TITLES.NAME,
        field: CALC_COLS.FIELDS.DISPLAY_NAME,
        visible: true,
        width:convertViewportToPx(550)
    },
    {
        title: CALC_COLS.TITLES.FORMULA,
        field: CALC_COLS.FIELDS.FORMULA,
        visible: false,
    },
    {
        title: CALC_COLS.TITLES.DESCRIPTION,
        field: CALC_COLS.FIELDS.DESCRIPTION,
        visible: true,
        tooltip: true,
        width: convertViewportToPx(750)
    },
    {
        title: CALC_COLS.TITLES.TOTAL_AMOUNT,
        field: CALC_COLS.FIELDS.TOTAL_AMOUNT,
        format_type: FormatTypes.AMOUNT,
        visible: true,
        width: convertViewportToPx(250)
    },
    {
        title: CALC_COLS.TITLES.DETAILS,
        field: CALC_COLS.FIELDS.DETAILS,
        visible: true,
        headerSort: false,
        width: convertViewportToPx(150)

    },
    {
        title:"",
        field: CALC_COLS.FIELDS.DELETE,
        visible: true,
        width: convertViewportToPx(150),
        headerSort: false,

    },
    {
        title: ROW_STATUS.TITLE,
        field: ROW_STATUS.FIELD,
        visible: false
    },
    {
        title: "",
        field: CALC_COLS.FIELDS.IS_USED_IN_MAPPING,
        visible: false
    }
]

const newRowTemplate = {
    [CALC_COLS.FIELDS.NAME]: "",
    [CALC_COLS.FIELDS.DISPLAY_NAME]: "",
    [CALC_COLS.FIELDS.FORMULA]: "",
    [CALC_COLS.FIELDS.DESCRIPTION]: "",
    [CALC_COLS.FIELDS.TOTAL_AMOUNT]: "",
    [ROW_STATUS.FIELD]: ROW_STATUS.VALUES.NEW
}

class CalculatedColumns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            calculatedColumnsData: [],
            numericColumns: [],
            confirmationFunction: undefined,
        };

        this.getCalculatedColumns = this.getCalculatedColumns.bind(this);
        this.getColumnFormatter = this.getColumnFormatter.bind(this);
        this.goBackToStaging = this.goBackToStaging.bind(this);
        this.saveCalculatedColumns = this.saveCalculatedColumns.bind(this);
        this.showFormula = this.showFormula.bind(this);
        this.hideFormula = this.hideFormula.bind(this);
        this.setRowFormula = this.setRowFormula.bind(this);
        this.checkEmptyFields = this.checkEmptyFields.bind(this);
        this.checkIsColumnUsed = this.checkIsColumnUsed.bind(this);

        this.hasUnsavedChanges = false;
        this.isLoadingTotalAmount = false;
        this.controller = new AbortController()
        this.signal = this.controller.signal;
    }

    setTabulatorColumns = () => {
        let _this = this;
        var componentColumns = copyObjectValues(columns);
        componentColumns.forEach((col)=>{
            if((!col.formatter && !col.format_type) || col.format_type) {
                col.formatter = _this.getColumnFormatter(col.field);
            } else {
                col.formatterParams = {defaultValue:""}
                col = cleanUpSingleTabulatorColumn(col, {id: tableId});
            }
        });
        componentColumns.forEach((col)=>{
            if([CALC_COLS.FIELDS.ADD, CALC_COLS.FIELDS.DELETE].indexOf(col.field) === -1 &&
                col.visible === true) {
                    // col.titleFormatter = (cell) => addTooltipFormatter(cell, lang[col.field]);
            } else if(col.field === CALC_COLS.FIELDS.ADD) {
                col.titleFormatter = function() {
                    let button = getTableButton(
                        "Add column",
                        ["uk-text-normal"],
                        ["uk-button-icon", "transparent-bg", "dark-bg"],
                        [
                          "far",
                          "fa-plus-circle",
                          "fa-lg",
                          "add-calculated-plus-top",
                          "uk-margin-small-right",
                        ],
                        "left",
                        "Add new column"
                      );
                    button.setAttribute("id", "add-row-top-icon");
                    button.style.display = "none";
                    button.style.width = "max-content"
                    button.onclick = () => _this.showFormula();

                    return button;
                }
            }
        });
        _this.tabulator.setColumns(componentColumns);
    }

    loadCalculatedColumnsTotalAmounts = () => {
        let _this = this;
        _this.isLoadingTotalAmount = true;
        //We need to set columns here for the smaller loader to show
        _this.setTabulatorColumns()
        $("#add-row-top-icon").show();
        
        let query = {
          action: "loadCalculatedColumnsTotalAmounts",
          timePeriod: this.props.selectedPeriod.value,
          calculatedColumns: _this.tabulator.getData()
        }

        let onThenCallback = (data) => {
          if(data.data) {
            let calculatedColumnsData = data.data;
            _this.setTableData(calculatedColumnsData,false);
            _this.isLoadingTotalAmount = false;
          }
        }
        let fetchOptions = {
          [FETCHAPI_PARAMS.funcName]: "loadCalculatedColumnsTotalAmounts",
          [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
          [FETCHAPI_PARAMS.showLoader]: false,
          [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING, 
          [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
          [FETCHAPI_PARAMS.query]: query,
          [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
          [FETCHAPI_PARAMS.signal]: _this.signal,
          [FETCHAPI_PARAMS.periods]: _this.props.selectedPeriod.value,
          [FETCHAPI_PARAMS.screenName]:lang.observability.stage.calculated_columns_mapping.screen_name,
          [FETCHAPI_PARAMS.requestDescription]:lang.observability.stage.calculated_columns_mapping.requests_description.load_calculated_columns_total_amounts
        };
        fetchAPI(fetchOptions);
    }

    resetTotalAmounts = () => {
        let data = this.tabulator.getData();
        data.forEach(item => {
            item.total_amount = 0;
            item.total_amount_computed = false;
        })
        this.tabulator.replaceData(data);
    }
   

    abortCalculatedColumnsTotalAmountsRequest = () => {
        //cancel request
        this.controller.abort()
        this.isLoadingTotalAmount = false;
        this.resetTotalAmounts();
        //Re initialize controller after canceling
        this.controller = new AbortController()
        this.signal = this.controller.signal;
    }

    getCalculatedColumns() {
        var obj = this;
        var callback = (data) => {
            if (data) {
                if(data.data && data.data.length) {
                    obj.setTableData(data.data,true);
                }
            }
        }

        getCalculatedColumns(this.props.scenarioId, this.props.selectedPeriod.value, false, callback,lang.observability.stage.calculated_columns_mapping.key);
    }

    checkEmptyFields() {
        var data = this.tabulator.getData();
        for(var e in data) {
            if(data[e][CALC_COLS.FIELDS.DISPLAY_NAME === ""] || data[e][CALC_COLS.FIELDS.FORMULA] === "" && data[e][ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.DELETED ) {
                return true;
            }
        }
        return false;
    }

    saveCalculatedColumns() {
        
        if (this.checkEmptyFields()){
            this.setInfoDialogOpen(true, lang.empty_calculated_fields);
            return;
        }
        var obj = this;
        var query = {
            action: "saveCalculatedColumns",
            scenario_id: this.props.scenarioId,
            overrideScenario: this.props.scenarioStatus !== SCENARIO_STATUS.SANDBOX,
            calculatedColumnsData: JSON.stringify(this.tabulator.getData())
        }

        let onThenCallback = (response) => {
            if(response.err) {
                obj.setInfoDialogOpen(true, "Something went wrong.");
            } else if(response.data === true)  {
                obj.props.setSaveDialogOpen("Calculated columns saved successfully.")
                obj.goBackToStaging();
            }

       }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "saveCalculatedColumns",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader] : true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.stage.calculated_columns_mapping.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.stage.calculated_columns_mapping.requests_description.save_calculated_columns
        }
        fetchAPI(fetchOptions);
    }

    setTableData(data, fromMount) {
        if (data) {
            data.map(row=>{
                if(typeof row[CALC_COLS.FIELDS.FORMULA] === "string" && row[CALC_COLS.FIELDS.FORMULA] !== "") {
                    row[CALC_COLS.FIELDS.FORMULA] = JSON.parse(row[CALC_COLS.FIELDS.FORMULA]);
                }
                if(fromMount) { 
                    row[CALC_COLS.FIELDS.TOTAL_AMOUNT] = 0;
                    row[CALC_COLS.FIELDS.TOTAL_AMOUNT_COMPUTED] = false;
                    row["shouldInvalidate"] = "";
                }
            })
            this.setState({
                calculatedColumnsData: data
            },()=>{
                this.tabulator.replaceData(this.state.calculatedColumnsData);
            });
        }
    }

    checkIsColumnUsed(cell) {
        let rowData = cell.getRow().getData();
        let columnName = rowData[CALC_COLS.FIELDS.DISPLAY_NAME];

        let isUsed = false;
        this.state.calculatedColumnsData.filter(c=>c[ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.DELETED).forEach(row=>{
            if(isUsed) return;
            if(row[CALC_COLS.FIELDS.FORMULA]) {
                let formula = tryParse(row[CALC_COLS.FIELDS.FORMULA]);
                //loop through items in the formula's main result of each one
                formula.result.forEach(item=>{
                    if(item[CALC_COLS.FIELDS.COLUMN_FIELDS.TYPE] === FORMULA_ELEMENT_TYPE.COLUMN 
                        && item[CALC_COLS.FIELDS.COLUMN_FIELDS.VALUE] === columnName) {
                        isUsed = true;
                        return;
                    }
                });

                if(!isUsed) {
                    formula.conditions.forEach(cond=>{
                        cond.result.forEach(item=>{
                            if(item[CALC_COLS.FIELDS.COLUMN_FIELDS.TYPE] === FORMULA_ELEMENT_TYPE.COLUMN 
                                && item[CALC_COLS.FIELDS.COLUMN_FIELDS.VALUE] === columnName) {
                                isUsed = true;
                                return;
                            }
                        });
                    })
                }
            }
        });

        return isUsed;
    }

    checkCalculatedColumn(cell) {
        let itemCount = this.props.checkCalculatedColumn.filter(e=> e.item?.includes(cell.getRow().getData()[CALC_COLS.FIELDS.NAME]));
        if (itemCount.length > 0 && itemCount[0]["count"] > 0) {
            this.setInfoDialogOpen(true, cell.getRow().getData()[CALC_COLS.FIELDS.DISPLAY_NAME] + lang.calc_cols.col_used_mapping_tieoff);
        } else {
            this.setOpenDeleteRowDialog(true, cell)
            
        }
    }

    confirmRowDeletion(cell) {
        //check locally if column is used in the formula of another one
        let isUsed = this.checkIsColumnUsed(cell);

        if(!isUsed) {
            let linesUsingAttribute = this.props.usedAttributes ? this.props.usedAttributes.filter(attr=>attr[PS_MAPPING.FIELDS.ATTRIBUTE] === cell.getRow().getData()[CALC_COLS.FIELDS.DISPLAY_NAME]) : [];
            isUsed = linesUsingAttribute.length > 0;
        }
        if(!isUsed) {
            //check in the DB if column is used in Mapping & Tie off
            this.checkCalculatedColumn(cell);
        } else {
            this.setOpenDeleteRowUsedDialog(true, cell)
        }
        
    }

    deleteRow(cell) {
        //hide deleted row, until saved in DB
        if(cell.getRow().getData()[ROW_STATUS.FIELD] === ROW_STATUS.VALUES.DELETED) {
            cell.getRow().delete();
        }

        cell.getRow().getData()[ROW_STATUS.FIELD] = ROW_STATUS.VALUES.DELETED;

        let position = cell.getRow().getPosition();
        let calcColsData = copyObjectValues(this.state.calculatedColumnsData);

        calcColsData[position][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.DELETED

        this.tabulator.setFilter(ROW_STATUS.FIELD, "in", [ROW_STATUS.VALUES.NEW, ROW_STATUS.VALUES.OLD, ROW_STATUS.VALUES.EDITED]);
        
        this.setState({
            calculatedColumnsData:calcColsData
        },()=> {
            this.hasUnsavedChanges = true;
            this.props.setNotSavedWarning(true);
            if(this.isLoadingTotalAmount) {
                this.abortCalculatedColumnsTotalAmountsRequest();
            }
        })
        
    }

    getColumnFormatter(columnField) {
        var obj = this;
        var columnFormatter  = "";
        switch(columnField) {
            case CALC_COLS.FIELDS.ADD:
                columnFormatter = function(cell) {
                    
                    var icon = getTableIcon(["fal", "fa-sliders-h", "fa-lg", CALC_COLS.FIELDS.FORMULA])
                    icon.onclick = ()=>{
                        obj.showFormula(cell);
                    };
                    return icon;
                };
                break;
            case CALC_COLS.FIELDS.FORMULA:
                columnFormatter = function(cell) {
                    var div = document.createElement("div");
                    div.classList.add(CALC_COLS.FIELDS.FORMULA+"Div");

                    var stringFormula = translateFormulaToString(cell.getValue(), "field");
                    
                    if(stringFormula !== "") {
                        var input = document.createElement("input");
                        input.value = stringFormula;
                        input.title = stringFormula;
                        input.disabled = true;
                        input.style.marginRight = convertPxToViewport(5);
                        input.style.width = convertPxToViewport(175);
                        div.appendChild(input);
                    }

                    return div;
                };
                break;
            case CALC_COLS.FIELDS.DELETE:
                columnFormatter  = function(cell) {
                    var icon = getTableIcon(["fal","fa-trash-alt", "fa-lg"]);
                    icon.onclick = ()=>{
                        obj.confirmRowDeletion(cell);
                    };
                    
                    return icon;
                };
                break;
            case CALC_COLS.FIELDS.DETAILS:
                columnFormatter  = function(cell) {
                    var a = document.createElement("a");
                    var link = document.createTextNode("More");
                    a.appendChild(link); 
                    a.style.cursor = "not-allowed";
                    a.style.color = "#8d8d8d";
                    return a;
                };
                break;
            case CALC_COLS.FIELDS.TOTAL_AMOUNT:
                columnFormatter = function (cell, formatterParams) {
                    let totalAmount = cell.getRow().getData()[CALC_COLS.FIELDS.TOTAL_AMOUNT] || "";
                    let totalAmountComputed =  cell.getRow().getData()[CALC_COLS.FIELDS.TOTAL_AMOUNT_COMPUTED];
                    let p = document.createElement("p");
                    if(obj.isLoadingTotalAmount && !parseBoolean(totalAmountComputed)) {
                        let img = document.createElement("i");
                        img = document.createElement("img");
                        img.src = '/images/FhHRx.gif';
                        img.style.width = convertPxToViewport(15);
                        img.style.height = convertPxToViewport(15);
                        img.id = "small_loader";
                        p.appendChild(img);
                    } else {
                        totalAmount = totalAmount === 0 ? "-" : totalAmount.toString();
                        p.innerHTML = formatValHTML(totalAmount, FormatTypes.AMOUNT);
                        p.title = formatValString(totalAmount, FormatTypes.AMOUNT);
                    }
                    return p;
                }
                break;
                
        }
        
        return columnFormatter;
    }

    setCellValue(e, cell) {
        var data = this.state.calculatedColumnsData;
        var rowIndex = cell.getRow().getPosition();
        var row = data[rowIndex];
        row[CALC_COLS.FIELDS.DESCRIPTION] = $(e.currentTarget).val();
        
        if(row[ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.NEW) {
            row[ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED
        }
    }

    goBackToStaging() {
        this.props.goBackToStaging(true)
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.selectedPeriod.value !== this.props.selectedPeriod.value) {
            if(this.isLoadingTotalAmount) {
                this.abortCalculatedColumnsTotalAmountsRequest();
            } else {
                this.resetTotalAmounts();
            }
        }

        if(this.tabulator.getData().length > 0) {
            $("#add-row-top-icon").show();
        }
    }

    componentDidMount() {
        this.getCalculatedColumns();
        this.props.checkCalculatedColumnFunc();
        //extracting numeric transaction data columns

        var obj = this;
        var options = {
          id: "calculated-columns-container",
          layout: "fitColumns",      //fit columns to width of table
          responsiveLayout: false,   //hide columns that dont fit on the table
          tooltips: true,            //show tool tips on cells
          addRowPos: "top",          //when adding a new row, add it to the top of the table
          history: true,             //allow undo and redo actions on the table
          pagination: false,         //paginate the data
          movableColumns: false,     //allow column order to be changed
          selectable: false,
          movableRows: false,
          resizableColumns: false,
          autoResize: false,
          resizableRows: false,       //allow row order to be changed
          virtualDomBuffer: 800,
          placeholder: "",
          height: "100%",
          width: "100%",
          reactiveData: true,      //tabulator listens to any change in the data array and updates the table
          tooltips: function(column){},
        }

        this.tabulator = new Tabulator(this.refs.mainTable, options, this.refreshFilterDivs, this.tabulator, {id: tableId});
        this.setTabulatorColumns();

        $("#scenarioList-header").addClass("disabled"); //disabling scenario dropdown
        $("#header-title-icon").removeClass("uk-hidden");
        // $("#header-buttons").removeClass("uk-hidden");
        
        // $("#header-back").on("click", function (e) {
        //     obj.discardChangesAndGoBack();
        // });
        // $("#header-next").on("click", obj.props.showScenarioPopUp);
    }

    hideFormula(callback) {
        // This is to give the create columns table full width when glaccountmodal is hid
        $("#create-calculated-columns-container").addClass("w100").removeClass("w48");
        var rightTile = capitaliseFirstLetterAfterChar(ALL_WIDGETS.FIELDS.STAGE)+ " " + STAGING_SECTIONS_TITLES.TRANSACTION + " - " + capitaliseFirstLetterAfterChar(STAGING_SECTIONS.CALCULATED_COLUMNS.split("_")[0]) + " " + capitaliseFirstLetterAfterChar(STAGING_SECTIONS.CALCULATED_COLUMNS.split("_")[1])
        $(".filter-control-titles").find("p").text(rightTile)
        var _this = this;
        // $(".calculated-columns-table").animate({width: "100%"}, 1000, "linear");
        $('#GLAccountsModal').hide('slide', {direction:'right'}, 500, function(){
            //_this.nextMustBeDisabled = false;
            _this.props.setSavedDisabled(false);
            _this.setState({
                showFormula: false,
                operatedColumn: null
            }, function() {
                if(callback) {
                    callback();
                }
            });
        });
    }

    showFormula(cell) {
        // This is to give the create columns table 50% width when glaccountmodal appears
        $("#create-calculated-columns-container").removeClass("w100").addClass("w48");
        const _this = this;
        function showModal() {
            let position = cell ? cell.getRow().getPosition() : -1;
            let rowData = cell ? cell.getRow().getData() : copyObjectValues(newRowTemplate);
            _this.nextMustBeDisabled = true;  //disable when formula is open
            
            _this.setState({
                showFormula: true,
                rowPosition: position,
                operatedColumn: rowData,
            }, function() {
                // $(".calculated-columns-table").animate({width: "65%"}, 1000, "linear");
                var rightTile = capitaliseFirstLetterAfterChar(ALL_WIDGETS.FIELDS.STAGE) + " - " + STAGING_SECTIONS_TITLES.TRANSACTION;
                $("#create-calculated-columns-container").removeClass("w100").addClass("w48");
                $(".filter-control-titles").find("p").text(rightTile);
                $('#GLAccountsModal').show('slide', {direction:'right'}, 500);
            });
        }
        
        //hide the Modal first and then reshow it in case it was opened for another vector
        if($('#GLAccountsModal').is(":visible")) {
            this.hideFormula(showModal);
        } else {
            showModal();
        }
    }

    discardChangesAndGoBack() {
        var data = this.tabulator.getData();
        var changed = false;
        for (var e in data) {
            if ([ROW_STATUS.VALUES.NEW, ROW_STATUS.VALUES.EDITED, ROW_STATUS.VALUES.DELETED].includes(data[e][ROW_STATUS.FIELD])) {
                changed = true;
            }
        }
        //check if there are modified fields
        this.props.goBackToStaging(false, changed); 
    }

    setRowFormula(formula, name, description, isAdvanced) {
        let isNewRow = this.state.rowPosition === -1;
        var data = copyObjectValues(this.tabulator.getData());
        var row = copyObjectValues(this.tabulator.getData()[this.state.rowPosition]);
        
        if(isNewRow) {
            row = this.state.operatedColumn;
            row[CALC_COLS.FIELDS.SCENARIO_ID] = this.props.scenarioId;
        }

        function deleteOptions(filterRow) {
            Object.keys(filterRow).forEach(key=> {
                if(key.includes("options")) {
                    delete filterRow[key];
                }
            });
            return filterRow;
        }

        //fetch conditions and results from children
        let conditions = row["column_formula"]["conditions"];

        if(conditions){
            Object.keys(conditions).forEach(key=>{
                var filterRefs = conditions[key]["filters"];
                var conditionFilters = [];
    
                //extract condition filters
                Object.keys(filterRefs).forEach(key2=>{
                    let filterRow = filterRefs[key2];
                    filterRow = deleteOptions(filterRow);
                    conditionFilters.push(filterRow);
                });
    
                //saving the current condition
                row["column_formula"]["conditions"][key]["filters"] = conditionFilters;
            });
        }
        
        
        //invalidation occurs only if fromula or mapping type is changed
        let shouldInvalidate = !deepCompareObjects(row[CALC_COLS.FIELDS.FORMULA], formula) ;
        //if name later on should invalidate cc, add to the variable above this => || !(row[CALC_COLS.FIELDS.DISPLAY_NAME] === name)
        
        
        row[CALC_COLS.FIELDS.FORMULA] = formula;    //save formula in object
        row[CALC_COLS.FIELDS.DISPLAY_NAME] = name;
        row[CALC_COLS.FIELDS.NAME] = name;
        row[CALC_COLS.FIELDS.DESCRIPTION] = description;
        row[CALC_COLS.FIELDS.MAPPING_TYPE] = isAdvanced ? CALC_COLS.VALUES.MAPPING_TYPE.advanced : CALC_COLS.VALUES.MAPPING_TYPE.basic;
        if(row[ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.NEW) {
            row[ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED
        }
        row[CALC_COLS.FIELDS.TOTAL_AMOUNT] = 0;
        row[CALC_COLS.FIELDS.TOTAL_AMOUNT_COMPUTED] = false;
        
        if(shouldInvalidate){
            row["shouldInvalidate"] = "invalidate";
        }

        if(isNewRow) {
            data.unshift(row);
        } else {
            data[this.state.rowPosition] = row;
        }
        this.setTableData(data,false)
        this.hideFormula();
        this.hasUnsavedChanges = true;       //enable after user submits changes in right window
        this.props.setNotSavedWarning(true);
        if(this.isLoadingTotalAmount) {
            this.abortCalculatedColumnsTotalAmountsRequest();
        }
        // this.getCalculatedColumnsTotalAmounts();        //fetch total columns
    }

    setInfoDialogOpen = (isOpen, infoMsg) => {
      let _this = this;
      _this.setState({
        openInfoDialog: isOpen,
        infoMsg: infoMsg
      })
    }

    openInfoDialogActions = () => {
      return (
        <Button
          label={lang.modal.buttons.ok}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setInfoDialogOpen(false, "")}
        />
      )
    }

    deleteRowUsedDialogContent = () => {
      let _this = this;
      let cell = _this.state.selectedCell;

      if(!cell) {return;}

      let isUsed = _this.checkIsColumnUsed(cell);

      if(!isUsed) {
          let linesUsingAttribute = _this.props.usedAttributes ? _this.props.usedAttributes.filter(attr=>attr[PS_MAPPING.FIELDS.ATTRIBUTE] === cell.getRow().getData()[CALC_COLS.FIELDS.DISPLAY_NAME]) : [];
          if(linesUsingAttribute.length > 0) {
            return (
              <div>
                  <h4>{`${cell.getRow().getData()[CALC_COLS.FIELDS.DISPLAY_NAME]} ${lang.used_ps_no_delete_1_attr}`}</h4>
                  <ul>
                      {linesUsingAttribute.map((line) => <li key={line.name}><h4 className="uk-margin-left">{"- " + line.name}</h4></li>)}
                  </ul>
                  <h4>{lang.used_ps_no_delete_2}</h4>
              </div>
          )
          }
        }
      
          return (
            <h4>{cell.getRow().getData()[CALC_COLS.FIELDS.DISPLAY_NAME] + lang.calc_cols.col_used_by_another_col}</h4>      
        )
      
    }
    deleteRowUsedDialogActions = () => {
      return (
        <Button
          label={lang.modal.buttons.ok}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setOpenDeleteRowUsedDialog(false)}
        />
      )
    }
    setOpenDeleteRowUsedDialog = (isOpen, cell) => {
      let _this = this;
      _this.setState({
        openDeleteRowUsedDialog: isOpen,
        selectedCell: cell
      })
    }

    confirmBackDialogActions = () => {
      return (
        <>
          <Button
            label={lang.modal.buttons.yes}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={() => {
              this.goBackToStaging();
              this.setOpenConfirmBackDialog(false);
            }}
          />
          <Button
            label={lang.modal.buttons.no}
            variant={BUTTON_VARIANT.SECONDARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={() => this.setOpenConfirmBackDialog(false)}
          />
        </>
      )
      }
  setOpenConfirmBackDialog = (isOpen) => {
    let _this = this;
    _this.setState({
      openConfirmBackDialog: isOpen
    })
  }

  deleteRowDialogActions = () => {
    return (
      <>
        <Button
          label={"Delete"}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => {
            this.deleteRow(this.state.cellToDelete);
            this.setOpenDeleteRowDialog(false)
          }}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setOpenDeleteRowDialog(false)}
        />
      </>
    )
  }

      setOpenDeleteRowDialog = (isOpen, cell) => {
        let _this = this;
        _this.setState({
          openDeleteRowDialog: isOpen,
          cellToDelete: cell
        })
      }
    render() {
        // toggleEnableHeaderNext(this.hasUnsavedChanges && !this.nextMustBeDisabled);
        var deleted = this.state.calculatedColumnsData.filter(e=>e[ROW_STATUS.FIELD] === ROW_STATUS.VALUES.DELETED);
        var allDeleted = false;
        if (deleted && this.state.calculatedColumnsData && deleted.length === this.state.calculatedColumnsData.length) {
            allDeleted = true;
            $("#add-row-top-icon").hide();
        }
        return(
            <div id="calculated-columns-container" className="uk-display-inline-flex uk-width-1-1" style={{height: "100%"}}>
                <div id="create-calculated-columns-container" className="uk-display-flex uk-flex-column w100 uk-border uk-background-default">
                    <div className="mapping-header">
                        <span className="uk-text-bold uk-text-xmedium uk-display-flex uk-flex-middle">{capitaliseFirstLetterAfterChar(this.props.stagingReport).replaceAll("_", " ")}</span>
                        <i className="fal fa-info-circle uk-margin-xsmall-left uk-cursor-pointer" uk-tooltip={INFO_MESSAGE} />
                    </div>
                    <div id={tableId} ref="mainTable"/>
                    {this.state.calculatedColumnsData.length && !allDeleted  ? "" :
                        <div id="add-row-center" className="uk-height-large">
                            <div className="uk-display-flex uk-flex-column align-items-center">
                                <i className="far fa-plus-circle uk-heading-primary uk-add" onClick={()=>this.showFormula()} />
                                <h4>Add a calculated column</h4>
                            </div>
                        </div>
                    }
                </div>
                {this.state.showFormula ? 
                    <div id="GLAccountsModal" className="GL-accounts-modal uk-margin-remove-top">
                        <CalculatedColMapping stagingReport={STAGING_SECTIONS.CALCULATED_COLUMNS}
                            dataColumns={this.props.dataColumns} calcColsData={this.state.calculatedColumnsData}
                            operatedColumn={this.state.operatedColumn} rowPosition={this.state.rowPosition}
                            setRowFormula={this.setRowFormula} hideFormula={this.hideFormula} scenarioId={this.props.scenarioId}
                            disableHeaderButtons={this.props.disableHeaderButtons}scenarioStatus={this.props.scenarioStatus}
                        />
                    </div>
                    :""
                }
              
            <Modal
              id={"info-dialog"}
              openDialog={this.state.openInfoDialog}
              bodyContent={() => <h4>{this.state.infoMsg}</h4>}
              dialogActions={this.openInfoDialogActions}
              closeClick={() => this.setInfoDialogOpen(false)}
              size={DIALOG_SIZE.MEDIUM}
            />
            <Modal
              id={"delete-row-used-in-calculated-dialog"}
              openDialog={this.state.openDeleteRowUsedDialog}
              bodyContent={this.deleteRowUsedDialogContent}
              dialogActions={this.deleteRowUsedDialogActions}
              closeClick={() => this.setOpenDeleteRowUsedDialog(false)}
              size={DIALOG_SIZE.MEDIUM}
            />
            <Modal
              id={"confirm-back-dialog"}
              openDialog={this.state.openConfirmBackDialog}
              bodyContent={() => <h4>{lang.discard_changes_confirmation}</h4>}
              dialogActions={this.confirmBackDialogActions}
              closeClick={() => this.setOpenConfirmBackDialog(false)}
              size={DIALOG_SIZE.MEDIUM}
            />
            <Modal
              id={"delete-row-dialog"}
              openDialog={this.state.openDeleteRowDialog}
              bodyContent={() => <h4>{lang.calc_cols.delete_confirmation + this.state.cellToDelete?.getRow().getData()[CALC_COLS.FIELDS.DISPLAY_NAME] + "?"}</h4>}
              dialogActions={this.deleteRowDialogActions}
              closeClick={() => this.setOpenDeleteRowDialog(false)}
              size={DIALOG_SIZE.MEDIUM}
            />
          </div>
        );
    }
}


export default CalculatedColumns;