// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.set_password_stmt_two {
    color: #8D8D8D;
    font-size: 0.72917vw;
    font-weight: 500;
}
.password-input-width {
    width: 94.5% !important;
}
.section-password-setup {
    margin-top: 1vw !important;
}
.set_password_text_container{
    margin-top: 0.52083vw;
}
.mfa_text_container{
    margin-top: 1.25vw;
    margin-bottom: 1.25vw;
}
.mfa_text {
    color: #000;
    font-size: 0.72917vw;
    font-weight: 400;
}
.mfa_input_label {
    color: #000;
    font-size: 0.72917vw;
    font-weight: 400;
    margin-bottom: 0.41667vw;
}`, "",{"version":3,"sources":["webpack://./src/styles/mfa.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,oBAAoB;IACpB,gBAAgB;AACpB;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,qBAAqB;AACzB;AACA;IACI,kBAAkB;IAClB,qBAAqB;AACzB;AACA;IACI,WAAW;IACX,oBAAoB;IACpB,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,oBAAoB;IACpB,gBAAgB;IAChB,wBAAwB;AAC5B","sourcesContent":[".set_password_stmt_two {\n    color: #8D8D8D;\n    font-size: 0.72917vw;\n    font-weight: 500;\n}\n.password-input-width {\n    width: 94.5% !important;\n}\n.section-password-setup {\n    margin-top: 1vw !important;\n}\n.set_password_text_container{\n    margin-top: 0.52083vw;\n}\n.mfa_text_container{\n    margin-top: 1.25vw;\n    margin-bottom: 1.25vw;\n}\n.mfa_text {\n    color: #000;\n    font-size: 0.72917vw;\n    font-weight: 400;\n}\n.mfa_input_label {\n    color: #000;\n    font-size: 0.72917vw;\n    font-weight: 400;\n    margin-bottom: 0.41667vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
