// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cost_center_definition_container{
    /* height: 100%; */
    height: calc(100vh - 11.156987vw);
}
.cost_center_body{
    height: 94%;
}
.table_container{
    margin-top: 0 !important;
    height: 100%;
}
.cost_center_definition_container .tabulator-tableHolder{
    height: 80%;
}

#new-state-modal .dialog-body, #selectStateModal .dialog-body {
  overflow: visible !important;
  overflow: initial !important;
}
.costCenterContainer {
  padding: 0 0.5vw;
  height: 100%;
}

.cc-info-icon {
  display: none;
}
.manage-cc-title:hover .cc-info-icon {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/styles/manageCostCenters.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iCAAiC;AACrC;AACA;IACI,WAAW;AACf;AACA;IACI,wBAAwB;IACxB,YAAY;AAChB;AACA;IACI,WAAW;AACf;;AAEA;EACE,4BAA0B;EAA1B,4BAA0B;AAC5B;AACA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;AACf;AACA;EACE,cAAc;AAChB","sourcesContent":[".cost_center_definition_container{\n    /* height: 100%; */\n    height: calc(100vh - 11.156987vw);\n}\n.cost_center_body{\n    height: 94%;\n}\n.table_container{\n    margin-top: 0 !important;\n    height: 100%;\n}\n.cost_center_definition_container .tabulator-tableHolder{\n    height: 80%;\n}\n\n#new-state-modal .dialog-body, #selectStateModal .dialog-body {\n  overflow: unset !important;\n}\n.costCenterContainer {\n  padding: 0 0.5vw;\n  height: 100%;\n}\n\n.cc-info-icon {\n  display: none;\n}\n.manage-cc-title:hover .cc-info-icon {\n  display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
