// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-btn,
.save-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0vw;
  gap: 0.42vw;
  width: 1.46vw;
  height: 1.46vw;
  background: #f3f3f3;
  border-radius: 0.21vw;
  border: none;
  margin-left: 0.42vw;
}
.edit-text-field {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px;
  gap: 10px;
  max-width: 145px;
  height: 33px;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
}

.banner-error {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.83vw 0.83vw 0.83vw 0.83vw;
  isolation: isolate;
  width: inherit;
  height: 2.5vw;
  background: #fbe9e9;
  border: 0.05vw solid #ef9ea2;
  border-radius: 0.21vw;

}

.red-text {
  color: #620d12;
  margin-left: 10px
}

.cancel-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 32px;
  border: none;
  background: none;
}`, "",{"version":3,"sources":["webpack://./src/components/periodsContainer/periodsContainer.css"],"names":[],"mappings":"AAAA;;EAEE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,aAAa;EACb,cAAc;EACd,mBAAmB;EACnB,qBAAqB;EACrB,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,SAAS;EACT,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,oCAAoC;EACpC,kBAAkB;EAClB,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,4BAA4B;EAC5B,qBAAqB;;AAEvB;;AAEA;EACE,cAAc;EACd;AACF;;AAEA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".edit-btn,\n.save-btn {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 0vw;\n  gap: 0.42vw;\n  width: 1.46vw;\n  height: 1.46vw;\n  background: #f3f3f3;\n  border-radius: 0.21vw;\n  border: none;\n  margin-left: 0.42vw;\n}\n.edit-text-field {\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 0px 8px;\n  gap: 10px;\n  max-width: 145px;\n  height: 33px;\n  background: #ffffff;\n  border: 1px solid #dcdcdc;\n  border-radius: 4px;\n}\n\n.banner-error {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 0.83vw 0.83vw 0.83vw 0.83vw;\n  isolation: isolate;\n  width: inherit;\n  height: 2.5vw;\n  background: #fbe9e9;\n  border: 0.05vw solid #ef9ea2;\n  border-radius: 0.21vw;\n\n}\n\n.red-text {\n  color: #620d12;\n  margin-left: 10px\n}\n\n.cancel-btn {\n  font-family: 'Open Sans';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 16px;\n  margin-left: 32px;\n  border: none;\n  background: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
