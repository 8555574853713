// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* MonthlyBuildCurrentPeriodBreadcrumbs.css */
.breadcrumbs-container {
    display: flex;
    align-items: center;
    color: #08155E;
    font-weight: 700;
    margin: 0 0 10px 0;
}

.breadcrumb-icon {
    margin: 0 8px;
    width: 4px;
    height: 8px;
}

.periods-count {
    margin-left: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #E9EBF7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 5px;
    cursor: pointer;
    position: relative; /* Relative positioning for the hover list */
}

.empty-div-count {
    width: 60px;
    height: 25px;
    position: absolute; 
    z-index: 0;
    align-self: flex-start;
  }
`, "",{"version":3,"sources":["webpack://./src/components/monthlyBuild/Style/MonthlyBuildCurrentPeriodBreadcrumbs.css"],"names":[],"mappings":"AAAA,6CAA6C;AAC7C;IACI,aAAa;IACb,mBAAmB;IACnB,cAAc;IACd,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,UAAU;IACV,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,YAAY;IACZ,eAAe;IACf,kBAAkB,EAAE,4CAA4C;AACpE;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,UAAU;IACV,sBAAsB;EACxB","sourcesContent":["/* MonthlyBuildCurrentPeriodBreadcrumbs.css */\n.breadcrumbs-container {\n    display: flex;\n    align-items: center;\n    color: #08155E;\n    font-weight: 700;\n    margin: 0 0 10px 0;\n}\n\n.breadcrumb-icon {\n    margin: 0 8px;\n    width: 4px;\n    height: 8px;\n}\n\n.periods-count {\n    margin-left: 10px;\n    width: 20px;\n    height: 20px;\n    border-radius: 50%;\n    background-color: #E9EBF7;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 12px;\n    padding: 5px;\n    cursor: pointer;\n    position: relative; /* Relative positioning for the hover list */\n}\n\n.empty-div-count {\n    width: 60px;\n    height: 25px;\n    position: absolute; \n    z-index: 0;\n    align-self: flex-start;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
