// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arrow-btn-container {
    position: relative;
    z-index: 3;
    height: var(--value-35-px);
    margin: var(--value-4-px) 0;
}

.circle-arrow-button {
    border-radius: 100% 100% 100% 100%;
    width: var(--value-75-px);
    height:fit-content;
    /*border:var(--border-width) solid rgba(94,94,94,0.3);*/
    transform:translate(-50%);
    left:50%;
    top:0;
    position:absolute;
    overflow:hidden;
    box-shadow:0 0 var(--value-3-px) #999;
    opacity: 0.5;
}

.circle-arrow-button .top{
    border-radius: 100% 100% 0 0;
    background-color:#F3F3F3;
    height:1.95vw;
    border-bottom:var(--border-width) solid rgba(94,94,94,0.3);;
    display:flex;
    justify-content: center;
    align-items:center;
    font-size:var(--value-15-px);
    color:#0c0c0c;
    cursor:pointer;
}

.circle-arrow-button .bottom{
    border-radius: 0 0 100% 100%;
    background-color:#F3F3F3;
    height:1.95vw;
    display:flex;
    justify-content: center;
    align-items:center;
    font-size:var(--value-15-px);
    color:#0c0c0c;
    cursor:pointer;
}

.circle-arrow-button .top:hover {
    background-color:#fff;
}

.circle-arrow-button .bottom:hover {
    background-color:#fff;
}`, "",{"version":3,"sources":["webpack://./src/styles/elements.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,0BAA0B;IAC1B,2BAA2B;AAC/B;;AAEA;IACI,kCAAkC;IAClC,yBAAyB;IACzB,kBAAkB;IAClB,uDAAuD;IACvD,yBAAyB;IACzB,QAAQ;IACR,KAAK;IACL,iBAAiB;IACjB,eAAe;IACf,qCAAqC;IACrC,YAAY;AAChB;;AAEA;IACI,4BAA4B;IAC5B,wBAAwB;IACxB,aAAa;IACb,0DAA0D;IAC1D,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,4BAA4B;IAC5B,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,4BAA4B;IAC5B,wBAAwB;IACxB,aAAa;IACb,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,4BAA4B;IAC5B,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".arrow-btn-container {\n    position: relative;\n    z-index: 3;\n    height: var(--value-35-px);\n    margin: var(--value-4-px) 0;\n}\n\n.circle-arrow-button {\n    border-radius: 100% 100% 100% 100%;\n    width: var(--value-75-px);\n    height:fit-content;\n    /*border:var(--border-width) solid rgba(94,94,94,0.3);*/\n    transform:translate(-50%);\n    left:50%;\n    top:0;\n    position:absolute;\n    overflow:hidden;\n    box-shadow:0 0 var(--value-3-px) #999;\n    opacity: 0.5;\n}\n\n.circle-arrow-button .top{\n    border-radius: 100% 100% 0 0;\n    background-color:#F3F3F3;\n    height:1.95vw;\n    border-bottom:var(--border-width) solid rgba(94,94,94,0.3);;\n    display:flex;\n    justify-content: center;\n    align-items:center;\n    font-size:var(--value-15-px);\n    color:#0c0c0c;\n    cursor:pointer;\n}\n\n.circle-arrow-button .bottom{\n    border-radius: 0 0 100% 100%;\n    background-color:#F3F3F3;\n    height:1.95vw;\n    display:flex;\n    justify-content: center;\n    align-items:center;\n    font-size:var(--value-15-px);\n    color:#0c0c0c;\n    cursor:pointer;\n}\n\n.circle-arrow-button .top:hover {\n    background-color:#fff;\n}\n\n.circle-arrow-button .bottom:hover {\n    background-color:#fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
