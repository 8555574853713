// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.marginBottom {
    bottom: auto !important;
    bottom: initial !important;
}
.marginBottom-session-dialog {
    right: 0 !important;
    bottom: 0 !important;
    top: auto !important;
    top: initial !important;
    left: auto !important;
    left: initial !important;
}
.dialog-text{
    /* padding-left: var(--value-50-px) !important; */
    width: 90% !important;
    align-self: center !important;
}
.margin-button {
    margin-top:20px !important;
    margin-bottom:10px !important;
}
.margin-dialog-content{
    margin-top:10px !important;
}
.text{
    color:#333333 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/newDialog.css"],"names":[],"mappings":"AAAA;IACI,uBAAwB;IAAxB,0BAAwB;AAC5B;AACA;IACI,mBAAmB;IACnB,oBAAoB;IACpB,oBAAqB;IAArB,uBAAqB;IACrB,qBAAsB;IAAtB,wBAAsB;AAC1B;AACA;IACI,iDAAiD;IACjD,qBAAqB;IACrB,6BAA6B;AACjC;AACA;IACI,0BAA0B;IAC1B,6BAA6B;AACjC;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,wBAAwB;AAC5B","sourcesContent":[".marginBottom {\n    bottom: unset !important;\n}\n.marginBottom-session-dialog {\n    right: 0 !important;\n    bottom: 0 !important;\n    top: unset !important;\n    left: unset !important;\n}\n.dialog-text{\n    /* padding-left: var(--value-50-px) !important; */\n    width: 90% !important;\n    align-self: center !important;\n}\n.margin-button {\n    margin-top:20px !important;\n    margin-bottom:10px !important;\n}\n.margin-dialog-content{\n    margin-top:10px !important;\n}\n.text{\n    color:#333333 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
