import React from "react";
import Tabulator from "tabulator-tables"; //import Tabulator library
import { cleanUpTabulatorColumns, getRowVectorType, isFunction, toggleLoader } from "../class/common.js";
import {
    ALL_REPORTS,
    EXPANDED,
    FormatTypes,
    PROFILE_COLUMN,
    PSL_RETURN_NAMES,
    SELECTED_DRILL_LIST,
    SELECTED_VECTOR_VIEWS,
    SORT_TITLES,
    SUFFIX,
} from "../class/constants.js";
import { getMonthName } from "../class/date.js";
import { formatLongestValString, formatValHTML, formatValString } from "../class/format.js";
import { convertViewportToPx } from "../class/formatting.js";
import {
    hideDrillItem,
    hideProfitStackLink,
    replaceSpecialCharacters,
    showDrillItem
} from '../class/jqueries.js';
import { findLongestStringByTextWidth, getTextWidth } from "../class/string.js";
import { tabulatorExport } from "../class/tabulatorExport.js";
import {
    copyObjectValues,
    getMonthsNumber,
    getTranslationFile,
    parseBoolean,
    returnObjectsInArray
} from "../class/utils.js";
import { lang } from "../language/messages_en.js";
import { createCheckbox } from "../newComponents/CheckBoxTabulator.js";
import { getTableButton, getTableIcon } from "../newComponents/tabulatorComponents.js";
import PeriodsDropDown from "../sections/buildData/PeriodsDropDown.js";
import { atLeastOneVectorHasNAInData, initDrillColumnWidth } from "../templateLayout/functions/componentFunctions.js";
import { customColumnSorter, segmentsSorter } from "../templateLayout/functions/tabulatorFunctions.js";

const $ = require('jquery');
const UIkit = require('uikit');
const MESSAGES = getTranslationFile();
const _range = "range";
const _cnt = "cnt";

const tierCellObject = {
    title: "Vector",
    visible: true,
    sortable: false,
    field: "tier",
    width: '30%',
    profitListDisplay:false
}
const drillBackColumn = {
    title: "Drill Back",
    visible: true,
    sortable: false,
    field: "drill-back",
    width: '5%',
    download:false,
    profitListDisplay:false
}

const COMBINATION_FIELD = "combination";
const COMBINATION_ARRAY = [SORT_TITLES.FIELD.NAME_COMBINATION, SORT_TITLES.FIELD.NUMBER_COMBINATION, SORT_TITLES.FIELD.QUADRANT_COMBINATION, SORT_TITLES.FIELD.QUADRANTTIER_COMBINATION];


class TabulatorWrapper extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isDrilling: false,
        }

        this.setDrillClickListener = this.setDrillClickListener.bind(this);
        this.onCheckBoxChecked = this.onCheckBoxChecked.bind(this);
        this.isSecondDimension = false;
        this.containerResized = false;
        this.checkedDisabled = false;
        this.limitExceeded = false;
        this.stopHeaderClick = false;
        this.drillColumnCollapsed = true;
        
    }

    disableCheckboxesOnClick = () => {
        if(this.state.checkedDisabled){
            $('input.chosenEntity:not(:checked)').attr('disabled', 'disabled');
            $('input.chosenEntity:not(:checked)').addClass("input-checkbox-disabled");
            $('input.chosenEntity:not(:checked)').parent().attr("uk-tooltip", MESSAGES.profit_list.disable_checkbox);
        } else{
            $('input.chosenEntity').removeAttr('disabled');
            $('input.chosenEntity').removeClass("input-checkbox-disabled");
            $('input.chosenEntity').parent().removeAttr("uk-tooltip");
        }
    }

	/**
	 * Local Functions
	 */
    onCheckBoxChecked(e,cell) {
        cell.getData().checked = e.target.checked;
        let rows = this.tabulator.getRows('active');
        let selectedItems = this.tabulator.getData().filter(e=>e.checked);
        let count = Number($("#count_value").attr("count"));
        if (e.target.checked) {
            count++;
        }else {
            count--;
        }
        let checkedDisabled = "";
        if (count === 3) {
            checkedDisabled = true;
        } else {
            checkedDisabled = false;
        }
        this.setState({
            checkedDisabled: checkedDisabled,
            cell: cell
        }, () => {
            this.disableCheckboxesOnClick();
        })

        //   document.getElementById(this.props.id+"_counter").innerHTML ="<p class='uk-margin-xlarge-right'>" + selectedItems + " of 10 Selected </p>";
        //  document.getElementById(this.props.id+"_counter").classList.add("uk-flex", "uk-float-left");  
        cell.getData().checked = e.target.checked;
        this.addFooterText(rows,selectedItems);
        // if(this.checkedDisabled === false){
            this.props.saveChosenEntities(false, cell,e.target.checked);
        // }
        this.checkedDisabled = checkedDisabled;
    }

	resizeContainer() {
        let container = "";
        if(this.props) {    //this function is called on window resize, there would be no props
            container = this.props.isDrilling ? $('#table-drill-list') : $('#table-list'+this.props.widgetId);
        } else if($('#table-drill-list').length) {
            container = $('#table-drill-list');
        } else {
            container = $('#table-list');
        }
        var offset = container?.offset()?.top || 0;
        var windowHeight = $(window).height();
        let height =  windowHeight - offset;
        if (this.isSecondDimension) {
            var filterHeader = $(".second-dimension-header-container").height() ? $(".second-dimension-header-container").height() : 0;
            var header = $(".header").height() ? $(".header").height() : 0; //header filter is visible only in second dimension

            height = windowHeight - header - filterHeader - 15; //limit the height of second dimension lists
        }
		if(container.length && height > 0 && (this.props && !this.props.isDashBoards)) {
            if (this.props.fromExpanding) {
                container.height(0);
            } else {
                container.height(height);
            }
        }
        this.containerResized = true;
    }

	addResizeContainer() {
		$(window).on("resize", this.resizeContainer);
		this.resizeContainer();
    }
    
	removeResizeContainer() {
		$(window).off("resize", this.resizeContainer);
	}

    drill=(tier, type)=>{
        let obj = this;
        obj.tabulator.clearFilter(true);   //clear all table filters when data is updating
        $("#periods_comp"+this.props.id).addClass("uk-hidden");
        this.setDrillClickListener(obj.state.cell, tier, obj.props.index, undefined, type);
        if(this.props.displayDefaultColumns) {
          this.props.displayDefaultColumns();
        }
        // this.props.setNextTier(obj.state.e);
        // this.props.submitDrill()
    }

    renderPeriodsDropDown=(e, cell)=>{
        let obj = this;
        obj.state.cell = cell;
         $('[id^="periods_compTable_"]').each(function() { // hiding previous opened components in cards component
            $(this).addClass("uk-hidden");
        });
        obj.showVectorsComp(e, cell);
    }

    getColumnAccessor(colField, groupCol, colTitle) {
        var columnFormatter;
        columnFormatter = function (value, data) {
            if(!Array.isArray(value)){
                return formatValString(value, groupCol.format_type);
            }
            return value;
        }
        return columnFormatter;
    }

    calculateSpace=()=> {
        // Get the div element
        const divElement = document.getElementById(`${"periods_comp" + this.props.id}`);
      
        // Get the bottom position of the div relative to the viewport
        const divBottomPosition = divElement?.getBoundingClientRect().bottom;
      
        // Get the inner height of the window
        const windowHeight = window.innerHeight;
      
        // Calculate the space between the div and the bottom of the window
        const space = windowHeight - divBottomPosition;
              
        return space
    }

    checkboxObj = (cell, _this) => {
        let rowData = cell.getRow().getData();
        let rowNumber = cell.getRow().getPosition();
        cell.getElement().classList.add("dt-body-center","to-hide-on-drill","no-sort");
        cell.getColumn().getElement().classList.add("hidden-column-title"); //keep column title, just hide it from UI
        let objectInArr = returnObjectsInArray(copyObjectValues(_this.props.profitStackSelectedEntities), cell.getRow().getData());
        let paginationCheck = !!objectInArr;
        var checkbox = createCheckbox();
        checkbox.checked = rowData.checked || paginationCheck;
        checkbox.name='chosenEntity';
        checkbox.id = (_this.props.isDrilling ? 'drillcheckbox_icon_'+rowNumber : 'checkbox_icon_'+rowNumber);
        // checkbox.style.display = rowData.checked || paginationCheck?"":'none';
        checkbox.classList.add('chosenEntity','show-on-hover');
            checkbox.onchange =(e)=> {
                // obj.props.saveChosenEntities(false, cell, this.checked);
                _this.onCheckBoxChecked(e,cell);
            };
        return checkbox;
    }

    setCloseDrillContainer = (clickFunction) =>{
        let closeIconContainer = document.createElement("div");
        closeIconContainer.classList.add("close_drill_icon");
        closeIconContainer.onclick = clickFunction;
        return closeIconContainer
    }

    expandCollapseColumn = () => {
        let _this = this;
        let elements = document.querySelector("#Card_Wrapperdiv" + this.props.index).querySelectorAll('div[tabulator-field$="_combination"]')
        elements.forEach((element) => {
            let isEntityCount = element.getAttribute("tabulator-field").includes(SUFFIX.ENTITY_COUNT);
            let isFrozenLeftColumn = element.classList.contains("frozenLeftColumn");
            if (!isFrozenLeftColumn && !isEntityCount) {
                element.classList.toggle("collapsed_drill_column");
                if (element.classList.contains("collapsed_drill_column")) {
                    _this.drillColumnCollapsed = true;
                } else {
                    _this.drillColumnCollapsed = false;
                }
            } else {
                element.classList.toggle("drill_column_open");
            }
        });
    }

    createVectorColumnContent = (cell, obj, vectorCombination, div, cellData, notFirstEntity)=> {
        let vectorCols = vectorCombination;
        let expandCollapseDiv = document.createElement("div");
        expandCollapseDiv.classList.add("expand_collapse_drill");
        expandCollapseDiv.onclick = function (e) {
            obj.expandCollapseColumn();
        };
        let vectorColsDiv = document.createElement("div");
        let numbDiv = document.createElement("div");
        numbDiv.classList.add("uk-display-inline-flex", "uk-flex-middle", "text-ellipsis");
        let isMainVector = [PSL_RETURN_NAMES.NAME, PSL_RETURN_NAMES.NUMBER, PSL_RETURN_NAMES.QUADRANT_TIER, SELECTED_VECTOR_VIEWS.QUADRANT_TIER, PSL_RETURN_NAMES.QUADRANT, PSL_RETURN_NAMES.ENTITY_COUNT, PSL_RETURN_NAMES.COUNT_PER_SELECTION, PSL_RETURN_NAMES.COUNT_PER_TOTAL].includes(cell.getField().replace("_"+COMBINATION_FIELD,""));
        if (notFirstEntity) {
            if(isMainVector){
                div.classList.add("main_vector_not_first_entity");
            }
        }
        
        if(!obj.props.isDashBoards && isMainVector && obj.props.hasDrillOption) {
            let isDuplicated = obj.tabulator.getColumns()?.filter(e => e.getField()?.includes(COMBINATION_FIELD))?.indexOf(cell.getColumn()) > 0;
            if(!isDuplicated && !notFirstEntity){
                let isEntityCountVectorOnly = obj.props.data?.otherSelectedVectorsData?.find(f => f.find(e=>!e.skipInFilter));
                if(obj.props.index === 0){
                    div.appendChild(obj.setCloseDrillContainer(() => {
                        obj.props.exitDrill();
                    }));
                    div.classList.add("drill-card-combination-div", "uk-flex-middle");
                    if (obj.props.data.otherSelectedVectorsData.length > 0 && isEntityCountVectorOnly) {
                        div.appendChild(expandCollapseDiv);
                    }
                } else if(obj.props.index > 0){
                    div.appendChild(obj.setCloseDrillContainer(() => {
                        if(obj.props.resetDrillVectorType && typeof obj.props.resetDrillVectorType === "function"){
                            obj.props.resetDrillVectorType();
                        }
                        obj.setDrillClickListener(cell, cellData.tier_key.replace(/ /g,''), obj.props.index-1, true, getRowVectorType(cellData));
                    }));
                    if (obj.props.data.otherSelectedVectorsData.length > 0 && isEntityCountVectorOnly) {
                        div.appendChild(expandCollapseDiv);
                    }
                    div.classList.add("combination-div", "uk-flex-middle");
                } else {
                    div.appendChild(document.createElement("div"));
                    div.appendChild(obj.checkboxObj(cell, obj));
                    div.classList.add("combination-div", "uk-flex-middle");
                }
            }
        }
    
        let nameValue;
        let numberValue;
    
        if(!vectorCols.find(e => e.includes(PSL_RETURN_NAMES.ENTITY_COUNT))){
            for(let col of vectorCols) {
                let number = cellData[PSL_RETURN_NAMES.NUMBER] || "";
                let name = cellData[PSL_RETURN_NAMES.NAME] || "";
                if(col.includes(PSL_RETURN_NAMES.NAME) && (cellData[col] || name)) {
                    nameValue = cellData[col] || name;
                } else if (col.includes(PSL_RETURN_NAMES.NUMBER) && (cellData[col] || number)) {
                    numberValue = cellData[col] || number;
                }
            }
        }
    
        let nameDiv = document.createElement("div");
        if (nameValue) {
            nameDiv.innerHTML = nameValue;
            nameDiv.classList.add("vector-name-col");
            vectorColsDiv.appendChild(nameDiv);
        }
    
        for (let col of vectorCols) {
            let quadrant = cellData[PSL_RETURN_NAMES.QUADRANT] || "";
            let quadranttier = cellData[PSL_RETURN_NAMES.QUADRANT_TIER] || "";
    
            let isNA = quadrant === lang.N_A || cellData[col] === lang.N_A;
            if(isNA) {
                if((!!nameValue || !!numberValue)) {
                    continue;
                }
            }
    
            if ((col.includes(PSL_RETURN_NAMES.QUADRANT_TIER) || col.includes(SELECTED_VECTOR_VIEWS.QUADRANT_TIER)) && (cellData[col] || quadranttier)) {
                numbDiv.innerHTML += formatValHTML((cellData[col] || quadranttier), FormatTypes.QUADRANT);
            } else if (col.includes(PSL_RETURN_NAMES.QUADRANT) && (cellData[col] || quadrant)) {
                numbDiv.innerHTML += formatValHTML((cellData[col] || quadrant), FormatTypes.QUADRANT);
            }
        }
    
        if (numberValue && numberValue !== nameValue) {
            let span = document.createElement("span");
            span.innerText = numberValue;
            span.classList.add("grey-text");
            if(numbDiv.childNodes.length === 1){
                span.classList.add("uk-margin-xsmall-left");
                span.classList.add("text-ellipsis");
                span.style.display = "inline-block";
            }
            numbDiv.appendChild(span);
        }
    
        if(!numberValue && !nameValue) {
            let countDiv = document.createElement("div");
            let sortedVectorCols = vectorCols.sort().reverse();
            for (let col of sortedVectorCols) {
                let colValue = cellData[col];
                if(!col.includes(PSL_RETURN_NAMES.QUADRANT)) {
                    if(col.includes(PROFILE_COLUMN.VIEW_OPTIONS.COUNT_PER_SELECTION)) {
                        colValue = colValue || cellData[PROFILE_COLUMN.VIEW_OPTIONS.COUNT_PER_SELECTION];
                        countDiv.classList.add("vertical-left-border");
                        countDiv.style.display = "inline-grid";
                    } else {
                        colValue = colValue || cellData[PROFILE_COLUMN.VIEW_OPTIONS.ENTITY_COUNT];
                    }
                    countDiv.classList.add(col + "_span");
                    let formatType = obj.props?.data?.columns?.find(f => f.field === col)?.format_type;
                    countDiv.innerHTML = formatValHTML(colValue, formatType);
                    numbDiv.appendChild(countDiv);
                    numbDiv.classList.add("uk-flex-middle");
                    countDiv = document.createElement("div");
                }
            }
        }
    
        if(numbDiv.childNodes.length > 0){
            vectorColsDiv.appendChild(numbDiv);
        }
    
        if(nameDiv.childNodes.length > 0 && numbDiv.childNodes.length > 0) {
            div.classList.remove("uk-flex-middle");
        }
        vectorColsDiv.classList.add("vector-cols-div");
        return vectorColsDiv;
    }
    

    getColumnFormatter(colName, colType, vectorCombination) {
        var obj = this;
        var columnFormatter;
            switch(colName){
                    case COMBINATION_FIELD:
                        columnFormatter = function(cell) {
                            let div = document.createElement("div");
                            if(Object.values(cell.getData()).find(e=>Array.isArray(e))){
                                for(let i=0; i < Object.values(cell.getData()).find(e=>Array.isArray(e)).length; i++){
                                    let tempCellData = copyObjectValues(cell.getRow().getData());
                                    Object.keys(tempCellData).forEach(e=>{
                                        if(Array.isArray(tempCellData[e])){
                                            tempCellData[e] = tempCellData[e][i];
                                        }
                                    })
                                    let tempDiv = document.createElement("div");
                                    div.classList.add("vector-column-div");
                                    let vectorColsDiv = obj.createVectorColumnContent(cell, obj, vectorCombination, tempDiv, tempCellData, i>0);
                                    tempDiv.appendChild(vectorColsDiv);
                                    div.appendChild(tempDiv);
                                } 
                                return div;
                            }else{
                                let vectorColsDiv = obj.createVectorColumnContent(cell, obj, vectorCombination, div, cell.getRow().getData());
                                div.appendChild(vectorColsDiv);
                                return div;
                            }
                        };
                        break;
                case "Vector":
                    columnFormatter = function(cell, formatterParams) {
                        let rowData = cell.getRow().getData();
                        //add className "tier" to vector column to be retrieved on drilling
                        cell.getElement().classList.add("tier");
                        cell.getElement().setAttribute("value", rowData["tier_key"]);
                        return cell.getValue();
                    };
                    break;
                
                case "Drill Back":
                    columnFormatter = function(cell) {
                        let rowData = cell.getRow().getData();
                        if(cell.getRow().getPosition(true) < cell.getTable().getRows().length -1) {
                            var drillBackIcon = document.createElement("img");
                            drillBackIcon.src ='/images/drill-back.png';
                            drillBackIcon.classList.add('drill-back');
                            drillBackIcon.alt = 'Drill Back';
                            drillBackIcon.title = 'Click to Drill Back';
                            drillBackIcon.onclick = function(e){
                                if(typeof obj.props.drillBack === "function") {
                                    obj.props.drillBack(cell);
                                }
                            }
    
                            return drillBackIcon;
                        }
                        return "";
                    }
                    break;                 
    
                    default:
                        columnFormatter = function(cell, formatterParams) {
                            if (colName !== "" && replaceSpecialCharacters(colName.trim().toLowerCase()) !== "") {
                                cell.getElement().classList.add(replaceSpecialCharacters(colName.trim().toLowerCase()));    //just add the title name as a class
                                cell.getColumn().getElement().classList.add(replaceSpecialCharacters(colName.trim().toLowerCase()));    //just add the title name as a class
                            }                       
                            return formatValHTML(cell.getValue(), colType);
                        }
                        break;
                }
            // }
            return columnFormatter;
    }

    showVectorsComp=(evt,cell)=> {
        let _this = this;
        $("#"+this.props.parentId+" .div-periods-drop-down").animate({ scrollTop: 0 }, "fast")
        $("#periods_comp"+this.props.id).removeClass("uk-hidden");
        // $("#periods_comp"+this.props.id).css({
        //     top: $(evt.currentTarget).offset().top - ($("#periods_comp"+_this.props.id).height()/2) + 135 + $("#periods_comp"+_this.props.id).height() > $(document).outerHeight() ?
        //         $(document).outerHeight() - $("#periods_comp"+_this.props.id).height()
        //         :($(evt.currentTarget).offset().top + $(".tabulator-col").height()),
        //     left:$(evt.currentTarget).offset().left
        // });
        
        if(_this.periodRef){
            _this.periodRef.refreshSearch();
            $('[id^="filterText"]').each(function() { // hiding previous opened components in cards component
                $(this).focus();
            });
        }
        let rowNumber = cell.getRow().getPosition();
        $("#drill_icon_"+_this.props.parentId+"_"+rowNumber).css("display", "block");// show drill icon on clicked cell
    }

    getColumns(columns) {
        columns = copyObjectValues(columns) || [];
        let _this = this;
        //add the drill icon and checkbox input
        if(this.props.parentTableReport !== "drill-table-top" && !this.props.isDashBoards) { 
            // columns.unshift(drillCellObject);       //add the drill button before the checkbox
        } else if(!this.props.isDashBoards) {
            //if it is Table_Top, add the Vector column in the beginning
            // columns.unshift(drillCellObject);       //add the drill button before the checkbox
            // columns.unshift(tierCellObject);
            columns.push(drillBackColumn);
        }

        columns.forEach(col => {
            col.headerSort = _this.props.isDashBoards ? false : col.headerSort;
            col.dontFilter = true;
            col.visible = col.profitListDisplay;
            col.hozAlign = true;
            col.formatter = this.getColumnFormatter(col.title, col.format_type);
            if(!["Drill"].includes(col.title)){
                col.titleDownload = col.title;
            }
        });
        return columns;
    }

    getDefaultSorter(columns) {
        var defaultSorter;
        columns.forEach(col => {
            if(col.isDefaultSorter === true) {
                defaultSorter = col.field;
                return false;
            }
        });

        return defaultSorter;
    }

   
    shouldComponentUpdate(nextProps) {
        //used nextProps instead of props to save 1 rendering cycle
        var shouldUpdate = false;
        shouldUpdate = nextProps.data !== null && nextProps.data.data !== undefined && this.props.data && this.props.data.data && JSON.stringify(this.props.data.data) !== JSON.stringify(nextProps.data.data);
        shouldUpdate = shouldUpdate || (!!nextProps.drillListDataId && this.props.drillListDataId !== nextProps.drillListDataId);
        shouldUpdate = shouldUpdate  || nextProps.isDrilling !== this.props.isDrilling;  //if same data, check isDrilling prop
        shouldUpdate = shouldUpdate || (!!nextProps.listDataId && this.props.listDataId !== nextProps.listDataId);
        shouldUpdate = shouldUpdate || (nextProps.id && nextProps.id.startsWith("Table_Top"));
        shouldUpdate = shouldUpdate || (nextProps?.parentId === "To_Scroll_List_Drill" && (nextProps.sorter !== this.props.sorter || nextProps.order !== this.props.order));
        shouldUpdate = shouldUpdate || this.props.isDashBoards
        return shouldUpdate || false;
    }

    /**
     * function display the column name as empty in drill and checkbox columns 
     * @param {*} cell 
     * @param {*} params 
     * @returns 
     */
    addEmptyTitleFormatter=(cell)=>{
        let div = document.createElement("div");
        div.innerHTML = "";
        return div;
    }

    getSnapshotBeforeUpdate(prevProps) {
        let columns = "";
        if(!this.props.data || this.props.data?.columns?.length === 0) {
            return null;
        }
        let columnsUsed = this.props.data.columns; //from api
        if (!prevProps.columnFiltering) {
            columns = this.getColumns(columnsUsed);
        } else {
            columnsUsed.forEach(col=>{
                col.dontFilter = true;
            })
            columns = cleanUpTabulatorColumns(columnsUsed, this.props.data.chunkData, this.refreshFilterDivs, this.getTabulator, { id: this.props.id });
            if (prevProps.parentTableReport !== "drill-table-top" && !prevProps.isDashBoards && !prevProps.isLandscapeBubble) {
                // columns.unshift(drillCellObject);       //add the drill button before the checkbox
            } else if (!prevProps.isDashBoards && !prevProps.isLandscapeBubble) {
                // columns.unshift(drillCellObject);       //add the drill button before the checkbox
                // columns.unshift(tierCellObject);
            }
            // columns = this.getColumns(columnsUsed);
        }

        return columns;
    }

    sortVectorCombinationArray = (vectorCombinationArray) => {
        vectorCombinationArray.forEach(vector => {
            if (vector.includes(SORT_TITLES.VALUE.NAME) || vector.includes(SORT_TITLES.VALUE.NUMBER)) {
                vectorCombinationArray.sort()
            } else {
                vectorCombinationArray.sort().toReversed();
            }
        })
        return vectorCombinationArray;
    }

    appendSortAZContainer = (div, column) => {
        let columnCombinations = this.sortVectorCombinationArray(column?.vectorCombination);
        let tier = this.props.isDrilling ? $("#Next_Tier").text() : this.props.tier;
        let selectedVector = this.props.vectors.filter(f => f.value === tier)[0];
        let isSelectedVectorFieldGenerated = selectedVector?.generated;

        for(let type of columnCombinations) {
            if (!isSelectedVectorFieldGenerated && type.includes(PROFILE_COLUMN.VIEW_OPTIONS.QUADRANT) && COMBINATION_ARRAY.includes(column.field)) {
                continue;
            }
            if (!type.includes(SORT_TITLES.VALUE.COUNT_PER_SELECTION)) {
                this.addSortType(div, type, column)
            }
        }
    }

    addSortType = (div, type, column) => {
        if (type.includes(SORT_TITLES.VALUE.ENTITY_COUNT)) {
            this.appendHighLowSortButtons(div, type, true, column);
        } else {
            this.appendSortAZButtons(div, type, column)
        }
    }


    setSortSectionTitle = (type) => {
        let sortSectionTitle;
        if (type.includes(SORT_TITLES.VALUE.NAME)) {
            sortSectionTitle = SORT_TITLES.TITLE.NAME;
        } else if (type.includes(SORT_TITLES.VALUE.NUMBER)) {
            sortSectionTitle = SORT_TITLES.TITLE.NUMBER
        } else if (type === SORT_TITLES.VALUE.ENTITY_COUNT) {
            sortSectionTitle = SORT_TITLES.TITLE.ENTITY_COUNT
        } else {
            sortSectionTitle = SORT_TITLES.TITLE.SEGMENT_TIER
        }
        return sortSectionTitle;
    }

    appendSortAZButtons = (div, type, column) => {
        let container = document.createElement("div");
        container.classList.add("sort_section");
        let span = document.createElement("span");
        span.classList.add("sort_title");
        span.textContent = "By " + this.setSortSectionTitle(type);
        let azSort = getTableButton(lang.sort_titles.sort_a_z, [], ["justify-content-start"], ["sort_a_z"], "left");
        azSort.style.width = "100%";
        azSort.onclick = ()=>{
            this.props.setTabulatorSortField(column.field);
            this.props.setOrder("asc", type);
            this.tabulator.setSort(type, "asc");
        }
        let zaSort = getTableButton(lang.sort_titles.sort_z_a, [], ["justify-content-start"], ["sort_z_a"], "left");
        zaSort.style.width = "100%";
        zaSort.onclick = ()=>{
            this.props.setTabulatorSortField(column.field);
            this.props.setOrder("desc", type);
            this.tabulator.setSort(type, "desc");
        }
        container.appendChild(span);
        container.appendChild(azSort);
        container.appendChild(zaSort);
        div.appendChild(container);
    }

    appendHighLowSortButtons = (div, field, forCombination, column) => {
        let container = document.createElement("div");
        container.classList.add("sort_section");
        if(forCombination){
            let span = document.createElement("span");
            span.classList.add("sort_title");
            span.textContent = "By " + this.setSortSectionTitle(SORT_TITLES.VALUE.ENTITY_COUNT);
            container.appendChild(span);
        }
        let descSortBtn = getTableButton("High to Low", [], ["justify-content-start"], ["sort_high_low"], "left");
        descSortBtn.style.width = "100%";
        descSortBtn.onclick = ()=>{
            let tabulatorSortField = forCombination ? column.field : field;
            this.props.setTabulatorSortField(tabulatorSortField);
            this.props.setOrder("desc", field);
            this.tabulator.setSort(field, "desc");
        }
        let ascSortBtn = getTableButton( "Low to High", [], ["justify-content-start"], ["sort_low_high"], "left");
        ascSortBtn.style.width = "100%";
        ascSortBtn.onclick = ()=>{
            let tabulatorSortField = forCombination ? column.field : field;
            this.props.setTabulatorSortField(tabulatorSortField);
            this.props.setOrder("asc", field);
            this.tabulator.setSort(field, "asc");
        }
        container.appendChild(descSortBtn);
        container.appendChild(ascSortBtn);
        div.appendChild(container);
    }

    createDotsDropdownContainer = (value, column) => {
        let dropdownContainer = document.createElement("div");
        dropdownContainer.id = "dropDown_" + this.props.id + "_" + column.field;
        dropdownContainer.classList.add("header-column-dropdown", "uk-dropdown");
        if(column.fieldName === "combination") {
            this.appendSortAZContainer(dropdownContainer, column)
        } else {
            this.appendHighLowSortButtons(dropdownContainer, column.field);
        }
        return dropdownContainer;
    }

    createDotsButtonDropdown = (value, column) => {
        let dropdownContainer = this.createDotsDropdownContainer(value, column);
        let dotsButtonContainer = document.createElement("div");
        dotsButtonContainer.classList.add("uk-inline");
        dotsButtonContainer.classList.add("dots-button-container");
        let dotsButton = document.createElement("button");
        dotsButton.classList.add("uk-button-icon", "list-dots-button");
        dotsButton.setAttribute("type", "button");
        dotsButton.addEventListener("click", (e) => {
            $(".header-column-dropdown").removeClass("uk-open");
            $(".list-dots-button").removeClass("uk-open");

            document.getElementById("dropDown_" + this.props.id + "_" + column.field).classList.toggle("uk-open");
            document.getElementById("sorting_" + this.props.id + "_" + column.field).classList.toggle("uk-open");

            let buttonRect = dotsButton.getBoundingClientRect();
            let dropdown = document.getElementById("dropDown_" + this.props.id + "_" + column.field);
            let dropdownWidth = dropdown.offsetWidth;
            let leftMenuWidth = document.getElementsByClassName("left-hand-menu")[0].offsetWidth;

            // Check if the dropdown should open on the left or right
            let left = buttonRect.left - leftMenuWidth;
            if (buttonRect.left + 50 > window.innerWidth - leftMenuWidth) {
                // Open dropdown on the right if there's not enough space on the left
                dropdown.style.left = (left - dropdownWidth + dotsButton.offsetWidth - 10) + "px";
            } else {
                dropdown.style.left = left + "px";
            }
        });

        let icon = document.createElement("i");
        icon.classList.add("fa-1x", "fal", "fa-ellipsis-v", "icon_color");
        dotsButton.appendChild(icon);
        dotsButton.id = "sorting_" + this.props.id + "_" + column.field;
        dotsButtonContainer.appendChild(dotsButton);
        dotsButtonContainer.appendChild(dropdownContainer);

        dotsButtonContainer.classList.add("hidden-column"); // hide sort dots when rendering the tabulator
        return dotsButtonContainer;
    }

    headerTitleFormatter = (cell, column) => {
        let colHeaderDiv = document.createElement("div");
        let colTitleDotsContainer = document.createElement("div");
        colTitleDotsContainer.classList.add("title_dots_container");
        // let textAlign = column.format_type !== FormatTypes.TEXT ? "end" : "start";
        colTitleDotsContainer.style.textAlign = "start";

        let title = document.createElement("span");
        let isDrillList = this.props.isDrilling && (this.props.index > 0 || this.props.parentId === "To_Scroll_List_Drill");
        title.textContent = isDrillList && column.cssClass?.includes("frozenLeftColumn")? lang.drilled_to_text + cell.getValue() : cell.getValue();
        title.classList.add("column-title");

        if (this.props.isDrilling && this.props.index !== 0) {
            title.classList.add("drilled-col-title");
        }

        colHeaderDiv.classList.add("column-title-div")
        colTitleDotsContainer.appendChild(title);
        colHeaderDiv.appendChild(colTitleDotsContainer);

        if (!this.props.isDashBoards && this.props.parentId !== "To_Scroll_Top") {
            let dotsDiv = this.createDotsButtonDropdown(cell.getValue(), column);
            dotsDiv.classList.add("faded-background")
            colHeaderDiv.appendChild(dotsDiv);
        }

        colHeaderDiv.classList.add("column-header-height");
        return colHeaderDiv;
    }

    /**
     * Calculates and sets the width, minWidth, and maxWidth for a given column based on its content and title.
     * @param {Object} col - The column object containing metadata about the column.
     */
    getColumnWidths = (col) => {
        let chunkData = this.props?.data?.chunkData || this.props?.data?.data;
        if (col?.cssClass === "hidden-column" || !col?.visible || !chunkData) {
            return;
        }
        let colField = col.field;
        let formattedColsWithoutName = [];
        let shouldAddSegmentPadding = false;

        let columnValues = this.getFormattedColumnsArrayOfStrings(chunkData, col);
        // this condition is used when we have name + other combinations selected. It adds to columnValues other combinations to check if they are longer than the name
        if(col?.vectorCombination?.find(f => f.includes(PSL_RETURN_NAMES.NAME))) {
            formattedColsWithoutName = this.getFormattedColumnsArrayOfStrings(chunkData, col, true);
            columnValues = columnValues.concat(formattedColsWithoutName);
        }

        const hasExpandIcon = this.props.data.otherSelectedVectorsData?.length > 0;
        const isSelectedVectorCol = col.cssClass?.includes("frozenLeftColumn");
        const isFirstDrillCard = this.props.index === 0;
        const isDrillCard = this.props.index !== undefined;
        const fontSize = convertViewportToPx(14);
        const font = `${fontSize}px Lato`;
        const totalPadding = convertViewportToPx(38);
        const dotsWidth = isDrillCard || this.props.isDashBoards ? 0 : convertViewportToPx(12);
        const isColSelectedVector = COMBINATION_ARRAY.includes(colField);
        const gapWidth = convertViewportToPx(12); // gap between checkbox and vector values
        const expandIconWidth = isDrillCard && hasExpandIcon && isSelectedVectorCol ? convertViewportToPx(8) : 0;

        let longestString = findLongestStringByTextWidth(columnValues, font);
        // this flag is used when we have tier + number longer than the name. In this case we add the segment div padding on the width
        shouldAddSegmentPadding = formattedColsWithoutName.includes(longestString);

        // Define static needed values to calculate width correctly
        let selectedVectorColPadding = isColSelectedVector ? convertViewportToPx(isFirstDrillCard ? 35 : 65) : 0;
        selectedVectorColPadding = !this.props.hasDrillOption ? 0 : selectedVectorColPadding; // remove padding when in landscape/geography

        let title = !this.props.isDrilling || this.props.index === 0 || !col.cssClass?.includes("frozenLeftColumn") ? col.title : lang.drilled_to_text + col.title;
        let contentWidth = getTextWidth(longestString, font) + totalPadding;
        let titleWidth = getTextWidth(title, font) + (isDrillCard ? convertViewportToPx(35) : totalPadding) + dotsWidth;// calculate the title width with the padding and dots width
        let savedColumnWidths = this.getSavedColumnsWidth();

        const selectedVectorTitleWidth = titleWidth + selectedVectorColPadding + 10; // if it's the selected vector, we should take into consideration the padding added to its title
        
        // If column was resized, its width will be saved in state. If found in state then use it, else do the column width logic
        if (savedColumnWidths?.map(m => m.colField).includes(colField)) {
            let currCol = savedColumnWidths.find(f => f.colField === colField);
            col.width = currCol?.width;
        } else {
            if (col.fieldName === COMBINATION_FIELD) {
                const defaultVectorColWidth = convertViewportToPx(250) + expandIconWidth; // default vector column width, defined by UI/UX.
                col.width = defaultVectorColWidth < selectedVectorTitleWidth ? selectedVectorTitleWidth : defaultVectorColWidth; 
            } else {
                let width = contentWidth;
                let maxWidth = Math.max(width, titleWidth); // Max width is the maximum of width and titleWidth
                let minWidth =  width < convertViewportToPx(60) ? convertViewportToPx(60) : width;
                let columnAppearanceProfile = this.props.mainListManageProfile || this.props.drillCardProfile;
                col.width = columnAppearanceProfile?.column_appearance === EXPANDED ? maxWidth :  minWidth;
                if(!this.props.useAppearanceList) {
                    col.width = maxWidth;
                }
            }
        }
        if (col.fieldName === COMBINATION_FIELD) {
            let segmentDivPadding = 0;
            if((!col.vectorCombination.find(f => f.includes(PSL_RETURN_NAMES.NAME)) || shouldAddSegmentPadding) && col.vectorCombination.find(f => f.includes(PSL_RETURN_NAMES.QUADRANT))) {
                segmentDivPadding = convertViewportToPx(18);
            }
            let minWidth = isColSelectedVector ? convertViewportToPx(175) + expandIconWidth : convertViewportToPx(113); // min vector column width, defined by UI/UX. Other vectors's width is calculated without checkbox
            let maxWidth = contentWidth + selectedVectorColPadding + gapWidth + segmentDivPadding + expandIconWidth;
            maxWidth = Math.max(maxWidth, minWidth);
            maxWidth = Math.max(maxWidth, selectedVectorTitleWidth);
            col.maxWidth = maxWidth;
            col.minWidth = minWidth;
        } else {
            let width = contentWidth;
            let maxWidth = Math.max(width, titleWidth); // Max width is the maximum of width and titleWidth
            col.maxWidth = maxWidth;
            col.minWidth = width < convertViewportToPx(60) ? convertViewportToPx(60) : width; // prevent user from minimizing more than 60px so that they don't hide the whole title header
        }
    }

    /**
     * Formats the columns of chunk data into an array of strings based on the column configuration.
     * 'removeName' param is used to remove 'name' because we already got all formatted names and
     * we want to check the length of other combination fields if they are longer than the name. This is used for column width calculationsfilter(f => !f.includes("name")filter(f => !f.includes("name").
     *
     * @param {Array} chunkData - The data to be processed, typically an array of objects.
     * @param {Object} col - The column configuration object.
     * @param {Boolean} removeName - This flag is used to remove the 'name' field from vector combination array
     * @returns {Array<string>} - An array of formatted string values based on the column configuration.
     */
    getFormattedColumnsArrayOfStrings = (chunkData, col, removeName = false) => {
        const field = col.field.replace(`_${COMBINATION_FIELD}`, "");
        const originalCombinations = copyObjectValues(col.vectorCombination);

        let combinations = col.vectorCombination;
        combinations = removeName ? combinations.filter(f => !f.includes(PSL_RETURN_NAMES.NAME)) : combinations;
        
        const fontSize = convertViewportToPx(14);
        const font = `${fontSize}px Lato`;

        const formatVectorColumn = (item) => {
            const { QUADRANT_TIER, QUADRANT, ENTITY_COUNT, COUNT_PER_SELECTION, NAME, NUMBER } = PSL_RETURN_NAMES;
            
            // these are used for the selected vector duplicate column, because we don't have the duplicate column's data becuase it's the same as selected vector data.
            const segmentTier = item[QUADRANT_TIER];
            const segment = item[QUADRANT];
            const entityCount = item[ENTITY_COUNT];
            const countPerSelection = item[COUNT_PER_SELECTION];
            const name = item[NAME];
            const number = item[NUMBER];
            
            const hasEntityCountCombo = combinations.some(comb => comb.includes(ENTITY_COUNT));
            const hasNameCombo = combinations.some(comb => comb.includes(NAME));
            const hasNumberCombo = combinations.some(comb => comb.includes(NUMBER));

            if (hasEntityCountCombo) { // if combination has segment/tier - entity count - count per selection
                return combinations.map(comb => {
                    let fieldValue, formatType;
                    
                    if (comb.includes(QUADRANT)) {
                        formatType = FormatTypes.QUADRANT;
                        fieldValue = item[comb] || (comb.includes(QUADRANT_TIER) ? segmentTier : segment);
                    } else if (comb.includes(COUNT_PER_SELECTION)) {
                        formatType = this.getFormatType(comb, COUNT_PER_SELECTION);
                        fieldValue = item[comb] || countPerSelection;
                    } else {
                        formatType = this.getFormatType(comb, ENTITY_COUNT);
                        fieldValue = item[comb] || entityCount;
                    }

                    return formatLongestValString(fieldValue, formatType, undefined, font);
                }).join(" ");
            } 
            
            if (hasNameCombo) { // if combination has name selected, then we care about name length only, even if other options are on
                return formatLongestValString(item[field] || name, col.format_type, undefined, font);
            }
            
            if (hasNumberCombo) {  // if combination only has number - segment/tier selected
                return combinations.map(comb => {
                    let isSegmentNA = item[comb] ? item[comb] === lang.N_A : (segmentTier === lang.N_A || segment === lang.N_A);
                    let isSegmentTier = comb.includes(QUADRANT_TIER) || comb.includes(PROFILE_COLUMN.VIEW_OPTIONS.QUADRANT_TIER);
                    let fieldValue = "";
                    if(comb.includes(QUADRANT)) { // in case we have N/A, we should not include it in the width calculation because we do not display it in the table
                        fieldValue = !isSegmentNA ? item[comb] || (isSegmentTier ? segmentTier : segment) : "";
                    } else {
                        fieldValue = item[comb] || number;
                        // if the name and number are equal, the number is not displayed next to segment, so ignore it.
                        if(originalCombinations.includes(NAME) && item[field] === fieldValue) {
                            fieldValue = "";
                        }
                        
                    }

                    return formatLongestValString(fieldValue, comb.includes(QUADRANT) && !isSegmentNA ? FormatTypes.QUADRANT : FormatTypes.TEXT, undefined, font);
                }).join(" ").trimEnd();
            }
             
            const fieldValue = field.includes(QUADRANT_TIER) ? segmentTier : segment;
            return formatLongestValString(fieldValue, FormatTypes.QUADRANT, undefined, font);
        };

        const formatOtherColumns = (item) => formatLongestValString(item[field], col.format_type, undefined, font);

        const columnValues = chunkData.map(item => 
            col.vectorCombination ? formatVectorColumn(item) : formatOtherColumns(item)
        );

        return columnValues;
    };

    /**
     * Helper function to get format type.
     */
    getFormatType = (comb, fallbackField) => {
        return this.props?.data?.columns?.find(f => f.field === comb)?.format_type ||
            this.props?.data?.columns?.find(f => f.field === fallbackField)?.format_type;
    };

    /**
     * Finds the saved column widths.
     * @returns columns widths array
     */
    getSavedColumnsWidth = () => {
        let savedColumnWidths = this.props.columnsWidths;
        if(this.props.isDrilling) {
            let index = this.props.index;
            const isDrillCard = index !== undefined; // this is instead of 'this.props.index' because when index is 0, it will return false

            if(isDrillCard) { // if drill card, then we need to get the widths of this card by finding its index
                savedColumnWidths = this.props.drillCardWidths?.find(f => f.cardIndex === index)?.widths;
            } else {
                savedColumnWidths = this.props.drillListWidths;
            }
        }

        return savedColumnWidths;
    }

   
    componentDidUpdate(prevProps, prevState, snapshot) {
        let _this = this;
        $("#drillIsClicked").val("0");
        this.checkedDisabled = false;
        if (_this.tabulator.fromProfitInfo) {
            _this.setState({
                checkedDisabled: false,
            })
        }
        _this.tabulator.fromProfitInfo = false;
        var element = document.getElementById(this.props.id+"_footer");
        if(element){
            UIkit.tooltip(element["children"][1]).show(); // each footer is unique , get the child div that contains the tooltip text
        }
        //fill prop data in the state to compare for component update
       
        if (snapshot !== null) {
            var obj = this;

            let recordsLessThanPageSize = this.props.data?.data.length > 0 ? this.tabulator.getPageSize() > this.props?.data?.data[0][_cnt] : true
            let dontSortHeader = !prevProps.headerSort || prevProps.isDashBoards
            let currTabulatorColumns = this.tabulator.getColumns();
            let updateColumns = snapshot.length !== currTabulatorColumns.length;
            let i =0;

            snapshot.forEach(col => {
                col.headerSort = false;
                i++;
                col.visible = col.profitListDisplay;
                col.headerTooltip = true;
                col.hozAlign = true;
               
                if (![FormatTypes.TEXT, FormatTypes.QUADRANT].includes(col.format_type)) {
                    col.tooltip = false;
                }
                if (col.title !== "Drill") {
                    col.titleFormatter = (cell, params) => obj.headerTitleFormatter(cell, col);
                }
                if(col.fieldName === COMBINATION_FIELD) {
                    if (col.field === "name" || col.field === "number" || col.field === "quadranttier" || col.field === "quadrant") {
                        if (_this.drillColumnCollapsed) {
                            col.cssClass = "frozenLeftColumn drill_column_open";
                        } else {
                            col.cssClass = "frozenLeftColumn";
                        }
                    } else {
                        if(_this.drillColumnCollapsed && _this.props.parentId !== "To_Scroll_List_Drill" && _this.props.isDrilling && !col.field.includes("_entity_count")) {
                            col.cssClass = "collapsed_drill_column";
                        }
                    }
                    col.field += "_" + COMBINATION_FIELD;
                    col.download = false;
                    col.formatter = obj.getColumnFormatter(col.fieldName, col.formatter, col.vectorCombination);
                }else if (typeof col.formatter !== "function" || col.title === "Drill") {  //Drill should always be reformatted
                    //some columns will be formatted in cleanUpTabulatorColumns, and shouldn't be
                    //reformatted here, others haven't and should be formatted here
                    col.formatter = obj.getColumnFormatter(col.title, col.formatter, col.field);
                    col.titleFormatter = obj.addEmptyTitleFormatter;
                }else if(col?.field?.includes(PSL_RETURN_NAMES.QUADRANT_TIER) || col?.field?.includes(PSL_RETURN_NAMES.QUADRANT)){
                    col.accessor = obj.getColumnAccessor(col.field, col, col.title); 
                    col.accessorDownloadParams = {obj};
                    col.sorter = segmentsSorter;
                } else {
                    col.sorter = customColumnSorter;
                }
                // if (["Drill", "Check"].includes(col.title)) {
                //    col.titleFormatter = obj.addEmptyTitleFormatter;
                // }
                if (col.field && col.field.includes(_range)) {
                    col.formatter = obj.getColumnFormatter(col.field, col.format_type, col.field);
                }
                if(dontSortHeader){
                    col.headerSort = false;     //remove sorting for table_top
                }
                if (!updateColumns) {
                    if (currTabulatorColumns[i] && col.field !== currTabulatorColumns[i].getDefinition().field) {
                        updateColumns = true;
                    }
                }
                if([PSL_RETURN_NAMES.NAME, PSL_RETURN_NAMES.NUMBER, PSL_RETURN_NAMES.QUADRANT_TIER , SELECTED_VECTOR_VIEWS.QUADRANT_TIER, PSL_RETURN_NAMES.QUADRANT, PSL_RETURN_NAMES.ENTITY_COUNT, PSL_RETURN_NAMES.COUNT_PER_SELECTION, PSL_RETURN_NAMES.COUNT_PER_TOTAL].includes(col.fieldName)) {
                    col.cssClass = "hidden-column";
                }

                if (col?.field) {
                    obj.getColumnWidths(col);
                }
            });
    
            if(this.limitExceeded) {
                if(this.tabulator.options.virtualDomBuffer != 8000){
                    this.tabulator.options.virtualDomBuffer = 8000;
                }
            } else if(this.tabulator.options.virtualDomBuffer != 450){
                this.tabulator.options.virtualDomBuffer = 450;
            }

            this.tabulator.options.ajaxSorting = !recordsLessThanPageSize;
            this.tabulator.setColumns(snapshot);
            
            if(!this.props.isPaginated) { // if paginated, the data will be set when calling ajaxRequestFunc
                this.tabulator.replaceData(this.props.data.data);
            }

            if (this.props.isDrilling && !this.containerResized) {
                this.addResizeContainer();
            }
            if (this.props.isDrilling !== prevProps.isDrilling) {
                this.setState({
                    isDrilling: this.props.isDrilling,
                })
            } else if (this.props.scenario_id !== prevState.scenarioId) {
                this.setState({
                    scenarioId: prevProps.scenario_id
                })
            }
            this.containerResized = false;
            this.addHoverEffectOnResize();
        }
    
    }

    /**
     * Adds hover effects to elements with the class 'tabulator-col-resize-handle' on mouseover and removes them on mouseout.
     * This function handles the fading effect when we hover on the resize handles.
     */
    addHoverEffectOnResize = () => {
        const handles = document.querySelectorAll('#' + this.props.id + ' .tabulator-col-resize-handle');
        handles.forEach(handle => {
            handle.addEventListener('mouseover', (event) => {
                const headerRows = document.querySelectorAll('#' + this.props.id + ' div[tabulator-field="' + $(event.currentTarget.offsetParent).attr("tabulator-field") + '"]');
                // Iterate over each of the selected divs
                headerRows.forEach(headerRow => {
                    // Find all divs with the class tabulator-col-resize-handle within the current grossprofitDiv
                    const resizeHandles = headerRow.querySelectorAll('#' + this.props.id + ' .tabulator-col-resize-handle');
                    // Iterate over each resize handle and perform your logic
                    resizeHandles.forEach(handle => handle.classList.add('hovered'));
                });
            });
            handle.addEventListener('mouseout', (event) => {
                $('#' + this.props.id + ' div[tabulator-field="' + $(event.currentTarget.offsetParent).attr("tabulator-field") + '"] .tabulator-col-resize-handle').removeClass('hovered');
            });
        });
    }

    /**
     * Save drill widths in state when we click on drill.
     * @param {*} cardIndex this is defined only when we re-drill on a card, else its undefined.
     */
    saveDrillWidths = (cardIndex) => {
        let _this = this;
        let drillWidths = [];
        if(cardIndex !== undefined && this.props.drillCardWidths?.length > 0) { // this condition is true we are redrilling on a card
            _this.props.setDrillCardWidthState(copyObjectValues(this.props.drillCardWidths.filter(f => f.cardIndex <= cardIndex)));
            _this.props.setDrillListWidthState(this.props.drillCardWidths.find(f => f.cardIndex === cardIndex)?.widths);
        } else if(!this.props.drillCardWidths || this.props.drillCardWidths?.length === 0) {
            // this condition is true when we are first time drilling. We initiate the states of drill widths
            drillWidths = initDrillColumnWidth(this.props.columnsWidths);
            _this.props.setDrillCardWidthState(drillWidths);
            _this.props.setDrillListWidthState(this.props.columnsWidths);
        } else {
            // when drilling more than once, we save the widths of the new card. The widths are same as the drillList widths.
            drillWidths = copyObjectValues(this.props.drillCardWidths);
            let newDrillCardIndex = drillWidths?.length || 0;
            drillWidths.push({cardIndex: newDrillCardIndex, widths: this.props?.drillListWidths || [] })
            _this.props.setDrillCardWidthState(drillWidths);
        }
    }

    /**
    function called when we click on a vector to drill or when undrilling
    @param cell {object}
    @param tier {var}
    @param cardIndex {var} cardIndex is sent either as index itself or index -1 when undrilling
     */
    setDrillClickListener(cell,tier, cardIndex, undrill, type) {
        let _this = this;

        if(!undrill) {
            this.saveDrillWidths(cardIndex);
        }
        // when drilling, save selected manage profile in session to use it for drill list
        if(_this.props.mainListManageProfile) {
          sessionStorage.setItem(SELECTED_DRILL_LIST, JSON.stringify(_this.props.mainListManageProfile));
        }
        let rowData = _this.props.profitStackSelectedEntities.length>0? _this.props.profitStackSelectedEntities : [cell?.getRow().getData()]; //extract the data of the row from tabulator
        if(_this.props.profitStackSelectedEntities.length>1 && isFunction(_this.props.setMultipleDrillEntitiesSelected)){
            _this.props.setMultipleDrillEntitiesSelected(_this.props.profitStackSelectedEntities);
        }
        toggleLoader(true, "setDrillClickListener");
        setTimeout(() => {
            // Show drill filter
            $('#DrillDown').show();
            // Clear old flagged name to be drilled from top table
            $("#Table_Top").find(".item-to-drill").removeClass("item-to-drill");
            if (cell) {
                _this.props.saveChosenEntities(true, cell, false);
            }
            showDrillItem(this.refs.mainTable);

            if(this.props.isSecondDimension) { // make drill full screen in heat map
                $("#heatMap-list").addClass("full-screen-list");
                $("#heatMap-list #table-list").addClass("full-screen-list-height");
                $("#heatMap-list #table-list").removeClass("half-screen-list");
                $(".heatMap-container").hide();
                $("#hm-third-header").hide();
                $(".expand-close-btns").hide();
            }

            if(this.props.isLandscapeBubble && !undrill) { // make drill full screen in heat map
                $("#expanding-component").addClass("full-height");
                $("#expanding-component #full-screen-list").addClass("full-screen-list");
                $("#expanding-component #table-list").removeClass("half-screen-list");
                $("#bubble-chart-div").hide();
                $(".expand_compress_button").hide();
            }
            
            if (cell) {
                var drillIcon = $(cell.getElement()).find("i");
                var isDrillingFromList = $(drillIcon).hasClass("drillItem");
            }
            
            //only do the following if drilling from main list
            if (isDrillingFromList) {
                //clearing data from Table_Top only when the data of the list changes
                if (!_this.state.isDrilling) {
                    _this.props.clearDrillingData();
                }
                //uncheck all checkboxes
                $(".chosenEntity").prop("checked", false);
                //Update drilling and parent drill tiers
                var nextTier = $("#Next_Tier").text();
                //the main tier to be ignored
                var toIgnoreTier = this.props.tier;
                // var oldTier = $(this).closest("tr").find(".tier").text() || toIgnoreTier;
                var oldTier = toIgnoreTier;
                $("#Old_Tier").text(oldTier);
                hideProfitStackLink();
                if (typeof this.props?.toggleDropDownState === "function")
                    this.props?.toggleDropDownState(true);
                // Set old tier filter if new
                if ((nextTier !== oldTier) && (toIgnoreTier !== oldTier)) {
                    oldTier = nextTier;
                    $("#Old_Tier").text(nextTier);
                }
            }
            // Flagging to drill number
            if (cell) {
                var itemToDrill = $(cell.getRow().getElement());
                itemToDrill.attr("tier", $("#Old_Tier").text());
                //getting the Number of the item being drilled
                var itemToDrillNumber = itemToDrill.find(".number");
                itemToDrillNumber.addClass("item-to-drill");
                const mainNumber = itemToDrillNumber.text();
                var loopedItemTr = "";
                var loopedItemNumber = "";
                //removing all other rows that aren't the one being drilled from the shown table (either list or drill table)
                var drillingClass = isDrillingFromList ? "drillItem" : "drillItemDTable";
                $('.' + drillingClass).each(function () {
                    loopedItemTr = $(this).closest(".tabulator-row");
                    loopedItemNumber = loopedItemTr.find(".number").text();
                    //mainNumber is the number of the drilled item, compared against the number of each looped row
                   // if (loopedItemNumber !== mainNumber) {
                    //     loopedItemTr.remove();
                    // }
                });
                $('#To_Scroll_Top').show();     //show table top
                var tempRowData = rowData;      //extract the data of the row from tabulator
                tempRowData.forEach(e=>{
                    e.tier = $("#Old_Tier").text();       //add tier to the drilled row before adding it to Table_Top
                    e.drillVectorType = type;
                });
                // _this.props.appendDataToTableTop(tempRowData);
                _this.props.addNewCard(tempRowData,function(){
                    setTimeout(function(){
                        _this.props.setNextTier(tier);
                        // _this.props.submitDrill(tier, cardIndex, !cell, undrill);
                        $("#Next_Tier").text(tier);
                        $("#submit_drill").text(JSON.stringify({"cardIndex": cardIndex, "removeDrill": !cell, "undrill":undrill}));
                        _this.props.setDrillTabulatorPageSize(100)
                        toggleLoader(false, "setDrillClickListener");
                    },50)
                    
                });
            }else{
                setTimeout(function(){
                    _this.props.setNextTier(tier);
                    $("#Next_Tier").text(tier);
                    $("#submit_drill").text(JSON.stringify({"cardIndex": cardIndex, "removeDrill": !cell}));
                    _this.props.setDrillTabulatorPageSize(100); 
                    // _this.props.submitDrill(tier, cardIndex, !cell);
                    toggleLoader(false, "setDrillClickListener");
                },50)
            }
            hideDrillItem();        //hiding check boxes and drill icons          
            // Hide not to be used table
            $('#To_Scroll_List').hide();
            // Hide listing table
            $('#To_Scroll_List_Drill').hide();
            _this.props.setIsDrilling(true);
            $("#count_value").attr("count","0")
        }, 10);
    }
    
    onColumnMoved(newColumns, columnMovedField) {
        this.props.onColumnMoved(newColumns, columnMovedField);
        
    }
    
    ajaxRequestFunc = (url, config, params) => {
        let _this = this;

        return new Promise((resolve, reject) => {
            const callback = (data) => {
                const response = {
                    "last_page": Math.ceil(data[0]?.cnt / params.size),
                    "data": data,
                    "totalRecords": Number(data[0]?.cnt)
                }
                resolve(response);
            }

            let afterDrilling = $("#after_drilling").text() !== "" ? parseBoolean($("#after_drilling").text()) : false;
            if (_this.props.isDrilling) {
                let submitDrillParams = $("#submit_drill").text() === "" ? undefined : JSON.parse( $("#submit_drill").text());
                let tier = afterDrilling ? undefined : $("#Next_Tier").text();
                let cardIndex = afterDrilling ? undefined : submitDrillParams?.cardIndex;
                let removeDrill = afterDrilling ? undefined : submitDrillParams?.removeDrill;
                let undrill = afterDrilling ? undefined : submitDrillParams?.undrill;
                let fromProfitInfo = afterDrilling ? true : undefined;
                // _this.setState({
                //     checkedDisabled: false
                // },()=>{
                    _this.props.submitDrill(tier, cardIndex, removeDrill, undrill, fromProfitInfo, params.page, params.size, callback)
                // })
            } else {
                if(_this.stopHeaderClick){
                    return;
                }
                return _this.props.fetchProfitInfo(afterDrilling, params.page, params.size, callback, params.sorters ? params.sorters[0]?.field : undefined);
            }
        });
    }

    addBordersOnSort = () => {
        let _this = this;
        if(_this.props.isDashBoards) {
            return; 
        }

        let sorter = _this.props.sorter;

        document.querySelectorAll("#" + this.props.parentId + ' .border-dot').forEach(headerRow => {
            // remove all tabulator-cell and col that have border-dot classnames
            headerRow.classList.remove("border-dot");
        });
        document.querySelectorAll("#" + this.props.parentId + ' div[tabulator-field="' + (_this.props?.tabulatorSortField || sorter) + '"]').forEach(headerRow => {
            // find all cols/cells related to the sorter and add border-dot classnames
            headerRow.classList.add("border-dot");
        });
    }

    getLeftValueForFrozenColumn = () => {
        let baseSelector = ".list_table_container #table-list .tabulator-header";
        let drillSelector = ".list_table_container #table-drill-list .tabulator-header";
        let selector = this.props.isDrilling ? drillSelector : baseSelector;
        let leftValue = $(selector).css("margin-left");
        let leftValueNumber = leftValue.replace("px", "");
        let absoluteLeftValue = Math.abs(leftValueNumber);
        return absoluteLeftValue + "px";
    }

    componentDidMount() {
        this.isSecondDimension = !!this.props.isSecondDimension;
        var obj = this;
        let options = {
            layout: this.props.isDashBoards ? "fitColumns" : "fitData",      //fit columns to width of table
            columnHeaderSortMulti: false,
            tooltips: true,            //show tool tips on cells
            addRowPos: "top",          //when adding a new row, add it to the top of the table
            history: true,             //allow undo and redo actions on the table
            movableColumns: this.props.isDashBoards ? false : true,     //allow column order to be changed
            autoResize: this.props.isDashBoards,
            resizableRows: false,       //allow row order to be changed
            selectable: false,
            resizableColumns: true,
            headerSort: false,
            virtualDomBuffer: 450,
            placeholder: "",
            // initialSort:[{column: this.props.sorter, dir: "desc"}],
            // columnMaxWidth:100,
            invalidOptionWarnings: false,
            // layoutColumnsOnNewData:true, //column width adjust to the data each time its loaded to table
            height: "100%", //($(window).height() - $("#To_Scroll_List").offset().top-20)+"px" || this.props.id === 'table-drill-list'? "500px" :
            width: "100%",
            renderComplete: this.onTabulatorRenderComplete,
            columnMoved: function (column, columns) {
                //column - column component of the moved column
                //columns- array of columns in new order
                obj.onColumnMoved(columns, column.getField());
            },
            pageLoaded: function (pageno) {

                if (obj.state.checkedDisabled) {
                    $('input.chosenEntity:not(:checked)').attr('disabled', 'disabled');
                    $('input.chosenEntity:not(:checked)').addClass('input-checkbox-disabled');
                    $('input.chosenEntity:not(:checked)').parent().attr("uk-tooltip", MESSAGES.profit_list.disable_checkbox);
                } else {
                    $('input.chosenEntity').removeAttr('disabled');
                    $('input.chosenEntity').parent().removeAttr("uk-tooltip");
                    $('input.chosenEntity:not(:checked)').removeClass('input-checkbox-disabled');
                }
            },
            accessorDownload: function (dataObj) {
                let params = {
                    "Report": obj.props.profitFormat,
                    "User": obj.props.user.first_name + " " + obj.props.user.last_name,
                    "Date Run": new Date().getDate() + '-' + getMonthName(new Date().getMonth()) + '-' + new Date().getFullYear(),
                    "Filter": typeof obj.props.exportQueryFilter === "function" ? obj.props.exportQueryFilter() : obj.props.exportQueryFilter || "None",
                    "Data Set": getMonthsNumber(obj.props.FY) + " Mo " + obj.props.dataset,
                    "Scenario": obj.props.scenarioNumber
                }
                return tabulatorExport(dataObj, obj.tabulator, params);
            },
            downloadReady: function (fileContents, blob) {
                toggleLoader(false, "tablesToExcel");
                return blob; //must return a blob to proceed with the download, return false to abort download
            },
            dataSorted: function (sorters) {
                //sorters - an array of the sorters currently applied
                let sorter = obj.props.sorter ? obj.props.sorter : obj.props.data?.columns?.find(f=>f.isDefaultSorter)?.field;
                let order = obj.props.order ? obj.props.order : "desc";
                if(!obj.stopHeaderClick) { // when resizing column, don't set the sorter manually
                  if(sorter && obj.tabulator?.getColumn(sorter)._column) { // set sorting icon next to the sorted column
                    obj.tabulator.getColumn(sorter)._column.element.ariaSort = order;
                }
                } 
                
            },
            columnResized: (column) => {
                let field = column.getField();
                let columnWidths = copyObjectValues(obj.props?.columnsWidths) || [];
                let index = obj.props.index;
                const isDrillCard = index !== undefined; // this is instead of 'this.props.index' because when index is 0, it will return false

                if(this.props.isDrilling) {
                    if(isDrillCard && this.props.drillCardWidths?.length > 0) {
                        let cardWidths = this.props.drillCardWidths.find(f => f.cardIndex === index)?.widths;
                        columnWidths = copyObjectValues(cardWidths /*|| this.props.drillListWidths*/); // if we drill again, the second card shoul take the widths of the list drill
                    } else {
                        columnWidths = copyObjectValues(this.props.drillListWidths);
                    }
                }

                // Check if the column already exists in the columnWidths array
                if (columnWidths.find(f => f.colField === field)) {
                    let currCol = columnWidths.find(f => f.colField === field);
                    currCol.width = column.getWidth();
                } else {
                    // Add the new column with its width to the columnWidths array
                    columnWidths.push({ colField: field, width: column.getWidth() });
                }

                // Update the state with the new column widths
                if(this.props.isDrilling) {
                    if(isDrillCard) {
                        let drillCardWidths = this.props.drillCardWidths || [];
                        if(drillCardWidths?.find(f => f.cardIndex === index)) {
                            drillCardWidths.find(f => f.cardIndex === index).widths = columnWidths;
                        } else {
                            drillCardWidths.push({cardIndex: index, widths: columnWidths});
                        }
                        obj.props.setDrillCardWidthState(drillCardWidths);
                    } else {
                        obj.props.setDrillListWidthState(columnWidths);
                    }
                
                } else {
                    obj.props.setColumnWidthState(columnWidths);
                }
              },
            scrollVertical:function(top){
                $('#' + obj.props.id + ' .tabulator-col-resize-handle').removeClass('hovered');
                obj.addBordersOnSort();
                obj.addHoverEffectOnResize();
                obj.disableCheckboxesOnClick();
            },
            scrollHorizontal: function (left) {
                $('.frozenLeftColumn[role="columnheader"]').css({"left": obj.getLeftValueForFrozenColumn()});
            },

        }
        if (this.props.isPaginated === true) {
            options.pagination = 'remote';
            options.paginationInitialPage = 1;
            options.paginationButtonCount = 3;
            options.paginationSize = 100;
            options.paginationSizeSelector = [10, 50, 100];
            options.ajaxRequestFunc = obj.ajaxRequestFunc;
            options.footerElement = "<div id='" + this.props.id + "_footer'></div>";
            options.dataFiltered = function (filters, rows) {
                obj.addFooterText(rows);
            };
        }
        this.tabulator = new Tabulator(this.refs.mainTable, options);

        /** closing sort dropdown if we click outside */
        $(document).on("click", function (event) {
            if(!event.target.classList.contains("list-dots-button")) {
                $(".header-column-dropdown").removeClass("uk-open");
                $(".list-dots-button").removeClass("uk-open");
            }
          });
    }

    resizeTableList = () => {
        // $("#table-list").css("min-height", (window.innerHeight - $(".header").height() - ($(".border-third-header").height()===undefined ? 0 : 8)));
        // $("#table-list").css("max-height", (window.innerHeight - $(".header").height() - ($(".border-third-header").height()===undefined ? 0 : 8)));
    }


    onTabulatorRenderComplete=(nextProps)=>{
        this.renderSortButton();
        this.addHoverEffectOnResize();
    }

    /**
     * This function is used when we change a column order and on tabulator render complete.
     * After reordering the columns, the height of the table is being minimized because of the content of the sorter's 3 dots 
     * in the column header. To fix this issue, we hide the 3 dots and we re-show them after the columns have been reordered.
     */
    renderSortButton = () => {
        this.addBordersOnSort();
        $(".dots-button-container").removeClass("hidden-column"); // this class is used to hide 3 dots. We remove it when tabulator renders complete
    }

    componentWillUnmount() {
        let _this = this;
        this.removeResizeContainer();
        window.removeEventListener('resize', _this.resizeTableList());
    }
    
    addFooterText=(propsData, selectedItems, entities)=>{
        let _this = this;
        selectedItems = selectedItems || (this.tabulator ? this.tabulator.getData().filter(e=>e.checked) : []);
        let savedSelection = copyObjectValues(entities || _this.props.profitStackSelectedEntities);
        let filteredSavedSelection = [];
        // In List screen, we count the savedSelection items but in heatmap we already have the items checked from filtering tabulator data and getting the checked items
        if(selectedItems?.length === 0) {
          filteredSavedSelection = savedSelection;
        }
    
        let count = filteredSavedSelection?.length + selectedItems.length;
        let data = propsData || [];
        let totalsText = "";
        let iconDiv = document.createElement('div');
        if(data && data.length > 0){
            totalsText ="<span class=\"uk-margin-small-right\">" + formatValString(data[0][_cnt] ? data[0][_cnt] : data[0].getData()[_cnt], FormatTypes.NUMERIC) + " records "+ "</span>"
            if(_this.props.parentId === "To_Scroll_List_Drill"){
                _this.props.refreshDataLenght((!data || data.length === 0 ? data.length : data && data.length > 0 && data[0] && data[0][_cnt] ? data[0][_cnt] : data[0].getData()[_cnt] ?  data[0].getData()[_cnt] : data.length));
            }  
        }
        if (document.getElementById(this.props.id+"_footer")) {
            document.getElementById(this.props.id+"_footer").innerHTML ="<p class='uk-margin-small-right' id='count_value' count="+count.toString()+">" + (typeof count === "number" && count > 0 ? Number(count) +" of ": "") + totalsText + "</p>";
            document.getElementById(this.props.id+"_footer").appendChild(iconDiv);
            document.getElementById(this.props.id+"_footer").classList.add("uk-flex", "uk-flex-middle");
        }
    }

    replaceTableData=(amountColumns, data)=> {
        var report = this.props.profitFormat;
        var cardsData = copyObjectValues(this.props.cardsData);
         //when no data is returned
        if (data.length === 0) {
            cardsData.map((dt, index)=> {
                let cols = amountColumns[index].filter(e=>e.format_type !== "text" && e.format_type !== "quadrant"); // to not replace key, name,number, q, qt with 0 they should maintain their values
                for(let e in cols){
                    let column = cols[e]["field"];
                    dt["data"][0][column] =  "0";
                }
                // dt[column] =  "0";
            });
            $('#table-drill-list .tabulator-placeholder').find("span").text(MESSAGES.no_data_available);
        } else {
            //replacing the data returned
            let dataOnly = [];
            for(let e in cardsData){
                dataOnly.push(cardsData[e]["data"][0]);
            }
            data.map((dt, index) => {
                var filtered = [];
                if ([ALL_REPORTS.LIST_QUADRANT, ALL_REPORTS.LIST_QUADRANT_TIER].indexOf(report) > -1) {
                    filtered = dataOnly.filter(e => e.name === dt.name);
                } else {
                    filtered = dataOnly.filter(e => e.key === dt.key);
                }

                if(filtered.length > 0) {
                    for(var i = 0 ;i<filtered.length;i++){
                        let cols = amountColumns[index].filter(e=>e.format_type !== "text" && e.format_type !== "quadrant");
                        for(let e in cols){
                            let column = cols[e]["field"];
                            filtered[i][column] =  dt[column];
                        }
                    }  
                } else {
                    console.log("missing column : " + dt.key);
                }
            });

            for(let e in cardsData){
                cardsData[e]["data"][0] = dataOnly[e];
            }

        }

        this.props.setCardsData(cardsData);
    }


    replaceEmptyTableData=(data, amountColumns)=> {
        var cardsData = copyObjectValues(this.props.cardsData);
		var count = data.length;
		//when the data returned is less than than tabulator data (means the other rows should be filled with zeros)
		while (count < cardsData.length) {
            let cols = amountColumns[count].filter(e=>e.format_type !== "text" && e.format_type !== "quadrant");
            cols.map((item) => {
                var object = item.field;
                cardsData[count]["data"][0][object] = "0";
            });
            count++;
        }
        this.props.setCardsData(cardsData);
    }

    hideVectorComp=(container, e, _this)=>{
        let container2 = $("#period-drop-down"+_this.props.parentId+(_this.props.idex>-1? _this.props.index : ""));
        // if the target of the click isn't the container nor a descendant of the container
        if ((!container.is(e.target) && container.has(e.target).length === 0) && (container2[0] && e.target && e.target.parentElement && container2[0].id !== e.target.parentElement.id)) {
            container.addClass("uk-hidden");
            if(_this.periodRef){
                _this.periodRef.refreshSearch();
            }
        }
    }

    render() {
        let _this = this;
        var heightClass = " custom-height ";
        var container =  $("#periods_comp"+this.props.id);
        $(document).click(function(e) {
            _this.hideVectorComp(container, e, _this);
        });
        return (
            <div className={this.props.isBase === true ? "list" : " list_table_container"}>
                <div id={"periods_comp"+this.props.id} className={"hide-columns-dropdown uk-hidden" + heightClass}>
                    <PeriodsDropDown  ref={el=>this.periodRef = el} funkName={_this.drill} periods={_this.props.vectors} 
                    placeHolderText={lang.search_vectors_placeholder} 
                    report = {lang.COMMON.LIST} 
                    />
                </div>
            <div id={this.props.id}  ref="mainTable" />
            </div>
        )
    }

}

export default TabulatorWrapper;