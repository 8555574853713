// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-subtitle {
  color: #a2a2a2;
  font-size: 0.42vw;
  font-style: normal;
  font-weight: 500;
}

.landing-footerContainer .footer-rights {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/templateLayout/styles/footer.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,SAAS;AACX","sourcesContent":[".footer-subtitle {\n  color: #a2a2a2;\n  font-size: 0.42vw;\n  font-style: normal;\n  font-weight: 500;\n}\n\n.landing-footerContainer .footer-rights {\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
