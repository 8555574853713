import React, { Component } from 'react';

import {timestampFormatter, ratioFormatter, percentFormatter, numberFormatter, moneyFormatter, quadrantFormatter, minMaxFilterEditor, minMaxFilterFunction, textFilterEditor, textFilterFunction, selectFilterEditor, selectFilterFunction, createthreeDotsFormatter, filesFormatter, dateFormatter, statusFormatter, selectFormatter, textInputFormatter, menuDropDownFormatter} from "./tabulatorFormatting.js";
import { RULE_ENTITIES, FILTER, API_URL, FormatTypes, ColFilterTypes, SECTION, FY_VALUES, ALL_WIDGETS, COST_FUNCTIONS_FIELDS, INVOICELINETYPE, PSS, column_suffixes_values, VECTOR_STAGING_ATTRIBUTES, RULE_ENTITIES_PSL_DEFINITIONS, QUADRANTS, CLIENT_ID_STORAGE, CUSTOM_REPORT, SEGMENTS_TITLES, MENU_ITEM, logged_in, EMPTY_FILTER, CURRENCY, USD_SIGN, ENTITY_TYPES } from './constants.js';
import { showMenu, getObjectAsArray, removeAllCookies, arrangeAncillaryFiles } from './jqueries.js';
import { auth } from '../firebase/firebase.js';
import {signOut} from 'firebase/auth';
import {createFormatValTabulator} from "./format";
import { getTranslationFile, findOptionByKeyValue, deepCompareObjects, tryParse, findOptionByKey, capitaliseFirstLetterAfterChar, copyObjectValues } from './utils.js';
import { logUIError, logUIInfo, logUITracking } from './networkUtils';
import { returnLastDatasets } from '../sections/filter/FilterHelperFunctions';
import { getColumnFilterButton, getColumnLegend, getHeaderGrpButtons } from '../newComponents/tabulatorComponents';
import { Segment } from '../components/Segment';
import { formatCurrentDate, getSelectedPeriodString } from './date.js';
import {linearizeHierarchy} from "./array";
const $ = require('jquery');
const shortid = require("shortid");
const _LOGGED_OUT = "logged_out";

const lang = getTranslationFile();

class Logo extends Component{
	constructor(props){
		super(props);
		this.props = props;
	}

	render() {
		return (
			<div id='logo' className='logo' style={{ backgroundImage: 'url('+this.props.url+')'}}></div>
		);
	}
}

function getRandomColor() {
	var letters = '0123456789ABCDEF';
	var color = '#';
	for (var i = 0; i < 6; i++) {
	  color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
  }

function filterableTitleFormatter(cell, params) {
	var tableId = params.id ? "#" + params.id + " " : "";
	var toggle = tableId ? "target:" + tableId + "#filter-dropdown-" + params.field : "";
	if (params.alphaLegend) {
		return getColumnLegend(params, toggle)
	}
	
	return getColumnFilterButton(params, toggle);
}

function translateSymbols(title) {
	if (title) {
		let new_title = title.replace("α", "<span class='uk-text-bolder text-blue' > α </span>");
		new_title = new_title.replace("β", "<span class='uk-text-bolder uk-text-primary' > β </span>")
		return new_title
	}

	return title;
}

function expandableGroupTitleFormatter(cell, params) {
	if (params.columns.filter(c => c.title !== "...").length === 1) {
		return "<p>" + params.title + "</p>";	//do not add the toggle button
	}
	var threeDotsCol = params.columns.filter(c => c.title === "...");
	var collapsed = threeDotsCol[0].visible;
	let onclick = "";
	let onLinkclick = "";
	let element = "p";
	let isExpanded = params.visibilityLength && params.visibilityLength >= 2;
	if (typeof window._pi_onHeaderClickCallback === "function" && params.hasTitleClickCallback) {
		onLinkclick = function (e) {window._pi_onHeaderClickCallback(e, params.field, params.title);}
		element = "a";
	}

	onclick = function () {window[params.funkName]();}
	return getHeaderGrpButtons(isExpanded, params, onclick, element, onLinkclick)
}


var cleanUpSingleTabulatorColumn = function(col, options, callback) {
	col.title = window._format.replaceCurrency(col.title);
	col.formatterType = col.formatter;
	if(!col.dontFilter){ 
		if([ColFilterTypes.TEXT, ColFilterTypes.TEXT_AREA, ColFilterTypes.QUADRANT, ColFilterTypes.INPUT, ColFilterTypes.FILES, ColFilterTypes.DATE, ColFilterTypes.STATUS].includes(col.formatter)
		|| [FormatTypes.TEXT, FormatTypes.QUADRANT].includes(col.format_type)) {
			col.headerFilter = "input";
		} else if([ColFilterTypes.MONEY, ColFilterTypes.NUMBER, ColFilterTypes.PERCENTAGE, ColFilterTypes.RATIO].includes(col.formatter)
		|| [FormatTypes.AMOUNT,FormatTypes.RATIO,FormatTypes.PERCENTAGE,FormatTypes.BASIS_POINT,FormatTypes.NUMERIC].includes(col.format_type)
		|| (col.format_type?col.format_type.includes(FormatTypes.RATIO+":"):false)) { // special ratio formats like ratio:lines or ratio:orders
			col.headerFilter = "minmax";
		} else if([ColFilterTypes.HEADER_SELECT, ColFilterTypes.SELECT].includes(col.formatter)) {
			col.headerFilter = "select";
		}
	}
	

	if (col.format_type != null) {
		col.formatter = createFormatValTabulator(col.format_type);
	} else if (col.formatter === "timestamp") {
		col.formatter = timestampFormatter;
		// timestampFormatter({});
	} else if (col.formatter === "quadrant") {
		col.formatter = quadrantFormatter;
	} else if (col.formatter === "money") {
		col.formatter = moneyFormatter;
	} else if (col.formatter === "number") {
		col.formatter = numberFormatter;
	} else if (col.formatter === "percentage") {
		col.formatter = percentFormatter;
	} else if (col.formatter === "ratio") {
		col.formatter = ratioFormatter;
	} else if (col.formatter === "select") {
		col.formatter = selectFormatter;
	} else if (col.formatter === "input") {
		col.formatter = textInputFormatter;
	} else if (col.formatter === "files") {
		col.formatter = filesFormatter;
	} else if (col.formatter === "date") {
		col.formatter = dateFormatter;
	} else if (col.formatter === "status") {
		col.formatter = statusFormatter;
	} else if (col.formatter === "menuDropDown") {
		col.formatter = menuDropDownFormatter;
		col.formatterParams = options;
	}

	col.headerDblClick = function(e, column){
		e.stopPropagation();
    //e - the tap event object
    //column - column component
    };

    col.columnResized = function(e, column){
    	$('.tabulator-col tabulator-col-group').css({'width':''});
    };

	if (col.headerFilter === "select" || col.headerFilter === "headerselect") {
		let placeholder = col.title;
		col.titleFormatterParams = {
			title: col.title,
			hideFilter: col.hideFilter,
			field: col.field,
			id: options ? options.id : "",
			align: col.align,
			alignment: col.alignment,
			format_type: col.format_type,
			tooltipMessage: col.tooltipMessage ? col.tooltipMessage : undefined, // display tooltip message and filter in titleFormatter
			bold: col.bold ? col.bold : undefined, // display tooltip message and filter in titleFormatter
			hideIcon: col.hideIcon ? col.hideIcon : undefined,
			symbolColor: col.symbolColor ? col.symbolColor : undefined,
			alphaLegend: col.alphaLegend ? col.alphaLegend: undefined,
			betaLegend: col.betaLegend ? col.betaLegend: undefined,
			addClass: col.addClass ? col.addClass : undefined, // display tooltip message and filter in titleFormatter
		}
		col.titleFormatter = filterableTitleFormatter;
		col.headerFilter = selectFilterEditor(callback);
		col.headerFilterFunc = selectFilterFunction;
		col.headerFilterFuncParams = { field: col.field, childs: options.childrenFieldName };
		col.headerFilterParams = {
			placeholder: "Filter " + placeholder + "...",
			values: options && options.dataVals ? options.dataVals[col.field] : []
		};
	} else if (col.headerFilter === "input") {
		let placeholder = col.title;
		col.titleFormatter = filterableTitleFormatter;
		col.titleFormatterParams = {
			title: col.title,
			field: col.field,
			hideFilter: col.hideFilter,
			id: options ? options.id : "",
			align: col.align,
			alignment: col.alignment,
			format_type: col.format_type,
			tooltipMessage: col.tooltipMessage ? col.tooltipMessage : undefined, // display tooltip message and filter in titleFormatter
			bold: col.bold ? col.bold : undefined, // display tooltip message and filter in titleFormatter
			hideIcon: col.hideIcon ? col.hideIcon : undefined,
			symbolColor: col.symbolColor ? col.symbolColor : undefined,
			alphaLegend: col.alphaLegend ? col.alphaLegend: undefined,
			betaLegend: col.betaLegend ? col.betaLegend: undefined,
			alphaClassName: col.alphaClassName,
			isCompareScenarios: col.isCompareScenarios,
			addClass: col.addClass ? col.addClass : undefined, // display tooltip message and filter in titleFormatter
		}
		// col.title = "<div id='filter-dropdown-button-"+col.field+"' uk-toggle='target: #filter-dropdown-"+col.field+"' class='filter-toggle-but "+col.field+"'></div>"+col.title;
		col.headerFilter = textFilterEditor(callback);
		col.headerFilterFunc = textFilterFunction;
		col.headerFilterParams = {
			placeholder: "Filter " + placeholder + "...",
			values: options && options.dataVals ? options.dataVals[col.field] : [],
			headerFilters: options && options.headerFilters ? options.headerFilters[col.field] : []
		};
	} else if (col.headerFilter === "minmax") {
		col.titleFormatter = filterableTitleFormatter;
		col.titleFormatterParams = {
			title: col.title,
			field: col.field,
			hideFilter: col.hideFilter,
			id: options ? options.id : "",
			align: col.align,
			alignment: col.alignment,
			format_type: col.format_type,
			tooltipMessage: col.tooltipMessage ? col.tooltipMessage : undefined, // display tooltip message and filter in titleFormatter
			bold: col.bold ? col.bold : undefined, // display tooltip message and filter in titleFormatter
			hideIcon: col.hideIcon ? col.hideIcon : undefined,
			symbolColor: col.symbolColor ? col.symbolColor : undefined,
			alphaLegend: col.alphaLegend ? col.alphaLegend: undefined,
			betaLegend: col.betaLegend ? col.betaLegend: undefined,
			addClass: col.addClass ? col.addClass : undefined, // display tooltip message and filter in titleFormatter
		}
		// col.title = "<div id='filter-dropdown-button-"+col.field+"' uk-toggle='target: #filter-dropdown-"+col.field+"' class='filter-toggle-but "+col.field+"'></div>"+col.title;
		col.headerFilter = minMaxFilterEditor(callback);
		col.headerFilterFunc = minMaxFilterFunction;
		col.headerFilterParams = {
			min: options && options.dataMin ? options.dataMin[col.field] : 0,
			max: options && options.dataMax ? options.dataMax[col.field] : 0,
			step: 1
		}
	} else if (col.headerFilter === "menuDropDown") {
		col.titleFormatter = menuDropDownFormatter;
		col.titleFormatterParams = options
	} else if(!col.headerFilter){
		col.titleFormatter = filterableTitleFormatter;
		col.titleFormatterParams = {
			title: col.title,
			field: col.field,
			hideFilter: true,
			id: options ? options.id : "",
			align: col.align,
			format_type: col.format_type,
			tooltipMessage: col.tooltipMessage ? col.tooltipMessage : undefined, // display tooltip message and filter in titleFormatter
			bold: col.bold ? col.bold : undefined, // display tooltip message and filter in titleFormatter
			hideIcon: col.hideIcon ? col.hideIcon : undefined,
			symbolColor: col.symbolColor ? col.symbolColor : undefined,
			alphaLegend: col.alphaLegend ? col.alphaLegend: undefined,
			betaLegend: col.betaLegend ? col.betaLegend: undefined,
			addClass: col.addClass ? col.addClass : undefined, // display tooltip message and filter in titleFormatter
		}
	}
	return col;
}

function cleanUpTabulatorColumns(columns, data, callback, getTabulator, options = { dataMin: null, dataMax: null, dataVals: null, tableId: null, headerFilters: {} }) {
	if(Array.isArray(data) && data.length > 0){
		// Calculating min and max values for all non String attributes
		options.dataMax = JSON.parse(JSON.stringify(data[0]));
		options.dataMin = JSON.parse(JSON.stringify(data[0]));
		options.dataVals = {}; // {column1:{val1:count1, val2:count2}, column2:{...}, ...}
		for(var d_i =0; d_i < data.length; d_i ++){
			var d = data[d_i]
			// console.log(d);
			for(var k in d) {
				// var k = k_i;
				// console.log(k);
				if(typeof d[k] === "string") {
					var obj = options.dataVals[k];
					if(!obj){
						obj = options.dataVals[k] = {}; 
					}
					if(!obj[d[k]]) {
						obj[d[k]] = 1;
					} else {
						obj[d[k]] ++;
					}
				} else if(d[k] >= 0 || d[k] < 0) {
					// console.log("numeric");
					if(d[k] > options.dataMax[k])
					options.dataMax[k] = d[k];
		    		if(d[k] < options.dataMin[k])
					options.dataMin[k] = d[k];
				}
			}
		}
		var temp = options.dataVals;
		options.dataVals = {};
		for(var k in temp){
			var obj = temp[k];
			options.dataVals[k] = [];
			for(var v in obj) {
				options.dataVals[k].push(v);
			}
			options.dataVals[k] = options.dataVals[k].sort();
		}
		temp = undefined;
	}
	
	var columns = columns ? JSON.parse(JSON.stringify(columns)): "";
	if(!Array.isArray(columns)) {
		console.log("cleanUpTabulatorColumns columns not an array skipping");
		return columns;
	}
	columns.map(function(col){
		if(Array.isArray(col.columns)) {
			col.columns = cleanUpTabulatorColumns(col.columns, null, callback, getTabulator, options);
			if(col.collapsable && typeof getTabulator === 'function') {
				// Creating function to hide all columns except the three dots which is hidden by default, and shown when everything else is hidden.
				var funkName = "_profitisle_tabulator_title_click_"+shortid.generate();
		        window[funkName] = function(){
					let wasCollapsed = false;
		            if($('#'+funkName).hasClass('inset')) {
						wasCollapsed = true;
		            	$('#'+funkName).removeClass('inset');
		            } else {
		            	$('#'+funkName).addClass('inset');
		            }
		            for(var c_i in col.columns){
		            	var c = col.columns[c_i];
		                if(c.visible === undefined)
		                    c.visible = true;
		                c.visible = !c.visible;
		                var tabulator;
		                if(typeof getTabulator === 'function')
		                    tabulator = getTabulator();
		                if(tabulator)
		                {
		                    var colComps = tabulator.getColumns();
		                    for(var cComp_i in colComps) {
		                    	var cComp = colComps[cComp_i];
								if(cComp._column.definition.id === c.id   && c && c.field && !c.field.includes("difference")) {
		                            if(wasCollapsed) {
		                                cComp.show()
									} else if(!c.alwaysVisible){	//not all default visible cols have "difference" in their field value
										cComp.hide();
									}
		                        }
		                    }
		                }
		            }
		            if(typeof callback === 'function')
                		callback(col.field, $('#'+funkName), wasCollapsed);
				};
					col.columns.push({title:"...", formatter:createthreeDotsFormatter(function(){window[funkName]();}), headerSort:false, dontFilter:true, visible:false});
					for(var c_i in col.columns){
						var c = col.columns[c_i];
						c.id = shortid.generate();
					}
					
				
				col.titleFormatter = expandableGroupTitleFormatter;
				col.titleFormatterParams = {title:col.title, field:col.field || col.title, funkName:funkName, columns: col.columns, hasTitleClickCallback: col.hasTitleClickCallback, visibilityLength: col.visibilityLength };
				// col.title = "<div title='"+col.title+"' onclick='window[\""+funkName+"\"]();' > <div id='"+funkName+"' class='group-expand-but'></div>" + col.title+"</div>";
				// col.title = "<span title='"+col.title+"'><div class='group-expand-but inset' id='"+funkName+"' onclick='window[\""+funkName+"\"]();' ></div>" + col.title+"</span>";

				 //= createExpandableGroupTitle(col, funkName);
				// createExpandableGroupTitle(col, callback);
			}
		} else {
			col = cleanUpSingleTabulatorColumn(col, options, callback);
		}
	});
	/* $('body').bind("DOMSubtreeModified", function() {
		window._profitisle_tabulator_filter_toggle_dirty = true;
	});
	window.setInterval(function(){
		if(window._profitisle_tabulator_filter_toggle_dirty)
		{
			$('.tabulator .filter-toggle-but').on('click', function(ev){
				ev.stopPropagation();
			});
			$('.tabulator .filter-toggle-but').on('mousover', function(ev){
				ev.stopPropagation();
			});
		}
		window._profitisle_tabulator_filter_toggle_dirty = false;
	}, 10); */

	document.addEventListener("mousedown", function(ev){
		// this event will clear the beforehide listener so that the uikit drop will close when clicking outside the drop
		$("*[id*=filter-dropdown-]:not('*[id*=filter-dropdown-cont]'):not('*[id*=filter-dropdown-button]').uk-open").off('beforehide');
	});

	return columns;
}

var checkIfSectionIdExists = function(data, id){
	if(Array.isArray(data)) {
		for(var i = 0; i < data.length; i++){
			var d = checkIfSectionIdExists(data[i], id);
			if(d)
				return d;
		}
		return false;
	} else if (data) {
		if(( id ? id.toLowerCase() : undefined) === (data[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME]?.toLowerCase() || data[SECTION.RETURN_NAME]?.toLowerCase()))
			return data;
		else{
			return checkIfSectionIdExists(data.children, id);
		}
	} else{
		return false;
	}
};

function getReadableRuleText(ruleText, vectors,metricFields=[],hiddenVectors=[]) {
	var readableText = lang.rule_text.TO_THE;
	var ruleRows = ruleText.split(",");
	ruleRows.forEach((row, index) => {
		var splitRow = row.split(":");
		let vectorLabel = "";
		if( vectors && vectors.length > 0 && vectors.filter(e=>e.value === splitRow[0]).length > 0) {
			vectorLabel = vectors.filter(e=>e.value === splitRow[0])[0].label
		} else if(hiddenVectors && hiddenVectors.length > 0  && hiddenVectors.filter(e=>e[VECTOR_STAGING_ATTRIBUTES.MACHINE_NAME] === splitRow[0]).length > 0) {
			vectorLabel = hiddenVectors.filter(e=>e[VECTOR_STAGING_ATTRIBUTES.MACHINE_NAME] === splitRow[0])[0][VECTOR_STAGING_ATTRIBUTES.NAME];
		} else {
			vectorLabel = splitRow[0];
		}

		if(splitRow.length > 1 && splitRow[1].startsWith("Metric.") && metricFields.length){
			let metricRepresentation = splitRow[1].split(".");
			let metricDisplay = metricFields.filter(e=> e.metric === metricRepresentation[1]).length ? metricFields.filter(e=> e.metric === metricRepresentation[1])[0].metric_display_name :  metricRepresentation[1];
			splitRow[1] = "Metric." + metricDisplay;
		}
	
		let vector = splitRow[0] === RULE_ENTITIES.INVOICE_LINE ? RULE_ENTITIES.LINE : vectorLabel;
		let condition = splitRow[1] === RULE_ENTITIES.COUNT_LINES ? RULE_ENTITIES.COUNT : splitRow[1];
	
		readableText += index > 0 ? "," + lang.rule_text.THEN_TO_THE : "";
		readableText += vector + lang.rule_text.BY + condition;
	});
	return readableText;
}

function translateTabulatorFilter (filter){
	let newFilter = [];
	for(let e in filter){
		let obj = {};
		obj.field = filter[e].field;
		if(filter[e].value.val) {
			obj.type = "STRING";
			obj.value = filter[e].value.val;
		} else {
			 obj.type = "NUMERIC"
			 if(filter[e].value.start !== "" &&  filter[e].value.end !== ""){
				obj.value = "BETWEEN " + Number(filter[e].value.start) + " AND " + Number(filter[e].value.end)
			 } else if(filter[e].value.start !== "" &&  filter[e].value.end === "") {
				obj.value = ">= " + Number(filter[e].value.start);
			 } else {
				obj.value = "<= " + Number(filter[e].value.end);
			 }
		}
		newFilter.push(obj);
	}
	return newFilter;
}

function translateTabulatorSorter(sorter) {
	let newSorter = [];
	let obj = {};
	obj.field = sorter[0].field;
	obj.dir = sorter[0].dir;
	newSorter.push(obj);
	return newSorter;
}

function getReadableRuleTextPSLDefinition(ruleText, vectors,metricFields=[],hiddenVectors=[]) {
	var readableText = lang.rule_text.TO_THE;
	var ruleRows = ruleText.split(",");

	ruleRows.forEach((row, index) => {
		var splitRow = row.split(":");
		let vectorLabel = vectors && vectors.length > 0 ? vectors.filter(e=>e.value === splitRow[0]).length > 0 ? vectors.filter(e=>e.value === splitRow[0])[0].label :hiddenVectors && hiddenVectors.length > 0 ? hiddenVectors.filter(e=>e[VECTOR_STAGING_ATTRIBUTES.MACHINE_NAME] === splitRow[0]).length > 0 ? hiddenVectors.filter(e=>e[VECTOR_STAGING_ATTRIBUTES.MACHINE_NAME] === splitRow[0])[0][VECTOR_STAGING_ATTRIBUTES.NAME]:splitRow[0]:splitRow[0]:splitRow[0];
		let vector = splitRow[0] === RULE_ENTITIES.INVOICE_LINE ? RULE_ENTITIES_PSL_DEFINITIONS.LINE : vectorLabel;
		let condition = splitRow[1] === RULE_ENTITIES.COUNT_LINES || splitRow[1] === RULE_ENTITIES.COUNT ? RULE_ENTITIES_PSL_DEFINITIONS.COUNT : splitRow[1];
		if(condition?.startsWith("Metric.") && metricFields.length){
			let metricRepresentation = condition.split(".");
			let metricDisplay = metricFields.filter(e=> e.metric === metricRepresentation[1]).length ? metricFields.filter(e=> e.metric === metricRepresentation[1])[0].metric_display_name :  metricRepresentation[1];
			condition = metricDisplay;
		}	
		readableText += index > 0 ? "," + lang.rule_text.THEN_TO_THE : "";
		readableText += index === ruleRows.length-1 ? vector + lang.rule_text.BY + condition + "." : vector + lang.rule_text.BY + condition + "";
		if (!condition) { //CHANGE 
			readableText = lang.rule_text.BY + vector + "."; 
		}
	});
	return readableText.replace(/Metric./g, "");
}

/**
 * replace the vector machine name with the vector display name
 * @param {*} attrFormula 
 * @param {*} vectors 
 * @param {*} hiddenVectors 
 * @returns 
 */
function getReadableCostTerm(attrFormula, vectors, hiddenVectors=[]) {
	let attrFormulaArr = attrFormula?.split(" ");
	attrFormulaArr.forEach(row => {
		if(row && vectors?.find(e=>e.value === row.toLowerCase())){
			let vectorLabel = vectors.find(e=>e.value === row.toLowerCase()).label;
			attrFormula = attrFormula.replaceAll(row, vectorLabel);
		}else if (row && hiddenVectors?.find(e=>e[VECTOR_STAGING_ATTRIBUTES.MACHINE_NAME] === row.toLowerCase())){
			let vectorLabel = hiddenVectors.find(e=>e[VECTOR_STAGING_ATTRIBUTES.MACHINE_NAME] === row.toLowerCase())[VECTOR_STAGING_ATTRIBUTES.NAME];
			attrFormula = attrFormula.replaceAll(row, vectorLabel);
		}
	});
	return attrFormula;
}

function humanTranslate(value) {
	if (value !== undefined) {
		if(value.includes("CASE")) {
			return value.replace(/[t][_]\d+./g, "").replaceAll("  "," ").replaceAll("CAST","").replaceAll("AS STRING","").replaceAll("CONCAT","").replace(/IFNULL/g,'').replace('"UNK"','').replace('0','').replace(/[,\(\)'"]+/g,"");
		}
		return value.replace(/[t][_]\d+./g, "").replaceAll("CAST","").replaceAll("AS STRING","").replaceAll("CONCAT","").replace(/IFNULL/g,'').replace('"UNK"','').replace('0','').replace(/[,\(\)'"]+/g,"");
	} else{
		return "";
	}
}
function getReadableFilterTextVectors(filterObj,allTypeColumns,subTypeId,name,scenarioId){
	if(filterObj && filterObj["result"] && allTypeColumns){
		
		var conditions = filterObj["conditions"][0];
		var filters = conditions["filters"];
		var result = conditions["result"];
		var elseResult = filterObj["result"][0];
		var readableText = ""
		for (var filter in filters){
			if(filter > 0){
				readableText += " ";
			}
			readableText += lang.filter_text.WHEN +" ";
			for (var index in filters[filter]){
				let field = filters[filter][index].column; // (TRANSLATE)
				var raw_file_subtype_id = filters[filter][index].raw_file_subtype_id;
				var fieldObj = allTypeColumns.filter(e => e.raw_field_name === field && e.raw_file_subtype_id === raw_file_subtype_id)[0];
				if(fieldObj) {
					var dataFileType = fieldObj.display_file_type;
					var raw_field_name = fieldObj.raw_field_name;
					var name = fieldObj.name !== ""? "("+fieldObj.name+") " :"";
					var wholeField = dataFileType + " - " + raw_field_name + name

					let operator = filters[filter][index].function ? filters[filter][index].function.toLowerCase() : filters[filter][index].operator.toLowerCase() ;
					var values = filters[filter][index].value;
					var value = "";
					if (typeof values === "object") {
						if (values.length > 0 && values[0].value) {
							values.forEach(function(val, key){
								values[key] = val.value.toLowerCase();
							});
						}
						else {
							values.forEach(function(val, key){
								values[key] = {value:val,label:val,action:"set-value"}
							});
						}
						value = values.length > 0 ? values.join(',') : "";
					} else { // case when numeric field value is not multi 
						value = values;
					}
					var operatorOptions = getObjectAsArray(lang.ui_filter.dropdowns.engine_filter_functions, "string", "value_type");
					operatorOptions = operatorOptions.concat(getObjectAsArray(lang.ui_filter.dropdowns.functions, "numeric", "value_type"));
		
					var tempOpObj = findOptionByKeyValue(operatorOptions, "value", operator.toUpperCase());
					operator = tempOpObj ? tempOpObj.label.toLowerCase() : "";
		
					readableText += index > 0 ? " " +filters[filter][index].logical_operator.toLowerCase() +" "+ lang.filter_text.AND_THE : "";
					readableText += wholeField + " " + operator + " '" + value + "' ";
				} else {
					logUIInfo("Vector " + name + " for scenario_id " + scenarioId + " has missing configuration.");
				}
			}
			if(result[filter].length == 1 && elseResult[0].raw_file_subtype_id === ""){
				readableText+= " "+lang.filter_text.THEN + " " + result[filter][0].label;
			}
			else {
				readableText+= " "+lang.filter_text.THEN +" ";
				for(var i in result[filter]){
					var type = result[filter][i].type;
					var value = result[filter][i].value;
					
					var raw_file_subtype_id = result[filter][i].raw_file_subtype_id; //Translate
					if(type === "field"){
						var fieldObj = allTypeColumns.filter(e => e.raw_field_name === value && e.raw_file_subtype_id === raw_file_subtype_id)[0];
						if(fieldObj) {
							var dataFileType = fieldObj.display_file_type;
							var raw_field_name = fieldObj.raw_field_name;
							var name = fieldObj.name !== ""? "("+fieldObj.name+") " :"";
							var wholeField = dataFileType + " - " + raw_field_name + name
						} else {
							logUIInfo("Vector " + name + " for scenario_id " + scenarioId + " has missing configuration.");
						}
					} else if (type === "connector"){
						var wholeField = value;
					}
				
					if(type === "raw_file_subtype_id"){
						continue;
					}
					readableText += wholeField +" ";
				}
			}
			
		}
		if(elseResult.length === 1 && elseResult[0].raw_file_subtype_id === "" ){
			readableText+= " "+lang.filter_text.ELSE + " " + elseResult[0].label;
		} else{
			readableText += " " + lang.filter_text.ELSE +" ";
			for (var i in elseResult){
				var type = elseResult[i].type;
				var value = elseResult[i].value;
				var raw_file_subtype_id = elseResult[i].raw_file_subtype_id;
				if(type=== "field"){
					var fieldObj = allTypeColumns.filter(e => e.raw_field_name === value && e.raw_file_subtype_id === raw_file_subtype_id)[0];
					if(fieldObj){
						var dataFileType = fieldObj.display_file_type;
						var raw_field_name = fieldObj.raw_field_name;
						var name = fieldObj.name !== ""? "("+fieldObj.name+") " :"";
						var wholeField = dataFileType + " - " + raw_field_name + name
					} else {
						logUIInfo("Vector " + name + " for scenario_id " + scenarioId + " has missing configuration.");
					}
				} else if (type === "connector"){
					var wholeField = value;
				} else if(type === "raw_file_subtype_id"){
					continue;
				}
				readableText += wholeField +" ";
			}
		}
		return readableText;
	} else {
			var readableText = "";
			for (var e in filterObj){
				var value = filterObj[e].value;
				var raw_file_subtype_id = filterObj[e].raw_file_subtype_id;
				var type = filterObj[e].type;
				
				if(type  === "field" && raw_file_subtype_id){
					var fieldObj = allTypeColumns.filter(e => e.raw_field_name === value && e.raw_file_subtype_id === raw_file_subtype_id)[0];
					if (fieldObj) {
						var dataFileType = fieldObj.display_file_type;
						var raw_field_name = fieldObj.raw_field_name;
						var name = fieldObj.name !== ""? "("+fieldObj.name+") " :"";
						var wholeField = dataFileType + " - " + raw_field_name + name;
						readableText+= wholeField;
					} else {
						logUIInfo("Vector " + name + " for scenario_id " + scenarioId + " has missing configuration.");
					}
				}
				else if(type==="connector"){
					readableText+= value;
				}
				else if(type  === "field" && !raw_file_subtype_id){
					if(name.toLowerCase() === "totalcompany"){
						readableText += humanTranslate(value);
					} else {
						var fieldObj = allTypeColumns.filter(e => e.raw_field_name === value.toLowerCase() && e.raw_file_subtype_id === parseInt(subTypeId))[0];
						if(fieldObj) {
							var dataFileType = fieldObj.display_file_type;
							var raw_field_name = fieldObj.raw_field_name;
							var name = fieldObj.name !== ""? "("+fieldObj.name+") " :"";
							var wholeField = dataFileType + " - " + raw_field_name + name;
							readableText+= wholeField;
						} else {
							logUIInfo("Vector " + name + " for scenario_id " + scenarioId + " has missing configuration.");
						}
					}
				}
			}
			return readableText;
	}
	
}

function getLeafNodes(nodes, result = []){
	for(var i = 0, length = nodes.length; i < length; i++){
	  if(!nodes[i].children || nodes[i].children.length === 0){
		result.push(nodes[i]);
	  }else{
		result = getLeafNodes(nodes[i].children, result);
	  }
	}
	return result;
  }


function getReadableFilterText(filterObj, vectors, hiddenVectors) {

	if(!filterObj.length) {
		return "";
	}
		var readableText = lang.filter_text.WHERE_THE;
		filterObj.forEach((row, index) => {
		let field = row.column;
		let vectorPrefix = field.includes(column_suffixes_values.number.label) ? column_suffixes_values.number.label : field.includes(column_suffixes_values.name.label) ? column_suffixes_values.name.label : field.includes(column_suffixes_values.key.label) ? column_suffixes_values.key.label : "";
		let vectorLabel = vectors?.find(e=>e.value === field.replace(vectorPrefix, ""))? vectors.find(e=>e.value === field.replace(vectorPrefix, "")).label + vectorPrefix :
		hiddenVectors?.find(e=>e[VECTOR_STAGING_ATTRIBUTES.MACHINE_NAME] === field.replace(vectorPrefix, "")) ? hiddenVectors.find(e=>e[VECTOR_STAGING_ATTRIBUTES.MACHINE_NAME] === field.replace(vectorPrefix, ""))[VECTOR_STAGING_ATTRIBUTES.NAME] + vectorPrefix : field;

		let operator = row.function.toLowerCase();
		var values = row.value || row.values;
		var value = "";
		if (typeof values === "object") {
			if (values.length > 0) {
				values.forEach(function(val, key){
					values[key] = val.value.toLowerCase();
				});
			}
			value = values.length > 0 ? values.join(',') : "";
		} else { // case when numeric field value is not multi 
			value = values;
		}
		

		var operatorOptions = getObjectAsArray(lang.ui_filter.dropdowns.engine_filter_functions, "string", "value_type");
		operatorOptions = operatorOptions.concat(getObjectAsArray(lang.ui_filter.dropdowns.functions, "numeric", "value_type"));

		var tempOpObj = findOptionByKeyValue(operatorOptions, "value", operator.toUpperCase());
		operator = tempOpObj ? tempOpObj.label.toLowerCase() : "";

		readableText += index > 0 ? " " +row.logical_operator.toLowerCase() +" "+ lang.filter_text.AND_THE : "";
		readableText += vectorLabel + " " + operator + " '" + value + "'";
	});
	return readableText;
}

function getReadableExclusionByParameterFilterText(filterObj, vectors) {

	if(!filterObj.length) {
		return "";
	}
		var readableText = lang.filter_text.WHERE;
		filterObj.forEach((row, index) => {
		let field = row.column;
		let vectorPrefix = field.includes(column_suffixes_values.number.label) ? column_suffixes_values.number.label : field.includes(column_suffixes_values.name.label) ? column_suffixes_values.name.label : field.includes(column_suffixes_values.key.label) ? column_suffixes_values.key.label : "";
		let vectorLabel = vectors && vectors.length > 0 && vectors.filter(e=>e.value === field.replace(vectorPrefix, "")).length > 0 ? vectors.filter(e=>e.value === field.replace(vectorPrefix, ""))[0].label + vectorPrefix : field;

		let operator = row.function.toLowerCase();
		var values = row.value || row.values;
		var value = "";
		if (typeof values === "object") {
			if (values.length > 0) {
				values.forEach(function(val, key){
					values[key] = val.value.toLowerCase();
				});
			}
			value = values.length > 0 ? values.join(', ') : "";
		} else { // case when numeric field value is not multi 
			value = values;
		}
		

		var operatorOptions = getObjectAsArray(lang.ui_filter.dropdowns.engine_filter_functions, "string", "value_type");
		operatorOptions = operatorOptions.concat(getObjectAsArray(lang.ui_filter.dropdowns.functions, "numeric", "value_type"));

		var tempOpObj = findOptionByKeyValue(operatorOptions, "value", operator.toUpperCase());
		operator = tempOpObj ? tempOpObj.label.toLowerCase() : "";

		readableText += index > 0 ? " " +row.logical_operator.toUpperCase() +" ": "";
		readableText += vectorLabel + " " + operator + " " + value + "";
	});
	return readableText;
}

function wrapFilterWithParenthesis (filter) {
	filter = copyObjectValues(filter);
	if(filter.filter && typeof filter.filter !== 'function'){
		if(filter.filter[0].parenthesis_before){
			filter.filter[0].parenthesis_before += "(";
		}
		if(filter.filter[filter.filter.length-1].parenthesis_after){
			filter.filter[filter.filter.length-1].parenthesis_after += ")";
		}
	}else{
		if(filter[0].parenthesis_before){
			filter[0].parenthesis_before += "(";
		}
		if(filter[filter.length-1].parenthesis_after){
			filter[filter.length-1].parenthesis_after += ")";
		}
	}
	
	return filter;
}

function wrapFilterArrayWithParenthesis (arr) {
	if(arr?.length>0){
		arr[0].parenthesis_before += "(";
		arr[arr.length-1].parenthesis_after += ")";
	}
	return arr;
}

function getReadableFilterTextPSLDefinition(filterObj, vectors, hiddenVectors = []) {

	if(!filterObj.length) {
		return "";
	}
	
	
		var readableText = lang.filter_text.WHERE_THE;
		filterObj.forEach((row, index) => {
		let field = row.column;
		let vectorPrefix = field.includes(column_suffixes_values.number.label) ? column_suffixes_values.number.label : field.includes(column_suffixes_values.name.label) ? column_suffixes_values.name.label : field.includes(column_suffixes_values.key.label) ? column_suffixes_values.key.label : "";
		let vectorLabel = vectors?.find(e=>e.value === field.replace(vectorPrefix, ""))? vectors.find(e=>e.value === field.replace(vectorPrefix, "")).label + vectorPrefix:
		hiddenVectors?.find(e=>e[VECTOR_STAGING_ATTRIBUTES.MACHINE_NAME] === field.replace(vectorPrefix, ""))?hiddenVectors.find(e=>e[VECTOR_STAGING_ATTRIBUTES.MACHINE_NAME] === field.replace(vectorPrefix, ""))[VECTOR_STAGING_ATTRIBUTES.NAME] +vectorPrefix : field;

		let operator = row.function.toLowerCase();
		var values = row.value;
		var value = "";
		if (typeof values === "object") {
			if (values.length > 0) {
				values.forEach(function(val, key){
					values[key] = val.value.toLowerCase();
				});
			}
			value = values.length > 0 ? values.join(',') : "";
		} else { // case when numeric field value is not multi 
			value = values;
		}
		

		var operatorOptions = getObjectAsArray(lang.ui_filter.dropdowns.engine_filter_functions_psl_definitions, "string", "value_type");
		operatorOptions = operatorOptions.concat(getObjectAsArray(lang.ui_filter.dropdowns.functions, "numeric", "value_type"));

		var tempOpObj = findOptionByKeyValue(operatorOptions, "value", operator.toUpperCase());
		operator = tempOpObj ? tempOpObj.label.toLowerCase() : "";

		readableText += index > 0 ? " " +row.logical_operator.toLowerCase() +" "+ lang.filter_text.AND_THE : "";
		if (value.length > 1) {
			if (value.indexOf(',') > -1) {
				var ruleRows = value.split(",");
				for (var i = 0; i < ruleRows.length; i++) {
					if (ruleRows[i].length === 1) {
						ruleRows[i] = ruleRows[i].toUpperCase();
					}
				}
				readableText += vectorLabel + " " + operator + " " + ruleRows.join(",") + "";
			} else {
				readableText += vectorLabel + " " + operator + " " + value.toLowerCase() + "";
			}
		} else {
			readableText += vectorLabel + " " + operator + " " + value.toUpperCase() + "";
		}
	});
	return readableText.replace(/\s+/g, ' ').trim(); //remove any double space
}


/**
 * This function should be the last modification to the filter, 
 * any changes needed to be made on the filter just before it's sent to the api
 * should be written in this function
 * @param {*} filter 
 * @param {*} isCompDidMount 
 */
function prepareFilter(filter, embedInFilterAttr, isPsFilterDisabled) {
	if(!filter) {
		filter = []
	} else if(typeof filter === "string") {
		filter = JSON.parse(filter);
	}

	if(typeof filter.filter === "object") {		//if the filter is an object having an array of filters under the "filter" attribute
		filter = filter.filter;
	}
	var finalFilter = [];
	filter.map(item=>{
		//escaping profit stack filter when psFilterDisabled = true
		if(isPsFilterDisabled && item.type === FILTER.FILTER_TYPE_PROFITSTACK) {
			return;
		}
		finalFilter.push(item);
	});

	if(embedInFilterAttr) {
		let tempFilter = {};
		tempFilter.filter = finalFilter;
		finalFilter = tempFilter;
	}
	
	return JSON.stringify(finalFilter);
}


function updateWindowLoading(show, tag, incrementTag=false) {

  if (!window._loading) {
    window._loading = {};
  }
  if (show) {
    if (incrementTag && window._loading[tag] !== undefined) {
      window._loading[tag] += 1;
    } else {
      window._loading[tag] = 1;
    }
  } else {
    if (window._loading[tag] !== undefined) {
      if (incrementTag && window._loading[tag] > 1) {
        window._loading[tag] -= 1; //if count for this key is > 1, reduce it
      } else {
        delete window._loading[tag]; //if count = 1, delete the key all together instead of setting it to 0
      }
    }
  }

  window.dispatchEvent(new Event("loadingChange"));

}

/**
 * This function toggles the loader on or off, it should be bound to each component
 * that has to use it, written here for the sake of modularity, just add:
 * this.toggleLoader = toggleLoader.bind(this); //we no longer need to bind since it's not depending on states anymore
 * UPDATE: When you want a loader that fires on a specific event and not a global one (when having multiple loaders rendered),
 *  send loaderClassName as param. This will prevent from having multiple loaders on the screen
 * @param {*} showLoader 
 * @param {String} loaderClassName a unique className for the loader
 */
function toggleLoader(showLoader, tag, smallLoader, showCustomizedLoader, callback, loaderClassName) {
	updateWindowLoading(showLoader, tag, true);
	
  let className = loaderClassName ?? "loader-body";
	var counter = 0;
	Object.keys(window._loading).filter(f => f !== "skeleton-loader").map(key=>{counter++});
	if (counter > 0) {
		if (smallLoader && showLoader) {
        	if (!$(".loading").not(".uk-width-1-3").is(":visible")) {
				$(".loading.uk-width-1-3").show();
			}
		} else if (showLoader && !smallLoader && !showCustomizedLoader){
      /** This is used for when we have a full screen loader and a loader for reports to prevent duplicate loaders.
       * In case we have want to show the full screen loader, we used the loaderClassName to show it so that we don't
       * show the report loader at the same time, because of these loaders are rendered.
       * See example of Format popup loader in output screens
       */
      $("." + className).show();

      /** When in engine screens, we only render 1 loader that have the below className so in that case we won't have 
       * the issue of having duplicate loaders. And this loader is only rendered in engine screens so it won't affect the above code.
       */
      $(".engine-full-loader").show();

			$(".loading").not(".uk-width-1-3").show();
			$(".loading.uk-width-1-3").hide();
		} else if(showLoader && showCustomizedLoader){
			$(".configure-loading").show();
			$(".loading").not(".uk-width-1-3").hide();
      $(".engine-full-loader").hide();
      
		} 
	} else {
			$(".loading").not(".uk-width-1-3").hide();
			$(".loading.uk-width-1-3").hide();
			$(".configure-loading").hide();
      $("." + className).hide();
      $(".engine-full-loader").hide()
	}
	if (callback && typeof callback === 'function') {
		callback();
	}
  
  window.dispatchEvent(new Event("loadingChange"));
}

function logout() {
	let _this=this;
	localStorage.removeItem('saved_filter_id');
	localStorage.removeItem('saved_filter');
  sessionStorage.removeItem(logged_in);
	removeAllCookies(['filterBox','filterFinal','isDrilling','tempScenarioId']);
	
	const myUrl = process.env.REACT_APP_BASE_URL + API_URL.USER +"?";
	setLocalStorageValueByParameter(window.location.host+"_"+_LOGGED_OUT, _LOGGED_OUT);
	setLocalStorageValueByParameter(CLIENT_ID_STORAGE, "");
	const body = {
		"action": "logout",
		"idToken": _this && _this.state && _this.state.idToken
	}

	// _this.setState({
	// 	user: null
	// },function(){
		if (_this?.state?.user) {
			_this.state.user = null; // remove rerendering on logout
		}
		$.ajax({url: myUrl, async: false, crossDomain:true, xhrFields: { withCredentials: true }, type: 'POST', data:JSON.stringify(body),
		complete: function(data){
			signOut(auth).then(() =>{
				if(_this && _this.toggleLoader) {
					_this.toggleLoader(false);
				}
				sessionStorage.clear();		//clearing session storage from all the saved data
				window.location.href = process.env.REACT_APP_CLIENT_BASE_URL;
			}).catch(function(error){
				console.log("error: "+ error);
			});
		}
	});
	// });

}

function goToReport(extractHref, eventOrPathname, tempState, isTableau) {
	//handling the pathName by removing the origin
	var url = $(eventOrPathname.target).attr("url");
	var isPeriodAllowed = $(eventOrPathname.target).attr("allowperiodselection");
	if (url && url !== null && !isTableau) {
		// $('.menu-toggle-item').click();
		$("#menu_close").click();
		window.open(url);
		return;
	}
	var pathName = extractHref ? $(eventOrPathname.target).attr("data_href") : eventOrPathname;
	pathName = pathName.replace(window.location.origin, "");
	// pathName = pathName[0] === "/" ? pathName.substr(1) : pathName;
	let destComponent = pathName.substr(0, pathName.indexOf("/",1));
	var parentState = extractHref ? this.props.parentState || {} : tempState;
	parentState.origin = window.location.href;
	parentState.url = url; // tableau url
	parentState.reportName = $(eventOrPathname.target).attr("reportname"); // tableau report name
	parentState.isPeriodAllowed = isPeriodAllowed; // tableau url

	//Menu is not exported withRouter() so we have to send the parent report's props.history as history
	if(this.props.history) {
		let sourceComponent = this.props.history.location.pathname.substr(0, this.props.history.location.pathname.indexOf("/",1));// /financial_reporting

		// props.history.push replaces the value after the last "/" only,
		//so if current URL contains more than 1 "/", the new pathName will be wrong, fix before going to new URL

		// if(this.props.history.location.pathname.match(/\//g).length > 1) {
		// 	this.props.history.push({
		// 		pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.indexOf("/",1)),
		// 		search: "?",
		// 		hash: "",
		// 		state: parentState
		// 	});
		// }

		this.props.history.push({
			pathname: pathName,
			search: "?",
			hash: "",
			state: parentState
		});
		// if((destComponent.toLowerCase() === sourceComponent.toLowerCase() && destComponent.toLowerCase() !== "/"+ALL_WIDGETS.FIELDS.SECOND_DIMENSION.toLowerCase()) 
		// || (destComponent.toLowerCase() !== sourceComponent.toLowerCase() && [destComponent.toLowerCase(), sourceComponent.toLowerCase()].includes("/"+ALL_WIDGETS.FIELDS.SECOND_DIMENSION.toLowerCase()))) {
		// 	window.location.reload();
		// }

		// reload page for dataModeling and customReports
		if(["/" + CUSTOM_REPORT].includes(destComponent.toLowerCase())) {
			window.location.reload();
		}	//this is a hack to force reload when changing url between component with same parent like CMTK, will be reviewed
		
	} else {
		//if props.history not sent as props, just change the URL
		window.location.href = $(eventOrPathname.target).attr("data_href");
	}
}

/**
 * This function reloads the page, but instead of using window.location.reload,
 * it uses React Router functions to try and preserve the history state
 * -- requires binding to context
 * @param {*} passedState 
 */
function historyPushAndReload(passedState) {
	passedState = passedState ? passedState : this.props.history && this.props.history.location.state ? this.props.history.location.state : {
		origin: window.location.href,
		profitFormat: this.state.profitFormat || this.props.profitFormat
	}

	this.props.history.push({
		pathname: window.location.pathname,
		search: "?",
		hash: "",
		state: passedState
	});
	this.props.history.go();	//pushing the state to the current pathname doesn't reload the page, so we use go()
}

/**
 * This function implements a basic comparison functionality
 * between props/state and nextProps/nextState
 * @param {*} comp -- since context is passed as param, doesn't require binding
 * @param {*} nextProps 
 * @param {*} nextState 
 */
function shouldCompUpdate(comp, nextProps, nextState) {
	var shouldUpdate = false;
	shouldUpdate = !deepCompareObjects(nextProps, comp.props);
	if(!shouldUpdate) {
		shouldUpdate = !deepCompareObjects(nextState, comp.state);
	}
	return shouldUpdate;
}

function addSimpleTooltipIcon(tooltipMessage, faClass) {
    faClass = faClass || "fa-info-circle";  //default class if none is provided
    var el = <i className={"fs-12 fal uk-margin-xsmall-left " + faClass} uk-tooltip={tooltipMessage}></i>;
    return el;
}

/**
 * this function takes a list of css classes and makes the elements having these classes draggable
 * @param {*} classes 
 */
function setDraggable(classes = []) {
	var draggableOptions = {
		// start: function( event, ui ) {
		//     let $node = $(event.target);
		//     obj.$clone = $node.clone();
		//     $node.removeClass("uk-position-none");
		//     $node.css("position","absolute");          //freeing the space reserved by the original node that is being dragged
		//     $node.after(obj.$clone);                //immediately replacing the emptied space with a clone of the original node
		// },
		// stop: function(event, ui) {
		//     //if dragged and dropped outside the formula div
		//     let $node = $(ui.helper[0]);
		//     $node.remove();   //removing dragged node since it was immediately replaced
		//     obj.$clone.draggable(draggableOptions);
		//     obj.$clone.show();  //show hidden clone node
		// },
		scroll: false,
		cursor: "grab",
		helper: 'clone',
		appendTo: 'body',
		containment: 'window',
		revert: "invalid",
	};

	if(classes.length && !Array.isArray(classes)) {
		classes = [classes];    //if props was a single class and sent as string instead of array
	}
	$("."+ classes.join(", .")).draggable(draggableOptions);
}

function getMonthsFromQuarters(startQuarter, endQuarter) {
	let tempQuarter = startQuarter;
	let array = [tempQuarter];
	do {
		let tempYear = Number(tempQuarter.split("Q")[0]);
		let tempQ = Number(tempQuarter.split("Q")[1]);
		if(tempQ === 4) {
			tempQ = 1;
			tempYear++;
		} else {
			tempQ++;
		}
		tempQuarter = tempYear + "Q" + tempQ;
		array.push(tempQuarter);
	} while (tempQuarter !== endQuarter && startQuarter !== endQuarter);
	
	let quarterDifference = array.lastIndexOf(endQuarter) - array.indexOf(startQuarter) + 1;	//+1 to compensate for index starting at 0
	switch(quarterDifference) {
		case 1:
			return FY_VALUES.M3;
		case 2:
			return FY_VALUES.M6;
		case 3:
			return FY_VALUES.M9;
		case 4:
			return FY_VALUES.FY;
	}
}

const loaderCallback = (instance, isLoading) => {
	if(typeof instance.props.loaderCallback === "function") {
		instance.props.loaderCallback(isLoading);
	}
}


function getCostTooltipData(costClassification, glCostsProps, client_costcenter, rowData, periods, vectorOptions,metricFields=[],hiddenVectors=[]) {

	let returnName = rowData[PSS.RETURN_NAME];
	let name = rowData[PSS.NAME];
	let description = rowData[PSS.DESCRIPTION];
	let costcenter = rowData[PSS.COST_CENTER];
		
	var comp = this;
	var html = lang.no_data_available;
	if(costClassification){
		var costClassification = costClassification.filter(e => e.returnname === returnName);
		var glCosts = "";
		var humanReadableRule = "";
		var humanReadableFilter = "";
		var rules = [];
		var filters = [];
		var planTerm = lang.not_defined_value;
		if(costClassification.length > 0)  { 
			glCosts = glCostsProps.map(function(cost, index) {
				var costName = cost.name + (costClassification[0][cost.name] && costClassification[0][cost.name].includes(", ") ? "s" : "");
				var costValue = costClassification[0][cost.name];
			  
				// Check if costValue is "Undefined" and replace it with an empty string
				if (typeof costValue === 'undefined') {
				  costValue = "";
				}
			  
				return (
				  <div
					title={costValue}
					key={returnName + "_cost_" + shortid.generate()}
					className="uk-padding-xxsmall-top psl-tooltip-overfow-ellipsis whitespace-initial uk-text-capitalize"
				  >
					{costName + ": " + costValue}
				  </div>
				);
			  });
			if (costClassification[0][COST_FUNCTIONS_FIELDS.CONFIG].length > 0) {
				for (var e in costClassification[0][COST_FUNCTIONS_FIELDS.CONFIG]) {
					var periods = costClassification[0][COST_FUNCTIONS_FIELDS.CONFIG].length === 1 ? [] : costClassification[0][COST_FUNCTIONS_FIELDS.CONFIG][e].periods && costClassification[0][COST_FUNCTIONS_FIELDS.CONFIG][e].periods.length === 0 ? fetchPeriods(costClassification[0][COST_FUNCTIONS_FIELDS.CONFIG], e, periods) :[costClassification[0][COST_FUNCTIONS_FIELDS.CONFIG][e].periods];
					var rule =  [undefined, ""].includes(costClassification[0][COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.RULE]) ? lang.undefined_value : costClassification[0][COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.RULE];
					var readableRule = costClassification[0][COST_FUNCTIONS_FIELDS.COST_TYPE.toLowerCase()] === INVOICELINETYPE ? rule.replace("_cc","") : getReadableRuleText(rule, vectorOptions,metricFields,hiddenVectors);
					var ruleToShow = periods.length === 0 ? readableRule : readableRule + " (" + periods.join(",") + ")";  			
          rules.push(ruleToShow.replace(/_cc/g, ""));
					var filter = [undefined, ""].includes(costClassification[0][COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.JSON_FILTER]) ? lang.psl_tooltip.none_value : costClassification[0][COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.JSON_FILTER];
					var readableFilter = costClassification[0][COST_FUNCTIONS_FIELDS.COST_TYPE.toLowerCase()] === INVOICELINETYPE || filter === lang.psl_tooltip.none_value
					? filter : getReadableFilterText(tryParse(filter, "") ? tryParse(filter, "").filter : "", vectorOptions, hiddenVectors);
					var filterToShow = periods.length === 0 ? readableFilter : readableFilter + " (" + periods.join(",") + ")";
					filters.push(filterToShow.replace(/_cc/g, ""));
				}
			}
			planTerm = [undefined, ""].includes(costClassification[0]["planterm"]) ? lang.not_defined_value : costClassification[0]["planterm"];
			humanReadableRule = costClassification[0][COST_FUNCTIONS_FIELDS.COST_TYPE.toLowerCase()] === INVOICELINETYPE ? rule.replace("_cc","") : getReadableRuleText(rule, vectorOptions,metricFields);
      		humanReadableFilter = costClassification[0][COST_FUNCTIONS_FIELDS.COST_TYPE.toLowerCase()] === INVOICELINETYPE || filter === lang.psl_tooltip.none_value
									? filter : getReadableFilterText(tryParse(filter, "") ? tryParse(filter, "").filter : "", vectorOptions, hiddenVectors);
		}
		
		html = <div>
				{/* <div className="more-details-dialog-title uk-text-bold uk-text-center">{lang.psl_tooltip.profit_stack_line + name}</div> */}
				<div className="more-details-dialog-body">
					{description !== undefined && 
						<div className="uk-padding-small-top">
							<div className="uk-text-bold">{lang.psl_tooltip.description}</div>
							{description}
						</div>
					}
					<div className="uk-padding-small-top">
						<div className="uk-text-bold">{lang.psl_tooltip.gl_cost_source}</div>
						{glCosts}
					</div>
					<div className="uk-padding-small-top">
						<div className="uk-text-bold">{client_costcenter + " " + lang.psl_tooltip.assignment}</div>
						{costcenter && costcenter.toLowerCase() !== lang.all.toLowerCase() ? lang.modal.buttons.yes : lang.modal.buttons.no}
					</div>
					<div className="uk-padding-small-top">
						<div className="uk-text-bold">{lang.psl_tooltip.cost_function}</div>
						<div title={rules.join(",")} className="uk-padding-xxsmall-top psl-tooltip-overfow-ellipsis">{lang.psl_tooltip.rule + rules.join(",")}</div>
						<div title={filters.join(",")} className="uk-padding-xxsmall-top psl-tooltip-overfow-ellipsis">{lang.psl_tooltip.filter + filters.join(",")}</div>
					</div>
					<div className="uk-padding-small-top">
						<div className="uk-text-bold">{lang.psl_tooltip.plan_term}</div>
						<div className="uk-padding-xxsmall-top">{planTerm}</div>
					</div>
				</div>
			</div>;	
	}
	return html;
}

function fetchPeriods(config, index, periods){
	var periods = periods;
	var periodstoPush = [];
	for (var em in periods) {
		var found = false;
		for (var e in config) {
			if (index !== e) {
				if (config[e].periods.includes(periods[em].value)) {
					found = true
				}
			}
		}
		if (!found) {
			periodstoPush.push(periods[em].value);
		}
	}
	return periodstoPush
}

/**
 * prepare the downloaded csv file name
 * @param {*} data 
 * @returns 
 */
const formatFileName = (data, userSettings) => {
	let fileName = "U"+ (userSettings.userId?userSettings.userId:"");
	fileName += "_" + (userSettings.machine_name || "");
	fileName += "_" + (data.tierName? data.tierName.replace(/\s/g, "_").replace(/[()]/g, ""):"");
	fileName += "_" + getSelectedPeriodString(data.selectedPeriods) +"_";
	fileName += data.filter === EMPTY_FILTER? "F0" : "F1";
	fileName += "_" + formatCurrentDate();
	fileName +=".csv";
	return fileName;
}

/**
 * start downloading the csv file
 * @param {*} blob 
 * @param {*} data 
 * @param {*} clientName 
 */
function triggerExportData(blob, data, userSettings) {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = formatFileName(data, userSettings);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

/**
 * Function that returns if a number is within a given range
 * @param x
 * @param min
 * @param max
 * @returns {boolean}
 */
function between(x, min, max) {
	return x >= min && x <= max;
}

function getDefaultLocalStorageValueByParameter(param, defaultValue) {
	var storageValue = localStorage.getItem(param);
    if([undefined, "undefined", "null", null].includes(storageValue)) {
        return defaultValue;
    } else {
        return storageValue;
    }
}

function getLocalStorageValueByParameter(param) {
	return localStorage.getItem(param);
}

function setLocalStorageValueByParameter(param, value) {
	localStorage.setItem(param, value);
}


/**
 * sum of all the attributes of data
 * @param {*} data 
 * @param {*} attribute 
 * @returns 
 */
function getSummationOfAttribute(data, attribute){
	return data.reduce(function(prev, current) {
		return prev + +current[attribute]
	  }, 0);
}

/**
 * Translates used function to symbols
 * @param {*} filterFunction 
 * @returns 
 */
function getFormatedFilterFunction(filterFunction) {
  switch (filterFunction) {
    case lang.ui_filter.dropdowns.functions.greater.value:
      return ">";
    case lang.ui_filter.dropdowns.functions.less.value:
      return "<";
    case lang.ui_filter.dropdowns.functions.less_or_equalsNUM.value:
      return "<=";
    case lang.ui_filter.dropdowns.functions.greater_or_equalsNUM.value:
      return ">=";
    case lang.ui_filter.dropdowns.functions.equals.value:
      return "=";
    case lang.ui_filter.dropdowns.functions.betweenNUM.value:
      return lang.ui_filter.dropdowns.functions.betweenNUM.label.toLowerCase();
    case lang.ui_filter.dropdowns.functions.start_with.value:
      return lang.ui_filter.dropdowns.functions.start_with.label.toLowerCase();
    case lang.ui_filter.dropdowns.functions.end_with.value:
      return lang.ui_filter.dropdowns.functions.end_with.label.toLowerCase();
    case lang.ui_filter.dropdowns.functions.not_empty.value:
    case lang.ui_filter.dropdowns.functions.empty.value:
      return getObjectAsArray(lang.ui_filter.dropdowns.functions, filterFunction, "value")[0].label;
    default:
      return "";
  }
}

/**
 * if the function used is "is contains", wrap filter with *
 * @param {*} filter 
 * @returns 
 */
function filterContainsFormat(filter) {
  return "*" + filter + "*";
}

/**
 * if the function used is "not contains"/"not equal", wrap filter with !
 * @param {*} filter 
 * @returns 
 */
function filterNotFormat(filter) {
  return "!" + filter + "!";
}

/**
 * wrap with () based on operator used
 * @param {*} operator 
 * @returns 
 */
function getOperatorFormat(operator) {
  const _AND = lang.ui_filter.dropdowns.buttons.AND;
  const _OR = lang.ui_filter.dropdowns.buttons.OR;

  operator = operator.toUpperCase();
  switch (operator) {
    case _OR:
      return " " + operator + " ";
    case _AND:
      return " " + operator + " ";
	default:
		return "";
  }
}

/**
 * format basic filter used
 * @param {*} filters 
 * @param {*} allowedVectors 
 * @returns 
 */
function formatBasicFilter(filters, allowedVectors) {
  if (!allowedVectors) {
    return "";
  }
  
  if (typeof filters === "string") {
    filters = JSON.parse(filters);
  }

  if(!filters || filters.length === 0) {
	return "";
  }

  let filterText = "";
  if(filters && filters[0]?.filter_display_name) {
	filterText += filters[0]?.filter_display_name + " ";
  }
  filterText += "(";
  filters.map((filter, index) => {
    let tempFilterText = "";
	// check access of basic filter on name/number
	if((allowedVectors[filter.vector]?.show_number === false && filter.field === "number") || (allowedVectors[filter.vector]?.show_name === false && filter.field === "name")) {
		return "";
	}
    tempFilterText += (filter.vectorLabel || filter.vector)  + ":" + filter.field + " " + getFormatedFilterFunction(filter.function) + " ";
	if ([SEGMENTS_TITLES.PROFIT_SEGMENTS.value, SEGMENTS_TITLES.PROFIT_TIERS.value].includes( filter.field)) {
		let segment = new Segment();
		let filterEntitiesArr = typeof filter.entities === 'object' ? filter.entities : filter.entities.split(",");
		let final = [];
		for (var e in filterEntitiesArr) {
		  final.push(segment.getSegmentObject(typeof filterEntitiesArr[e] === 'object' ? filterEntitiesArr[e].value.replace(/ /g,'') : filterEntitiesArr[e].replace(/ /g,''))?.label)
		}
	   
		let label =  final.join(",");
		tempFilterText += label;
	  }else{
		if(typeof filter.entities === "object"){
			filter.entities.map((entity, i) => {
				return (tempFilterText += (i > 0 ? ", " : "") + entity.value.toUpperCase());
			});
		}
	
	  }
	
    if (index < filters.length - 1) {
      tempFilterText += getOperatorFormat(filter.logical_operator) || (" " + lang.ui_filter.dropdowns.buttons.AND + " ");
    }

    filterText += tempFilterText;
    return filterText;
  });

  filterText += ")";
  return filterText;
}

function getLastDataset(dataset, options) {
	var fromOptions = [{label: lang.default_label, value: ""}];
	fromOptions = fromOptions.concat(options);

	dataset = dataset.value ? dataset.value : dataset;
	let datasets = returnLastDatasets(fromOptions, dataset);
		if (datasets.length === 4) {
		   return datasets[datasets.length-1].value; //send first element
	}

	return null;
}

/**
 * Replace the constanr "%CURRENCY%" with the actual currency, and replace the USD_SIGN (the default currency) with the actual currency
 * @param {*} str 
 * @returns 
 */
function handleCurrency(str){
	str = str.replace(CURRENCY ,(window._format?.format_currency_sign || USD_SIGN)).replace(USD_SIGN ,(window._format?.format_currency_sign || USD_SIGN))
	return str;
}

function getCurrency(){
	return window._format?.format_currency_sign;
}

/**
 * translate a color from hex form to RGB
 * @param {*} hex 
 * @returns 
 */
function hexToRgb(hex) {
	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
	  r: parseInt(result[1], 16),
	  g: parseInt(result[2], 16),
	  b: parseInt(result[3], 16)
	} : null;
  }

/**
 * format advanced filter
 * @param {*} filters 
 * @param {*} allowedVectors 
 * @param {*} vectorOptions 
 * @returns 
 */
function formatAdvancedFilter(filters, allowedVectors, fromOptions, vectorOptions, pslOptions) {
	const _neq = lang.ui_filter.dropdowns.functions.not_equals.value;
	const _ct = lang.ui_filter.dropdowns.functions.contains.value;
	const _nct = lang.ui_filter.dropdowns.functions.not_contains.value;
	const _isBetween = lang.ui_filter.dropdowns.functions.betweenNUM.value;
	const _quadrantField = SEGMENTS_TITLES.PROFIT_SEGMENTS.label;
	const _quadrantTierField = SEGMENTS_TITLES.PROFIT_TIERS.label;
	const _from = FILTER.KEYS.FROM_QUARTER;
	const _to = FILTER.KEYS.TO_QUARTER;

	if (!allowedVectors) {
    return;
  }
  if (typeof filters === "string") {
    filters = JSON.parse(filters);
  }

  if(!filters || filters.length === 0) {
	return "";
  }

  let filterText = "";
  if(filters && filters[0]?.filter_display_name) {
	filterText += filters[0]?.filter_display_name + " ";
  }

  var heatmapFilterIndex = filters.findIndex(v => v.filter_row_type === FILTER.VALUES.FILTER_ROW_TYPE.HEATMAP);
  if(heatmapFilterIndex > 0) {
	filters[0].parenthesis_before = "((";
	filters[heatmapFilterIndex-1].parenthesis_after="))";
  }

  filters.map((filter, index) => {
    let tempFilterText = "";
	// check access of basic filter on name/number
	if((allowedVectors[filter.vector]?.show_number === false && filter.field === "number") || (allowedVectors[filter.vector]?.show_name === false && filter.field === "name")) {
		return;
	}

	let isPsl = filter.type === FILTER.VALUES.TYPE.PROFIT_STACK;
	let vectorName = filter.vectorLabel ? filter.vectorLabel : vectorOptions?.filter(f => f.value === filter.vector)[0]?.label;
	let pslName = isPsl ? linearizeHierarchy(pslOptions, SECTION.CHILDREN)?.filter(f => f.value === filter.field)[0]?.label : "";
    let filterField = isPsl ? pslName : filter.field;

	if(index > 0) {
		filterText += getOperatorFormat(filter.logical_operator) 
	}
	// if(heatmapFilterIndex === index && filter.logical_operator.toUpperCase() === lang.ui_filter.dropdowns.buttons.AND) {
	// 	filterText += "(";
	// }
	filterText += filter.parenthesis_before;

	tempFilterText += vectorName + ":" + capitaliseFirstLetterAfterChar(filterField).replace("_"," ") + " " + getFormatedFilterFunction(filter.function) + " ";
    if (filter.function === _isBetween) {
      tempFilterText += filter[FILTER.KEYS.MIN] + " and " + filter[FILTER.KEYS.MAX];
    } else {
		let label;
      if (typeof filter.entities === "object" && filter.entities.length > 0) {
		if ([SEGMENTS_TITLES.PROFIT_SEGMENTS.value, SEGMENTS_TITLES.PROFIT_TIERS.value].includes( filter.field)) {
			let segment = new Segment();
			let filterEntitiesArr = filter.entities;
			let final = [];
			for (var e in filterEntitiesArr) {
			  final.push(segment.getSegmentObject(filterEntitiesArr[e].value.replace(/ /g,''))?.label)
			}
			label =  final.join(", ");
			tempFilterText += label;
		  }else{
			filter.entities.map((entity, i) => {
			return (tempFilterText += (i > 0 ? ", " : "") + entity.value.toUpperCase().replace("_"," "));
			});
		}
      } else {
		if ([SEGMENTS_TITLES.PROFIT_SEGMENTS.value, SEGMENTS_TITLES.PROFIT_TIERS.value].includes( filter.field)) {
			let segment = new Segment();
			let filterEntitiesArr = filter.entities.split(",");
			let final = [];
			for (var e in filterEntitiesArr) {
			  final.push(segment.getSegmentObject(filterEntitiesArr[e].replace(/ /g,''))?.label)
			}
		   
			label =  final.join(", ");
			tempFilterText += label;
		  }else{
        	tempFilterText += filter.entities; //value written in input for profit stack filter
		  }
      }
    }

    let quarters = [];
    if ([_quadrantField, _quadrantTierField].includes(filter.field)) {
      if (filter[_from] && filter[_from] !== "") {
        quarters.push(filter[_from]);
      }
      if (filter[_to] && filter[_to] !== "") {
        quarters.push(
          filter[_to] === lang.ui_filter.dropdowns.months.mo3.value ? filter[_from] : getLastDataset(filter[_from], fromOptions)
        );
      }
    }
    let quarterDisplay = quarters.length ? " [" + quarters.join(" - ") + "]" : "";
    tempFilterText += quarterDisplay;

    if ([_ct, _nct].includes(filter.function)) {
      tempFilterText = filterContainsFormat(tempFilterText); // add star if filter function is contains/not contains
    }
    if ([_neq, _nct].includes(filter.function)) {
      tempFilterText = filterNotFormat(tempFilterText); // add ! if filter function has not (ie: not equal, not contains)
    }

    filterText += tempFilterText;
	// if(filter.parenthesis_after.length > 1) {
		filterText += filter.parenthesis_after
	// }
    return filterText;
  });

//   filterText += ")";
  return filterText;
}

function areObjectsEqual(obj1, obj2) {
	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);
  
	if (keys1.length !== keys2.length) {
	  return false;
	}
  
	for (let key of keys1) {
	  if (obj1[key] !== obj2[key]) {
		return false;
	  }
	}
  
	return true;
}

function equalsIgnoreCase(str1, str2) {
    return str1.toLowerCase() === str2.toLowerCase();
}

function getRowVectorType(row) {
	if (!row) return;
	if(row.name || row.number){
		return ENTITY_TYPES.KEY;
	}else if(row.quadrant_tier || row.quadranttier) {
		return ENTITY_TYPES.PROFITTIER;
	}else{
		return ENTITY_TYPES.SEGMENT;
	}
}

function isFunction(func) {
	return func && typeof func === "function";
}



export {cleanUpTabulatorColumns, Logo, cleanUpSingleTabulatorColumn, checkIfSectionIdExists, getReadableRuleText, getReadableRuleTextPSLDefinition,
	getReadableFilterText, getReadableFilterTextPSLDefinition, prepareFilter, toggleLoader, logout, goToReport, shouldCompUpdate, addSimpleTooltipIcon,
	setDraggable,getReadableFilterTextVectors,humanTranslate,getLeafNodes,getRandomColor, getMonthsFromQuarters, loaderCallback, getCostTooltipData,translateSymbols, between,
	getLocalStorageValueByParameter, setLocalStorageValueByParameter,getSummationOfAttribute, _LOGGED_OUT, formatBasicFilter, formatAdvancedFilter, hexToRgb,
	getDefaultLocalStorageValueByParameter,translateTabulatorFilter,translateTabulatorSorter,getReadableExclusionByParameterFilterText,
  	updateWindowLoading, areObjectsEqual, equalsIgnoreCase, wrapFilterWithParenthesis, getReadableCostTerm, triggerExportData, formatFileName, handleCurrency, 
  	getCurrency, wrapFilterArrayWithParenthesis, getRowVectorType, isFunction
 };
