import {forwardRef, useImperativeHandle, useRef} from "react";
import {ReactComponent as Check} from "../../../../styles/images/icons/check-new.svg";
import {ReactComponent as Close} from "../../../../styles/images/icons/close-new.svg";
import { ReactComponent as CalendarsIcon } from "../../../../styles/images/icons/calendars.svg";
import { ReactComponent as WarningIcon } from "../../../../styles/images/circle-exclamation.svg";
import NewBanner from "../../../NewBanner.js";
import {useEffect, useState} from "react";
import {BUTTON_TYPE, BUTTON_VARIANT, DETAILED_TIE_OUT, DIALOG_SIZE, SIZES, TIE_OUT_PL_GL} from "../../../../class/constants";
import {FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI} from "../../../../class/networkUtils";
import {formatDateMMDDYYY, formatUsdCurrency, getTranslationFile} from "../../../../class/utils.js";
import Button from "../../../../newComponents/Button";
import Input from "../../../../newComponents/Input";
import "./style/tieOutPLGL.css";
import  Tabulator from "tabulator-tables";
import { exportToExcel } from "../../../../class/tabulatorExport";
import { getReadableExclusionByParameterFilterText, getReadableFilterText } from "../../../../class/common";
import Modal from "../../../../newComponents/Modal";
import DetailedTieOutModal from "./DetailedTieOutModal";
import MonthlyBuildCurrentPeriodBreadcrumbs from "../../MonthlyBuildCurrentPeriodBreadCrumps.js";


const lang = getTranslationFile();

const TieOutPLGL = ({period, startDate, endDate, setTieOutMessageParam, goToUpload, showErrorsDialog, hideErrorsDialog, renderMonthlyBuildBanner}, ref) => {

    const [formattedValue, setFormattedValue] = useState("");
    const [disableInput, setDisableInput] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [accountInfoData, setAccountInfoData] = useState(null);
    const [tiedOutValue, setTiedOutValue] = useState("");
    const [tieOutCalculation, setTieOutCalculation] = useState(null);
    const [activeStep, setActiveStep] = useState(0);

    const glRevenueTabulator = useRef(null);
    const glCogsTabulator = useRef(null);
    const glOpexTabulator = useRef(null);
    const glExclusionsTabulator = useRef(null);
    const glExclusionsByParameterTabulator = useRef(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [showModalError, setShowModalError] = useState(false);
    const detailedTieOutModalRef = useRef();
    const [detailedTieOutErrorMessage, setDetailedTieOutErrorMessage] = useState(lang.monthly_build.tie_out_pl_gl.no_value_error_message);

    const bannerIcon = {
        TIED_OUT: <Check className = {"green-circle banner-icon"}/>,
        NEARLY_TIED_OUT: <Check className = {"yellow-circle banner-icon"}/>,
        NOT_TIED_OUT: <Close className = {"red-circle banner-icon"}/>,
    };


    useEffect(() => {
        initializeTabulators();
        fetchGLAccountInfo();
    }, []);


    useImperativeHandle(ref, () => ({
        saveTiedOutValue: () => {
            saveTiedOutValue();
        },
        tieOutCalculation: () => {
            return tieOutCalculation;
        },
        tiedOutValue: tiedOutValue,
        accountInfoData: accountInfoData
    }));
    const initializeTabulators = () => {
        let columns = [
            { title: 'Account Number', field: 'account_number',format_type:"text"},
            { title: 'Account Name', field: 'account_name',format_type:"text" },
            { title: 'GL Amount', field: 'amount' , format_type:"amount"},
          ];
        let exclusionByParameterColumns = [
            { title: 'Subtype', field: 'subtype',format_type:"text"},
            { title: 'Filter', field: 'filter',format_type:"text" },
        ]
        glRevenueTabulator.current = new Tabulator("#glRevenueAccounts", {
            data: [],
            columns: columns,
        });
        glCogsTabulator.current = new Tabulator("#glCogsAccounts", {
            data: [],
            columns: columns,
        });
        glOpexTabulator.current = new Tabulator("#glOpexAccounts", {
            data: [],
            columns: columns,
            });
        glExclusionsTabulator.current = new Tabulator("#glExclusions", {
            data: [],
            columns: columns,
        });
        glExclusionsByParameterTabulator.current = new Tabulator("#glExclusionsByParameter", {
            data: [],
            columns: exclusionByParameterColumns,
        });
    }
    const setDetailedDialogOpen = (param) => {
        setOpenDialog(param);
        setShowModalError(false);
    }

    const setModalProceed = (param) => {
        setShowModalError(param);
    }

    const setTieOutFromModalValue = (param) => {
        formatAndUpdateInput(param);
        calculateTieOutLogic(null, param);
    }
    const setDetailedTieOutErrorMessageParam = (message) => {
        setDetailedTieOutErrorMessage(message);
    }

    const renderBanner = () => {

        let formattedProfitGLAmount = formatAndUpdateInput(accountInfoData.glAccountsAmount.profitGLAmount, true);
        let formattedTieOutDelta = formatAndUpdateInput(tieOutCalculation.tieOutDelta, true);

        let tieOutMessage = lang.monthly_build.tie_out_pl_gl.banner_messages[tieOutCalculation.tieOutResult]
        ?.replaceAll("<EBITDA>", accountInfoData.profitName)
        .replaceAll("<GL EBITDA>", formattedProfitGLAmount)
        .replaceAll("<Tie out Δ value>", formattedTieOutDelta)
        .replaceAll("<Tie out %>", Math.abs(tieOutCalculation.tieOutPercentage) == Infinity ? 0 : tieOutCalculation.tieOutPercentage);

        let messageWithLineBreaks = formatMessage(tieOutMessage);

        let tiedOutBody = <div className="p-text " >
                            {messageWithLineBreaks}
                            {tieOutCalculation.tieOutResult !== TIE_OUT_PL_GL.TIED_OUT &&
                            <div className = {"uk-flex uk-flex-center"}> <Button
                                id={"detailed-tie-out-btn"}
                                label="Perform detailed tie out"
                                variant={BUTTON_VARIANT.SECONDARY}
                                size={SIZES.DEFAULT}
                                type={BUTTON_TYPE.DEFAULT}
                                disabled = {buttonDisabled}
                                className = {"uk-margin-small-top"}
                                onBtnClick={() => {
                                    setDetailedDialogOpen(true);
                                }}
                            /> </div>}
                        </div>
       
        let banner =
            <NewBanner
                id={"banner-" + TIE_OUT_PL_GL.BANNER_CLASS_NAME[tieOutCalculation.tieOutResult]}
                bannerClassName={TIE_OUT_PL_GL.BANNER_CLASS_NAME[tieOutCalculation.tieOutResult] + " large-margin-bottom tie-out-banner"}
                icon={bannerIcon[tieOutCalculation.tieOutResult]}
                body={tiedOutBody}
            />

        return banner;
    }


    const formatAndUpdateInput = (value, formatAndReturn) => {
        const isNegative =  String(value).includes("-");
        const cleanedValue = String(value).replace(/[^\d.]/g, '');
        const parts = cleanedValue.split('.');
        let integerPart = parts[0] || '';
        const decimalPart = parts[1] || '';
        let formatted = '';
        for (let i = 0; i < integerPart.length; i++) {
            if(i === 0){
                formatted += '$';
            }
            if (i > 0 && (integerPart.length - i) % 3 === 0) {
                formatted += ',';
            }
            formatted += integerPart[i];
        }
        if (cleanedValue.includes('.')){
            formatted += '.';
        }
        if (decimalPart !== ''){
            formatted += decimalPart[0] +  (decimalPart[1] ? decimalPart[1] : ''); // allow the user to only add 2 decimals only
        }
        if(isNegative){
            formatted = '-'+ formatted;
        }
        if(formatAndReturn){
            return formatted;
        }
        let tieOutValueState = isNegative ? "-"+cleanedValue : cleanedValue;
        setFormattedValue(formatted);
        setTiedOutValue(tieOutValueState);
    };

    const onInputChange = (e) => {
        formatAndUpdateInput(e.target.value);
        //reset the calculation on input change and hide the banner
        setTieOutCalculation(null);

    };

    const calculateTieOutLogic = (paramData, tieOutParam) => {

        let data = paramData || accountInfoData;
        let plAmount = tieOutParam || tiedOutValue;
        if(paramData !== undefined){
            plAmount = data.tiedOutValue;
        }
        if (plAmount !== "" && plAmount !== "-"){
            hideErrorsDialog();
            let tieOutThreshold = data.tieoutThresholds.tieoutThreshold;
            let glAmounts = data.glAccountsAmount.profitGLAmount;
            let tieOutDelta = Math.floor(glAmounts - plAmount);
            let tieOutPercentage = getTieOutPercentage(glAmounts, plAmount);
            let tieOutDifference = Math.abs(100 - tieOutPercentage);
            let tieOutResult = tieOutDifference === 0 ? TIE_OUT_PL_GL.TIED_OUT : tieOutDifference <= tieOutThreshold ? TIE_OUT_PL_GL.NEARLY_TIED_OUT : TIE_OUT_PL_GL.NOT_TIED_OUT;
            let tieOutCalculationObj = {
                tieOutDelta,
                tieOutResult,
                tieOutPercentage,
                plAmount
            }
            setTieOutCalculation(tieOutCalculationObj);
        }else {
            setTieOutCalculation(null);
            setTieOutMessageParam(lang.monthly_build.tie_out_pl_gl.no_value_error_message);
            showErrorsDialog();
        }

    };

    const getTieOutPercentage = (glAmounts, plAmount) => {
        // Tie out percentage should be 100 in case both glAmounts & plAmount are 0
        if (parseInt(glAmounts) === 0 && parseInt(plAmount) === 0) {
            return 100;
        }

        return parseFloat(((glAmounts / plAmount) * 100).toFixed(1));
    }

    
    const formatMessage = (message, resultParam) => {
        const tieOutResult = resultParam || tieOutCalculation.tieOutResult;
        const lines = message.split('\n');
        const formattedLines = lines.map((line, index) => {
          if (line.includes('(Tie out Δ') && tieOutResult === TIE_OUT_PL_GL.NOT_TIED_OUT) {
            return (
              <span key={index} style={{ color: 'red' }}>
                {line}
              </span>
            );
          }
          return <span key={index}>{line}</span>;
        });
      
        return formattedLines.map((formattedLine, index) => (
          <div key={index}>{formattedLine}</div>
        ));
      };

    const fetchGLAccountInfo = () => {
        var query = {
            action: "fetchGLAccountInfo", //It's still required by networkUtils.js
            period: period,
        };
        let onThenCallback = (data) => {
            if (data) {
                setAccountInfoData(data);
                if(data.tiedOutValue !== undefined){
                    calculateTieOutLogic(data);
                    formatAndUpdateInput(data.tiedOutValue);
                }
                if(data.allGLAccountNumbersAreDefined === false){
                    setButtonDisabled(true);
                    setDisableInput(true);
                }
            }
        };

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "fetchGLAccountInfo",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: "/get-gl-account-info",
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.periods]: period,
            [FETCHAPI_PARAMS.screenName]:lang.observability.monthly_build.tie_out_pl_and_gl.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.monthly_build.tie_out_pl_and_gl.requests_description.fetch_gl_account_info
            
        };
        fetchAPI(fetchOptions);
    };
    const fillExclusionsByParameterData = (exlusionByParameterObj) =>{
        let data = [];
        if(!Object.keys(exlusionByParameterObj).length) {
            return data;
        }
        for (let key in exlusionByParameterObj) {
            if (exlusionByParameterObj.hasOwnProperty(key)) {
                data.push({subtype:key,filter:getReadableExclusionByParameterFilterText(exlusionByParameterObj[key],[])})
            }
        }
        return data;
    }
    
    const fetchDetailedTieOutExportData = ()=>{
        var query = {
            action: "fetchDetailedTieOutExportData", //It's still required by networkUtils.js
            period: period
        };
        let onThenCallback = (response) => {
            if (response) {
                glRevenueTabulator.current.replaceData(response.glRevenue);
                glCogsTabulator.current.replaceData(response.glCogs);
                glOpexTabulator.current.replaceData(response.glOpex);
                glExclusionsTabulator.current.replaceData(response.glExclusions);
                glExclusionsByParameterTabulator.current.replaceData(fillExclusionsByParameterData(response.glExclusionsByParameter));
                let sheets = {};
                if(response.glRevenue?.length) {
                    sheets['GL Revenue'] = true;
                }
                if(response.glCogs?.length) {
                     sheets['GL COS'] = '#glCogsAccounts';
                }
                if(response.glOpex?.length){
                     sheets['GL Opex'] = '#glOpexAccounts';
                }
                if(response.glExclusions?.length){
                    sheets['Out of scope GL Accounts'] = '#glExclusions'
                }
                if(Object.keys(response.glExclusionsByParameter).length){
                    sheets['Out of scope GL Entries'] = '#glExclusionsByParameter'
                }
                var options = {};
                options.data = glRevenueTabulator.current.getData();
                options.sheets = sheets;
                options.isProfitStack = false;
                options.fileName =  "Accounts Configuration"
                exportToExcel(options, glRevenueTabulator.current);
            }
        };

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "fetchDetailedTieOutExportData",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: "/get-detailed-tieout-data",
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.periods]: period,
            [FETCHAPI_PARAMS.screenName]:lang.observability.monthly_build.tie_out_pl_and_gl.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.monthly_build.tie_out_pl_and_gl.requests_description.fetch_detailed_tie_out_export_data
        };
        fetchAPI(fetchOptions);
    }
    const saveTiedOutValue = () => {
        var query = {
            action: "saveTiedOutValue", //It's still required by networkUtils.js
            period: period,
            tiedOutValue : tieOutCalculation?.plAmount
        };
        let onThenCallback = () => {

        };

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "saveTiedOutValue",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: "/save-tiedout-value",
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.periods]: period,
            [FETCHAPI_PARAMS.screenName]:lang.observability.monthly_build.tie_out_pl_and_gl.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.monthly_build.tie_out_pl_and_gl.requests_description.save_tiedout_value
        };
        fetchAPI(fetchOptions);
    };

    const modalDialogAction = () => {
        const label =  activeStep === 2 ? DETAILED_TIE_OUT.MODAL.BUTTON.FINISH : DETAILED_TIE_OUT.MODAL.BUTTON.PROCEED;
            return (
            <span style={{display:"flex", alignItems: "center"}}>
                <Button 
                    label={label}
                    variant={"configure"}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT} 
                    onBtnClick={() => detailedTieOutModalRef?.current?.next()}
                    rightIcon = {<i className="fas fa-arrow-right"></i>}
                />
            {showModalError && 
                    <div id="fill-above-value-tie-out-error" className='red uk-display-block uk-margin-left'><i className='fal fa-times-circle uk-margin-xsmall-right'></i>{detailedTieOutErrorMessage}</div>
                    }
            </span>
            )
        
    }

    const setActiveStepState = (step) => {
        setActiveStep(step);
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" , columnGap: "32px"}}>
            <div style={{display: "flex", flexDirection: "column", width: "39.5vw"}}>
                {accountInfoData?.allGLAccountNumbersAreDefined === false && (
                <NewBanner
                    id={"banner-undefined-accounts-detected"}
                    label={
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width:"100%"}}>
                            <WarningIcon className="uk-margin-small-right"/>
                            <span className="warning-banner-body no-cursor">{lang.monthly_build.tie_out_pl_gl.banner_error}</span>
                        </div>
                    }
                    bannerClassName={"banner-error large-margin-bottom banner-body-configure fs-12"}
                />
                )}
        <span className="p-text small-padding-bottom">
          {lang.monthly_build.tie_out_pl_gl.step_3}
        </span>
                <span className="h1-text">
          {lang.monthly_build.tie_out_pl_gl.tie_out_pl_gl_title}
        </span>
        
        <MonthlyBuildCurrentPeriodBreadcrumbs currentPeriod={period}/>

            {renderMonthlyBuildBanner()}
                
                <span className="p-text large-padding-bottom">
                    {lang.monthly_build.tie_out_pl_gl.tie_out_pl_gl_description.replace("[EBITDA]", accountInfoData?.profitName)}
                </span>
                <div className="tie-out-calc large-padding-bottom">
                    <span className="p-text">
                    {lang.monthly_build.tie_out_pl_gl.input_question.replace("[EBITDA]", accountInfoData?.profitName).replace("[period]",period)}
                    <span className="red">
                        {" (Required)"}
                    </span>
                    </span>
                    <Input
                        className="detail-modal-input"
                        id={"pl-value"}
                        step=".01"
                        name= {"pl-value"}
                        type="text"
                        autoComplete="off"
                        value={formattedValue}
                        disabled={disableInput}
                        onChange={(e)=>{onInputChange(e)}}
                        notFullWidth = "true"
                    />
                    <Button
                        id={"tie-out-btn"}
                        label="Tie Out"
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        disabled = {buttonDisabled}
                        onBtnClick={() => {
                            calculateTieOutLogic();
                        }}
                    />
                </div>
                {tieOutCalculation && accountInfoData?.allGLAccountNumbersAreDefined === true? renderBanner() : ""}
            </div>
            <div id={"glRevenueAccounts"} style={{ display: 'none' }} forwardRef="mainTable" />
            <div id={"glCogsAccounts"} style={{ display: 'none' }} forwardRef="mainTable" />
            <div id={"glOpexAccounts"} style={{ display: 'none' }} forwardRef="mainTable" />
            <div id={"glExclusions"} style={{ display: 'none' }} forwardRef="mainTable" />
            <div id={"glExclusionsByParameter"} style={{ display: 'none' }} forwardRef="mainTable" />
            <Modal
                id={"detailed_tie_out_dialog"}
                title={DETAILED_TIE_OUT.MODAL.TITLE}
                openDialog={openDialog}
                bodyContent={() => <DetailedTieOutModal ref={detailedTieOutModalRef} data={accountInfoData}
                                        setOpenDialog={setOpenDialog} period={period} bannerIcon={bannerIcon}
                                        setModalProceedError = {setModalProceed} setTieOutFromModalValue={setTieOutFromModalValue}
                                        fetchDetailedTieOutExportData={fetchDetailedTieOutExportData} formatMessage = {formatMessage} setDetailedTieOutErrorMessageParam={setDetailedTieOutErrorMessageParam}
                                        goToUpload={goToUpload} setActiveStep = {setActiveStepState}
                                        />
                                    }
                dialogActions={modalDialogAction}
                closeClick={() => setDetailedDialogOpen(false)} 
                size={DIALOG_SIZE.LARGE}
                />
        </div>
    );
};

export default forwardRef(TieOutPLGL);
