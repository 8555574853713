import { DialogContentText } from '@mui/material';
import React, { Component } from 'react';
import shortid from 'shortid';
import { ALL_WIDGETS, API_URL, BUTTON_TYPE, BUTTON_VARIANT, DIALOG_SIZE, DROPDOWN_TYPE, HEADER_ELEMENT, MANAGE_COST_CENTER, SCENARIO_TYPE, SIZES } from '../class/constants';
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from '../class/networkUtils';
import { copyObjectValues, findOptionByKey, getSectionExists, getTranslationFile, groupBy, parseBoolean } from '../class/utils.js';
import SidePanel from '../components/SidePanel';
import { PeriodCostTab } from '../form/elements.js';
import Button from '../newComponents/Button';
import Dropdown from '../newComponents/DropDown';
import Input from '../newComponents/Input';
import Modal from '../newComponents/Modal';
import RadioButton from '../newComponents/RadioButton';
import CostCenterDefinition from '../tables/CostCenterDefinition';
import CostCenterTable from '../tables/CostCenterTable';
import SidePanelNew from '../components/sidePanelNew/SidePanelNew';
import Backdrop from '../components/sidePanelNew/Backdrop';
import '../styles/manageCostCenters.css'
import { toggleLoader } from '../class/common.js';

/** @author Sarah Farjallah 
 *  Landing page of Manage Cost Centers
 *  Component renders the main cost center table depending on the view selected 
 */
const lang = getTranslationFile();
const $ = require('jquery');
const sidePanelTitle = "Define Cost Center State for ";
const typeOptions= [{value:'0',label:'Non Revenue Generting'},{value:'1', label:'Revenue Generating'}];
const _type = "type";
const _name = "name";
const _DisplayName = lang.monthly_build.cost_centers.modal.name;
const _DisplayDescription = lang.monthly_build.cost_centers.modal.description;
const _nameId = "name_id";
const _description = "description";
const _descId = "cc_desc";
const _state = "state ";
const _sourceSystem = "use_source_system";
const _closed = "closed";
const _selling = "selling";
const _revenue_generating = MANAGE_COST_CENTER.FIELDS.GENERATING_REVENUE;
const _non_revenue_generating = MANAGE_COST_CENTER.FIELDS.NON_GENERATING_REVENUE;
class ManageCostCenters extends Component {
    constructor(props){
        super(props);
        this.state = {
            view: lang.COST_CENTER_VIEW.PERIODS.value,
            displayPanel: false,
            type: _non_revenue_generating,
            isChanged: false,
            costCenterState: undefined,
            costCenterStates: [],
            costCentersData: [],
            editedPeriods: [],
            showLoader: false,
            isActual: true,
            fromMount: true,
            drawerOpen: false
        }
        this.newState={};
        this.messages={}
    }

    componentDidMount(){
      this.init();
    }

    /**
     * sending request to save cost centers 
     */
    saveChanges=(callback)=>{
        let _this = this;
        let costCenters  = copyObjectValues(_this.state.periodCostCenters.filter(e=>_this.state.editedPeriods.includes(e[MANAGE_COST_CENTER.FIELDS.PERIOD])));
        let editedPeriods = [];
        let undefinedPeriods = [];
        let periods = _this.state.editedPeriods;
        let data = groupBy(_this.state.periodCostCenters ,e => e[MANAGE_COST_CENTER.FIELDS.PERIOD]);
        for (var elt in periods) {
            let arr = data.get(periods[elt]);
            if (arr && arr.filter(x=>!x.cost_center_state || x.cost_center_state === '').length > 0) {
              if (!undefinedPeriods.includes(periods[elt])) {
                undefinedPeriods.push(periods[elt]);
              }
            }
        }
        costCenters.map(function(item){
          if(editedPeriods.filter(e=>e[MANAGE_COST_CENTER.FIELDS.PERIOD] === item[MANAGE_COST_CENTER.FIELDS.PERIOD]).length === 0){
            editedPeriods.push({[MANAGE_COST_CENTER.FIELDS.PERIOD]: item[MANAGE_COST_CENTER.FIELDS.PERIOD]});
          }
        });
        if (undefinedPeriods.length === 0) {
            // Reduce the payload size by removing the unused attributes
            costCenters.forEach(costCenter => {
                delete costCenter.gl_revenue;
                delete costCenter.gl_cogs;
                delete costCenter.gl_opex;
                delete costCenter.cost_center_name;
            });

            const query = {
              action: "generateCostCenterStates",
              scenario_id:this.props.scenarioId,
              edited_periods: JSON.stringify(editedPeriods),
              cost_centers:JSON.stringify(costCenters),
              isMonthlyBuild:false
          }
          let onThenCallback = (data)=>{
              if (data) {
                _this.setState({
                  isChanged:false
                },function(){
                  _this.props.setHasUnsavedChanges(false);
                  _this.getUploadedFilesStatusInterval();
                  if(callback) {
                    callback()
                  }
                })
              }
          }
          let fetchOptions = {
              [FETCHAPI_PARAMS.funcName]: "generateCostCenterStates",
              [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
              [FETCHAPI_PARAMS.showLoader]: true,
              [FETCHAPI_PARAMS.path]: API_URL.MANAGE_COST_CENTER,
              [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
              [FETCHAPI_PARAMS.query]: query,
              [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
              [FETCHAPI_PARAMS.screenName]:lang.observability.manage_cost_center.screen_name,
              [FETCHAPI_PARAMS.requestDescription]:lang.observability.manage_cost_center.requests_description.generate_cost_center_states
          }
          fetchAPI(fetchOptions);
        } else {
          _this.setState({
            warning: undefinedPeriods.length === 1 ? lang.manage_cost_centers.warning.replace('[X]', undefinedPeriods.join(", ")).replaceAll("are","is").replace("Periods","Period").replace("they","it") : lang.manage_cost_centers.warning.replace('[X]', undefinedPeriods.join(", "))
          },()=>{
            _this.showDialog(true);
          })
        }
    }

    showDialog=(show)=>{
      let _this = this;
      _this.setState({
        show:show
      })
    }

    getUploadedFilesStatus = () => {
        let _this = this;
        const query = {
            action: "getUploadedFilesStatus",
            scenario_id: _this.props.scenarioId
        };
        let onThenCallback = (data) => {
            let showLoader = (data && data.data && data.data[0].count > 0);
            _this.setState({
                showLoader: showLoader,
                isChanged: false,
                editedPeriods: []
            }, function () {
                _this.props.setHasUnsavedChanges(false);
                // toggleLoader(showLoader)
                _this.props.setIsFileUploading(showLoader);
            })
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getUploadedFilesStatus",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.manage_cost_center.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.manage_cost_center.requests_description.get_uploaded_files_status
            
        }
        fetchAPI(fetchOptions);
    }

    getUploadedFilesStatusInterval = () => {
        let count = 0;
        this.getUploadedFilesStatus();
        const interval = setInterval(() => {
            this.getUploadedFilesStatus();
            if ((count > 0 && count > 1000) || (count > 0 && !this.state.showLoader)) {
                this.launchToast();
                clearInterval(interval);
            } else if (!this.state.showLoader) {
                clearInterval(interval);
            }
            count++;
        }, 5000);
    }

        /**
     * function for handling switching between tabs
     * @param {*} order 
     */
    handleElementChange=(element, e)=>{
        let _this = this;
       switch(element){
            case HEADER_ELEMENT.VIEW: 
              _this.setState({view: e.toLowerCase()});
              if(e.toLowerCase() === lang.COST_CENTER_VIEW.COST_CENTER.value){
                _this.displayCostCentersMainTable(_this.ccTabC.formatTable);
              } else {
                _this.displayCostCentersMainTable(_this.ccTab.formatTable);
              }

            break;
        }
    }

    /**
     * function called on didmount to fetch data 
     */
    init = () => {
        let _this = this;
        _this.fetchCostCenterPeriods();
        _this.getUploadedFilesStatusInterval();
        _this.getGeneratedBudgetScenarios();
    }

    fetchCostCenterPeriods=(scenarioId)=>{
        let _this = this;
        var query = {
            action: "fetchCostCenterPeriods",
            scenario_id: scenarioId || 0
        }
        let onThenCallback = (data)=>{
            if (data && data.data) {
                let defined = data.data.filter(e=>e[MANAGE_COST_CENTER.FIELDS.STATUS] === MANAGE_COST_CENTER.STATUS.DEFINED).length;
                _this.setState({
                    data:data.data,
                    columns:data.columns,
                    id:shortid.generate(),
                    defined: defined,
                    undefined: data.data.length - defined,
                    costCenterStates: data.cost_center_states,
                    newStates:[],
                    periodCostCenters: data.period_cost_centers,
                    originalPeriodCostCenters: copyObjectValues(data.period_cost_centers),
                    originalData: copyObjectValues(data.data),
                    statesMap: data.statesMap,
                    definitionColumns: data.definition_columns,
                    costCenterColumns: data.cost_center_columns,
                    index: shortid.generate(),
                    changedScenario: true,
                    selectedScenario: scenarioId
                },function(){
                    if(_this.state.fromMount){
                        _this.ccTab.formatTable(_this.state.columns, copyObjectValues(_this.state.data),_this.state.statesMap);
                        _this.ccTabC.formatTable(_this.state.costCenterColumns, [],_this.state.statesMap);
                        _this.displayCostCentersMainTable(null, true);
                        _this.setState({fromMount : false,});
                    } else if (_this.state.view  === lang.COST_CENTER_VIEW.COST_CENTER.value){
                        _this.displayCostCentersMainTable(_this.ccTabC.formatTable);
                    } else {
                        _this.displayCostCentersMainTable(_this.ccTab.formatTable);
                    }
                })
            }  
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "fetchCostCenterPeriods",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.MANAGE_COST_CENTER,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.manage_cost_center.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.manage_cost_center.requests_description.fetch_cost_center_periods
            
        }
        fetchAPI(fetchOptions);
    }

    getGeneratedBudgetScenarios = () => {
      let _this = this;
      var query = {
          action: "getGeneratedBudgetScenarios"
      }
      let onThenCallback = (data)=>{
          if (data && data.generatedBudgetScenarios.length > 0) {
            let generatedBudgetScenarios = [];
            data.generatedBudgetScenarios.map(function(item) {
              generatedBudgetScenarios.push({label: item.scenario_number + "-" +item.scenario_name, value:item.client_model_scenario_id});
            });
            this.setState({
              generatedBudgetScenarios: generatedBudgetScenarios
            })
          } else {
            this.setState({
              generatedBudgetScenarios: false
            })
          }
      }
      let fetchOptions = {
          [FETCHAPI_PARAMS.funcName]: "getGeneratedBudgetScenarios",
          [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
          [FETCHAPI_PARAMS.showLoader]: true,
          [FETCHAPI_PARAMS.path]: API_URL.MANAGE_COST_CENTER,
          [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
          [FETCHAPI_PARAMS.query]: query,
          [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
          [FETCHAPI_PARAMS.screenName]:lang.observability.manage_cost_center.screen_name,
          [FETCHAPI_PARAMS.requestDescription]:lang.observability.manage_cost_center.requests_description.get_generated_budget_scenarios
      }
      fetchAPI(fetchOptions);
    }

    /**
     * function return the count of either generating or non generating states for a certain period
     * @param {*} isGenerating 
     * @param {*} period 
     * @returns 
     */
    countGeneratingPerPeriod=(isGenerating, period)=> {
        let _this = this;
        let data =  _this.state.periodCostCenters.filter(e=>e[MANAGE_COST_CENTER.FIELDS.PERIOD] === period);
        let statesMap = _this.state.statesMap;
        if (data.length > 0) {
          if (isGenerating) {
              return data.filter(e=>statesMap[e[MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE]] !== undefined && statesMap[e[MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE]]).length
          } else{
              return data.filter(e=>statesMap[e[MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE]] !== undefined && !statesMap[e[MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE]]).length
          }
        }else{
            return 0; 
        }

    }

    /**
     * returns the count of the cost center' states defined
     * @param {*} costCenter 
     * @returns 
     */
    countCostCenterStates = (costCenter) => {
        let _this = this;
        let data = _this.state.periodCostCenters.filter(
          (e) => e[MANAGE_COST_CENTER.FIELDS.COST_CENTER] === costCenter
        );
        if (data.length > 0) {
          return data
            .map((s) => s[MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE])
            .filter((f) => f !== undefined)
            .filter((state, index, self) => self.indexOf(state) === index).length;
        }
        return 0;
    }

    /**
     * function checks if this cost center appears for the first time in this period
     * @param {*} period 
     * @param {*} costCenter 
     */
    isCostCenterNew=(period,costCenter)=>{
        let _this = this;
        return _this.state.periodCostCenters.filter(e=>e[MANAGE_COST_CENTER.FIELDS.COST_CENTER] === costCenter && e[MANAGE_COST_CENTER.FIELDS.PERIOD] < period).length == 0
    }

    /**
     * function checks if cost center's state of current period is different than the state in the previous period 
     * @param {*} period 
     */
    isCostCenterStateDifferentThanPrevious=(row, period)=>{
        let _this = this;
        let prePeriodRow = _this.state.periodCostCenters.filter(e=>e[MANAGE_COST_CENTER.FIELDS.COST_CENTER] === row[MANAGE_COST_CENTER.FIELDS.COST_CENTER]
            && e[MANAGE_COST_CENTER.FIELDS.PERIOD] < period && e[MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE]);
            if(!_this.state.isBulkdefine){
              if (prePeriodRow.length > 0) {
                if (row[MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE] !== prePeriodRow[0][MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE]) {
                  return prePeriodRow[0][MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE];
                }
              }
            }
        return false;
    }

    /**
     * function return ost center state for latest previous period that has the cost center defined 
     * @param {*} period 
     */
     pullPreviousState=(period, row)=>{
        let _this = this;
        let prePeriodRow = _this.state.periodCostCenters.filter(e=>e[MANAGE_COST_CENTER.FIELDS.COST_CENTER] === row[MANAGE_COST_CENTER.FIELDS.COST_CENTER]
            && e[MANAGE_COST_CENTER.FIELDS.PERIOD] < period && e[MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE]);
            if(!_this.state.isBulkdefine){
              if (prePeriodRow.length > 0) {
                prePeriodRow = prePeriodRow.sort((a,b)=> a.period.localeCompare(b.period))
                return prePeriodRow[prePeriodRow.length-1][MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE];
              }
            }
        return undefined;
    }

    backdropClickHandler = () => {
      this.setState({
          drawerOpen: false
      })
  }

    renderSidePanel() {
      return (
        <>
          {
            this.state.isBulkdefine ?
              <div className='cost_center_definition_container'>
                <CostCenterDefinition ref={el => this.bulkDef = el} openNewStateModal={this.openNewStateModal} data={this.state.definitionData}
                  columns={this.state.definitionColumns} id={this.state.index} index={"definition"} isCostCenterNew={this.isCostCenterNew}
                  isCostCenterStateDifferentThanPrevious={this.isCostCenterStateDifferentThanPrevious} period={this.state.editedPeriod}
                  costCenterStates={this.state.costCenterStates} statesMap={this.state.statesMap} user={this.props.user}
                  pullPreviousState={this.pullPreviousState} openSelectStateModal={this.openSelectStateModal} isBulkdefine={this.state.isBulkdefine} />
              </div>
              :
              this.state.view === lang.COST_CENTER_VIEW.PERIODS.value ?
              <div className='cost_center_definition_container'>
                <CostCenterDefinition ref={el => this.ccDef = el} openNewStateModal={this.openNewStateModal} data={this.state.definitionData} view = {this.state.view}
                  columns={this.state.definitionColumns} id={this.state.index} index={"definition"} isCostCenterNew={this.isCostCenterNew}
                  isCostCenterStateDifferentThanPrevious={this.isCostCenterStateDifferentThanPrevious} period={this.state.editedPeriod}
                  costCenterStates={this.state.costCenterStates} statesMap={this.state.statesMap} user={this.props.user}
                  pullPreviousState={this.pullPreviousState} openSelectStateModal={this.openSelectStateModal} />
              </div>
              :
              <div className='cost_center_definition_container'>
                <CostCenterDefinition ref={el => this.ccDef = el} openNewStateModal={this.openNewStateModal} data={this.state.definitionData} view = {this.state.view}
                  columns={this.state.definitionColumns} id={this.state.index} index={"definition"} isCostCenterNew={this.isCostCenterNew}
                  isCostCenterStateDifferentThanPrevious={this.isCostCenterStateDifferentThanPrevious} period={this.state.editedPeriod}
                  costCenterStates={this.state.costCenterStates} statesMap={this.state.statesMap} user={this.props.user}
                  pullPreviousState={this.pullPreviousState} openSelectStateModal={this.openSelectStateModal} isCCDefinition />
              </div>
          }
        </>
      )
    }
    m
     /**
     * function sets displayPanel to false or true
     * @param {*} row
     */
      onToggleBoard(){
          let _this = this;
          if (this.state.displayPanel) {
              this.manageDashboardsOverlay.toggleBoard();
          }
          this.setState({
              displayPanel: !this.state.displayPanel,
              isBulkdefine: this.state.displayPanel && this.state.isBulkdefine ? false : this.state.isBulkdefine
          },function(){
              if (this.state.displayPanel){
                  _this.manageDashboardsOverlay.toggleBoard()
              }
          })
    }

    drawerToggleClickHandler = (bulkDefine) => {
      let _this = this
      _this.setState({
          drawerOpen: !_this.state.drawerOpen,
          // isBulkdefine: bulkDefine

      })
  }

    /**
     * opens define section with the period of the row 
     * @param {*} row 
     */
     callDefine=(row, isByPeriod)=>{
       let _this = this;
       let activePeriods = copyObjectValues(this.state.data).map(m => m.period);
       let ccActivePeriods = this.state.periodCostCenters.filter(e => activePeriods.includes(e.period))

       let data = groupBy(isByPeriod ? _this.state.periodCostCenters : ccActivePeriods, isByPeriod ? e => e[MANAGE_COST_CENTER.FIELDS.PERIOD] : e => e[MANAGE_COST_CENTER.FIELDS.COST_CENTER]);
       let periodCostCenters = data.get(row[isByPeriod ? MANAGE_COST_CENTER.FIELDS.PERIOD : MANAGE_COST_CENTER.FIELDS.COST_CENTER]);

       if (!isByPeriod) {
         let arr = copyObjectValues(periodCostCenters);
         let ccPeriods = arr.map(m => m.period);
         let ccPeriodData = copyObjectValues(_this.state.data).filter(f => ccPeriods.includes(f.period));
         let invalidGls = ccPeriodData.filter(f => f[MANAGE_COST_CENTER.FIELDS.VALID_GL] === 'false');
         periodCostCenters = invalidGls.length > 0 ? periodCostCenters.filter(f => !invalidGls.map(m => m.period).includes(f.period)) : periodCostCenters;
       }

       _this.setState({
          editedPeriod: row[isByPeriod ? MANAGE_COST_CENTER.FIELDS.PERIOD : MANAGE_COST_CENTER.FIELDS.COST_CENTER],
          definitionData: copyObjectValues(periodCostCenters),
          row: row,
          index: shortid.generate(),
          isBulkdefine: false
        },()=>{
          let ccDefinitionColumns =  copyObjectValues(_this.state.definitionColumns);
          ccDefinitionColumns = ccDefinitionColumns.filter(f => ![MANAGE_COST_CENTER.FIELDS.COST_CENTER, 'alert'].includes(f.field));
          ccDefinitionColumns.filter(f => f.field === MANAGE_COST_CENTER.FIELDS.COST_CENTER_NAME).map(m => { m.field = MANAGE_COST_CENTER.FIELDS.PERIOD; m.title = MANAGE_COST_CENTER.TITLES.PERIOD;});
          let definitionColumns = isByPeriod ? _this.state.definitionColumns : ccDefinitionColumns;

          _this.drawerToggleClickHandler();
          _this.ccDef.ccTabRef.initTabulator(); //We need to re initialize tabulator to get correct index 
          _this.ccDef.ccTabRef.formatTable(definitionColumns, _this.state.definitionData);
          _this.ccDef.ccTabRef.tabulator.setPageSize(_this.ccDef.ccTabRef.tabulator.options.paginationSize)
          // _this.onToggleBoard();
        })
    }

  bulkDefine = () => {
    let _this = this;
    let activePeriods = copyObjectValues(this.state.data).map((obj) => { return obj.period })
    let bulkPeriods = this.state.periodCostCenters.filter(e => activePeriods.includes(e.period))
    let data = groupBy(bulkPeriods, e => e[MANAGE_COST_CENTER.FIELDS.COST_CENTER]);
    let finalArr = [];
    for (const key of data.keys()) {
      let arr = data.get(key);
      let amount = 0;
      let revenue = 0;
      let lineCount = 0;
      let glRevenue = 0;
      let glCogs = 0;
      let glOpex = 0;
      for (var i in arr) {
        amount += Number(!isNaN(arr[i].amount) ? arr[i].amount : 0) ;
        revenue += Number(!isNaN(arr[i].revenue) ? arr[i].revenue : 0);
        lineCount += Number(!isNaN(arr[i].line_count) ? arr[i].line_count : 0);
        glRevenue += Number(!isNaN(arr[i]["gl_revenue"]) ? arr[i]["gl_revenue"] : 0) ;
        glCogs += Number(!isNaN(arr[i]["gl_cogs"]) ? arr[i]["gl_cogs"] : 0);
        glOpex += Number(!isNaN(arr[i]["gl_opex"]) ? arr[i]["gl_opex"] : 0);
      }
      finalArr.push({
        [MANAGE_COST_CENTER.FIELDS.COST_CENTER]: key, 
        [MANAGE_COST_CENTER.FIELDS.COST_CENTER_NAME]: arr[0][MANAGE_COST_CENTER.FIELDS.COST_CENTER_NAME],
        [MANAGE_COST_CENTER.FIELDS.GL_AMOUNT]: isNaN(amount) ? 0 : amount, [MANAGE_COST_CENTER.FIELDS.REVENUE]: isNaN(revenue) ? 0 : revenue,
        [MANAGE_COST_CENTER.FIELDS.LINE_COUNT]: isNaN(lineCount) ? 0 : lineCount,
        [MANAGE_COST_CENTER.FIELDS.GL_COGS]: isNaN(glCogs) ? 0 : glCogs,
        [MANAGE_COST_CENTER.FIELDS.GL_REVENUE]: isNaN(glRevenue) ? 0 : glRevenue,
        [MANAGE_COST_CENTER.FIELDS.GL_OPEX]: isNaN(glOpex) ? 0 : glOpex,
      });
    }
    _this.setState({
      definitionData: finalArr,
      isBulkdefine: true,
      index: shortid.generate()
    }, () => {
      let definitionColumns = _this.state.definitionColumns.filter(function (obj) { return obj.field !== 'alert' })
      _this.drawerToggleClickHandler();
      _this.bulkDef.ccTabRef.formatTable(definitionColumns, _this.state.definitionData);
      _this.bulkDef.ccTabRef.tabulator.setPageSize(_this.bulkDef.ccTabRef.tabulator.options.paginationSize)
      // _this.onToggleBoard();
    })
  }

  displayCostCentersMainTable = (callback, fromMount) => {
    let _this = this;

    let finalArr = [];
    let periodData = copyObjectValues(_this.state.data);
    let activePeriods = periodData.filter(f => parseBoolean(f[MANAGE_COST_CENTER.FIELDS.VALID_GL])).map(m => m.period);
    let periodCostCenters = _this.state.periodCostCenters;
    let costCentersActivePeriods = periodCostCenters.filter(e => activePeriods.includes(e.period))
    let costCentersData = groupBy(costCentersActivePeriods, e => e[MANAGE_COST_CENTER.FIELDS.COST_CENTER]);
    for (const key of costCentersData.keys()) {
      let arr = costCentersData.get(key);
      let states = 0;

      let ccPeriods = arr.map(m => m.period);
      let data = copyObjectValues(_this.state.data).filter(f => ccPeriods.includes(f.period));
      let lastModified = data.filter(f => f[MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_DATE] !== '').sort((a, b) => a[MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_DATE] > b[MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_DATE] ? -1 : 1)[0];
      let ccData = data.filter(f => f.status !== MANAGE_COST_CENTER.STATUS.DEFINED || !parseBoolean(f[MANAGE_COST_CENTER.FIELDS.VALID_GL]));
      let oldStatus = _this.state.costCentersData.length > 0 ? _this.state.costCentersData.filter(f => f[MANAGE_COST_CENTER.FIELDS.COST_CENTER] === key)[0][MANAGE_COST_CENTER.FIELDS.STATUS] : undefined;
      let status = oldStatus && oldStatus === MANAGE_COST_CENTER.STATUS.DEFINED ?
                      oldStatus : ccData.length > 0 ? MANAGE_COST_CENTER.STATUS.UNDEFINED : MANAGE_COST_CENTER.STATUS.DEFINED;

      states = arr.map(s => s[MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE]).filter(f => f !== undefined).filter((state, index, self) => self.indexOf(state) === index).length;
      finalArr.push({
        [MANAGE_COST_CENTER.FIELDS.COST_CENTER]: key,
        [MANAGE_COST_CENTER.FIELDS.COST_CENTER_NAME]: arr[0][MANAGE_COST_CENTER.FIELDS.COST_CENTER_NAME],
        [MANAGE_COST_CENTER.FIELDS.STATUS]: status,
        [MANAGE_COST_CENTER.FIELDS.STATES]: states, 
        [MANAGE_COST_CENTER.FIELDS.VALID_GL]: true, 
        [MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_DATE]: lastModified ? lastModified[MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_DATE] : "",
        [MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_BY]: lastModified ? lastModified[MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_BY] : "",
      });
    }

    let definedCostCenters = finalArr.filter(e=>e[MANAGE_COST_CENTER.FIELDS.STATUS] === MANAGE_COST_CENTER.STATUS.DEFINED).length;
    let originalCostCentersData = fromMount ? finalArr : _this.state.originalCostCentersData;

    _this.setState({
        costCentersData: finalArr,
        originalCostCentersData: originalCostCentersData,
        definedCostCenters: definedCostCenters,
        undefinedCostCenters: finalArr.length - definedCostCenters,
      },
      () => {
        if (typeof callback === "function") {
            if( _this.state.view === lang.COST_CENTER_VIEW.COST_CENTER.value){
                callback(this.state.costCenterColumns, finalArr);
            }else {
                callback(this.state.columns, this.state.data);
            }
        } 
      });
  }

    /**
     * closing define section
     */
    startCancel=(callback)=>{
      let _this = this;
      let ref = this.state.isBulkdefine ? _this.bulkDef : _this.ccDef;
      ref.ccTabRef.tabulator.clearFilter(true);
      ref.setState({
        errorMessage: ""
      },function(){
        if(typeof callback === "function"){
          callback();
        }
        // _this.onToggleBoard();
        ref.enableSelectState(false);
      });
    }

    /**
     * openning new state modal
     */
    openNewStateModal=()=>{
        let _this = this;
        _this.setState({openAddNewStateDialog: true})
    }

     /**
     * openning select state modal
     */
    openSelectStateModal=()=>{
        let _this = this;
        _this.setState({
          openSelectStateDialog: true
        })
    }

    /**
     * closing new state modal
     */
     closeSelectStateModal=()=>{
        let _this = this;
        // $(".modal-backdrop").css({'z-index':'0'});
        // _this.messages = {};
        _this.setState({
          costCenterState: undefined,
          openSelectStateDialog: false
        })
    }

    /**
     * saving cost center state in drop down of the select state modal
     * @param {*} e 
     */
    saveState=(e)=>{
      let _this = this;
      _this.setState({
        costCenterState: e
      })
    }
    /**
     * validating state is selected then updating it in defintion table
     */
    selectState=()=>{
      let _this = this;
      let ccState = _this.state.costCenterState;
      let ref = this.state.isBulkdefine ? _this.bulkDef : _this.ccDef;
      if (!_this.state.costCenterState) {
        _this.messages[_state] = lang.manage_cost_centers.select_state_message;
        _this.setState(_this.state);
      }else{
        ref.ccTabRef.setState({isChanged:true})
        _this.setState({
          costCenterState: undefined,
          openSelectStateDialog: false
        },function(){
          ref.ccTabRef.selectState(ccState);
        })
      }
    }
    /**
     * closing new state modal
     */
    closeNewStateModal=()=>{
        let _this = this;
        _this.setState({
          type: _non_revenue_generating,
          openAddNewStateDialog: false
        },()=>{
          _this.messages = {};
          _this.newState={};
        })
    }

    /**
     * setting type of new state in add new ost enter state modal
     * @param {*} e 
     */
    updateFields= (e,type)=>{
        let _this = this;
        let val = $(e.currentTarget).val();
        _this.newState[type] = val;
        _this.messages[type] = undefined;
        if (type === _type) {
          _this.setState({
            type: val
          });
        }else{
          _this.setState(_this.state);
        }
    }

    /**
     * checks if user entered all needed values 
     */
    validInputs=()=>{
        let valid = true;
        let _this = this;
        if (!_this.state.type) {
          _this.messages[_type] = lang.manage_cost_centers.empty_type;
          valid = false;
        }
        if(!_this.newState[_name]) {
          valid = false;
          _this.messages[_name] = lang.manage_cost_centers.empty_name;
        }
        if (_this.newState[_name] && _this.state.costCenterStates.filter(e=>e[_name].trim().toLowerCase() === _this.newState[_name].trim().toLowerCase()).length > 0) {
          valid = false;
          _this.messages[_name] = lang.manage_cost_centers.duplicate;
        }
        return valid;
    }

    /**
     * adds the new cost center state in state 
     * @returns 
     */
    addNewState=()=>{
        let _this = this;
        
        if (!_this.validInputs()) {
          _this.setState(_this.state);
          return;
        }
        _this.saveNewState();
    }

    launchToast=()=> {
        $("#toastSaveCostCenter").addClass("show");
        setTimeout(function(){
            $("#toastSaveCostCenter").removeClass("show");
        }, 4000);
    }

  /**
   * validates whether all cot center states are defined
   */
  validateDefinition = (undefined, callback) => {
    let _this = this;
    let isByPeriod = _this.state.view === lang.COST_CENTER_VIEW.PERIODS.value;
    let ref = _this.state.isBulkdefine ? _this.bulkDef : _this.ccDef;
    let row = _this.state.row;
    let pageSize = ref.ccTabRef.tabulator.getPageSize();
    ref.ccTabRef.tabulator.setPageSize(ref.ccTabRef.tabulator.getDataCount());
    let tabData = ref.ccTabRef.tabulator.getData();
    ref.ccTabRef.tabulator.setPageSize(pageSize);
    ref.ccTabRef.tabulator.options.paginationSizeSelector = [10, 50, 100];
    if (tabData.filter(e => !e[MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE]).length > 0) {
      ref.setState({
        errorMessage: isByPeriod ? lang.manage_cost_centers.not_defined_cost_centers : lang.manage_cost_centers.not_defined_periods
      });
      return -1;
    }
    ref.ccTabRef.tabulator.clearFilter(true);
    if (!_this.state.isBulkdefine && row[MANAGE_COST_CENTER.FIELDS.STATUS] !== MANAGE_COST_CENTER.STATUS.DEFINED) {
      ref.setState({
        errorMessage: "",
      });
      _this.applyDefinition(isByPeriod);
      _this.props.setHasUnsavedChanges(true);
    } else {
      if (ref.ccTabRef.state.isChanged) {
        ref.setState({
          errorMessage: "",

        }, function () {
          if (!_this.state.isBulkdefine) {
            _this.applyDefinition(isByPeriod,callback);
          } else{
            _this.applyBulkDefinition(callback);
          }
          _this.props.setHasUnsavedChanges(true);
          ref.ccTabRef.setState({ isChanged: false })
        })
      } else {
        if (typeof callback === "function"){
          callback()
        }
        // _this.onToggleBoard();
        // _this.drawerToggleClickHandler();
      }
    }
  }

  /**
     * applies the changes of the definition
     * 1- applies new states to PeriodcostCentersArray
     * 2- changes the status to defined of the defined period
     * 3- changes the modified_date and last_modified_by
     */
   applyBulkDefinition=(callback)=>{
    let _this = this;
        let row = _this.state.row;
        let tabData = _this.bulkDef.ccTabRef.tabulator.getData();
        let periodCostCenters = _this.state.periodCostCenters;
        let periodStatus = _this.state.data;
        let activePeriods = periodStatus.filter(f => parseBoolean(f[MANAGE_COST_CENTER.FIELDS.VALID_GL])).map(m => m.period);
        let bulkPeriods = _this.state.periodCostCenters.filter(e => activePeriods.includes(e.period))
        // updating cost center state for edited period
        bulkPeriods.map(function(item){
          item[MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE] = tabData.filter(e=>e[MANAGE_COST_CENTER.FIELDS.COST_CENTER] === item[MANAGE_COST_CENTER.FIELDS.COST_CENTER])[0][MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE]
        });

         // updating status to defined and modified by and modified date
         periodStatus.filter(f => parseBoolean(f[MANAGE_COST_CENTER.FIELDS.VALID_GL])).map(function(item){
          item[MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_DATE] = new Date().toLocaleDateString('en-CA');
          item[MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_BY] = parseBoolean(_this.props.user.is_system) ? 'System User' : _this.props.user.first_name + " " + _this.props.user.last_name;
          item[MANAGE_COST_CENTER.FIELDS.STATUS] = MANAGE_COST_CENTER.STATUS.DEFINED;
        })

        let mainData = _this.ccTab.tabulator.getData();
        for (var ccRow in mainData) {
          mainData[ccRow][MANAGE_COST_CENTER.FIELDS.GENERATING_REVENUE] = _this.countGeneratingPerPeriod(true, mainData[ccRow][MANAGE_COST_CENTER.FIELDS.PERIOD]);
          mainData[ccRow][MANAGE_COST_CENTER.FIELDS.NON_GENERATING_REVENUE] = _this.countGeneratingPerPeriod(false, mainData[ccRow][MANAGE_COST_CENTER.FIELDS.PERIOD]);
          mainData[ccRow][MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_DATE] === new Date().toLocaleDateString('en-CA');
          mainData[ccRow][MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_BY] = parseBoolean(_this.props.user.is_system) ? 'System User' : _this.props.user.first_name + " " + _this.props.user.last_name;
          mainData[ccRow][MANAGE_COST_CENTER.FIELDS.STATUS] = MANAGE_COST_CENTER.STATUS.DEFINED;
        }

        let defined = periodStatus.filter(e=>e[MANAGE_COST_CENTER.FIELDS.STATUS] === MANAGE_COST_CENTER.STATUS.DEFINED).length;
        _this.setState({
          data: periodStatus,
          periodCostCenters: periodCostCenters,
          isChanged: true,
          defined: defined,
          undefined: periodStatus.length - defined,
          editedPeriod: "",
          editedPeriods: activePeriods,
          showLoader:false,
        },function(){
          _this.props.setHasUnsavedChanges(true);
          // _this.onToggleBoard();
          // _this.drawerToggleClickHandler();
          _this.ccTab.tabulator.replaceData(mainData);
          _this.displayCostCentersMainTable();
          if (typeof callback === "function"){
            callback()
          }
        })
}

    /**
     * applies the changes of the definition for edited period
     * 1- applies new states to PeriodcostCentersArray for edited period
     * 2- changes the status to defined of the defined period for edited period
     * 3- changes the modified_date and last_modified_by for edited period
     */
    applyDefinition=(isByPeriod, callback)=>{
      let _this = this;
      let row = _this.state.row;
      let tabData = _this.ccDef.ccTabRef.tabulator.getData();
      let periodCostCenters = _this.state.periodCostCenters;
      let costCentersData = _this.state.costCentersData;
      let periodStatus = copyObjectValues(_this.state.data);
      let activePeriods = periodStatus.filter(f => parseBoolean(f[MANAGE_COST_CENTER.FIELDS.VALID_GL])).map(m => m.period);
      let editedPeriods = _this.state.editedPeriods;
      
      if (!isByPeriod) {
        periodCostCenters =  _this.state.periodCostCenters.filter(e => activePeriods.includes(e.period))
      }

      // updating cost center state for edited period
      periodCostCenters
        .filter((e) =>e[isByPeriod ? MANAGE_COST_CENTER.FIELDS.PERIOD : MANAGE_COST_CENTER.FIELDS.COST_CENTER] === _this.state.editedPeriod)
        .map(function (item) {
          item[MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE] = tabData.filter((e) =>
              e[isByPeriod ? MANAGE_COST_CENTER.FIELDS.COST_CENTER : MANAGE_COST_CENTER.FIELDS.PERIOD] === 
              item[isByPeriod ? MANAGE_COST_CENTER.FIELDS.COST_CENTER : MANAGE_COST_CENTER.FIELDS.PERIOD])[0][MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE];
        });
      
      if(isByPeriod) {
        row[MANAGE_COST_CENTER.FIELDS.GENERATING_REVENUE] = _this.countGeneratingPerPeriod(true, row[MANAGE_COST_CENTER.FIELDS.PERIOD]);
        row[MANAGE_COST_CENTER.FIELDS.NON_GENERATING_REVENUE] = _this.countGeneratingPerPeriod(false, row[MANAGE_COST_CENTER.FIELDS.PERIOD]);
        editedPeriods.push(_this.state.editedPeriod);
      } else {
        row[MANAGE_COST_CENTER.FIELDS.STATES] = _this.countCostCenterStates(row[MANAGE_COST_CENTER.FIELDS.COST_CENTER])                                         
      }

      if (isByPeriod) {// updating status to defined and modified by and modified date
        periodStatus
          .filter(e => e[MANAGE_COST_CENTER.FIELDS.PERIOD] === _this.state.editedPeriod)
          .map(function (item) {
            item[MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_DATE] = new Date().toLocaleDateString('en-CA');
            item[MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_BY] = parseBoolean(_this.props.user.is_system) ? 'System User' : _this.props.user.first_name + " " + _this.props.user.last_name;
            item[MANAGE_COST_CENTER.FIELDS.STATUS] = MANAGE_COST_CENTER.STATUS.DEFINED;
          });
      } else {
        costCentersData
          .filter((e) => e[MANAGE_COST_CENTER.FIELDS.COST_CENTER] === _this.state.editedPeriod)
          .map(function (item) {
            item[MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_DATE] = new Date().toLocaleDateString("en-CA");
            item[MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_BY] = parseBoolean(_this.props.user.is_system) ? "System User" : _this.props.user.first_name + " " + _this.props.user.last_name;
            item[MANAGE_COST_CENTER.FIELDS.STATUS] = MANAGE_COST_CENTER.STATUS.DEFINED;
          });
        // update last modified date and modified by in periods tab
        periodStatus
          .filter((p) =>
            periodCostCenters
              .filter((f) => f[MANAGE_COST_CENTER.FIELDS.COST_CENTER] === this.state.editedPeriod)
              .map((m) => m[MANAGE_COST_CENTER.FIELDS.PERIOD])
              .includes(p[MANAGE_COST_CENTER.FIELDS.PERIOD])
          )
          .map((item) => {
            item[MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_DATE] =
              new Date().toLocaleDateString("en-CA");
            item[MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_BY] = parseBoolean(
              _this.props.user.is_system
            )
              ? "System User"
              : _this.props.user.first_name + " " + _this.props.user.last_name;
          });

        // update the periods status that has all cost centers defined
        let periodsData = groupBy(periodCostCenters, e => e[MANAGE_COST_CENTER.FIELDS.PERIOD]);
        periodStatus.filter((f) => periodsData.get(f[MANAGE_COST_CENTER.FIELDS.PERIOD]))
          .map((m) => {
            let definedPeriods = periodsData.get(m[MANAGE_COST_CENTER.FIELDS.PERIOD]).filter((f) => !f[MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE]);
            m[MANAGE_COST_CENTER.FIELDS.STATUS] = definedPeriods.length > 0 ? MANAGE_COST_CENTER.STATUS.UNDEFINED : MANAGE_COST_CENTER.STATUS.DEFINED;
            // if (definedPeriods.length === 0) {
              // editedPeriods.push(m[MANAGE_COST_CENTER.FIELDS.PERIOD]);
            // }
          }
          );

          tabData.map(m => editedPeriods.push(m[MANAGE_COST_CENTER.FIELDS.PERIOD]))
      }

      row[MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_DATE] = new Date().toLocaleDateString('en-CA');
      row[MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_BY] = parseBoolean(_this.props.user.is_system) ? 'System User' : _this.props.user.first_name + " " + _this.props.user.last_name;
      row[MANAGE_COST_CENTER.FIELDS.STATUS] = MANAGE_COST_CENTER.STATUS.DEFINED;

      let defined = periodStatus.filter(e=>e[MANAGE_COST_CENTER.FIELDS.STATUS] === MANAGE_COST_CENTER.STATUS.DEFINED).length;
      let definedCC =  costCentersData.filter(e=>e[MANAGE_COST_CENTER.FIELDS.STATUS] === MANAGE_COST_CENTER.STATUS.DEFINED).length;

      _this.setState({
        data: periodStatus,
        periodCostCenters: periodCostCenters,
        isChanged: true,
        defined: defined,
        undefined: periodStatus.length - defined,
        definedCostCenters: definedCC,
        undefinedCostCenters: costCentersData.length - definedCC,
        costCentersData: costCentersData,
        editedPeriod: "",
        editedPeriods:editedPeriods,
        showLoader:false,
      },function(){
        _this.props.setHasUnsavedChanges(true);
        // _this.onToggleBoard();
        if(isByPeriod) {  // Use correct tabulator to update row
          _this.ccTab.tabulator.updateRow(row[ MANAGE_COST_CENTER.FIELDS.PERIOD], row);
        } else {
          _this.ccTabC.tabulator.updateRow(row[MANAGE_COST_CENTER.FIELDS.COST_CENTER], row);
        }
       
        if(typeof callback === "function"){
          callback();
        }
        _this.backdropClickHandler() // to close side panel
      })
    }
  
    /**
     *function saves newState to the database  
     */
    saveNewState=()=>{
        let _this = this;
        let ref = _this.state.isBulkdefine ? _this.bulkDef : _this.ccDef;
        let newState = {name: _this.newState[_name], is_revenue_generating: _this.state.type === MANAGE_COST_CENTER.FIELDS.GENERATING_REVENUE, description:_this.newState[_description], display_name:_this.newState[_name]};
        let statesMap = _this.state.statesMap;
        let states = _this.state.costCenterStates || [];
        states.push(newState);
        statesMap[newState[_name]] = newState.is_revenue_generating;
        _this.setState({
          costCenterStates:states,
          statesMap: statesMap
        },function(){
          _this.closeNewStateModal();
          ref.ccTabRef.tabulator.replaceData(ref.ccTabRef.tabulator.getData());
          var query = {
              action: "saveCostCenterState",
              scenario_id:this.props.scenarioId,
              cost_center_state:JSON.stringify(newState)
          }
          let onThenCallback = (res)=>{
            let tempState={};
              if (res && res.SUCCESS) {
                tempState.isError = false;
                tempState.message = lang.manage_cost_centers.state_saved_successfully;
              } else{
                tempState.isError = true;
                tempState.message = lang.manage_cost_centers.state_saved_error,
                tempState.costCenterStates = _this.state.costCenterStates.filter(e=>e[_name] === newState[_name])
              }
              _this.setState(tempState,function(){
                _this.launchToast();
                _this.newState={};
              })
          }
          let fetchOptions = {
              [FETCHAPI_PARAMS.funcName]: "saveCostCenterState",
              [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
              [FETCHAPI_PARAMS.showLoader]: false,
              [FETCHAPI_PARAMS.path]: API_URL.MANAGE_COST_CENTER,
              [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
              [FETCHAPI_PARAMS.query]: query,
              [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
              [FETCHAPI_PARAMS.screenName]:lang.observability.manage_cost_center.screen_name,
              [FETCHAPI_PARAMS.requestDescription]:lang.observability.manage_cost_center.requests_description.save_cost_center_state
          }
          fetchAPI(fetchOptions);
        })
      }

    /**
     * discarding changes for definition section and closing the sidepanel
     */
     discardAllChanges=()=>{
        let _this = this;
        if(_this.state.loadScenario){
            
            _this.setState({
                isChanged: false,
                editedPeriods: [],
                },()=>{
                _this.fetchCostCenterPeriods(this.state.selectedScenario)
                _this.props.setHasUnsavedChanges(false);
                })
            
        } else {
            let defined = _this.state.originalData.filter(e=>e[MANAGE_COST_CENTER.FIELDS.STATUS] === MANAGE_COST_CENTER.STATUS.DEFINED).length;

            _this.setState({
            isChanged: false,
            data:copyObjectValues(_this.state.originalData),
            defined: defined,
            undefined: _this.state.originalData.length - defined,
            periodCostCenters: copyObjectValues(_this.state.originalPeriodCostCenters),
            costCentersData: copyObjectValues(_this.state.originalCostCentersData),
            editedPeriods: [],
            },()=>{
            _this.ccTab.tabulator.replaceData(copyObjectValues(_this.state.data));
            _this.displayCostCentersMainTable( _this.ccTab.formatTable);
            _this.props.setHasUnsavedChanges(false);
            })
        }
        _this.props.setHasUnsavedChanges(false);
        
    }

    warningDialogActions = () =>{
      let obj = this;
      return(
          <Button 
            label={lang.modal.buttons.ok}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={()=>{obj.showDialog(false)}}
            aria-label="Close"
            className='uk-padding-small-right'
				  />
      )
    }

    handleRadioChange = (value) => {
      let _this = this;
      let isActual = value === SCENARIO_TYPE.NORMAL;
      let prevSelectedScenario = _this.state.selectedScenario;
      let prevChangedScenario = _this.state.changedScenario;

      _this.setState({
        isActual: isActual,
        changedScenario: false,
        prevSelectedScenario: prevSelectedScenario,
        prevChangedScenario: prevChangedScenario
      })
      if(isActual && this.state.changedScenario){
        if(_this.state.isChanged){
            this.setState({
                loadScenario: true,
                selectedScenario: '',
                openDiscardChangesModal: true
            })
            
        } else {
            _this.fetchCostCenterPeriods()
        } 
      } 
    }
  
    handleScenarioChange = (e) => {
      let _this = this;
      let prevSelectedScenario = _this.state.selectedScenario;
      this.setState({
        prevSelectedScenario: prevSelectedScenario
      })
      if(e){
        _this.setState({
          selectedScenario: e.value
        },()=>{
            if(_this.state.isChanged){
                this.setState({
                    loadScenario: true,
                    openDiscardChangesModal: true
                })
            } else {
                _this.fetchCostCenterPeriods(this.state.selectedScenario);
            } 
        })
      }
    }

    cancelReset = () => {
        let _this = this;
        if(_this.state.loadScenario){
            let prevSelectedScenario = _this.state.prevSelectedScenario;
            let prevChangedScenario = _this.state.prevChangedScenario;
            let isActual = !prevSelectedScenario;
            _this.setState({
                loadScenario: false,
                selectedScenario: prevSelectedScenario,
                isActual: isActual,
                changedScenario: prevChangedScenario
            })
        }
    }

    warningContent = () => {
      return (
          <div className='pi-warning-background uk-border-rounded uk-padding-small'>
              <DialogContentText id="alert-dialog-description" fontSize={"0.83vw"} className="text">
                  <i className="fa-2x fal fa-exclamation-triangle uk-margin-default-right" />
                  {this.state.warning}
              </DialogContentText>
          </div>
      )
  }

  selectStateDialogContent = () => {
    let _this = this;
    let states = [{ label: MANAGE_COST_CENTER.FIELDS.GENERATING_REVENUE_TITLE, value: MANAGE_COST_CENTER.FIELDS.GENERATING_REVENUE, isDisabled: true }];
    _this.state.costCenterStates.filter(e => e[MANAGE_COST_CENTER.FIELDS.IS_REVENUE_GENERATING]
      && e.name.toLowerCase() == _closed || e.name.toLowerCase() == _selling).sort((function (a, b) {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      })).map(function (item) {
        states.push({ label: item.display_name, value: item.name, tooltipText: item.description });
      });
    _this.state.costCenterStates.filter(e => e[MANAGE_COST_CENTER.FIELDS.IS_REVENUE_GENERATING]
      && e.name.toLowerCase() !== _closed && e.name.toLowerCase() !== _selling).sort((function (a, b) {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      })).map(function (item) {
        states.push({ label: item.display_name, value: item.name, tooltipText: item.description });
      });
    states.push({ label: MANAGE_COST_CENTER.FIELDS.NON_GENERATING_REVENUE_TITLE, value: MANAGE_COST_CENTER.FIELDS.NON_GENERATING_REVENUE, isDisabled: true });
    _this.state.costCenterStates.filter(e => !e[MANAGE_COST_CENTER.FIELDS.IS_REVENUE_GENERATING]
      && e.name.toLowerCase() === _sourceSystem).sort((function (a, b) {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      })).map(function (item) {
        states.push({ label: item.display_name, value: item.name, tooltipText: item.description });
      });
    _this.state.costCenterStates.filter(e => !e[MANAGE_COST_CENTER.FIELDS.IS_REVENUE_GENERATING]
      && e.name.toLowerCase() !== _sourceSystem).sort((function (a, b) {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      })).map(function (item) {
        states.push({ label: item.display_name, value: item.name, tooltipText: item.description });
      });

    const stateOptions = ({ label, isDisabled, tooltipText }) => (
      <div
        className={
          (isDisabled ? "option-group-header " : "") +
          " option-padding uk-flex-between uk-display-flex manage-columns-title"
        }
      >
        {label}

        {!isDisabled ?
          <i
            className="fs-12 fal fa-info-circle uk-margin-xsmall-left uk-cursor-pointer info-icon"
            uk-tooltip={tooltipText}
            aria-hidden="true"
            title=""
            aria-expanded="false"
            tabIndex="0"
          />
          : ""}

      </div>
    );
    return (
      <>
        <div>
          <span className="uk-text-xmedium">{lang.manage_cost_centers.state}</span>
        </div>
        <Dropdown
          id="select-type"
          className="width-200 input__dropdown"
          name="type"
          placeholder={lang.manage_cost_centers.select_cost_center_state}
          value={findOptionByKey(states, this.state.costCenterState)}
          onChange={(e) => this.saveState(e)}
          options={states}
          formatOptionLabel={stateOptions}
          type={DROPDOWN_TYPE.INPUT}
        />
        <div className="uk-display-block uk-margin-top">
          {this.messages[_state] ?
            <span className="red uk-display-inline-block">{this.messages && this.messages[_state]}</span>
            : ""}
        </div>
      </>
    )
  }

  selectStateDialogActions = () => {
    return (
      <>
        <Button 
          label={lang.modal.buttons.apply}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}                   
          onBtnClick={this.selectState}
        />
       <Button 
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={this.closeSelectStateModal}
        />
      </>
    )
  }  

  addNewStateDialogContent = () => {
    return (
      <>
       <div className="med-gap-flex">
                <div className="small-gap-flex">
                    <div className="uk-text-bolder fs-14">
                        <span>{_DisplayName}</span>
                        <span>{lang.tags.required}</span>
                    </div>
                    <div className="uk-width-1-2">
                        <Input
                            placeholder = {"Cost Center Name"}
                            onBlur={(e)=>{this.updateFields(e, _name)}}
                            notFullWidth={true}
                        />
                    </div>
                    
                </div>

                <div className="small-gap-flex">
                    <div>
                        <span className="uk-text-bolder fs-14">{MANAGE_COST_CENTER.TITLES.TYPE}</span>
                    </div>
                    <div className="uk-display-flex radio-button-flex">
                        <div className="uk-display-flex">
                            <RadioButton
                                className="radioButton uk-margin-large-right"
                                name={_non_revenue_generating}
                                value={_non_revenue_generating}
                                checked={(this.state.type == _non_revenue_generating)}
                                onChange={(e) => this.updateFields(e, "type")}
                                label={lang.manage_cost_centers.non_revenue_generating}
                            />
                        </div>
                        <div className="uk-display-flex">
                            <RadioButton
                                className="radioButton uk-margin-small-right"
                                name={_revenue_generating}
                                value={_revenue_generating}
                                checked={(this.state.type == _revenue_generating)}
                                onChange={(e) => this.updateFields(e, "type")}
                                label={lang.manage_cost_centers.revenue_generating}
                            />
                        </div>
                    </div>
                </div>

                <div className="small-gap-flex fs-14">
                    <div>
                        <span className="uk-text-bolder">{_DisplayDescription}</span>
                        <span className="disabled">{lang.tags.optional}</span>
                    </div>
                    <div>
                    <textarea onBlur={(e)=>{this.updateFields(e,_description)}} rows="4" placeholder={lang.manage_cost_centers.description} className=" text-area input-default-no-height"/>
                    {this.messages[_name] ? <span className="red uk-display-inline-block uk-margin-small-left">{this.messages && this.messages[_name]}</span>: ""}
                    {this.messages[_type] ? <span className="red uk-display-inline-block uk-margin-small-left">{this.messages && this.messages[_type]}</span>: ""}
                    </div>
                </div>
            </div>
      </>
    )
  }

  addNewStateDialogActions = () => {
    return (
      <>
        <Button 
          label={lang.manage_cost_centers.add_state}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          className={"uk-margin-small-left"}
          type={BUTTON_TYPE.DEFAULT}                   
          onBtnClick={this.addNewState}
        />
        <Button 
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={this.closeNewStateModal}
        />
      </>
    )
  }

    render(){
      let _this = this;
      let isBudgetingAllowed =  _this.props.userAllowedMenuLinks ? getSectionExists(_this.props.userAllowedMenuLinks, ALL_WIDGETS.BUDGETING) : false;
      let isPeriodView = _this.state.view === lang.COST_CENTER_VIEW.PERIODS.value;
      let options = [lang.COST_CENTER_VIEW.PERIODS,lang.COST_CENTER_VIEW.COST_CENTER]
      let buttons = [];
      buttons.push(
        { button: lang.modal.buttons.apply, isSubmit: true, isDisabled: false },
        { button: lang.modal.buttons.cancel, isSubmit: false, isDisabled: false, onClick: this.startCancel },
      );
      let backdrop = "";
      if (this.state.drawerOpen) {
          backdrop = <Backdrop close={this.backdropClickHandler} />;
      }
    
       return (
         <div className="costCenterContainer">
           <div id="toastSaveCostCenter" className="toast toast-success">
             <div id="desc">
               <i
                 className={
                   "fa-lg fas uk-margin-small-right " + (this.state.isError ? "fa-minus-circle uk-text-primary" : "fa-check-circle greenText")
                 }
                 aria-hidden="true"
               ></i>
               {lang.saved}
             </div>
           </div>
           <div style={{height: "100%"}}>
             <div style={{ padding: "0.52vw 1.56vw", display: "flex" }}>
               <PeriodCostTab
                 options={options}
                 defaultValue={this.state.view}
                 onSelectTab={(order) => this.handleElementChange(HEADER_ELEMENT.VIEW, order)}
               />
             </div>
             <div style={{ display: "flex", justifyContent: isBudgetingAllowed ? "space-between" : "flex-end" }}>
               {isBudgetingAllowed && (
                 <div className="uk-display-flex uk-flex-middle" style={{ columnGap: "0.63vw", alignItems: "center" }}>
                   <span className="uk-text-large uk-text-bolder  uk-margin-small-right">{lang.manage_cost_centers.show_amounts_using}</span>
                   <RadioButton
                     className="radioButton uk-margin-remove-top"
                     name={SCENARIO_TYPE.NORMAL}
                     value={SCENARIO_TYPE.NORMAL}
                     checked={this.state.isActual}
                     onChange={() => this.handleRadioChange(SCENARIO_TYPE.NORMAL)}
                     disabled={false}
                     label={lang.manage_cost_centers.actual_data}
                   />
                   <span
                     className={
                       !this.state.generatedBudgetScenarios ? "disabled-with-events uk-display-flex uk-flex-middle" : "uk-display-flex uk-flex-middle"
                     }
                     uk-tooltip={!this.state.generatedBudgetScenarios ? lang.manage_cost_centers.no_budget_scenario : "title:"}
                   >
                     <RadioButton
                       className="radioButton uk-margin-remove-top"
                       name={SCENARIO_TYPE.BUDGETING}
                       value={SCENARIO_TYPE.BUDGETING}
                       checked={!this.state.isActual}
                       onChange={() => this.handleRadioChange(SCENARIO_TYPE.BUDGETING)}
                       disabled={!this.state.generatedBudgetScenarios}
                       label={lang.manage_cost_centers.budget_data}
                     />
                   </span>
                   <Dropdown
                     id="select-type"
                     className="width-200 input__dropdown"
                     name="type"
                     placeholder={lang.manage_cost_centers.select_budget_scenario}
                     value={findOptionByKey(this.state.generatedBudgetScenarios, this.state.selectedScenario)}
                     onChange={(e) => this.handleScenarioChange(e)}
                     options={this.state.generatedBudgetScenarios}
                     disabled={this.state.isActual}
                     type={DROPDOWN_TYPE.INPUT}
                   />
                 </div>
               )}
               <div>
                 <div className="periods-defined uk-display-inline-block">
                   <div className="uk-display-inline-block">
                     <span className="uk-text-large uk-text-bold">
                       {isPeriodView ? lang.manage_cost_centers.defined_periods_title : lang.manage_cost_centers.defined_cost_centers_title}
                     </span>
                     <span className="uk-text-large uk-margin-xsmall-left">
                       {isPeriodView ? _this.state.defined : _this.state.definedCostCenters}
                     </span>
                   </div>
                   <div className="uk-display-inline-block uk-margin-small-left">
                     <span className="uk-text-large uk-text-bold">
                       {isPeriodView ? lang.manage_cost_centers.undefined_periods_title : lang.manage_cost_centers.undefined_cost_centers_title}
                     </span>
                     <span className="uk-text-large uk-margin-xsmall-left">
                       {isPeriodView ? this.state.undefined : _this.state.undefinedCostCenters}
                     </span>
                   </div>
                 </div>
                 {this.state.view === lang.COST_CENTER_VIEW.PERIODS.value ? (
                   <Button
                     id="bulk-define"
                     label={"Bulk Define"}
                     title={"Select State"}
                     variant={BUTTON_VARIANT.SECONDARY}
                     size={SIZES.DEFAULT}
                     type={BUTTON_TYPE.DEFAULT}
                     className="uk-float-right uk-margin-small-left"
                     leftIcon={<i className={"fa-lg fal fa-tasks"} />}
                     onBtnClick={this.bulkDefine}
                   />
                 ) : (
                   ""
                 )}
               </div>
             </div>
             <div className={"uk-margin-small-top cc-container " + (this.state.view !== lang.COST_CENTER_VIEW.PERIODS.value ? " uk-hidden" : "")}>
               <CostCenterTable
                 ref={(el) => (this.ccTab = el)}
                 columns={this.state.columns}
                 data={this.state.data}
                 id={this.state.id}
                 index={MANAGE_COST_CENTER.FIELDS.PERIOD}
                 define={this.callDefine}
                 countGeneratingPerPeriod={this.countGeneratingPerPeriod}
                 isCostCenterNew={this.isCostCenterNew}
                 isCostCenterStateDifferentThanPrevious={this.isCostCenterStateDifferentThanPrevious}
                 view={this.state.view}
               />
             </div>
             <div
               className={
                 "uk-margin-small-top cc-container cost-centers-table" + (this.state.view === lang.COST_CENTER_VIEW.PERIODS.value ? " uk-hidden" : "")
               }
             >
               <CostCenterTable
                 ref={(el) => (this.ccTabC = el)}
                 pagination={true}
                 columns={this.state.costCenterColumns}
                 data={this.state.costCentersData}
                 id={this.state.id}
                 index={MANAGE_COST_CENTER.FIELDS.COST_CENTER}
                 define={this.callDefine}
                 countGeneratingPerPeriod={this.countGeneratingPerPeriod}
                 isCostCenterNew={this.isCostCenterNew}
                 isCostCenterStateDifferentThanPrevious={this.isCostCenterStateDifferentThanPrevious}
                 view={this.state.view}
               />
             </div>
             <Modal
               id="selectStateModal"
               openDialog={this.state.openSelectStateDialog}
               title={lang.manage_cost_centers.what_select_state}
               bodyContent={this.selectStateDialogContent}
               dialogActions={this.selectStateDialogActions}
               closeClick={this.closeSelectStateModal}
               size={DIALOG_SIZE.MEDIUM}
             />
             <Modal
               id="new-state-modal"
               openDialog={this.state.openAddNewStateDialog}
               title={lang.monthly_build.cost_centers.modal.title}
               bodyContent={this.addNewStateDialogContent}
               dialogActions={this.addNewStateDialogActions}
               closeClick={this.closeNewStateModal}
               size={DIALOG_SIZE.LARGE}
             />
             <Modal
               id={"warning-datasource-dialog"}
               title={"Warning"}
               openDialog={_this.state.show}
               closeClick={() => _this.showDialog(false)}
               bodyContent={_this.warningContent}
               dialogActions={_this.warningDialogActions}
               size={DIALOG_SIZE.MEDIUM}
             />
             {this.state.displayPanel ? <div id="overlay-dashboards"></div> : ""}
             {/* <SidePanel ref={r=>this.manageDashboardsOverlay=r} onToggleBoard={this.onToggleBoard} onCancel={this.startCancel} 
                title={this.state.isBulkdefine ? sidePanelTitle + lang.manage_cost_centers.bulk_define_title : sidePanelTitle + this.state.editedPeriod} 
                hideCancel={true}
                body={this.renderSidePanel()} onSave={this.validateDefinition} isApplyButtonText={true} 
                showTooltipMessage={this.state.isBulkdefine ? lang.manage_cost_centers.bulk_define_tooltip : isPeriodView ? lang.manage_cost_centers.define_tooltip.replace("[period]", this.state.editedPeriod) : lang.manage_cost_centers.cc_define_tooltip.replace("[cost_center]", this.state.editedPeriod)} 
              /> */}
             <SidePanelNew
               body={this.renderSidePanel()}
               onSubmit={this.validateDefinition}
               show={this.state.drawerOpen}
               close={this.backdropClickHandler}
               buttons={buttons}
               halfScreenDisplay
               title={
                 this.state.isBulkdefine ? sidePanelTitle + lang.manage_cost_centers.bulk_define_title : sidePanelTitle + this.state.editedPeriod
               }
               showTooltipMessage={
                 this.state.isBulkdefine
                   ? lang.manage_cost_centers.bulk_define_tooltip
                   : isPeriodView
                   ? lang.manage_cost_centers.define_tooltip.replace("[period]", this.state.editedPeriod)
                   : lang.manage_cost_centers.cc_define_tooltip.replace("[cost_center]", this.state.editedPeriod)
               }
             />
             {backdrop}
           </div>
         </div>
       );
    }
}



export default ManageCostCenters