import React, { Component } from 'react';
import shortid from 'shortid';
import Tabulator from "tabulator-tables"; //import Tabulator library
import { findIndexOfValue, getObjectValues, linearizeHierarchy } from '../class/array';
import { formatAdvancedFilter, formatBasicFilter, loaderCallback, logout, prepareFilter, setLocalStorageValueByParameter, toggleLoader, wrapFilterWithParenthesis } from '../class/common';
import {
	ALL_REPORTS,
	ALL_WIDGETS,
	API_URL,
	BUTTON_TYPE,
	BUTTON_VARIANT,
	COLUMN_PROFILE,
	column_suffixes,
	DIALOG_SIZE,
	drillParam,
	ENTITY_TYPES,
	FILTER,
	HEADER_ELEMENT,
	MANAGE_COLUMNS,
	MENU_ITEM,
	ORDER_VALUES,
	PROFILE_COLUMN,
	PSL_RETURN_NAMES,
	ROLLING_SEGMENTS,
	SELECTED_DRILL_LIST,
	SIZES,
	SUFFIX,
	UI_ACTIONS,
	vectorViews
} from '../class/constants.js';
import { getMonthName } from '../class/date';
import { convertPxToViewport } from '../class/formatting';
import { alertError, destroyDrillSections, hideProfitStackLink, resetDrillSet, setDrillRowsLimit, setLimit } from '../class/jqueries.js';
import { FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS } from '../class/networkUtils';
import { numberCommaFormat } from '../class/number';
import { exportToExcel } from '../class/tabulatorExport.js';
import { copyObjectValues, findOptionByKey, getTranslationFile, removeAttributes, returnQuadrantAbv, tryParse } from '../class/utils';
import ManageColumns from '../components/manageColumns/ManageColumns';
import TabulatorWrapper from '../Items/TabulatorWrapper.js';
import '../styles/media.css';
import '../styles/profitSummary.css';
import ExcelDetailsTable from '../tables/ExcelDetailsTable';
import { setUpDrillFilter } from './filter/FilterHelperFunctions';

import { updateGeographyListData } from '../actions/geographyConcentrationActions';
import { updateHeatmapListData } from '../actions/heatMapActions';
import { updateLandscapeBubbleListData } from '../actions/landscapeBubbleActions';
import { updateDrillListData, updateListData } from '../actions/listActions';
import { getItemFromStore } from '../class/reduxStoreUtils';
import Button from '../newComponents/Button';
import Modal from '../newComponents/Modal';
import DrillCard from "../newComponents/DrillCard";
import { lang } from '../language/messages_en';

const $ = require('jquery');

const MESSAGES = getTranslationFile();
const drillCellObject = {
	title: "Drill",
	visible: true,
	headerSort: false,
	width: convertPxToViewport(50),
	titleDownload: "\n"
}

const _selectedVector = "selected_vector";
class ProfitMapList extends Component {

	constructor(props) {
		super(props)
		this.tabulatorTop = React.createRef();
		this.tabulatorList = React.createRef();
		this.tabulatorDrill = React.createRef();

		this.state = {
			user: null,
			drillDataList: null,
			drillRows: props.drillRows,
			drillTier: this.props.dataTier,
			dataTop: null,
			tier: this.props.dataTier,
			roundTwo: false,
			buttonClass: "triangle-up",
			appending: "",
			filter: [{
				"tier": null,
				"type": "key",
				"opp": "=",
				"logic": "",
				"value": ""
			}],
			refresh: this.props.refresh,
			drilling: 0,
			newColumnOrder: [],
			order: this.props.order || ORDER_VALUES.DESC,
			dataFinal: { data: [], columns: [] },
			cardsData: props.cardsData,
			// firstChunkData: []
		};
        this.isMainReport = { isList: true}
		this.fetchAPI = fetchAPI.bind(this);
		this.exportTableToExcel = this.exportTableToExcel.bind(this);
		this.submitDrill = this.submitDrill.bind(this);
		this.setNextTier = this.setNextTier.bind(this);
		this.handleChangeNoExec = this.handleChangeNoExec.bind(this);
		this.setHideTable = this.setHideTable.bind(this);
		this.handleDrillInputChange = this.handleDrillInputChange.bind(this);
		this.appendDataToTableTop = this.appendDataToTableTop.bind(this);
		this.clearDrillingData = this.clearDrillingData.bind(this);
		this.fetchProfitInfo = this.fetchProfitInfo.bind(this);
		this.onColumnMoved = this.onColumnMoved.bind(this);
		this.drillBack = this.drillBack.bind(this);
		this.logout = logout.bind(this);
		this.setOrder = this.setOrder.bind(this);
		this.onToggleManageColsBoard = this.onToggleManageColsBoard.bind(this);
		this.cardRef = []
    	this.drillManageColumnsProfile = React.createRef();
	}

	// getdataFinal=()=>{
	// 	this.props.setDataColumns(this.state.dataFinal.columns);
	// }

	getExportFilter = () => {
        let _this = this;
        let filter = _this.props.filter? typeof _this.props.filter === "string" ? (_this.props.filter === "" ? [] : tryParse(_this.props.filter)) : _this.props.filter:"";
        let exportQueryFilter = _this.props.getExportQueryFilter && typeof _this.props.getExportQueryFilter === "function" ? _this.props.getExportQueryFilter(filter) :undefined;
            if((!exportQueryFilter || exportQueryFilter === "None") && filter?.length > 0) {
                exportQueryFilter = filter.find(f => !f.isBasicFilter) ? formatAdvancedFilter(filter, this.props?.userSettings?.user?.user_allowed_vectors, this.props.datasetOptions, this.props.vectorOptions, this.props.psLinesOptions) 
				: formatBasicFilter(filter, this.props.userSettings?.user?.user_allowed_vectors);
            }

        return exportQueryFilter;
    }

	getParams = () => {
		let obj = this;
		let exportFilter =  this.props.getExportQueryFilter ? this.props.getExportQueryFilter :this.getExportFilter() || "None";
		
		if (obj.props.profitFormat && obj.props.user && obj.props.scenarioObject && (obj.props.months || obj.props.FY) && obj.props.dataset) {
			let params = {
				"Report": obj.translateReportTitle(obj.props.isLandscapeBubble ? obj.props.reportExcelName : obj.props.profitFormat),
				"User": obj.props.user.first_name + " " + obj.props.user.last_name,
				"Date Run": new Date().getDate() + '-' + getMonthName(new Date().getMonth()) + '-' + new Date().getFullYear(),
				"Filter": exportFilter,
				// "Data Set": getMonthsNumber(obj.props.FY) +" Mo "+ obj.props.dataset,
				"Scenario": obj.props.scenarioObject['scenario_number'],

			}
			if ([ALL_REPORTS.CONTOUR_MAP].includes(obj.props.profitFormat)) {
				params["Data Set"] = obj.props.dataset;
			} else {
				if (obj.props.selectedPeriods) {
					params["Start Date"] = obj.props.selectedPeriods[0];
					params["End Date"] = obj.props.selectedPeriods[obj.props.selectedPeriods.length - 1];
				}

			}
			params = this.formatParams(params);
			return params;
		}



	}

	formatParams = (params) => {
		let data = []
		for (const property in params) {
			let obj = { title: property, detail: params[property] }
			data.push(obj)
		}
		return data;
	}

	translateReportTitle(profitFormat) {
		let result = "";
		if (profitFormat === ALL_REPORTS.HEATMAP) {
			result = ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.HEATMAP;
		} else if (profitFormat === ALL_REPORTS.CONTOUR_MAP) {
			result = ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.CONTOUR_MAP;
		} 	else if(profitFormat === MENU_ITEM.FIELDS.PROFIT_LANDSCAPE) {
			result = ALL_REPORTS.PROFIT_LANDSCAPE;
		}else {
			result = profitFormat;
		}
		return result;
	}

	handleArrayData (dataCopy, columns, name, mergeMap, exportOpts) {
		if (dataCopy.some(e => Object.values(e).some(Array.isArray))) { // if at least one element of the data has an element having an array as value
			let arrayLength = Object.values(dataCopy[0]).find(Array.isArray).length; // number of selected entities
			let mergeArr = [];
			let formattedData = [];
			
			for (let i = 0; i < arrayLength; i++) { // for each selected entity
				let row = {};
				let keys = Object.keys(dataCopy[0]);
				keys.forEach(key => {
					if (Array.isArray(dataCopy[0][key])) {
						row[key] = dataCopy[0][key][i]; // for the array elements, split them into two rows, one value for each row
					} else if (i === 0) { // for the first row
						row[key] = dataCopy[0][key]; // only add the elements with one value (pslines) to the first row
					} else { // the empty lines (pslines under the other rows)
						// reorder columns and remove hidden columns to be able to get the index of the columns that should be merged (pslines)
						let orderedCols = columns.filter(e => !e.getField().includes("_combination") && e.getField() !== "key")
							.sort((a, b) => b.column_order - a.column_order); // sort by column_order to get the same order as in the excel order
						let colIndex = orderedCols.indexOf(columns.find(e => e.getField() === key));
						let mergeRange = {
							s: { r: 1, c: colIndex },
							e: { r: i + 1, c: colIndex }
						};
						// s stands for start, e for end, r for row and c for column
						// under "start", row should be 1 (we always start at index 1 since the index 0 is for the title), and col = the index of the column
						// under "end", row = i (index of entity) + 1 and col = the index of the column
						if (colIndex > -1 && i === arrayLength - 1) {
							// let existingMerge = mergeArr.find(f => f.e.c === colIndex && f.s.c === colIndex);
							// if (existingMerge) { // if we already have a merge for this column, remove it, to take the largest merge
							// 	mergeArr.splice(mergeArr.indexOf(existingMerge), 1);
							// }
							mergeArr.push(mergeRange); // push the new merge range
						}
					}
				});
				formattedData.push(row);
			}
			mergeMap.set(name, mergeArr); // set mergeArr by sheet name
			exportOpts.mergeRange = mergeMap;
			
			return formattedData; // Return the formatted data
		}
		return null; // If no array data, return null
	}
	

	exportTableToExcel(exportOpts) {
		let _this = this;
		let cardsData = copyObjectValues(this.state.cardsData);
		let isDrilling = this.props.isDrilling;
		let drillTierLabel = this.state.drillTier ? this.props.options.filter(e => e.value === this.state.drillTier)[0].label : ""
		let tempSheetName = isDrilling ? "DrillData " + drillTierLabel : exportOpts.vector;
		let mainSheet = tempSheetName.length > 31 ? tempSheetName.substring(0, 28) + "..." : tempSheetName;
		let sheets = {};
		let existingVectorsNames = [];
		let name = "";
		let number = 1;
		let startDate = _this.props.selectedPeriods[0];
		let endDate = _this.props.selectedPeriods[_this.props.selectedPeriods.length - 1];
		if (isDrilling) {
			let mergeMap = new Map();
			let tempDiv;
			for (let card in cardsData) {
				if (isDrilling && this.cardRef[card].ref && this.cardRef[card].ref.current && this.cardRef[card].ref.current.tabulator && this.cardRef[card].ref.current.props.data.data && this.cardRef[card].ref.current.props.data.data.length > 0) { // user already clicked the drill button on the list table
					name = cardsData[card].data[0].tier;
					existingVectorsNames.forEach(vName => { // check how many times the name is repeated to get the right numbering
						if (vName === name) {
							number++;
						}
					});
					if (number > 1) {
						name = name + " " + number; // if the name exists for another card, add numbering 
					}
					number = 1;
					name = drillParam + ". " + name;
					if (name.length > 30) { // if the name is long (>30), replace the last 3 chars by "…"
						name = name.substring(0, 27) + "...";
					}
			
					let columns = this.cardRef[card].ref.current.tabulator.getColumns();
					let dataCopy = copyObjectValues(this.cardRef[card].ref.current.tabulator.getData());			
					let formattedData = this.handleArrayData(dataCopy, columns, name, mergeMap, exportOpts);
			
					if (!formattedData) { // if no array data, just use the original data
						formattedData = dataCopy;
					}
			
					tempDiv = document.createElement('div');
					tempDiv.setAttribute("id", "export" + card);
					document.body.appendChild(tempDiv);
			
					// Create a new Tabulator instance for exporting
					let exportTabulator = new Tabulator("#export" + card, {
						data: formattedData,
						columns: columns.map(colComponent => colComponent.getDefinition()), // format columns
					});
					sheets[name] = exportTabulator;
					document.body.removeChild(tempDiv);
			
					existingVectorsNames.push(cardsData[card].data[0].tier); // push the original name of each card in the list
					if (this.tabulatorDrill?.current?.props?.data) {
						exportOpts.data = this.tabulatorDrill.current.tabulator.getData();
					} else {
						this.setInfoDialogOpen(true, "Drill result is not yet loaded to be exported");
						return false;
					}
				}
			}
			sheets[mainSheet] = true;
			exportOpts.sheets = sheets;
			sheets["Description"] = this.excelDetails.tabulator;
			exportToExcel(exportOpts, this.tabulatorDrill?.current?.tabulator,startDate,endDate);
		} else {
			sheets[mainSheet] = true;
			exportOpts.sheets = sheets;
			exportOpts.data = this.tabulatorList.current.tabulator.getData();
			sheets["Description"] = this.excelDetails.tabulator;
			exportToExcel(exportOpts, this.tabulatorList.current.tabulator,startDate,endDate);
		}
	}


	setOrder(order, sorter = this.state.sorter) {
		var _this = this;
		this.setState({
			order: order,
			sorter: sorter || ""
		});
	}

	setTableColumns(tableColumns) {
		if (this.props.headerRef) {
			this.props.headerRef.setTableColumns(tableColumns);
		}
	}

	/**
	 * remove vector having only entity count AND/OR count per selection AND/OR count per total
	 * @param {*} arr 
	 * @param {*} suffixesToBeExcluded 
	 * @returns 
	 */
	filterArray(arr, suffixesToBeExcluded) {
		let newArr  = copyObjectValues(arr);
		// Count the occurrences of each column_return_name
		const nameCounts = newArr.reduce((acc, item) => {
		  acc[item.column_return_name] = (acc[item.column_return_name] || 0) + 1;
		  return acc;
		}, {});
	  
		// Identify the names that have all elements' machine_name ending with suffixesToBeExcluded
		const namesToExclude = new Set();
		
		for (const name in nameCounts) {
		  const allExcluded = newArr
			.filter(item => item.column_return_name === name)
			.every(item => suffixesToBeExcluded.some(suffix => item.machine_name.endsWith(suffix)));
		  
		  if (allExcluded) {
			namesToExclude.add(name);
		  }
		}
	  
		// Filter out the elements with names to exclude
		return newArr.filter(item => !namesToExclude.has(item.column_return_name));
	  }

	/**
	 * should return true if we reached the limit of selected vectors, else it should return false
	 * we only count the vectors selected in the manage columns (without the vector selected in the header)
	 * @param {*} columns 
	 * @param {*} columnsViews 
	 * @returns 
	 */
	disableAllCheckBoxes(columns, columnsViews) {
		let isColumnsArrayOfStrings = Array.isArray(columns) && columns.every(item => typeof item === 'string');
		let toBeUsedCols = columns;
		if(isColumnsArrayOfStrings){
			toBeUsedCols = columnsViews;
		}
		let _this = this;
		let disableCheckBoxes;
		const suffixesToBeExcluded = ["entity_count", "count_per_selection", "count_per_total"];

		let filteredCoumns;
		let vectorsSelected;
		if(!_this.props.isDrilling || toBeUsedCols){
			filteredCoumns = this.filterArray(toBeUsedCols, suffixesToBeExcluded);
			let selectedVector = !_this.props.isDrilling? _this.props.selectedDropdownVector : _this.props.dataTier;
				vectorsSelected = filteredCoumns.filter((col, index, self) =>
					index === self.findIndex((c) => (c.column_return_name === col.column_return_name && (col.type === MANAGE_COLUMNS.VECTOR_MACHINE_NAME || col.type === MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME) && col.column_return_name !== selectedVector)));
			disableCheckBoxes = vectorsSelected.length > _this.props.vectorsSelectionLimit;
			let allVectorsSelected = toBeUsedCols.filter(f => f.type === MANAGE_COLUMNS.VECTOR_MACHINE_NAME || f.type === MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME);
			_this.setState({
				allVectorsSelected: allVectorsSelected
			})
		}
		return disableCheckBoxes;
	}

	getDataQuery(action, afterDrilling, page, size) {
		let _this = this;
		var parsedFinalFilter = typeof _this.props.filter === "string" ? _this.props.filter === "" ? tryParse("[]") : tryParse(_this.props.filter) : _this.props.filter;
		if (afterDrilling && this.props.drillFilter !== "[]") {
			// var i = 0;
      let drillFilter = copyObjectValues(this.props.drillFilter);
			drillFilter.forEach((item) => {
				// idnt use index because drillFrilter can have objects with row type not equal to drill making its inde different than the index in cardsData
				if (item[FILTER.KEYS.FILTER_ROW_TYPE] === FILTER.VALUES.FILTER_ROW_TYPE.DRILL) {
					// item.columnsViews = _this.state.cardsData[i].columnsViews;
					parsedFinalFilter.push(item);
					// i = i + 1;
				}
			});
		}

		let concatenatedFilter = parsedFinalFilter ? copyObjectValues(parsedFinalFilter) : "";
		let dashBoardFilter = tryParse(this.props?.dashboardFilter);
		if (this.props.fromWidget && dashBoardFilter?.length > 0 ) {
			if (concatenatedFilter.filter.length > 0) {
				concatenatedFilter.filter[0][FILTER.KEYS.PARENTHESIS_BEFORE] += "(";
				concatenatedFilter.filter[concatenatedFilter.filter.length - 1][FILTER.KEYS.PARENTHESIS_AFTER] += ")";
			}
			let dashboardFilterObj = "";
			dashBoardFilter[0][FILTER.KEYS.PARENTHESIS_BEFORE] += "(";
			dashBoardFilter[0].logical_operator = "AND";
			dashBoardFilter[dashBoardFilter.length - 1][FILTER.KEYS.PARENTHESIS_AFTER] += ")";
			dashBoardFilter.forEach(e => {
				dashboardFilterObj = e;
				concatenatedFilter.filter.push(dashboardFilterObj);
			})
			parsedFinalFilter = concatenatedFilter
		}

		var stringifiedDrillFilter = afterDrilling ? JSON.stringify(parsedFinalFilter) : "[]";
		var filter = afterDrilling ? stringifiedDrillFilter : parsedFinalFilter;

		// var filter = this.props.filter;
		filter = prepareFilter(filter, false, this.props.psFilterDisabled);
		var order = this.props.isDashBoards ? this.props.order : this.state.order;
		let columns = this.props.isDashBoards ? this.props.columns : this.props.manageColumnsProfile ? this.props.manageColumnsProfile.columns || [] : [];
		let columnsViews = this.props.isDashBoards ? removeAttributes(this.props.simplifiedColumns) : this.props.manageColumnsProfile ? removeAttributes(this.props.manageColumnsProfile[COLUMN_PROFILE.SIMPLIFIED_COLUMNS]) || [] : [];

		var query = {
			action: action,
			machine_name: this.props.machine_name,
			tier: this.props.dataTier,
			profitFormat: this.props.profitFormat,
			parentKey: '201',
			quarter: this.props.dataset,
			dbDataset: this.props.dbDataSet,
			tablePrefix: this.props.tablePrefix,
			months: this.props.months || this.props.FY,
			sorter: this.props.sorting_psl || this.state.sorter,
			idToken: this.props.idToken,
			order: order,
			filter: "{'filter':" + encodeURIComponent(filter ? filter : "[]") + "}",
			project_id: this.props.project_id,
			section_id: this.props.sectionId,
			url: window.location.href.split("#")[0],
			scenario_id: this.props.scenario_id,
			page: page || 1,
			size: size || 100,
			rollingSegment: ROLLING_SEGMENTS.R_12,
			rollingPeriod: this.props.rollingPeriod
		}
		if (columns) {
			let selectedProfileFromSession = typeof tryParse(sessionStorage.getItem("selectedProfile_" + this.props.profitFormat.toLowerCase())) === "object" ? tryParse(sessionStorage.getItem("selectedProfile_" + this.props.profitFormat.toLowerCase())) : undefined;
			let profileId = selectedProfileFromSession && selectedProfileFromSession !== null ? selectedProfileFromSession[COLUMN_PROFILE.ID] : null;
			if (profileId !== null && !_this.props.isLandscapeBubble) {
				columns = selectedProfileFromSession.columns;
				let columnsViews = removeAttributes(selectedProfileFromSession[COLUMN_PROFILE.SIMPLIFIED_COLUMNS]);

				if (columns.length > 0 && typeof columns[0] === "object") {
					columns.sort((x, y) => x.column_order - y.column_order);
					let simplifiedColumns = columns.map(e => e[PROFILE_COLUMN.RETURN_NAME])
					query.columns = simplifiedColumns;
					query.columnsViews = columns;
				} else {
					query.columns = columns;
					query.columnsViews = columnsViews;
				}
			} else {
				query.columns = columns;
				query.columnsViews = columnsViews;
			}

			query.columnsViews = JSON.stringify(query.columnsViews);

			// disable checkbox if we selected multiple vectors
			this.setState({ isCheckboxDisabled: _this.disableAllCheckBoxes(columns, columnsViews)});
		}
		if (!!this.props.selectedPeriods) {
			query.selectedPeriods = this.props.selectedPeriods;
		}

		return query;
	}
	getCheckedColumns(data, checkedColumns) {
		data = linearizeHierarchy(data, "children");
		return data.map(col => col[PROFILE_COLUMN.RETURN_NAME]);
	}
	fetchProfitInfo(afterDrilling = false, page, size, callback) {
		let _this = this;
		if (_this.props.isDashBoards) {
			loaderCallback(this, true);
		}
		if (this.props.updateStateFromParent) {
			this.props.updateStateFromParent("");
		}

		if (afterDrilling) {
			this.props.setIsDrilling(true);
		} else {
			$("#isDrilling").val("0");
			if (typeof this.props.setIsDrilling === "function") {
				this.props.setIsDrilling(false);
				// this.props.setIsDrilling(false);
				destroyDrillSections();
				_this.clearDrillingData();
				$("input[name=tier]").val(this.props.dataTier)
			}
		}

		var query = this.getDataQuery("getProfitList", afterDrilling, page, size);
		query.parentComponent = _this.props.parentComponent || "";
		query.limit = _this.props.isDashBoards ? 10 : _this.props.dataChunkLimit; // send the limit to be 500 if not from dashboard
		if(_this.state.sorter){
			query.sorter =  _this.state.sorter;
		}

		//show export all columns
		$("#exportAll-img").css("display", "block");

		let dataKey = this.props.isGeography ? "geographyListData" :this.props.isLandscapeBubble ? "landscapeBubbleListData" : this.props.isHeatmap ? "heatmapListData" : "listData";
		let dataFromStore = getItemFromStore(dataKey, this, dataKey);

		var onThenCallback = (data) => {
			let hasInvalidAccess = _this.props.checkForLimitAccessMessage(data, _this.props.parentComp && (_this.props.parentComp.constructor.name === "Widget" || _this.props.parentComp.constructor.name === "Base"));
			if (hasInvalidAccess) {
				if (_this.props.headerRef) {
					_this.props.setDataLength(0);
				}
				_this.setState({
					dataFinal: { data: [], columns: [] },
					tableTopColumns: [],
					listDataId: shortid.generate()
				});
				this.props.handleAPIError(MESSAGES.dashboards.messages.no_permission);
				return;
			}

			// checks if no ps lines are selected
			if(data.noColumnsAvailable) {
				this.showNoPSLColumnsWarning(true);
				_this.setState({
					dataFinal: { data: [], columns: [] },
				});
				return;
			}

			let dataLength = data.data ? data.data.length : 0;
			if (_this.props.isHeatmap) {
				setLimit(dataLength);
			} else if (typeof _this.props.setLimit === "function") {
				_this.props.setLimit(dataLength);
			}

			if (data.ERROR && typeof this.props.handleAPIError === "function") {
				this.props.handleAPIError(data.ERROR);
				return;
			}
			let oldHideExportOnList = _this.state.hideExportOnList;
			_this.setState({
				sorter: data.orderer,
				order: dataFromStore ? data.order : _this.state.order,
				dataFinal: data,
				tableTopColumns: data.columns ? data.columns : [],
				limit: data.length,
				profitStack: false,
				drilling: 0,
				listDataId: shortid.generate(),
				hideExportOnList: data.data.length >= size,
				tabulatorSortField: dataFromStore ? data.tabulatorSortField : this.state.tabulatorSortField,
				columnsWidths: dataFromStore ? data.columnsWidths : this.state.columnsWidths
				// firstChunkData: _this.props.headerRef.state.headerGoEnabled ? [] : _this.state.firstChunkData.concat(data.data)
			}, function () {
				if (_this.props.updateStateFromParent && data.data && data.data[0]) {
					_this.props.updateStateFromParent(numberCommaFormat(data.data[0]["cnt"]));
				}
				var columns = _this.state.newColumnOrder && _this.state.newColumnOrder.length > 0 ? _this.state.newColumnOrder : data.columns;
				if (_this.props.setTableColumns && typeof _this.props.setTableColumns === "function") {
					_this.props.setTableColumns(columns);
				} else {
					_this.setTableColumns(columns);
				}
				toggleLoader(false, 'Base_didMount');
				if (_this.props.isDashBoards) {
					loaderCallback(_this, false);
				}

        let recordsLessThanPageSize = data?.data.length > 0 ? size > data?.data[0]["cnt"] : true;
        if(dataFromStore) {
          if(recordsLessThanPageSize) {
			if (_this.tabulatorList.current && _this.tabulatorList.current!== null) {
				_this.tabulatorList.current.tabulator.fromProfitInfo = true;
			}
            _this.tabulatorList.current?.tabulator.setSort(data.orderer, data.order)
          }
          _this.setOrder(data.order, data.orderer)
        }

        if(dataFromStore && _this.props.dispatch) {
					_this.props.dispatch(updateHeatmapListData([], dataKey, true));
					_this.props.dispatch(updateLandscapeBubbleListData([], dataKey, true));
					_this.props.dispatch(updateGeographyListData([], dataKey, true));
					_this.props.dispatch(updateListData([], dataKey, true));

				}
				//_this.getdataFinal();
				if(callback) {
					callback(data.chunkData); // send the chunk data which is 500 to display it in tabulator
				} else {
					_this.tabulatorList.current?.tabulator.setData(data.chunkData);
				}
			});

			if (afterDrilling) {
				_this.resetTotals(data);
			}

			if (data.data && !this.props.isDashBoards) {
				if (typeof _this.props?.setDataLength === 'function') {
					_this.props?.setDataLength(data?.data?.length || 0 );
				}
				if (typeof _this.props?.setExportOptionsForUser === "function")
				_this.props?.setExportOptionsForUser(data.data.length >= size);
			}

			if (data.error) {
				alertError(data.error);
			}
			return data;
		};

		let sourceScreenObj = _this.getSourceScreenObj();
		var fetchOptions = {
			[FETCHAPI_PARAMS.funcName]: "fetchProfitInfo",
			[FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
			[FETCHAPI_PARAMS.showLoader]: !this.props.isDashBoards,
			[FETCHAPI_PARAMS.path]: API_URL.PROFIT_LANDSCAPE,
			[FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
			[FETCHAPI_PARAMS.query]: query,
			[FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
			[FETCHAPI_PARAMS.machine_name]: this.props.machine_name,
			[FETCHAPI_PARAMS.profitFormat]: this.props.profitFormat,
			[FETCHAPI_PARAMS.useStore]: dataFromStore !== undefined,
			[FETCHAPI_PARAMS.scenarioId]: dataKey,
			[FETCHAPI_PARAMS.dataKey]: dataKey,
			[FETCHAPI_PARAMS.screenName]: this.props.isDashBoards? lang.observability.output.dashboards.screen_name : sourceScreenObj.screen_name,
			[FETCHAPI_PARAMS.requestDescription]: sourceScreenObj.requests_description.apply + (this.props.isDashBoards? lang.observability.output.dashboards.widget :""),
			[FETCHAPI_PARAMS.vector]: this.props.dataTier,
			[FETCHAPI_PARAMS.periods]: this.props.selectedPeriods.join(","),

			isDashBoards: _this.props.isDashBoards,
		}

		this.state.latestListParameters = fetchOptions;

		if (afterDrilling) {
			$("#DrillRows").val(this.props.records_limit);
			this.setDrillTabulatorPageSize();
		} else {
			resetDrillSet();
		}
		return this.fetchAPI(fetchOptions);

	}

	getSourceScreenObj = () =>{
		let isHeatMap = this.props.isHeatmap;
		let isGeography = this.props.isGeography;
		let isLandscape = this.props.isLandscapeBubble;
		let sourceScreenObj;
		switch (true) {
			case isHeatMap:
				sourceScreenObj = lang.observability.output.heatmap.heatmap_list;
				break;
			case isGeography:
				sourceScreenObj = lang.observability.output.geography.geography_list;
				break;
			case isLandscape:
				sourceScreenObj = lang.observability.output.landscape;
				break;
			default:
				sourceScreenObj = lang.observability.output.list;
		}
		return sourceScreenObj;
	}

	setCardsData = (cardsData) => {
		this.setState({
			cardsData: cardsData
		})
	}

	resetTotals(dataFinal) {
		var comp = this;
		var data = dataFinal.data;
		var columns = dataFinal.cardsColumns;
		comp.cardRef[0].ref.current.replaceTableData(columns, data);
		comp.cardRef[0].ref.current.replaceEmptyTableData(data, columns);
		comp.cardRef[comp.state.cardsData.length - 1].expandTable(true); // reexpanding last card
	}

	/**
	 * function called when selecting a profile from manage columns in drill screen
	 * @param {*} profile 
	 * @param {*} callback 
	 */
	selectProfile = (profile, callback) => {
		let _this = this;
    _this.drillManageColumnsProfile = profile;
    
		if (typeof callback === "function") {
			callback();
		}
		_this.props.saveChosenEntities(true);

	}

	/**
	 * function called when applying changes from manage columns in drill screen
	 */
	onApplyProfile = (listChanged) => {
		let _this = this;
		_this.props.saveChosenEntities(true);

		_this.setDrillTabulatorPageSize(undefined, listChanged);
	}

	/**
	 * function removes duplicates from array sent as param
	 * @param {*} arr 
	 * @returns 
	 */
	removeDups = (arr) => {
		var returnedArr = [];
		for (var e in arr) {
			if (returnedArr.filter(elt => elt[MANAGE_COLUMNS.COLUMN_RETURN_NAME] === arr[e][MANAGE_COLUMNS.COLUMN_RETURN_NAME] && elt[MANAGE_COLUMNS.MACHINE_NAME] === arr[e][MANAGE_COLUMNS.MACHINE_NAME]).length === 0) {
				returnedArr.push(arr[e]);
			}
		}
		return returnedArr;
	}

	resetDrillVectorType = () =>{
		this.setState({
			drillVectorType: undefined
		})
	}

	updateDrillprofile = (prf) => {
		let obj = this;
		if (obj.props.manageColumnsDrillRef && prf) { //first time we enter the drill screen submitDrill will be called and onSelectProfile from manage columns will be called too so this condition is for
			//the second call and not first in order to stop it from fetching data again and setting the checkedItems and profile same as the profile columns and profile in list so that they're inhertied
			obj.props.manageColumnsDrillRef.applyProfileChanges(prf, undefined, undefined, undefined, undefined, true);
			obj.props.manageColumnsDrillRef.setState({ checkedItems: prf[COLUMN_PROFILE.COLUMNS] });
		}
	}

	returnDrillColumnProfile=()=>{
		let _this = this;
		return _this.drillManageColumnsProfile;
	}

	/**
	 * Called in TabulatorWrapper in drillListener to set page size of drill tabulator so that ajaxRequestFunc gets called
	 * @param {} size 
	 */
	setDrillTabulatorPageSize = (size=100, listChanged) => {
		let _this = this;
		let sorter = _this.state.dataFinal?.data?.length > size ? _this.state.sorter : undefined;
		let tempState = {};
		tempState.sorter = sorter;
		tempState.order = "desc";
		if(listChanged){
			tempState.drillVectorType = undefined;
		}
		_this.setState(tempState, () => {
			_this.tabulatorDrill?.current?.tabulator?.setPageSize(size);
		})
	}

	submitDrill(nextTier = $("#Next_Tier").text().trim(), cardIndex = -1, removeDrill = false, undrill, fromProfitInfo, page, size, callback) {
		var _this = this;
    var dataKey = "drillListData";
    let dataFromStore = getItemFromStore(dataKey, _this, dataKey);
    var queryColumnViews = [];
    var filter = "";
    var cardsData = _this.state.cardsData;

    let nameIndex = findIndexOfValue(_this.state.tableTopColumns, "field", PSL_RETURN_NAMES.NAME);
    let numberIndex = findIndexOfValue(_this.state.tableTopColumns, "field", PSL_RETURN_NAMES.NUMBER);
    let quadrantColIndex = findIndexOfValue(_this.state.tableTopColumns, "field", PSL_RETURN_NAMES.QUADRANT_TIER); //get index of quadrant column
    quadrantColIndex = quadrantColIndex > -1 ? quadrantColIndex : findIndexOfValue(_this.state.tableTopColumns, "field", PSL_RETURN_NAMES.QUADRANT);

	if (!dataFromStore) {
		if (typeof nextTier === "object") {
			nextTier = $("#Next_Tier").text().trim();
		}
		if (_this.state.submitDrill && !fromProfitInfo) {
			if (_this.props.manageColumnsDrillRef) {
				//first time we enter the drill screen submitDrill will be called and onSelectProfile from manage columns will be called too so this condition is for
				//the second call and not first in order to stop it from fetching data again and setting the checkedItems and profile same as the profile columns and profile in list so that they're inhertied
				_this.props.manageColumnsDrillRef.applyProfileChanges(_this.props.drillProfile?.length ? _this.props.drillProfile : _this.props.manageColumnsProfile, undefined, undefined, undefined, undefined, true);
				_this.props.manageColumnsDrillRef.setState({
				checkedItems: _this.props.drillProfile?.length ? _this.props.drillProfile[COLUMN_PROFILE.COLUMNS] : _this.props.manageColumnsProfile[COLUMN_PROFILE.COLUMNS],
				});
			}
			return;
		}
      
		hideProfitStackLink();
		if (this.props.toggleDropDownState) {
		this.props?.toggleDropDownState(true);
		}
		toggleLoader(false, "submitDrill"); // remove loader if from go
		toggleLoader(true, "submitDrill");

		var drillRows = Number($("#DrillRows").val());
		setDrillRowsLimit(drillRows);

		$("#drillIsClicked").val("1");
		$("#Previous_Tier").val($("#Next_Tier").val());

		// Revoke check on header tier
		$(".form-control-header-tier").removeClass("form-control-header-tier");

		var filterFromBox = tryParse(this.props.filter);
		
		if(filterFromBox?.length > 1){
			filterFromBox = wrapFilterWithParenthesis(filterFromBox);
		}
		filter = setUpDrillFilter(filterFromBox, this.state.cardsData, cardIndex, removeDrill);
		if (filter.length === filterFromBox.length) {
			filter = this.state.filter;
			if (filterFromBox.length !== 0) {
				filter = filter.concat(filterFromBox);
			}
		}
		this.props.saveDrillFilter(filter);
		filter = encodeURIComponent(JSON.stringify(filter));
		let views = undrill
		? cardsData.filter(function (value, index, arr) {
			return index === cardIndex + 1;
			})[0].columnsViews
		: []; // getting columnsViews for the card being undrilled

		let undrillProfile = undrill
		? cardsData.filter(function (value, index, arr) {
			return index === cardIndex + 1;
			})[0].profile
		: {}; // getting columnsViews for the card being undrilled

		// submitDrill is undefined meaning it's the firt time we call submitDrill when we enter the page so we concat the columns of profile from list with drill profile columns to make sure they're inherited
		let submitDrill =
		this.state.submitDrill !== undefined
			? this.state.submitDrill
			: this.props.parentComp && this.props.parentComp.props.location && this.props.parentComp.props.location.state.submitDrill;

		let columnsViews =
		views.length > 0
			? views
			: submitDrill === undefined && this.props.manageColumnsProfile
			? this.props.manageColumnsDrillRef?.state.checkedItems.concat(
				removeAttributes(this.props.manageColumnsProfile[COLUMN_PROFILE.SIMPLIFIED_COLUMNS])
				)
			: this.drillManageColumnsProfile
			? removeAttributes(this.drillManageColumnsProfile[COLUMN_PROFILE.SIMPLIFIED_COLUMNS]) || []
			: [];

		if (fromProfitInfo && this.props.drillProfile) {
		//when changing the filter in the drill screen, we get the columns of the drill list after fetching the list info
		columnsViews = this.props.drillProfile[COLUMN_PROFILE.SIMPLIFIED_COLUMNS]
			? this.props.drillProfile[COLUMN_PROFILE.SIMPLIFIED_COLUMNS]
			: this.props.drillProfile[COLUMN_PROFILE.COLUMNS];
		}

		let selectedProfileFromSession = tryParse(sessionStorage.getItem(SELECTED_DRILL_LIST));
		if (selectedProfileFromSession) {
		columnsViews = selectedProfileFromSession[COLUMN_PROFILE.SIMPLIFIED_COLUMNS] || selectedProfileFromSession[COLUMN_PROFILE.COLUMNS];
		}

		if (undrill && views.length > 0) {
		let selectedProfileFromSession =
			typeof tryParse(sessionStorage.getItem("selectedProfile_" + this.props.profitFormat.toLowerCase())) === "object"
			? tryParse(sessionStorage.getItem("selectedProfile_" + this.props.profitFormat.toLowerCase()))
			: undefined;
		let profileId = selectedProfileFromSession && selectedProfileFromSession !== null ? selectedProfileFromSession.column_profile_id : undefined;
		if (profileId) {
			views = selectedProfileFromSession.columns;
		} else {
			views = _this.props.manageColumnsDrillRef.state.columnProfiles.filter((e) => e.column_profile_id === undrillProfile.column_profile_id)[0].simplified_columns;
			columnsViews = views;
		}

		this.props.manageColumnsDrillRef.setState({ checkedItems: views }, function () {
			if (profileId && profileId === undrillProfile.column_profile_id) {
			this.setSelectedProfile(selectedProfileFromSession);
			} else {
			undrillProfile.columns = this.state.originalColumnProfiles.filter(
				(e) => e.column_profile_id === undrillProfile.column_profile_id
			)[0].columns;
			undrillProfile.originalColumns = undrillProfile.columns;
			undrillProfile.is_modified = false;
			undrillProfile.is_applied = false;
			this.setSelectedProfile(undrillProfile);
			}
		});
		}

		if (columnsViews) {
			if (submitDrill === undefined) {
				// if first time entering drill screen, inherit columns' order and columns from list
				let selectedProfileFromSession = typeof tryParse(sessionStorage.getItem("selectedProfile_" + this.props.profitFormat.toLowerCase())) === "object" ? tryParse(sessionStorage.getItem("selectedProfile_" + this.props.profitFormat.toLowerCase())) : undefined;
				let profileId = selectedProfileFromSession && selectedProfileFromSession !== null ? selectedProfileFromSession[COLUMN_PROFILE.ID] : null;
				if (profileId !== null) {
				let columns = selectedProfileFromSession.columns;
				let columnsViewsFromSession = removeAttributes(selectedProfileFromSession[COLUMN_PROFILE.SIMPLIFIED_COLUMNS]);

				if (columns.length > 0 && typeof columns[0] === "object") {
					columns.sort((x, y) => x.column_order - y.column_order);
					queryColumnViews = columns;
				} else {
					queryColumnViews = columnsViewsFromSession;
				}
				} else {
				queryColumnViews = columnsViews;
				}
			} else {
				if(this.props.drillProfile && typeof columnsViews[0] === "string") { // if columns views are a list of strings, and we are in drill screen
				queryColumnViews = this.props.drillProfile[COLUMN_PROFILE.SIMPLIFIED_COLUMNS] ? this.props.drillProfile[COLUMN_PROFILE.SIMPLIFIED_COLUMNS] : this.props.drillProfile[COLUMN_PROFILE.COLUMNS];
				} else {
				queryColumnViews = columnsViews;
				}
			}
		}
	} else {
		queryColumnViews = dataFromStore?.manageColumnsDrillProfile?.simplified_columns;
		filter = dataFromStore.drillFilter;
		cardsData = dataFromStore.cardsData;
		this.props.saveDrillFilter(filter);
	}
	let viewOptions = this.props.manageColsRef?.state?.viewOptions || this.props.manageColumnsDrillRef?.state?.viewOptions || this.props.viewOptions;
	
	setTimeout(function(){
		if(_this.state.drillVectorType && _this.props.isDrilling){			
			// handle drill changes
			queryColumnViews = _this.handleColumnsForDrill(queryColumnViews, _this.state.drillVectorType, viewOptions);
			let newProfile = copyObjectValues(_this.props.manageColumnsProfile);
			newProfile.originalColumns = queryColumnViews;
			newProfile.simplified_columns = _this.handleColumnsForDrill(newProfile.simplified_columns, _this.state.drillVectorType, viewOptions);
			newProfile.columns = queryColumnViews;
			_this.props.manageColumnsDrillRef.applyColumnsChanges(queryColumnViews, newProfile);
		}
		_this.setState({
			submitDrill: true,
			drillTier: nextTier,
			drillFilter: filter,
			drillDataList: { data: [], columns: [] },
			cardsData: cardIndex > -1 && !removeDrill ? cardsData.filter(function (value, index, arr) {
				return index <= cardIndex;
			}) : removeDrill ? cardsData.filter(function (value, index, arr) {
				return index !== cardIndex;
			}) : cardsData,
			// disable checkbox when drilling if we have multiple vectors
      		// isCheckboxDisabled: !!(queryColumnViews && queryColumnViews?.find(f => f.type === "vector") && queryColumnViews?.filter(e=>e.type === "vector" && e.column_return_name !== _this.props.drillTier)?.length >0) 
			isCheckboxDisabled : _this.disableAllCheckBoxes(queryColumnViews)
		})

		
		var query = {
			action: "getProfitListDrill",
			tier: nextTier,
			columnsViews: JSON.stringify(queryColumnViews),
			profitFormat: _this.props.profitFormat,
			parentKey: "201",
			order: _this.state.order,
			months: _this.props.months || _this.props.FY,
			limit: _this.props.dataChunkLimit,
			quarter: _this.props.dataset,
			filter: "{'filter':" + filter + "}",
			//  columns: columns,
			mainTier: _this.props.dataTier,
			scenario_id: _this.props.scenario_id,
			sorter: _this.state.sorter || "",
			page: page || 1,
			size: size || 100,
			rollingSegment: ROLLING_SEGMENTS.R_12,
			rollingPeriod: _this.props.rollingPeriod
		}
		if(_this.state.multipleDrillEntitiesSelected){
			query.multipleDrillEntitiesSelected = _this.state.multipleDrillEntitiesSelected;
		}

		if (!!_this.props.selectedPeriods) {
			query.selectedPeriods = _this.props.selectedPeriods;
		}
		
		setLocalStorageValueByParameter(window.location.host + "_" + "lastRequestSentTime", new Date());

		let sourceScreenObj = _this.getSourceScreenObj();
		var fetchOptions = {
			[FETCHAPI_PARAMS.funcName]: "submitDrill",
			[FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
			[FETCHAPI_PARAMS.showLoader]: !_this.props.isDashBoards,
			[FETCHAPI_PARAMS.path]: API_URL.PROFIT_LANDSCAPE,
			[FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
			[FETCHAPI_PARAMS.query]: query,
			[FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
			[FETCHAPI_PARAMS.machine_name]: _this.props.machine_name,
			[FETCHAPI_PARAMS.profitFormat]: _this.props.profitFormat,
			[FETCHAPI_PARAMS.useStore]: dataFromStore !== undefined,
			[FETCHAPI_PARAMS.scenarioId]: dataKey,
			[FETCHAPI_PARAMS.dataKey]: dataKey,
			[FETCHAPI_PARAMS.screenName]: sourceScreenObj.screen_name,
			[FETCHAPI_PARAMS.requestDescription]: sourceScreenObj.requests_description.drill,
			[FETCHAPI_PARAMS.vector]: nextTier,
			[FETCHAPI_PARAMS.periods]: _this.props.selectedPeriods.join(","),
		}

		_this.fetchAPI(fetchOptions);
		// Set old tier when drilled on new tier
		$("#Old_Tier").text($('#Next_Tier').text());
		$("#Previous_Tier").text($('#Next_Tier').text());
		// Re display scroll table
		$('#To_Scroll_List_Drill').show();
	}, 100);

    var onThenCallback = (data) => {
      if(dataFromStore) {
        // when we have data in store, it means we are coming from entity stacks. So all drill data are under 1 object that is dataFromStore, but we only need the drillDataList in this callback
        data = dataFromStore.drillDataList;
      }

      if (data.error && data.error.level == 2) {
        this.setInfoDialogOpen(true, data.error.message)
      }
      else {
        var roundTwo = false;
        if (data.length !== 0 && quadrantColIndex > -1 && nameIndex === -1 && numberIndex === -1) {
          roundTwo = true;
        } else if (quadrantColIndex === -1 && nameIndex !== -1 && numberIndex !== -1) {
            roundTwo = false;
        }

        let drillCols = [];
        let vectorObj = findOptionByKey(this.props.options, this.state.drillTier);
        data.columns = _this.getUpdatedColumns(!!vectorObj ? vectorObj.label : "", false, data.columns);
        data.columns.forEach(col => { col.new = false; drillCols.push(col); }); // to not add name and number each time we call getUpdatedColumns
        data.chunkData.forEach(row => { row.isCheckboxDisabled = _this.state.isCheckboxDisabled })
        let oldHideExportOnList = _this.state.hideExportOnList;
		let drillHeaderCardsData = copyObjectValues(_this.state.cardsData);

		if(data.drillHeaderData?.data){
			let drillIndex = drillHeaderCardsData.indexOf(drillHeaderCardsData.findLast(e=>e.data.find(f=>f.tier === data.drillHeaderData.data[0].tier[0])));
			if(drillIndex>-1){
				drillHeaderCardsData[drillIndex].data = data.drillHeaderData.data;
			}
		}

		_this.setState({
          submitDrill: false,
          drillDataList: data,
		  cardsData: drillHeaderCardsData,
          drilling: 1,
          roundTwo: roundTwo,
          drillColumns: drillCols,
          sorter: data.orderer,
          hideExportOnList: data.data.length >= size,
		  drillCardWidths: dataFromStore ? dataFromStore.drillCardWidths : _this.state.drillCardWidths,
		  drillListWidths: dataFromStore ? dataFromStore.drillListWidths : _this.state.drillListWidths,
		  columnsWidths: dataFromStore ? dataFromStore.columnsWidths : _this.state.columnsWidths,
		  multipleDrillEntitiesSelected: undefined
          // dataFinal: {data: [], columns: []}	//empty list data cz request is sent again when clicking Back
        }, function () {
          toggleLoader(false, 'submitDrill');
          let dataLength = data.chunkData ? data.chunkData.length : 0;
          if (dataLength === 0) {
            let columns = _this.tabulatorDrill?.current?.getColumns(data.columns);
            _this.tabulatorDrill?.current?.tabulator?.setColumns(columns);
            $('#table-drill-list .tabulator-placeholder').find("span").text(MESSAGES.no_data_available);
          }
          setDrillRowsLimit(dataLength);
          let cardsData = _this.state.cardsData.length > 0 ? _this.state.cardsData : _this.props.cardsData
          _this.cardRef[cardsData.length - 1].expandTable(true); // reexpanding last card
          window._pi_stopTrackingAndCheckAction(UI_ACTIONS.DRILL);
          $('#To_Scroll_Top').show();
          $('#To_Scroll_List_Drill').show();
          $('#To_Scroll_List').hide();
          if (data.data && oldHideExportOnList !== _this.state.hideExportOnList) {
            _this.props?.headerRef?.updateStateForExportExcel();
            if (typeof _this.props?.setExportOptionsForUser === "function")
            _this.props?.setExportOptionsForUser(_this.state.hideExportOnList);
          }

          
          let recordsLessThanPageSize = data?.data.length > 0 ? size > data?.data[0]["cnt"] : true
          if(dataFromStore) {
            if(recordsLessThanPageSize) {
              _this.tabulatorDrill.current?.tabulator.setSort(data.orderer, data.order);
            }
            _this.setOrder(data.order, data.orderer)
          }

          if(dataFromStore && _this.props.dispatch) {
            _this.props.dispatch(updateDrillListData([], dataKey, true));
          }

          if(callback) {
            callback(data.chunkData); 
          }
        });
        $("#isDrilling").val("1");
        $("#submit_drill").text("");
		if(this.props.setSelectedEntities && typeof this.props.setSelectedEntities === "function"){
			this.props.setSelectedEntities(0);
		}
      }
    
    }
	}

	setNextTier(e) {
		setDrillRowsLimit(this.state.drillRows);

		if (e !== null) {
			$("#Next_Tier").text(e.value);
			this.setState({
				drillTier: e.value
			})
			this.handleChangeNoExec(e)
		}
	}

	handleChangeNoExec(e) {
		this.setState({
			[e.name]: e.value
		});
	}

	setHideTable() {
		var name = (this.state.buttonClass === "triangle-up") ? "triangle-down" : "triangle-up";
		if (name !== 'triangle-up') {
			// var filterFromBox = tryParse(this.props.filter);
			// var filter = setUpDrillFilter([], this.props.isQuadrant, this.state.roundTwo);
			// this.state.filter = filter;
			$('#DrillDown').css("margin-top", convertPxToViewport(75));
			$("#Table_Top").css("display", "none");

		} else {
			$('#DrillDown').css("margin-top", convertPxToViewport(50));
			$("#Table_Top").css("display", "");
		}

		this.setState({
			buttonClass: name
		}, function () {
			var col = $("#Table_Top").find("td.to-hide-on-drill");
			col.remove();
		});
	}

	handleDrillInputChange() {
		var element = $("div");
		element.scrollTop("-100");
	}

	static getDerivedStateFromProps(props, state) {
		return {
			dataFinal: state.dataFinal,
			newOptions: props.drillVectorsOptions,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.tier !== prevProps.dataTier) {
			this.setState({
				tier: prevProps.dataTier
			});
		}

		if (this.props.drillVectorsOptions && this.props.drillVectorsOptions.length && this.props.drillVectorsOptions !== prevProps.drillVectorsOptions) {
			var value = this.props.drillVectorsOptions[0].value;
			$("#Next_Tier").text(value !== null ? value : "");
		}

		if (this.props.isDashBoards && !this.props.headerGoEnabled && prevProps.unique_id !== this.props.unique_id) {
			this.fetchProfitInfo();
		}

		if (this.props.isDrilling && !prevProps.isDrilling) {
			window._pi_stopTrackingAndCheckAction(UI_ACTIONS.PRE_DRILL);		//when going to drill, log the pre_drill action
		}
	}

	componentDidMount() {
		if (this.props.fetchDataOnMount && this.props.isDashBoards) {
			this.fetchProfitInfo();
		}
		var list = this.props.drillVectorsOptions ? this.props.drillVectorsOptions.filter(opt => opt.className !== "tier-group") : [];
		if (list[0] !== undefined && !this.state.tier) {
			this.setState({
				tier: list[0]['value']
			});

			// Setup next drill parent
			$("#Next_Tier").text(list[0]['value']);
		}

		$("#DrillRows").text(this.state.drillRows);
		if(!this.props.isDrilling) {
			// hide drill sections
			$('#To_Scroll_Top').hide();
			$('#To_Scroll_List_Drill').hide();
	
			$("#TopBottomContainer").show();
			$("#Filter_control").show();
			$("#isDrilling").val("0");
			$("#Table_Top").find("thead tr").prepend("<th></th>");
			if (!this.props.isRedirectionFromStacks && typeof this.props.setIsDrilling === "function") {
				this.props.setIsDrilling(false)
			}

		}

		if (list.length) {
			var value = list[0].value;
			this.setState({
				newOptions: list,
				drillTier: value
			});
		}

		// if(this.props.isDrilling) {
		// 	this.props.setIsDrilling(true);
		// 	this.updateDrillprofile(this.props.drillProfile);			
		// }

		//setting drill-back listener
		// var obj = this;
		// $(document).on("click", ".drill-back", function(e) {
		// 	var drillBackIcon = e.currentTarget;
		// 	var number = $(drillBackIcon).parents(".tabulator-row").find(".number").text();		//getting tier of the drilled back row
		// 	var rowIndex = obj.getIndexOfDrilledBackRow(number);
		// 	var nextRowTier = $($("#Table_Top").find(".tabulator-row")[rowIndex + 1]).find(".tier").text();		//getting tier of the row that is directly under the one drilled back

		// 	var tempTableTopdata = copyObjectValues(obj.state.tableTopData);
		// 	tempTableTopdata.length = rowIndex + 1;		//shortening the length of the array is faster than splice and does the same job

		// 	obj.setState({
		// 		drillTier: nextRowTier,	//change value of drilled tier
		// 		tableTopData: tempTableTopdata
		// 	});

		// 	$("#DrillRows").val("50");
		// 	$("#drillButton").click();

		// 	//when the table re-renders, the drill-back icon is removed since it is not part of the table data, re-add it
		//  var previousRows = $("#Table_Top").find(".tabulator-row:last").prevAll();
		//  previousRows.append("<div class='tabulator-cell' role='gridcell'><img src='/images/drill-back.png' class='drill-back' alt='Drill Back' title='Click to Drill Back'/></div>");
		// });
	}

	drillBack(cell) {
		var rowIndex = cell.getRow().getPosition(true);
		var nextRowTier = $($("#Table_Top").find(".tabulator-row")[rowIndex + 1]).find(".tier").text();		//getting tier of the row that is directly under the one drilled back
		var tableData = this.tabulatorTop.current.tabulator.getData();
		var tempTableTopdata = copyObjectValues(tableData);
		tempTableTopdata.length = rowIndex + 1;		//shortening the length of the array is faster than splice and does the same job

		this.setState({
			drillTier: nextRowTier,	//change value of drilled tier
			tableTopData: tempTableTopdata
		});

		$("#DrillRows").val(this.state.drillRows);
		this.submitDrill();
	}

	getIndexOfDrilledBackRow(number) {
		//get the index of the drilled back row and remove all the ones after it from data received from table_top
		var data = this.tabulatorTop.current.tabulator.getData();
		var index = data ? data.findIndex(row => row.number_PrimaryColumn === number) : "";
		return index
	}



	onColumnMoved(newColumns, columnMovedField) {
		let newColsOrder = [];
		let _this = this;
		if (this.props.manageColumnsDrillRef) {
			newColsOrder = this.props.manageColumnsDrillRef.updateColumnsOrder(newColumns, columnMovedField, (newColsOrders) => {
				//update the selectedProfile of the drilled list so that when we click on back from entity stacks, the column order will stay as it was
				let checkedItems = copyObjectValues(_this.props.manageColumnsDrillRef.state.checkedItems);
				let drillSelectedProfile = copyObjectValues(_this.props.manageColumnsDrillRef.state.selectedProfile);
				let drillProfile = copyObjectValues(_this.props.drillProfile);

				if(drillProfile) {
					drillProfile.columns = checkedItems;
					_this?.props?.parentComp?.setState({
						drillProfile: drillProfile
					})
				}

				if(drillSelectedProfile) {
					drillSelectedProfile.columns = checkedItems;
					_this.props.manageColumnsDrillRef.setState({
						selectedProfile: drillSelectedProfile
					});
				}
				if (_this.props.manageColsRef) {
					var tempColumnProfiles = copyObjectValues(_this.props.manageColsRef.state.columnProfiles);
                        var profileIndex = findIndexOfValue(tempColumnProfiles, COLUMN_PROFILE.ID, drillSelectedProfile[COLUMN_PROFILE.ID]);
                        tempColumnProfiles[profileIndex][COLUMN_PROFILE.COLUMNS].map(col=>{
                            let tempObj = newColsOrders.filter(colOrder=>col[PROFILE_COLUMN.RETURN_NAME] === colOrder[PROFILE_COLUMN.RETURN_NAME])[0];
                            if(!!tempObj) {
                                col[PROFILE_COLUMN.COLUMN_ORDER] = tempObj[PROFILE_COLUMN.COLUMN_ORDER];
                            }
                            return col;
                        });
                        tempColumnProfiles[profileIndex].originalColumns.map(col=>{
                            let tempObj = newColsOrders.filter(colOrder=>col[PROFILE_COLUMN.RETURN_NAME] === colOrder[PROFILE_COLUMN.RETURN_NAME])[0];
                            if(!!tempObj) {
                                col[PROFILE_COLUMN.COLUMN_ORDER] = tempObj[PROFILE_COLUMN.COLUMN_ORDER];
                            }
                            return col;
                        });
                        tempColumnProfiles[profileIndex].simplified_columns = undefined;
                        var tempProfileOptions = copyObjectValues(_this.props.manageColsRef.state.profileOptions);
                        profileIndex = findIndexOfValue(tempProfileOptions, COLUMN_PROFILE.ID, drillSelectedProfile[COLUMN_PROFILE.ID]);
                        tempProfileOptions[profileIndex][COLUMN_PROFILE.COLUMNS].map(col=>{
                            let tempObj = newColsOrders.filter(colOrder=>col[PROFILE_COLUMN.RETURN_NAME] === colOrder[PROFILE_COLUMN.RETURN_NAME])[0];
                            if(!!tempObj) {
                                col[PROFILE_COLUMN.COLUMN_ORDER] = tempObj[PROFILE_COLUMN.COLUMN_ORDER];
                            }
                            return col;
                        });
                        _this.props.manageColsRef.setState({
                            columnProfiles: tempColumnProfiles,
                            profileOptions: tempProfileOptions
                        })
				}
				
			sessionStorage.setItem(SELECTED_DRILL_LIST,JSON.stringify(drillSelectedProfile));
			});
			
		} else {
			if (this.props.manageColumnsRef) {
				newColsOrder = this.props.manageColumnsRef.updateColumnsOrder(newColumns, columnMovedField);
			} else {
				newColsOrder = _this.props.updateColumnsOrder(newColumns, columnMovedField);
			}
		}
		this.resortTableColumns(newColsOrder, this.props.isDrilling && this.state.drillDataList ? copyObjectValues(this.state.drillDataList.columns) : undefined);
	}

	resortTableColumns(colsOrder, tableColumns = copyObjectValues(this.state.dataFinal.columns)) {
		if (!colsOrder) {
			return;
		}
		let updatedRetNames = [];
		let newTableCols = [];
		const _returnName = PROFILE_COLUMN.RETURN_NAME;
		const vectorViewsValues = vectorViews;
		newTableCols.push(...tableColumns.filter(tableCol => !tableCol["field"]));	//pushing first two cols, check and drill

		colsOrder.forEach(col => {
			if ((updatedRetNames.includes(col[_returnName]))) {
				return false;	//continue
			}
			//when pushing multiple views in the below code, we are assuming they are originally ordered from the DB
			if (col[_returnName] === MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME) {
				newTableCols.push(...tableColumns.filter(tableCol => vectorViewsValues.includes(tableCol["field"])));		//push all identifiers to the list at this index
			} else {
				let suffixes = getObjectValues(column_suffixes);
				let retNamesRegex = suffixes.map(reg => {		//adding regex of all possible suffixes that belong to each column
					return new RegExp("^" + col[_returnName] + String(reg).replace(/\//g, ""));
				});
				retNamesRegex = retNamesRegex.concat(vectorViewsValues.map(iden => {		//adding regex of all identifiers suffixes that belong to each column
					return new RegExp("^" + col[_returnName] + iden + "$");			//$ to mark the end of the word
				}))
				retNamesRegex = retNamesRegex.concat(vectorViewsValues.map(iden => {		//adding regex of all identifiers suffixes that belong to each column
					return new RegExp("^" + col[_returnName] + "_" + iden + "$");			//$ to mark the end of the word
				}))
				//push all views related to this column
				newTableCols.push(...tableColumns.filter(tableCol => !!tableCol["field"] &&
					(retNamesRegex.filter(reg => reg.test(tableCol["field"])).length > 0 || tableCol["field"] === col[_returnName])));
			}

			updatedRetNames.push(col[_returnName]);
		});

		let tableData = copyObjectValues(this.props.isDrilling ? this.state.drillDataList : this.state.dataFinal);
		tableData.columns = newTableCols;

		let tempState = {};
		tempState.tableTopColumns = newTableCols;
		if(this.props.isDrilling) {
			tempState.drillDataList = tableData;
			tempState.drillListDataId = shortid.generate();
		} else {
			tempState.dataFinal = tableData;
			tempState.listDataId = shortid.generate();
		}

		this.setState(tempState, () => {
			if(this.props.isDrilling) {
				this.tabulatorDrill.current?.renderSortButton();
			} else {
				this.tabulatorList.current?.renderSortButton();
			}
		});
		
	}

	getUpdatedColumns(vectorName = "", isDrill = true, columns = this.state.tableTopColumns, useDrillColumns) {
		let tempColumns = useDrillColumns ? this.state.drillColumns ? copyObjectValues(this.state.drillColumns) : copyObjectValues(columns) : copyObjectValues(columns);
		// let quadField = ALL_REPORTS.LIST_QUADRANT === this.props.profitFormat ? PSL_RETURN_NAMES.QUADRANT : PSL_RETURN_NAMES.QUADRANT_TIER;
		let quadrantColIndex = findIndexOfValue(tempColumns, "field", PSL_RETURN_NAMES.QUADRANT_TIER);	//get index of quadrant column
		// quadrantColIndex = quadrantColIndex > -1 ? quadrantColIndex : findIndexOfValue(tempColumns, "field", PSL_RETURN_NAMES.QUADRANT);
		let nameIndex = findIndexOfValue(tempColumns, "field", PSL_RETURN_NAMES.NAME);
		let numberIndex = findIndexOfValue(tempColumns, "field", PSL_RETURN_NAMES.NUMBER);
		let quadrantIndex = findIndexOfValue(tempColumns, "field", PSL_RETURN_NAMES.QUADRANT);
		// if([ALL_REPORTS.LIST_QUADRANT, ALL_REPORTS.LIST_QUADRANT_TIER].includes(this.props.profitFormat)) {
		if (quadrantColIndex > -1 && nameIndex === -1 && numberIndex === - 1) {

			//when drilling from list q or qt, add name and number to the record inserted into table top:
			//name and number data doesn't exist when drilling from list q and qt, we add it below
			//name and number data exists for entities fetched after submitting drill, so we check for it before taking the key col data
			// let nameColIndex = findIndexOfValue(tempColumns, "field", PSL_RETURN_NAMES.NAME);
			// if(nameColIndex === -1) {
			// 	let tempNameCol = copyObjectValues(tempColumns[quadrantColIndex]);		//clone quadrant column
			// 	tempNameCol.title =  vectorName ;
			// 	tempNameCol.titleDownload =  vectorName ;
			// 	tempNameCol.field = PSL_RETURN_NAMES.NAME;
			// 	tempNameCol[FORMAT_TYPE] = FormatTypes.TEXT;
			// 	tempNameCol.new = true;

			// 	tempColumns.splice(quadrantColIndex + 1, 0, tempNameCol);				//insert name and number behind it
			// } else if(isDrill) {
			// 	tempColumns[nameColIndex].title = tempColumns[nameColIndex].title.replace(vectorName, "").trim();
			// }

			// let numberColIndex = findIndexOfValue(tempColumns, "field", PSL_RETURN_NAMES.NUMBER);
			// if(numberColIndex === -1) {
			// 	let tempNumberCol = copyObjectValues(tempColumns[quadrantColIndex]);	//clone quadrant column
			// 	tempNumberCol.title =  vectorName + " " + "#";
			// 	tempNumberCol.titleDownload =  vectorName + " " + "#";
			// 	tempNumberCol.field = PSL_RETURN_NAMES.NUMBER;
			// 	tempNumberCol[FORMAT_TYPE] = FormatTypes.TEXT;
			// 	tempNumberCol.new = true;

			// 	tempColumns.splice(quadrantColIndex + 2, 0, tempNumberCol);
			// } else if(isDrill) {
			// 	tempColumns[numberColIndex].title = tempColumns[numberColIndex].title.replace(vectorName, "").trim();
			// }

		}
		// else if (isDrill) {
		//
		// 	// if (quadrantIndex > -1) {
		// 	// 	tempColumns[quadrantIndex].title = tempColumns[quadrantIndex].title.replace(tempColumns[quadrantIndex].title, vectorName + " S").trim();
		// 	// }
		// 	//
		// 	// if (quadrantColIndex > -1) {
		// 	// 	tempColumns[quadrantColIndex].title = tempColumns[quadrantColIndex].title.replace(tempColumns[quadrantColIndex].title, vectorName + " T").trim();
		// 	// }
		//
		// 	if (nameIndex > -1) {
		// 		tempColumns[nameIndex].title = tempColumns[nameIndex].title.replace(tempColumns[nameIndex].title, vectorName).trim();
		// 	}
		//
		// 	// if (numberIndex > -1) {
		// 	// 	tempColumns[numberIndex].title = tempColumns[numberIndex].title.replace(tempColumns[numberIndex].title, vectorName + " #").trim();
		// 	// }
		// }
		return tempColumns;
	}

	appendDataToTableTop(newRowData) {
		let tableTopData = copyObjectValues(this.tabulatorTop.current.tabulator.getData());
		newRowData.tier_key = newRowData.tier;
		let vectorObj = findOptionByKey(this.props.options, newRowData.tier_key);
		newRowData.tier = vectorObj !== undefined ? vectorObj.label : newRowData.tier_key;

		if (!newRowData[PSL_RETURN_NAMES.NAME] || !newRowData[PSL_RETURN_NAMES.NUMBER]) {

			//when drilling from list q or qt, add name and number to the record inserted into table top:
			//name and number data doesn't exist when drilling from list q and qt, we add it below
			//name and number data exists for entities fetched after submitting drill, so we check for it before taking the key col data
			newRowData[PSL_RETURN_NAMES.NAME] = newRowData[PSL_RETURN_NAMES.NAME] || newRowData[PSL_RETURN_NAMES.KEY];
			newRowData[PSL_RETURN_NAMES.NUMBER] = newRowData[PSL_RETURN_NAMES.NUMBER] || newRowData[PSL_RETURN_NAMES.KEY];
		}

		tableTopData.push(newRowData);
		this.setState({
			tableTopColumns: this.getUpdatedColumns(newRowData.tier),
			tableTopData: tableTopData
		});
	}

	addNewCard = (rowDataArr, callback) => {
		let cardsData = copyObjectValues(this.state.cardsData);
		rowDataArr.forEach(e=>{
			e.tier_key = e.tier;
			let vectorObj = findOptionByKey(this.props.options, e.tier_key.trim());
			e.tier = vectorObj?.label || e.tier_key.trim();
		})

    // Adding other selected vectors (from manage columns) to cardData
		let otherSelectedVectorsData = [];
		rowDataArr.forEach(rowData=>{
			let tempOtherSelectedVectorsData = [];
			this.props.options.forEach(vector => {
				let vectorValue = vector.value.trim();
				if(!vector?.isGroupTitle && vectorValue.trim() !== rowData.tier_key.trim()) {
					let name = rowData[vectorValue + SUFFIX.NAME];
					let number = rowData[vectorValue + SUFFIX.NUMBER];
					let segmentTier = rowData[vectorValue + SUFFIX.SEGMENT_TIER];
					let segment = rowData[vectorValue + SUFFIX.SEGMENT];
					let entityCount = rowData[vectorValue + SUFFIX.ENTITY_COUNT];
					let perSelection = rowData[vectorValue + SUFFIX.COUNT_PER_SELECTION];
					let perTotal = rowData[vectorValue + SUFFIX.COUNT_PER_TOTAL];
			
					if(name || number || segment || segmentTier) {
						let allDrills = number ?? name ?? segmentTier ?? segment;
						let isQuad = !(name || number);
						let value = isQuad ? returnQuadrantAbv(allDrills.trim()) : allDrills.trim().toUpperCase();
						let type = isQuad ? PSL_RETURN_NAMES.QUADRANT : (number ? PSL_RETURN_NAMES.NUMBER : PSL_RETURN_NAMES.NAME);
						let otherVector = {};
						otherVector.name = name;
						otherVector.number = number;
						otherVector.segment = segmentTier || segment;
						otherVector.label = vector.label;
						otherVector.tier_key = vectorValue;
						otherVector.value = value;
						otherVector.type = type;
						otherVector.hasNameAndNumber = !!(name && number);
						otherVector.skipInFilter = false
		
						tempOtherSelectedVectorsData.push(otherVector);
					} else if(entityCount || perSelection || perTotal) {
						let otherVector = {};
						otherVector.label = vector.label;
						otherVector.tier_key = vectorValue;
						otherVector.value = entityCount || perSelection || perTotal;
						otherVector.skipInFilter = true // we wont add this vector in the filter 
						tempOtherSelectedVectorsData.push(otherVector);
					}
				}
			});
			otherSelectedVectorsData.push(tempOtherSelectedVectorsData);
		})

		cardsData.push({
			data: rowDataArr, columns: this.getUpdatedColumns(rowDataArr?.[0].tier, undefined, undefined, true),
			columnsViews: this.props.manageColumnsDrillRef ? this.props.manageColumnsDrillRef.state.checkedItems : this.props.manageColumnsProfile ?
				removeAttributes(this.props.manageColumnsProfile[COLUMN_PROFILE.SIMPLIFIED_COLUMNS]) || [] : [],
			profile: this.props.manageColumnsDrillRef ? this.props.manageColumnsDrillRef.state.selectedProfile : this.props.manageColumnsProfile,
      		otherSelectedVectorsData: otherSelectedVectorsData
		});
		this.setState({
			cardsData: cardsData,
			drillVectorType: rowDataArr[0].drillVectorType
		}, callback);
	}

	createNewCol = (viewOptions, machineName, columnProfileId, columnOrder) => {
		const col = viewOptions?.find(e => e.type === "vector" && e.machine_name === machineName);
		if (!col) return null; 
		
		return {
			column_view_option_id: col.column_view_option_id,
			machine_name: machineName,
			type: _selectedVector,
			view_order: col.view_order,
			column_return_name: _selectedVector,
			column_order: columnOrder,
			column_profile_id: columnProfileId
		};
	};
	
	/**
	 * handle profiles based on the option selected in the drill component
	 * @param {*} columnViews 
	 * @param {*} vectorType 
	 * @returns 
	 */
	handleColumnsForDrill = (columnViews, vectorType, viewOptions) => {
		let columns = copyObjectValues(columnViews);
		const columnProfileId = columns?.[0]?.column_profile_id;
		const columnOrder = columns?.find(e=>e.type === _selectedVector)?.column_order;
		const removeColumns = (columns, toRemove) => {
			return columns.filter(e => !(e.type === _selectedVector && toRemove.includes(e.machine_name)));
		};
		const addColumnsIfNotExists = (columns, toAdd) => {
			toAdd.forEach(item => {
				if (!columns.find(e => e.type === _selectedVector && e.machine_name === item)) {
					const newCol = this.createNewCol(viewOptions, item, columnProfileId, columnOrder);
					if (newCol) columns.push(newCol);
				}
			});
		};

		let columnsToRemove = [];
		let columnsToAdd = [];
		let OPTIONS = PROFILE_COLUMN.VIEW_OPTIONS;
		if (vectorType === ENTITY_TYPES.SEGMENT) {
			columnsToRemove = [OPTIONS.NUMBER, OPTIONS.NAME, OPTIONS.QUADRANT_TIER];// if we are drilling by segment, remove name, number and QT
			columnsToAdd = [OPTIONS.QUADRANT, OPTIONS.ENTITY_COUNT, OPTIONS.COUNT_PER_SELECTION];// if we are drilling by segment, add segment, entity count and count per selection
		} else if (vectorType === ENTITY_TYPES.PROFITTIER) {
			columnsToRemove = [OPTIONS.QUADRANT, OPTIONS.NUMBER, OPTIONS.NAME];
			columnsToAdd = [OPTIONS.QUADRANT_TIER, OPTIONS.ENTITY_COUNT, OPTIONS.COUNT_PER_SELECTION];
		} else { // KEY
			columnsToRemove = [OPTIONS.ENTITY_COUNT, OPTIONS.COUNT_PER_SELECTION, OPTIONS.QUADRANT];
			columnsToAdd = [OPTIONS.NAME, OPTIONS.NUMBER, OPTIONS.QUADRANT_TIER];
		}
		columns = removeColumns(columns, columnsToRemove);
		addColumnsIfNotExists(columns, columnsToAdd);
		columns = this.removeVectorsColumns(columns);
		return columns;
	};

	/**
	 * remove all selected vectors with by name, number, segment or profit tier
	 * @param {*} columns 
	 * @returns 
	 */
	removeVectorsColumns = (columns) =>{
		let filteredData = columns.filter(item => {
			if (item.type !== "vector") return true;
			const vectorGroup = columns.filter(i => i.column_return_name === item.column_return_name && i.type === "vector");
			return vectorGroup.every(i => i.machine_name === PROFILE_COLUMN.VIEW_OPTIONS.ENTITY_COUNT || i.machine_name === PROFILE_COLUMN.VIEW_OPTIONS.COUNT_PER_SELECTION);
		});
		return filteredData;
	}
	

	setTabulatorSortField = (tabulatorSortField) => {
		this.setState({
			tabulatorSortField: tabulatorSortField
		});
	}


	renderCards = () => {
		let cardsData = this.state.cardsData ? copyObjectValues(this.state.cardsData.length > 0 ? this.state.cardsData : this.props.cardsData) : [];
		let cards = []

		for (let e in cardsData) {
			let cardDetails = cardsData[e]["data"][0];
			cards.push(<DrillCard key={"card_" + e} ref={el => this.cardRef[e] = el} index={Number(e)} card={cardsData[e]} cardsData={cardsData} parentTableReport="drill-table-top" columnFiltering={false} headerSort={false}
				user={this.props.user} profitFormat={this.props.profitFormat} periods={this.props.options} saveChosenEntities={this.props.saveChosenEntities}
				filterHeaderCSSSelector={this.props.filterHeaderCSSSelector} onColumnMoved={this.onColumnMoved} FY={this.props.months || this.props.FY} submitDrill={this.submitDrill} setNextTier={this.setNextTier}
				scenario_id={this.props.scenario_id} drillBack={this.drillBack} scenarioNumber={this.props.scenarioNumber} dataset={this.props.dataset} clearDrillingData={this.clearDrillingData}
				isDashBoards={this.props.isDashBoards} isPaginated={false} tier={cardDetails["tier"]} name={cardDetails["name"]} qt={cardDetails["quadranttier"] ? cardDetails["quadranttier"] : cardDetails["quadrant"]} setCardsData={this.setCardsData}
				addNewCard={this.addNewCard} setIsDrilling={this.props.setIsDrilling} isDrilling={this.props.isDrilling} records_limit={this.props.records_limit} setDrillTabulatorPageSize={this.setDrillTabulatorPageSize}
				profitStackSelectedEntities={this.props.profitStackSelectedEntities || []} otherSelectedVectorsData={cardsData[e].otherSelectedVectorsData}
				setColumnWidthState={this.setColumnWidthState} columnsWidths={this.state.columnsWidths}
				setDrillCardWidthState={this.setDrillCardWidthState} drillCardWidths={this.state.drillCardWidths}
				setDrillListWidthState={this.setDrillListWidthState} drillListWidths={this.state.drillListWidths} 
				exitDrill={this.props.exitDrill} hasDrillOption={this.props.hasDrillOption}
				useAppearanceList={this.props.useAppearanceList}
				drillCardProfile={cardsData[e].profile}
        />);
		}
		return cards;

	}

	clearDrillingData() {
		this.setState({
			tableTopData: [],
			drillDataList: { data: [], columns: [] },
			cardsData: []
		})
	}

	onToggleManageColsBoard = () => {
		let _this = this;
		_this.manageColsRef.classList.toggle("uk-hidden");
	}

	showNoPSLColumnsWarning = (val) => {
		let _this = this;
		_this.setState({
			showNoPSLColumnsWarning: val
		});
	}

	showNoPSLColumnsWarningBodyContent = () => {
		return (
			<div className="uk-display-flex pi-warning-background uk-border-rounded uk-padding-small uk-margin-left uk-margin-large-right">
				<i className="fa-2x fal fa-exclamation-triangle uk-margin-default-right" />
				<div className="fs-16">{MESSAGES.no_psl_columns_selected}</div>
			</div>
		  );
	}

	showNoPSLColumnsWarningDialogActions = () => {
		return (
			<Button 
				label={"Ok"}
				variant={BUTTON_VARIANT.SECONDARY}
				size={SIZES.DEFAULT}
				type={BUTTON_TYPE.DEFAULT}
				onBtnClick={() => this.showNoPSLColumnsWarning(false)}
			/>
		  );
	}

  setInfoDialogOpen = (isOpen, infoMsg) => {
    let _this = this;
    _this.setState({
      openInfoDialog: isOpen,
      infoMsg: infoMsg
    })
  }

  openInfoDialogActions = () => {
    return (
      <Button
        label={MESSAGES.modal.buttons.ok}
        variant={BUTTON_VARIANT.PRIMARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => this.setInfoDialogOpen(false, "")}
      />
    )
  }


	/**
	 * Saving column widths in state in this component because we want to inherit it in drill card.
	 * @param {*} columnWidths 
	 */
	setColumnWidthState = (columnWidths=[]) => {
		this.setState({ 
			columnsWidths: columnWidths,
		});
	}

	/**
	 * Saving cards column widths in state. Each card will have its own columns widths.
	 * @param {*} columnWidths 
	 */
	setDrillCardWidthState = (columnWidths=[]) => {
		this.setState({ drillCardWidths: columnWidths });
	}

	/**
	 * Saving drill list column widths in state.
	 * @param {*} columnWidths 
	 */
	setDrillListWidthState = (columnWidths=[]) => {
		this.setState({ drillListWidths: columnWidths });
	}

	setMultipleDrillEntitiesSelected = (val) =>{
		this.setState({
			multipleDrillEntitiesSelected: val 
		});
	}

	render() {

		let _this = this;
		var drillTier = this.state.drillTier;
		let drillTierLabel = "";
		let drillTierObj = this.props.options ? this.props.options.filter(e => e.value === drillTier) : [];
		if (drillTierObj.length > 0) {
			drillTierLabel = drillTierObj[0].label
		}
		let cards = this.renderCards();
		$("#Next_Tier").text(drillTier);
		let params = this.getParams();
		var selectMenuStyle = "";
		//if coming from heatmap and there is not enough (>2 rows) data in the drilled list, open menu upwards
		if ([ALL_REPORTS.SECOND_DIMENSION].indexOf(this.props.profitFormat) !== -1 && (!this.state.drillDataList || !this.state.drillDataList.data || this.state.drillDataList.data.length <= 2)) {
			selectMenuStyle = { top: 'auto', bottom: '100%' };
		} else {
			selectMenuStyle = { bottom: 'auto', top: '100%' };
		}
		let allowDrill = !this.props.isDashBoards;

		return (
			<div id="profit_maplist_container" className='profit_maplist_container'>
				{this.props.isDashBoards ? "" :
					<React.Fragment>
						<section id="To_Scroll_Top" className="drill-profit-map-list">
							{cards}
						</section>
						<input id="Next_Tier" className="hidden" ></input>
						<input id="Old_Tier" className="hidden" ></input>
						<input id="submit_drill" className="hidden" ></input>
						<input id="after_drilling" className="hidden" ></input>
						<input id="Previous_Tier" className="hidden" ></input>
					</React.Fragment>
				}
				<section id="To_Scroll_List" className="profit-map-list" >
					<TabulatorWrapper parentId={"To_Scroll_List"} ref={this.tabulatorList} id={"table-list"+(this.props.widgetId || "")} data={this.state.dataFinal} submitDrill={this.submitDrill} setNextTier={this.setNextTier} sorter={this.state.sorter} vectors={this.props.options} parentTableReport="list"
						appendDataToTableTop={this.appendDataToTableTop} clearDrillingData={this.clearDrillingData} columnFiltering={true} order={this.state.order}
						headerSort={true} user={this.props.user} profitFormat={this.props.profitFormat} saveChosenEntities={this.props.saveChosenEntities}
						isSecondDimension={this.props.isHeatmap} filterHeaderCSSSelector={this.props.filterHeaderCSSSelector} tier={this.state.tier}
						setIsDrilling={this.props.setIsDrilling} onColumnMoved={this.onColumnMoved} isDrilling={this.props.isDrilling}
						FY={this.props.months || this.props.FY} widgetId={this.props.widgetId || ""}
						scenario_id={this.props.scenario_id} scenarioNumber={this.props.scenarioNumber} dataset={this.props.dataset}
						exportQueryFilter={this.props.exportQueryFilter} listDataId={this.state.listDataId} allowDrill={allowDrill}
						isDashBoards={this.props.isDashBoards} isPaginated={this.props.fromWidget ? false : true} addNewCard={this.addNewCard} setCardsData={this.setCardsData}
						setOrder={this.setOrder} records_limit={this.props.records_limit} isCheckboxDisabled={this.state.isCheckboxDisabled}
						dataTier={this.props.dataTier} dbDataSet={this.props.dbDataSet} tablePrefix={this.props.tablePrefix}
						months={this.props.months || this.props.FY } idToken={this.props.idToken} project_id={this.props.project_id} section_id={this.props.sectionId}
						fetchProfitInfo={this.fetchProfitInfo} fromExpanding={this.props.fromExpanding}
						selectedRange={this.props.selectedRange}
						selectedFilter={this.props.filter} isListExpanded={this.props.isListExpanded}
						selectedColumns={_this.state.manageColumnsProfile?.simplified_columns.map(m => m.column_return_name === _selectedVector ? m.machine_name : m.column_return_name)}
						setDrillTabulatorPageSize={this.setDrillTabulatorPageSize}
						isLandscapeBubble={this.props.isLandscapeBubble}
						profitStackSelectedEntities={this.props.profitStackSelectedEntities}
						isBase={this.props.isBase} headerRef={this.props.headerRef} toggleDropDownState={this.props?.toggleDropDownState}
            			displayDefaultColumns={this.props.displayDefaultColumns} mainListManageProfile={this.props.manageColumnsProfile}
						vectorsSelectionLimit={this.props?.vectorsSelectionLimit} selectedDropdownVector={this.props.selectedDropdownVector}
						setTabulatorSortField={this.setTabulatorSortField} tabulatorSortField={this.state.tabulatorSortField}
						setColumnWidthState={this.setColumnWidthState} columnsWidths={this.state.columnsWidths}
						setDrillCardWidthState={this.setDrillCardWidthState} drillCardWidths={this.state.drillCardWidths}
						setDrillListWidthState={this.setDrillListWidthState} drillListWidths={this.state.drillListWidths} hasDrillOption={this.props.hasDrillOption} setMultipleDrillEntitiesSelected={this.setMultipleDrillEntitiesSelected}
						useAppearanceList={this.props.useAppearanceList}
					/>
				</section>

				{this.props.isDrilling ?
					<section id="To_Scroll_List_Drill" className="profit-map-list profit-map-list-drill" >
						{/*<span style={{fontWeight:"700"}} className="uk-text-large uk-margin-small-left uk-margin-small-bottom">{drillTierLabel}</span>*/}
						<TabulatorWrapper parentId={"To_Scroll_List_Drill"} ref={this.tabulatorDrill} id={"table-drill-list"} data={this.state.drillDataList} submitDrill={this.submitDrill} setNextTier={this.setNextTier} sorter={this.state.sorter} vectors={this.props.options} parentTableReport="drill-list"
							appendDataToTableTop={this.appendDataToTableTop} columnFiltering={true} headerSort={true} user={this.props.user} profitFormat={this.props.profitFormat}
							saveChosenEntities={this.props.saveChosenEntities} filterHeaderCSSSelector={this.props.filterHeaderCSSSelector} setIsDrilling={this.props.setIsDrilling}
							onColumnMoved={this.onColumnMoved} isDashBoards={this.props.isDashBoards}
							scenario_id={this.props.scenario_id} scenarioNumber={this.props.scenarioNumber} dataset={this.props.dataset} isDrilling={this.props.isDrilling}
							FY={this.props.months || this.props.FY} exportQueryFilter={this.props.exportQueryFilter} allowDrill={allowDrill} isPaginated={this.props.fromWidget ? false : true} addNewCard={this.addNewCard}
							setCardsData={this.setCardsData} setOrder={this.setOrder} records_limit={this.props.records_limit} refreshDataLenght={this.props.refreshDataLenght}
							drillListDataId={this.state.drillListDataId} profitStackSelectedEntities={this.props.profitStackSelectedEntities || []}
							// selectedRange={this.props?.headerRef?.state?.periodOptions.find(f=>f.start_date === this.props?.headerRef?.state?.start_date)?.value + " to " + this.props?.headerRef?.state?.periodOptions.find(f=>f.end_date === this.props?.headerRef?.state?.end_date)?.value}
							// selectedFilter={this.props.filter}
							setDrillTabulatorPageSize={this.setDrillTabulatorPageSize}
							isLandscapeBubble={this.props.isLandscapeBubble}
							order={this.state.order} isBase={this.props.isBase}
							setTabulatorSortField={this.setTabulatorSortField} tabulatorSortField={this.state.tabulatorSortField}
							setColumnWidthState={this.setColumnWidthState} columnsWidths={this.state.columnsWidths}
							setDrillCardWidthState={this.setDrillCardWidthState} drillCardWidths={this.state.drillCardWidths}
							setDrillListWidthState={this.setDrillListWidthState} drillListWidths={this.state.drillListWidths} drillSelectionLimit={this.props?.drillSelectionLimit}
						  	cardsDataLength={this.state.cardsData.length} resetDrillVectorType={this.resetDrillVectorType} hasDrillOption={this.props.hasDrillOption} setMultipleDrillEntitiesSelected={this.setMultipleDrillEntitiesSelected}
							useAppearanceList={this.props.useAppearanceList}
							drillCardProfile={this.props.manageColumnsDrillRef?.state?.selectedProfile || this.props.manageColumnsProfile}
						/>
					</section>
					: ""}
				<div className="uk-hidden">
					<ExcelDetailsTable ref={el => this.excelDetails = el} params={params} />
				</div>
        <Modal
          id={"manage-scenarios-confirm-dialog"}
          title={"Warning"}
          openDialog={this.state.showNoPSLColumnsWarning}
          bodyContent={this.showNoPSLColumnsWarningBodyContent}
          dialogActions={this.showNoPSLColumnsWarningDialogActions}
          closeClick={() => this.showNoPSLColumnsWarning(false)}
          size={DIALOG_SIZE.MEDIUM}
        />
        <Modal
          id={"info-dialog"}
          openDialog={this.state.openInfoDialog}
          bodyContent={() => <h4>{this.state.infoMsg}</h4>}
          dialogActions={this.openInfoDialogActions}
          closeClick={() => this.setInfoDialogOpen(false)}
          size={DIALOG_SIZE.MEDIUM}
        />
			</div>
		);
	}

}

export default ProfitMapList;