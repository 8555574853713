import {forwardRef, React, useEffect, useState} from "react";
import { ReactComponent as NextIcon } from "../../../../styles/images/icons/next-white.svg";
import {ReactComponent as ErrorIcon} from "../../../../styles/images/icons/error.svg";
import ApprovalSuccessImage from "../../../../styles/images/monthly-build-approval-success.png";
import {
    BUTTON_TYPE,
    BUTTON_VARIANT,
    DIALOG_SIZE,
    SIZES
} from "../../../../class/constants";
import Button from "../../../../newComponents/Button";
import {FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS} from "../../../../class/networkUtils";
import { getTranslationFile, parseBoolean } from "../../../../class/utils";
import CheckBox from "../../../../newComponents/CheckBox";
import NewBanner from "../../../NewBanner";
import {ReactComponent as Close} from "../../../../styles/images/icons/close-new.svg";
import { updatePeriodsStatus, updatePeriodsStatusState } from "../../../../actions/scenariosActions";
import { useHistory } from 'react-router-dom';
import Modal from "../../../../newComponents/Modal";

const lang = getTranslationFile();
const PublishPeriod = (props) => {

    const {
        period,
        publishedScenarioId,
        setProgressBarClickable
    } = props;

    const [messageConfirmed,setMessageConfirmed] = useState(false);
    const [openRepublishModal,setOpenRepublishModal] = useState(false);
    const [approvalSuccess,setApprovalSuccess] = useState(undefined);
    const [isError,setIsError] = useState(false);
    const history = useHistory();


    useEffect(() => {
        getLastApproveStatus()
    }, [])

    useEffect(() => {
        if(approvalSuccess != undefined){
            setProgressBarClickable(false);
            $("#header-back").addClass("uk-disabled disabled");
        }
    }, [approvalSuccess])
    

    const approveMonthlyBuild = () => {
        if(!messageConfirmed && !openRepublishModal){
            setIsError(true);
            return;
        }
        setApprovalSuccess(undefined);
        setOpenRepublishModal(false);
        let query = {
            action: "approveMonthlyBuild",
        }
            
        let onThenCallback = (data) => {
          if(data && parseBoolean(data.success)){
            setApprovalSuccess(true);
            //Clear getPeriodsStatus request from redux
            props.dispatch(updatePeriodsStatus([],publishedScenarioId));
            props.dispatch(updatePeriodsStatusState([],publishedScenarioId+"_"+12));
          } else {
            setApprovalSuccess(false);
          }
          $("#header-back").addClass("uk-disabled disabled");
          setProgressBarClickable(false);
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "approveMonthlyBuild",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: "/approve-monthly-build",
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.monthly_build.publish_monthly_build.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.monthly_build.publish_monthly_build.requests_description.publish_monthly_build
        };
        fetchAPI(fetchOptions);
      
    }


    const getLastApproveStatus = () => {

        let query = {
            action: "getLastApproveStatus",
            scenarioId: publishedScenarioId
        }

        let onThenCallback = (data) => {
            if(!data.lastApproveStatus){
                setApprovalSuccess(data.lastApproveStatus);
            }
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getLastApproveStatus",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: "/get-last-approve-status",
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.monthly_build.publish_monthly_build.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.monthly_build.publish_monthly_build.requests_description.get_last_approve_status
        };
        fetchAPI(fetchOptions);

    }

    const handleChange = (e) => {
          setMessageConfirmed(e.currentTarget.checked);
          if(e.currentTarget.checked){
            setIsError(false);
          }
    };  
    const renderPublishPeriodStatement = (message) => {
        let splittedMessage = message.split("\n");
        let elements = splittedMessage.map(e=><span style={{fontFamily:"Lato",fontSize:"14px",color:"#000", fontWeight:"500",lineHeight:"24px"}}>{e}</span>)
        return <div style ={{display:"flex",flexDirection:"column",gap:"0.2vw"}}>
            {elements}
        </div>
    }
    const renderPublishPeriodEntry = () => {
        return (
            <div style = {{display:"flex",flexDirection:"column",gap:"1.5vw"}}>
                {renderPublishPeriodStatement(lang.monthly_build.build_scenario.publish_period_page_statement)}
                <div style ={{display:"flex",flexDirection:"row",gap:"0.2vw"}}>
                    <CheckBox id={"checkbox_approval_confirmation"} checked={messageConfirmed} disabled={false} onChange={(e)=> {handleChange(e)}} />
                    <span style={{fontFamily:"Lato",fontSize:"14px",color:"#333", fontWeight:"500",lineHeight:"24px"}}>{lang.monthly_build.build_scenario.publish_period_confirmation}</span>
                </div>
                <div>
                    <Button
                        id={"approve-monthly-build"}
                        label={"Agree and publish"}
                        variant={BUTTON_VARIANT.PRIMARY}
                        size={SIZES.LARGE}
                        type={BUTTON_TYPE.DEFAULT}
                        rightIcon={<NextIcon />}
                        className={"uk-margin-large-top"}
                        onBtnClick={approveMonthlyBuild}
                    />
                    {isError && 
                        <span className="error-text"> 
                            <ErrorIcon />
                            <span style={{ marginLeft: 5 }}>
                            {lang.monthly_build.build_scenario.publish_period_not_confirmed_error}
                            </span>
                        </span>}
                </div>
            </div>
        );
    }
    const redirectToConfiguration = () => {
        history.push('/profit_isle_start');
      };

    const renderApprovalResult = () => {
     if(approvalSuccess) {
        return (
            <div className="med-gap-flex">
                <img src={ApprovalSuccessImage} alt="Monthly Build Approval Success Image"/>
                <span style={{fontFamily:"Lato",fontSize:"18px",color:"#000", fontWeight:"800",lineHeight:"normal",textAlign:"center"}}>{lang.monthly_build.build_scenario.publish_period_success}</span>
                <span style={{fontFamily:"Lato",fontSize:"14px",color:"#000", fontWeight:"500",lineHeight:"normal",textAlign:"center"}}>{lang.monthly_build.build_scenario.publish_period_success_statement}</span>
                <div className="checkbox-container">
                    <Button
                            id={"redirect-after-publish"}
                            label={lang.monthly_build.build_scenario.get_started}
                            variant={BUTTON_VARIANT.SECONDARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            onBtnClick={redirectToConfiguration}
                        />
                </div>
            </div>
           )
       }
    }

    const renderErrorBanner = () => {

        let bannerBody = <div>
                            <span> {lang.monthly_build.build_scenario.banner_failed_1}</span>
                            <br></br>
                            <span>{lang.monthly_build.build_scenario.banner_failed_2[1]}</span>
                            <span className="uk-text-bold uk-text-decoration-underline uk-cursor-pointer" onClick={()=>setOpenRepublishModal(true)}>
                                {lang.monthly_build.build_scenario.banner_failed_2[2]}
                                </span>
                            <span>{lang.monthly_build.build_scenario.banner_failed_2[3]}</span>
                        </div>

        return (
            <NewBanner
                id={"banner-approve-build"}
                bannerClassName={"banner-not-tied-out tie-out-banner fs-14"}
                icon={ <Close className = {"red-circle banner-icon"}/>}
                body={bannerBody}
            />
        )
       }

       const republishModalActions = () => {
        return (
          <>
            <Button
              label={lang.monthly_build.build_scenario.republish_modal.action}
              variant={BUTTON_VARIANT.PRIMARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              onBtnClick={approveMonthlyBuild}
            />
    
            <Button
              label={lang.monthly_build.build_scenario.republish_modal.cancel}
              variant={BUTTON_VARIANT.TERTIARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              onBtnClick={() => setOpenRepublishModal(false)}
            />
          </>
        );
      };


    return (
        <div>
            {approvalSuccess === undefined ? renderPublishPeriodEntry() : approvalSuccess ?  renderApprovalResult() :renderErrorBanner()}

            <Modal //modal appears when the user clicks on try again in publish failure banner
                key={"republish-modal"}
                id={"republish-modal"}
                title={lang.monthly_build.build_scenario.republish_modal.title}
                openDialog={openRepublishModal}
                closeClick={() => setOpenRepublishModal(false)}
                bodyContent={() => <h5>{lang.monthly_build.build_scenario.republish_modal.body}</h5>}
                dialogActions={republishModalActions}
                size={DIALOG_SIZE.MEDIUM}
            />
        </div>
        
    );
};
export default PublishPeriod;
