// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.build-progress-bar .not-text-grey .MuiStepLabel-label.Mui-active,
.build-progress-bar .not-text-grey .MuiStepLabel-label.Mui-completed {
  color: #5455A8 !important;
}

.text-grey {
    color: #8D8D8D !important;
}`, "",{"version":3,"sources":["webpack://./src/components/monthlyBuild/steps/buildStep/BuildProgressBar.css"],"names":[],"mappings":"AAAA;;EAEE,yBAAyB;AAC3B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".build-progress-bar .not-text-grey .MuiStepLabel-label.Mui-active,\n.build-progress-bar .not-text-grey .MuiStepLabel-label.Mui-completed {\n  color: #5455A8 !important;\n}\n\n.text-grey {\n    color: #8D8D8D !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
