import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Board from "react-trello";
import {API_URL, UPLOAD_SECTIONS} from "../../class/constants";
import {
  FETCHAPI_PARAMS,
  FETCH_METHOD,
  fetchAPI,
} from "../../class/networkUtils";
import { lang } from "../../language/messages_en";
import LaneCard from "./LaneCard";
import LaneHeader from "./LaneHeader";
import "./trelloBoard.css";

const TrelloBoard = ({ className = "", currPeriod, user, machineName, data, getBoardData, appliedFilter, isProceedBtnPressed, toggleErrorsDialog,toggleIgnoreDialog}, ref) => {
  const [boardData, setBoardData] = useState({ lanes: [] });
  const [originalBoardData, setOriginalBoardData] = useState({ lanes: [] });
  const [boardDataBeforeProceed, setBoardDataBeforeProceed] = useState(boardData);
  const [timeInterval, setTimeInterval] = useState(5);
  const [proceedBtnPressed, setProceedBtnPressed] = useState(isProceedBtnPressed);

  useImperativeHandle(ref, () => ({
    getBoardData: () => {
      return boardData;
    },
    highlightRequiredFiles: () => {
      highlightRequiredFiles();
    },
    proceedBtnPressed: () => {
      onProceedBtnClick();
    },
  }));

  /**
   * On proceed we save the boardData because when the board refreshes, we compare the data to see
   * if a card has changed its status so wwe won't highlight it in red, until repressing on proceed.
   */
  const onProceedBtnClick = () => {
    setProceedBtnPressed(true);
    setBoardDataBeforeProceed(originalBoardData);
  }

  useEffect(()=>{
    if(data && data?.data){
      // let filteredData = Object.assign([], data.data);
      //   if(appliedFilter?.length > 0) {
      //     let appliedFilterList = appliedFilter.map(f=>f.value);
      //     filteredData = filteredData?.filter(e => appliedFilterList.includes(e.display_section));
      //   }
        // let boardData = formatBoardData(filteredData);
        let boardData = formatBoardData(data.data);
        let timeInterval = data.timeInterval;

        // let originalData = formatBoardData(data?.data);
        // setOriginalBoardData(originalData);
  

        if(!proceedBtnPressed && $(".upload-error-text").length === 0) { 
            boardData.lanes[0]['cards'] = sortCardsByError(boardData.lanes[0]['cards']);
            setBoardData(boardData);
        } else {
            highlightRequiredFiles(boardData);
        }
        
        setTimeInterval(timeInterval);
    }
  }, [data, appliedFilter])


  useEffect(() => {
    const interval = setInterval(() => {
      // Send your request here
      getBoardData(true);
    }, timeInterval * 20000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, [timeInterval, proceedBtnPressed, boardData]);

  const formatBoardData = (data) => {
    let notUploadedLane = data?.filter((e) => e.lane_status === lang.monthly_build.upload_files.status.not_uploaded.value);
    let pendingLane = data?.filter((e) => e.lane_status === lang.monthly_build.upload_files.status.pending.value);
    let processedLane = data?.filter((e) => e.lane_status === lang.monthly_build.upload_files.status.processed.value);

    // set all cards to hidden false by default
    notUploadedLane.map(m => {m.isHidden = false; return m});
    pendingLane.map(m => {m.isHidden = false; return m});
    processedLane.map(m => {m.isHidden = false; return m});

    // when we apply a filter, hide cards 
    if(appliedFilter?.length > 0) {
      let appliedFilterList = appliedFilter.map(f=>f.value); 

      notUploadedLane.filter(f => !appliedFilterList.includes(f.display_section)).map(m => {m.isHidden = true; return m});
      pendingLane.filter(f => !appliedFilterList.includes(f.display_section)).map(m => {m.isHidden = true; return m});
      processedLane.filter(f => !appliedFilterList.includes(f.display_section)).map(m => {m.isHidden = true; return m});
  }
   
    let lanes = [
      {
        id: lang.monthly_build.upload_files.status.not_uploaded.value,
        titleText: lang.monthly_build.upload_files.status.not_uploaded.label,
        hasButton: true,
        btnLabel: lang.monthly_build.upload_files.upload_sftp,
        isBtnDisabled: notUploadedLane?.length === 0,
        onBtnClick: uploadToSFTPClick,
        cards: notUploadedLane,
      },
      {
        id: lang.monthly_build.upload_files.status.pending.value,
        titleText: lang.monthly_build.upload_files.status.pending.label,
        hasButton: true,
        btnLabel: lang.monthly_build.upload_files.process_sftp,
        onBtnClick: initiateFTPUpload,
        isBtnDisabled: pendingLane?.length === 0,
        cards: pendingLane,
      },
      {
        id: lang.monthly_build.upload_files.status.processed.value,
        titleText: lang.monthly_build.upload_files.status.processed.label,
        cards: processedLane,
      },
    ];

    lanes?.map((lane) => {
      lane.label = lane?.cards?.filter(f => !f.isHidden).length;
      lane.cards?.map((card) => {
          card.draggable = false;
          return card;
      });
      return lane;
    });

    let result = { lanes: lanes };
    return result;
  };

  const initiateFTPUpload = () => {
    $(".lane-header-btn." + lang.monthly_build.upload_files.status.pending.value).addClass("disabled");
    const onThenCallback = (data) => {
      console.log(data.result);
    };
    const onCompleteCallback = () => {
      console.log("FTP Upload initiated.");
      setTimeout(() => {
        $(".lane-header-btn." +lang.monthly_build.upload_files.status.pending.value).removeClass("disabled");
      }, 30000);
    };
    let data = {
      action: "initiateFTPUpload",
      monthlyBuildPeriod: currPeriod
    };
    const fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "initiateFTPUpload",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
      [FETCHAPI_PARAMS.showLoader]: false,
      [FETCHAPI_PARAMS.path]: API_URL.DataPipeline,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: data,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.onCompleteCallback]: onCompleteCallback,
      [FETCHAPI_PARAMS.email]: user.email,
      [FETCHAPI_PARAMS.machine_name]: machineName,
      [FETCHAPI_PARAMS.periods]: currPeriod,
      [FETCHAPI_PARAMS.screenName]:lang.observability.monthly_build.upload_files.screen_name,
      [FETCHAPI_PARAMS.requestDescription]:lang.observability.monthly_build.upload_files.requests_description.initiate_ftp_upload
    };
    fetchAPI(fetchOptions);
  };

  /**
   * Upload to SFTP click. It opens a new tab with the upload sftp link
   */
  const uploadToSFTPClick = () => {
    window.open(lang.monthly_build.upload_files.upload_url, "_blank");
  };

  /**
   * Called when clicking on proceed to highlight the background of the rquired cards if they are still not processed.
   * If we click on proceed, dataFromReq is undefined so we highlight all necessary files.
   * It called in getBoardData(), dataFromReq are the new retrieved data, so we check if one of the files status has changed 
   * (by finding the difference between boardDataBeforeProceed and dataFromReq) then remove the highlight until we press proceed again. 
   * @param {*} dataFromReq 
   */
  const highlightRequiredFiles = (dataFromReq) => {
    let data = dataFromReq || boardData;

    if(dataFromReq  && boardDataBeforeProceed.lanes.length > 0) {
      // used Object.assign instead of copyObjectValues to keep the btnOnClick function found in lanes
      let tempData = Object.assign({}, boardDataBeforeProceed);
      let tempDataFromReq = Object.assign({}, dataFromReq);
      let notUploadedRequiredFiles = tempData.lanes.find(f => f.id === lang.monthly_build.upload_files.status.not_uploaded.value)?.cards.filter(f => f.required); // the data we had before refetching new data
      let newNotUploadedRequiredFiles = tempDataFromReq.lanes.find(f => f.id === lang.monthly_build.upload_files.status.not_uploaded.value)?.cards.filter(f => f.required); // new fetched data

      // remove laneId & proceedWarning attr to compare the objects with the one from the api
      notUploadedRequiredFiles?.map(file => {
        delete file.laneId;
        delete file.proceedWarning;
        return file;
      });

      let filesMovedToNotUploaded = newNotUploadedRequiredFiles.filter(f => !notUploadedRequiredFiles?.some(file => JSON.stringify(file) === JSON.stringify(f)));
      let filesMovedFromNotUploaded = notUploadedRequiredFiles.filter(f => !newNotUploadedRequiredFiles?.some(file => JSON.stringify(file) === JSON.stringify(f)));
      newNotUploadedRequiredFiles?.map(m => m.proceedWarning = true);

      filesMovedFromNotUploaded?.map(m => {delete m.proceedWarning; return m});
      filesMovedToNotUploaded?.map(m => {delete m.proceedWarning; return m});
      let newPendingLane = tempDataFromReq?.lanes?.find(f => f.id === lang.monthly_build.upload_files.status.pending.value).cards; // the data we had before refetching new data

      newPendingLane?.map(m => m.proceedWarning = true);
      let changedFiles = newPendingLane?.filter(f => filesMovedFromNotUploaded.map(m => m.raw_file_id).includes(f.raw_file_id) || filesMovedToNotUploaded.map(m => m.raw_file_id).includes(f.raw_file_id));
      changedFiles?.map(m => {delete m.proceedWarning; return m});
      
      data = tempDataFromReq;
    } else {
      let tempData = Object.assign({}, data) ;
      let notUploadedRequiredFiles = tempData.lanes.find(f => f.id === lang.monthly_build.upload_files.status.not_uploaded.value).cards.filter(f => f.required || (f.display_section.toLowerCase() === UPLOAD_SECTIONS.FIELDS.CROSSWALK && f.has_previous_crosswalk && f.has_previous_links && !f.is_crosswalk_ignored));
      notUploadedRequiredFiles.map(m => m.proceedWarning = true);
      let pendingLane = tempData?.lanes?.find(f => f.id === lang.monthly_build.upload_files.status.pending.value);
      pendingLane?.cards.map(m => m.proceedWarning = true);
      data = tempData;
    }
       
    data.lanes[0]['cards'] = sortCardsByError(data.lanes[0]['cards']);
    
    setBoardData(data);
  }
/**
 *  this function takes data that needs to be sorted by error,
 *  loops over it, creates an array with errors and else without,
 *  then concatinates both arrays them to have the errors before others
 * @param {*} data data of lane that needs to be adjusted
 * @returns 
 */
  const sortCardsByError = (data) => {
    let boardDataWithErros = []; // to be filled in map
    let boardDataWithoutErros = []; // to be filled in map
    data.sort((obj1, obj2) => obj1.display_order - obj2.display_order); // to sort data based on category
    data.map((e) => {
        if (
          e.proceedWarning ||
          [lang.monthly_build.upload_files.file_status.failed_upload, lang.monthly_build.upload_files.file_status.failed_decrypt].includes(e.status) ||
          [lang.monthly_build.upload_files.file_status.failed_upload].includes( e.rawFileStatus ) ||
          e.is_rejected
        ) {
          boardDataWithErros.push(e);
        } else {
          boardDataWithoutErros.push(e);
        }
      });

      return boardDataWithErros.concat(boardDataWithoutErros);
  }
  
  return (
    <div className={"trello-board " + className}>
      <Board
        data={boardData}
        style={{ backgroundColor: "white" }}
        components={{
          LaneHeader: (lane) => {
            return <LaneHeader lane={lane} />;
          },
          Card: (card) => {
            return (
              <LaneCard
                laneId={card.laneId}
                section={card.display_section}
                subSection={card.display_subsection}
                namingTemplate={card.naming_template}
                status={card.status}
                isRejected={card.is_rejected}
                response={card.response} 
                isRequired={card.required}
                proceedWarning={card.proceedWarning}
                isHidden={card.isHidden}
                toggleErrorsDialog={toggleErrorsDialog}
                toggleIgnoreDialog={toggleIgnoreDialog}
                isAutomated={card.is_automated}
                automationFailure={card.is_automation_failure}
                hasPreviousLinks={card.has_previous_links}
                hasCurrentLinks={card.has_current_links}
                hasPreviousCrosswalk={card.has_previous_crosswalk}
                rawFileId={card.raw_file_id}
                isCrosswalkIgnored= {card.is_crosswalk_ignored}
                rawFileStatus={card.raw_file_status} // status for crosswalk files
              />
            );
          },
        }}
      />
    </div>
  );
};

export default forwardRef(TrelloBoard);
