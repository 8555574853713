import React, { Component } from 'react';
import { ALL_WIDGETS, BUTTON_TYPE, BUTTON_VARIANT, COLUMN_PROFILE, FY_VALUES, MANAGE_COLUMNS, PROFILE_COLUMN, PSL_RETURN_NAMES, SIZES } from '../../class/constants';
import { convertPxToViewport } from '../../class/formatting';
import { deepCompareObjects, getTranslationFile } from '../../class/utils';
import DropDownContainer from '../DropDownContainer';
import { Badge } from '../../form/elements.js';
import Button from '../../newComponents/Button';
import SwitchToggle from '../../newComponents/SwitchToggle';
import { ReactComponent as SegmentationIcon } from "../../styles/images/icons/segmentation-icon.svg";


const lang = getTranslationFile();

const _returnName = PROFILE_COLUMN.RETURN_NAME;
const _isExpanded = PROFILE_COLUMN.IS_EXPANDED;

class ProfileColumn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }

        this.toggleColumn = this.toggleColumn.bind(this);
    }

    toggleColumn(key) {
        this.props.toggleColumn(this.props.value, key);
    }

    shouldComponentUpdate(nextProps, nextState) {
        let shouldUpdate = !deepCompareObjects(nextProps, this.props);
        return shouldUpdate;
    }

    toggleElement(id, machineName, groupName, checked, propData, viewOrder){
        this.props.updateCheckedItems(id, machineName, groupName, checked, propData, viewOrder);
    }

    /**
     * This function returns the visible toggled on options for this column.
     * @returns count of toggles on options or empty string if no options are on.
     */
    getBadgeCount = () => {
        let selectedColumnsCount = 0;
        if(this.props.isCheckable && this.props.checkedItems.filter(e=> e[_returnName] === this.props.value).length > 0) {
            if(this.props.type === MANAGE_COLUMNS.VECTOR_MACHINE_NAME) {
                selectedColumnsCount = this.props.checkedItems.filter(e=> e[_returnName] === this.props.value && ![PSL_RETURN_NAMES.COUNT_PER_SELECTION].includes(e.machine_name)).length;
            } else if(this.props.type === MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME) {
                selectedColumnsCount = this.props.checkedItems.filter(e=> e[_returnName] === this.props.value && ![PSL_RETURN_NAMES.ENTITY_COUNT, PSL_RETURN_NAMES.COUNT_PER_SELECTION].includes(e.machine_name)).length;
            } else {
                selectedColumnsCount = this.props.checkedItems.filter(e=> e[_returnName] === this.props.value).length;
            }
        }
           
        return selectedColumnsCount === 0 ? "" : selectedColumnsCount;
    }

    render() {
        const obj = this;
        const profileColumnData = {
            [PROFILE_COLUMN.COLUMN_FIELD_ID]: this.props.columnFieldId,
            [PROFILE_COLUMN.NAME]: this.props.name,
            [PROFILE_COLUMN.TYPE]: this.props.type,
            [PROFILE_COLUMN.COLUMN_DESCRIPTION]: this.props.description,
            [PROFILE_COLUMN.FORMAT_NAME]: this.props.formatType,
            [PROFILE_COLUMN.UNIT]: this.props.unit,
            [COLUMN_PROFILE.VISIBILITY]: this.props.visibility,
            [COLUMN_PROFILE.DISPLAY_NAME]: this.props.displayName,
            [_returnName]: this.props.value,
            [PROFILE_COLUMN.LINE_TYPE]: this.props.columnLineType,
            [COLUMN_PROFILE.CREATOR_NAME]: this.props.creatorName,
            [COLUMN_PROFILE.CREATED_BY]: this.props.createdBy,
            [PROFILE_COLUMN.COLUMN_TYPE]: this.props.column_type,
            [PROFILE_COLUMN.COLUMN_TYPE_LINE]:this.props.column_type_line,
            [PROFILE_COLUMN.COLUMN_GROUP_LINE]:this.props.column_group_line,
            [PROFILE_COLUMN.COLUMN_GROUP]: this.props.column_group,
            [PROFILE_COLUMN.FORMAT_ID]: this.props.formatId,
            [PROFILE_COLUMN.FORMULA]: this.props.formula,
            // [PROFILE_COLUMN.COLUMN_GROUP]: this.props.groupName
        }
        let indexOfValue="";
        let searchValueInName ="";
        let value = obj.props.viewOptions && obj.props.viewOptions.length > 0 ? obj.props.viewOptions.filter(e=>e.is_default) : [];
        indexOfValue = this.props.name && this.props.searchValue && this.props.name.toLowerCase().indexOf(this.props.searchValue.toLowerCase());
        searchValueInName = this.props.name && this.props.name.substr(indexOfValue, this.props.searchValue.length);

        let badgeCount = this.getBadgeCount();
        let name = this.props.name === "Invoiceline"? "InvoiceLine" : this.props.name; // to be changed 
        return (
           // indenting the child 10px for each level (10px because uk-margin-small is 10px)
            <div className="profile-column" style={this.props.displayAsParent ? {} : {paddingLeft: convertPxToViewport(this.props.level * 10)}}>
                <div className={`${(this.props.isExpandable ? "uk-cursor-pointer" : "")} uk-flex uk-flex-middle`} onClick={()=>this.toggleColumn(_isExpanded)}>
                    {this.props.isExpandable ?
                        <Button  
                            variant={BUTTON_VARIANT.TERTIARY}
                            size={SIZES.ICON}
                            type={BUTTON_TYPE.DEFAULT}
                            className="uk-flex uk-flex-center uk-flex-middle uk-margin-small-right"
                            leftIcon={<i className={"fs-16 far " + (this.props.isExpanded ? "fa-chevron-down" : "fa-chevron-right")} />}
                        />
                    :
                        <div style={{"width":convertPxToViewport(45),"height":convertPxToViewport(33)}}></div>
                    }
                    {this.props.visibility === COLUMN_PROFILE.VISIBILITY_OPTIONS.USER ? 
                        <i title="You" className="fa-lg fal fa-user uk-margin-small-right"></i>
                    : this.props.visibility === COLUMN_PROFILE.VISIBILITY_OPTIONS.COMPANY ? 
                        <i title="By Company" className="fa-lg fal fa-users uk-margin-small-right"></i> 
                    : ""}

                    {/** the highlight span */}
                    {this.props.searchValue !== "" && this.props.isInSearchResult ?
                        <h5>
                            {name.substr(0, indexOfValue)}
                            <span className="search-value-highlight">{searchValueInName}</span>
                            {name.substr(indexOfValue + this.props.searchValue.length)}
                        </h5>
                        :
                        <h5 title={this.props.description} style={{wordBreak: "break-word"}} className={!this.props.isExpandable ? "profile-column-width" : ""}>{name}</h5>
                    }
                    {this.props.hasWarning ?
                        <span className={'fas fa-exclamation pi-text-yellow fa-lg width-15 uk-margin-xsmall-left uk-cursor-pointer'} uk-tooltip={this.props.warningMessage}></span>
                    :""}

                    {this.props.isVectorGenerated ? <SegmentationIcon className="segmentation-icon" /> : ""}
                    <Badge count={badgeCount} className="manage_columns_badge" />

                    {this.props.hasBlueDot ?
                        <div className="column-blue-dot" uk-tooltip={lang.manage_columns.text.blue_dot_tooltip}></div>
                    :""}
                    {this.props.creatorName && !this.props.isCreatorSystem ?
                        <em className="profile-column-name text-grey fs-14 uk-margin-small-left">{this.props.creatorName}</em>
                    :""}
                </div>
                {this.props.isCheckable ?
                    <div className="uk-flex uk-flex-middle">
                        { value && value.length > 0 ?
                        <div className="uk-flex uk-flex-middle" title={value[0][PROFILE_COLUMN.DISPLAY_NAME]}>
                            {obj.props.checkedItems.filter(e=>e[PROFILE_COLUMN.VIEW_OPTION_ID] === value[0][PROFILE_COLUMN.VIEW_OPTION_ID] && e[_returnName] === obj.props.value).length > 0 ? 
                                <SwitchToggle id={value[0][PROFILE_COLUMN.VIEW_OPTION_ID]+"_"+profileColumnData[_returnName]+"_default"} name={"FY"} value={FY_VALUES.ON} checked={true}
                                onChange={(evt) => {obj.toggleElement(value[0][PROFILE_COLUMN.VIEW_OPTION_ID], value[0][PROFILE_COLUMN.MACHINE_NAME], obj.props.groupName, evt.currentTarget.checked, profileColumnData, value[0][PROFILE_COLUMN.VIEW_ORDER])}}/>
                                : 
                                <SwitchToggle isDisabled={this.props.disableToggles} tooltip={this.props.disableToggles? lang.column_selection_exceeded:"title:"}
                                    id={value[0][PROFILE_COLUMN.VIEW_OPTION_ID]+"_"+profileColumnData[_returnName]+"_default"} name={"FY"}  value={FY_VALUES.OFF}
                                    onChange={(evt) => {obj.toggleElement(value[0][PROFILE_COLUMN.VIEW_OPTION_ID], value[0][PROFILE_COLUMN.MACHINE_NAME], obj.props.groupName,
                                    evt.currentTarget.checked, profileColumnData, value[0][PROFILE_COLUMN.VIEW_ORDER])}}/>
                            }
                        </div>
                    :""}
                        <Button 
                            variant={BUTTON_VARIANT.TERTIARY}
                            size={SIZES.ICON}
                            type={BUTTON_TYPE.DEFAULT}
                            aria-expanded="true"
                            className={"uk-icon-container uk-button uk-invisible"}
                            leftIcon={<i className="fa-2x fal fa-ellipsis-v" aria-hidden="true" />}
                        />
                        {/* //TODO @georges delete commented line */}
                        <DropDownContainer editColumn={this.props.editColumn} viewOptions={this.props.viewOptions} groupName={this.props.groupName}
                            updateCheckedItems={this.props.updateCheckedItems} checkedItems={this.props.checkedItems} isColumnInList={this.props.isColumnInList}
                            profileColumnData={profileColumnData} profitFormat={this.props.profitFormat} access={this.props.access}
                            onDeleteColumn={this.props.onDeleteColumn} disableToggles={this.props.disableToggles}
                            isVectorGenerated={this.props.isVectorGenerated}
                        />
                    </div>
                :""}
            </div>
        );
    }
}

export default ProfileColumn