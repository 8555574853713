import React, { Component } from 'react';
import Popup from 'react-popup';
import ReactTable from "react-table";
import shortid from 'shortid';

import { logout, toggleLoader, setLocalStorageValueByParameter, getCurrency } from '../class/common.js';
import { getClientSetting } from '../class/commonRequests';
import { ALERT_BOOLEAN_MESSAGE, ALERT_DEFINE_MESSAGE, ALERT_FILL_MESSAGE, ALL_WIDGETS, Amendments, API_URL, CONFIG_SETTINGS, CROSSWALK_FIELDS, CURRENCY_FIELDS, DATATYPE_ENUM, DATA_DICTIONARY_ENUM, DEFAULT_DATE_FORMAT_UPLOAD, DEFINE_CREDIT_DEBIT_ENTRY_TYPE, DEFINE_DATA, DEFINE_FIELDS, Delimiters, ENTRY_TYPE, FISCAL_YEAR, Formats, FormatTypes, HISTORY_DATA, LEADING_CHARACTERS, NEW_SECTION, PERIOD_ONWARD, PI_CW, PRIMARY_KEY, RAW_FILE, RAW_FILE_VALIDATION, RAW_ITEMS, ROW_STATUS, STATUS_ENUM, SUMMARY_FIELDS, TOOLTIP_AMENDMENT_MESSAGE, TRAILING_CHARACTERS, UPLOAD_DATA, UPLOAD_FIELDS, UPLOAD_SECTIONS, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DROPDOWN_TYPE, MENU_ITEM, DIALOG_SIZE } from '../class/constants.js';
import { formatValHTML, formatValString } from "../class/format";
import { convertPxToViewport } from '../class/formatting';
import { alertAndLogError } from '../class/jqueries';
import { fetchAPI, FETCHAPI_PARAMS, FETCH_METHOD } from '../class/networkUtils';
import {
    adjustNumberFieldToInteger, capitaliseFirstLetterAfterChar, copyObjectValues, fancyTimeFormat, findOptionByKey, formatFileSizeByByte, getSectionExists, getTranslationFile, logoutIfUnauthenticated, parseBoolean
} from "../class/utils";
import { CustomSelect/*, Modal*/ } from '../form/elements.js';
import '../styles/common.css';
import '../styles/dataModeling.css';
import "../styles/tabulator_bootstrap4.css"; // use Theme(s)
import '../styles/upload.css';
import LinkDefinition from '../tables/LinkDefinition';
import UploadDataTable from '../tables/UploadDataTable';
import SectionFileInfo from './SectionFileInfo.js';
import { arrangeFileNames } from './filter/FilterHelperFunctions';
import Button from '../newComponents/Button';
import DropDown from '../newComponents/DropDown';
import SwitchToggle from '../newComponents/SwitchToggle';
import Modal from '../newComponents/Modal.js';
import { lang } from '../language/messages_en.js';


const $ = require('jquery');

const MESSAGES = getTranslationFile();

const P_AND_L = UPLOAD_SECTIONS.FIELDS.P_AND_L;
const GENERAL_LEDGER = UPLOAD_SECTIONS.FIELDS.GENERAL_LEDGER;
const TRANSACTION_DATA = UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA;
const ANCILLARY_FILES = UPLOAD_SECTIONS.FIELDS.ANCILLARY_FILES;
const MASTER_DATA = UPLOAD_SECTIONS.FIELDS.MASTER_DATA;
const DATA_SUMMARY =  UPLOAD_SECTIONS.TITLES.DATA_SUMMARY;
const CROSSWALK = UPLOAD_SECTIONS.FIELDS.CROSSWALK;
const CURRENCY = UPLOAD_SECTIONS.FIELDS.CURRENCY;
const _selectAll = "Select all";
const _deselectAll = "Deselect all";
var initialDefinition = [];
var definition = [];
var mandatorySectionFieldsVar = [];
var mandatorySectionFields_selectedVar = [];
var mandatorySectionFields_uneditableVar = [];
var dataTypesFieldsVar = [];
var currSectionPeriodInfo = {
    [P_AND_L]: {},
    [GENERAL_LEDGER]: {},
    [TRANSACTION_DATA]: {},
    [ANCILLARY_FILES]: {},
    [MASTER_DATA]: {},
    [CROSSWALK]: {},
    [CURRENCY]:{}
};
var currentDataTypesValues = [];
var currentDataType = Formats.String; //string as default
var currentRawFileId = "";
var cell = {};
var errorMessage = "";
var isTrailingorLeading = "Leading Characters";  //default
var boolMultiValue = [];
var boolValues = [];
const GoToDefine = "Go To Define";
const baseUrl = process.env.REACT_APP_BASE_URL;
const path = API_URL.DATA_MODELING;
const SETTINGS = "Settings";
const PERIOD_ID = "data-period_id";
const DEFAULT_DATA_VALUE = "Other";
const IS_PRIMARY = "is_primary"

class UploadData extends Component {
    constructor(props) {
        super(props);
        
		this.tableReferences = {
            [P_AND_L]: React.createRef(),
            [GENERAL_LEDGER]: React.createRef(),
            [TRANSACTION_DATA]: React.createRef(),
            [ANCILLARY_FILES]: React.createRef(),
            [MASTER_DATA]: React.createRef(),
            [DEFINE_DATA]: React.createRef(),
            [DATA_SUMMARY]: React.createRef(),
            [CURRENCY]: React.createRef(),
            [CROSSWALK]: React.createRef()
        }

		this.state = {
            getRawFileListReqSent: false,
            uploadedFilesFromBQ: [],
            filesToReplace: [],
            replacingFile: {},
            disableNew: true,
            disableAppend: false,
            disableReplace: false,
            multiValue: [],
            uploadResponseColumns: [{
                Header: "",
                accessor: ""
            }],
            validationResponse: [],
            uploadResponseStatus: "",
            uploadResponseHeaders: [],
            uploadResponseCount: "",
            uploadResponseError: "",
            definedFileName: "",
            definedTableType: "",
            uploadedFileSubtype: [],
            definedFileSubtype: [],
            selectedSection: "",
            selectedSubSection: "",
            selectedFile:{},
            defineAsReadOnly: false,
            uploadValidations: [],
            dataTypesFields: [],
            currentDataTypes: [],
            currentDataType: "",
            dateFormat: "",
            errorMessage: "",
            rowData: [],
            charactersOptions: [{"label":TRAILING_CHARACTERS,"value":TRAILING_CHARACTERS},{"label":LEADING_CHARACTERS, "value":LEADING_CHARACTERS}],
            isSectionExpanded: {
                [P_AND_L]: true,
                [GENERAL_LEDGER]: true,
                [TRANSACTION_DATA]: true,
                [ANCILLARY_FILES]: true,
                [MASTER_DATA]: true,
                [DEFINE_DATA]: true,
                [CROSSWALK]: true,
                [CURRENCY]: true,
            },
            clientPeriodsWithUploadedFiles: [],
            clientPeriods: [],
            pAndLData: [{
                name: 'Revenue',
                expected_value: "",
                confirmed_value: "",
            },{
                name: 'COGS',
                expected_value: "",
                confirmed_value: "",
            },{
                name: 'Profit',
                expected_value: "",
                confirmed_value: "",
            }],
            definedPandL : "false",
            fileClick: false,
            selectedPeriodInfo: { 
                [P_AND_L]: {},
                [GENERAL_LEDGER]: {},
                [TRANSACTION_DATA]: {},
                [ANCILLARY_FILES]: {},
                [MASTER_DATA]: {},
                [CROSSWALK]: {},
                [CURRENCY]: {}
            },
            selectedSectionPeriodInfo: { 
                [P_AND_L]: {},
                [GENERAL_LEDGER]: {},
                [TRANSACTION_DATA]: {},
                [ANCILLARY_FILES]: {},
                [MASTER_DATA]: {},
                [CROSSWALK]: {},
                [CURRENCY]: {}

            },
            selectedPeriod: "",
            afterDefineError: "",
            afterDefineMessage: MESSAGES.UPLOAD.define_successful,
            defaultDeleteMessage: MESSAGES.UPLOAD.selected_files_will_be_deleted,
            defaultReplaceMessage: MESSAGES.UPLOAD.file_exists_under_period,
            deleteReplaceCallback: this.deleteFiles,
            clickPosition: UPLOAD_DATA,
            fileResponseColumns: null,
            fileResponseHeaders: null,
            fileResponse: null,
            boolMultiValue: [],
            boolValues: [],
            transactionKey: "",
            encryption:"yes",
            fileIds: [],
            groupValues: [],
            clicked: false,
            isCCStateDefined: undefined,
            defaultSelectionName:_selectAll,
            crosswalkColumns: {
                id: shortid.generate(),
                columns: [],
                uniqueColumns: []
            }
        };
        this.fileInfoRef = React.createRef();

        this.fetchAPI = fetchAPI.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.getClientSetting = getClientSetting.bind(this);
        this.getRawFileDefinitionList = this.getRawFileDefinitionList.bind(this);
        this.validateUploadedFile = this.validateUploadedFile.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.updateSelectedAmendments = this.updateSelectedAmendments.bind(this);
        this.resetUploadPopupModal = this.resetUploadPopupModal.bind(this);
        this.handleMultiChange = this.handleMultiChange.bind(this);
        this.setDefineFileColumnNames = this.setDefineFileColumnNames.bind(this);
        this.getColumns =  this.getColumns.bind(this);
        this.defineFile = this.defineFile.bind(this);
        this.setFileHeader = this.setFileHeader.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.handleRowDataChange = this.handleRowDataChange.bind(this);
        this.updateUploadStatusResponse = this.updateUploadStatusResponse.bind(this);
        this.fillValidations = this.fillValidations.bind(this);
        this.handleSubSectionChange = this.handleSubSectionChange.bind(this);
        this.setSelectedSubSection = this.setSelectedSubSection.bind(this);
        this.getHistoryData = this.getHistoryData.bind(this);
        this.deleteFiles = this.deleteFiles.bind(this);
        this.showUploadPopup = this.showUploadPopup.bind(this);
        this.showDeleteFilesPopup = this.showDeleteFilesPopup.bind(this);
        this.prepareRawFileDefinition = this.prepareRawFileDefinition.bind(this);
        this.toggleSection = this.toggleSection.bind(this);
        this.onprogressHandler = this.onprogressHandler.bind(this);
        this.setChosenPeriod = this.setChosenPeriod.bind(this);
        this.setUploadStartPeriod = this.setUploadStartPeriod.bind(this);
        this.setUploadEndPeriod = this.setUploadEndPeriod.bind(this);
        this.confirmExpectedValues = this.confirmExpectedValues.bind(this);
        this.saveRawFileTieOff = this.saveRawFileTieOff.bind(this);
        this.getClientPeriodTieOff = this.getClientPeriodTieOff.bind(this); 
        this.setFormatsElements = this.setFormatsElements.bind(this);
        this.getDataRowDataType = this.getDataRowDataType.bind(this);
        this.applyCellFormats = this.applyCellFormats.bind(this);
        this.handleChangeIsTrailing = this.handleChangeIsTrailing.bind(this);
        this.validateFormatsPopUP = this.validateFormatsPopUP.bind(this);
        this.directToGoogleHelp = this.directToGoogleHelp.bind(this);
        this.resetDefineFileModal = this.resetDefineFileModal.bind(this);
        this.validateDefinedFile = this.validateDefinedFile.bind(this);
        this.validateDefinedFileNewArch = this.validateDefinedFileNewArch.bind(this);
        this.getPassedExpectedValues = this.getPassedExpectedValues.bind(this);
        this.fillTransitionCombinations = this.fillTransitionCombinations.bind(this);
        this.prepareHistoryPopup = this.prepareHistoryPopup.bind(this);
        this.handleChangeBoolean = this.handleChangeBoolean.bind(this);
        this.setBoolValues = this.setBoolValues.bind(this);
        this.logout = logout.bind(this);
        this.getTransactionColumns = this.getTransactionColumns.bind(this);
        this.handleTransactionColumnChange = this.handleTransactionColumnChange.bind(this);
        this.checkEncryptionProgress = this.checkEncryptionProgress.bind(this);
        this.launchToast = this.launchToast.bind(this);
        this.changeText = this.changeText.bind(this);
        this.redirectToFileCensus = this.redirectToFileCensus.bind(this);
        this.handleEncryptionChange = this.handleEncryptionChange.bind(this);
        this.getSectionStatus = this.getSectionStatus.bind(this);
        this.fileContainerChangeFile = this.fileContainerChangeFile.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.dragOver = this.dragOver.bind(this);
        this.leaveDrop = this.leaveDrop.bind(this);
        this.runMatchingKeysAudit = this.runMatchingKeysAudit.bind(this);
        this.setValue = this.setValue.bind(this);
        this.resetBoolValues = this.resetBoolValues.bind(this);
        this.confirmReplacement = this.confirmReplacement.bind(this);
        this.openUserDownloadWindow = this.openUserDownloadWindow.bind(this);
        this.setPage = this.setPage.bind(this);
        this.setClicked = this.setClicked.bind(this);
        this.getAllFileTypeColumns = this.getAllFileTypeColumns.bind(this);
        this.handleMappingFileChange = this.handleMappingFileChange.bind(this);
        this.getIsCostCenterStateDefined = this.getIsCostCenterStateDefined.bind(this);
        this.validateCrosswalkFile = this.validateCrosswalkFile.bind(this);
        this.defineCrosswalkFile = this.defineCrosswalkFile.bind(this);
        this.fetchCrosswalkLinks = this.fetchCrosswalkLinks.bind(this);
        this.showMatchingEntityModal = this.showMatchingEntityModal.bind(this);
        this.setMatchingEntityData = this.setMatchingEntityData.bind(this);
        this.periodsIntersect = this.periodsIntersect.bind(this);
        this.checkForIntersection = this.checkForIntersection.bind(this);
        this.configure = this.configure.bind(this);
        this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
        // this.validateCurrencyPeriodDate = this.validateCurrencyPeriodDate.bind(this);
        this.clearConfiguration = this.clearConfiguration.bind(this);
        this.initiateFTPUpload = this.initiateFTPUpload.bind(this);
    }

    directToGoogleHelp() {
        window.open("https://cloud.google.com/bigquery/docs/reference/standard-sql/timestamp_functions#supported_format_elements_for_timestamp");
    }

    redirectToFileCensus(){
        window.history.pushState({prevUrl: window.location.href},null,"/"+SETTINGS +"/");
        window.location.reload()
    }

    handleChangeIsTrailing(e) {
        if (e !== null) {
            isTrailingorLeading = e.value;
            $('input[name="CharacterType"]').attr("value", e.value);
            $('input[name="CharacterType"]').parent().find(".Select-value-label").text(e.value)
        }
    }

    handleChangeBoolean(option) {
        // if (option !== null) {
            if (this.state.boolMultiValue) {
                if (this.state.boolMultiValue[0].label === MESSAGES.no_values_are_true && option !== null) {
                    return;
                }
            }
            if( option){
                for (var e in option) {
                    if(option[e].value === MESSAGES.no_values_are_true && this.state.boolMultiValue && this.state.boolMultiValue !== null && this.state.boolMultiValue.length > 0) {
                        return;
                    }
                }
            }
            this.setState({
                boolMultiValue: option
            })
        // }
    }

    setFormatsElements(thisCell) {
        if (!this.state.defineAsReadOnly) {
            var rowData = thisCell.getRow().getData();
            this.getColumnBooleanValues(rowData, this);
            currentDataTypesValues = rowData;
            currentDataType = rowData[DEFINE_FIELDS.DATA_TYPE_FIELD];
            currentRawFileId = rowData[DEFINE_FIELDS.RAW_FILE_FIELD_ID_FIELD];
            cell = thisCell;
            isTrailingorLeading = rowData[DEFINE_FIELDS.IS_TRAILING_FIELD] === "false" ? "Leading Characters" : "Trailing Characters";
            errorMessage = "";

            window._profitIsleOpenModal('formatModal');
            // hiding all format divs, and showing only one
            $(".datatype-format").attr("hidden", "hidden");
            $(".datatype-format." + currentDataType).removeAttr("hidden");

            $("#maxLength").val(rowData[DEFINE_FIELDS.MAX_LENGTH_FIELD]);
            $("#minLength").val(rowData[DEFINE_FIELDS.MIN_LENGTH_FIELD]);
            $("#trailOrLead").val(rowData[DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD]);
            $("#max").val(rowData[DEFINE_FIELDS.MAX_FIELD]);
            $("#min").val(rowData[DEFINE_FIELDS.MIN_FIELD]);
            $("#decimal").val(rowData[DEFINE_FIELDS.DECIMAL_FIELD]);
            $("#dateFormat").val(rowData[DEFINE_FIELDS.DATE_FORMAT_FIELD] === "" ? DEFAULT_DATE_FORMAT_UPLOAD : rowData[DEFINE_FIELDS.DATE_FORMAT_FIELD]);
            // $("#dateFormat").val(AllcurrentDataTypesValues[DEFINE_FIELDS.DATE_FORMAT_FIELD] === "" ? DEFAULT_DATE_FORMAT_UPLOAD : AllcurrentDataTypesValues[boolMultiValue]);
            $("#trueInput").val(rowData[DEFINE_FIELDS.TRUE_INPUT_FIELD]); 
            $("#falseInput").val(rowData[DEFINE_FIELDS.FALSE_INPUT_FIELD]);
            
            var trueInputLabel = thisCell.getData()[DEFINE_FIELDS.DATA_FIELD] === DEFINE_CREDIT_DEBIT_ENTRY_TYPE.ENTRY_TYPE ? DEFINE_CREDIT_DEBIT_ENTRY_TYPE.CREDIT : "True";
            var falseInputLabel = thisCell.getData()[DEFINE_FIELDS.DATA_FIELD] === DEFINE_CREDIT_DEBIT_ENTRY_TYPE.ENTRY_TYPE ? DEFINE_CREDIT_DEBIT_ENTRY_TYPE.DEBIT : "False";
            $("#trueInputLabel").text(trueInputLabel);
            $("#falseInputLabel").text(falseInputLabel);
            var arrT = [];
            var arrF = [];
            var trueVals = rowData[DEFINE_FIELDS.TRUE_INPUT_FIELD] !== "" ? rowData[DEFINE_FIELDS.TRUE_INPUT_FIELD].split(",") : [];
            var falseVals = rowData[DEFINE_FIELDS.FALSE_INPUT_FIELD] !== "" ? rowData[DEFINE_FIELDS.FALSE_INPUT_FIELD].split(",") : [];
            if (trueVals.length > 0) {
                for (var e in falseVals) {
                    arrF.push({label:falseVals[e], value:falseVals[e]});
                }
                for (var e in trueVals) {
                    arrT.push({label:trueVals[e], value:trueVals[e]});
                }
                
                var finalOptions = ([{label:MESSAGES.no_values_are_true, value:MESSAGES.no_values_are_true}]).concat(arrT).concat(arrF)
                this.setState({
                    boolMultiValue: arrT,
                    boolValues: finalOptions
                },function(){
                    this.tableReferences[DEFINE_DATA].current.tabulator.scrollToRow(thisCell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]);
                })
            }  else if (trueVals.length === 0 && falseVals.length > 0) {
              
                arrT.push({label:MESSAGES.no_values_are_true, value:MESSAGES.no_values_are_true});
                
                for (var e in falseVals) {
                    arrF.push({label:falseVals[e], value:falseVals[e]});
                }
                var finalOptions = ([{label:MESSAGES.no_values_are_true, value:MESSAGES.no_values_are_true}])
                this.setState({
                    boolMultiValue: arrT,
                    boolValues: finalOptions
                },function(){
                    this.tableReferences[DEFINE_DATA].current.tabulator.scrollToRow(thisCell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]);
                })
            }
           
        }  
    }

    validateFormatsPopUP(format) {
        var text = "";
        $("#trueInput").removeClass("emptyField");
        $("#falseInput").removeClass("emptyField");
        $("#max").removeClass("emptyField");
        $("#min").removeClass("emptyField");
        $("#maxLength").removeClass("emptyField");
        $("#minLength").removeClass("emptyField");
        $('#trailOrLead').removeClass("emptyField");
        $("#decimal").removeClass("emptyField");
        $('#dateFormat').removeClass("emptyField");

        switch (format) {
            case Formats.String:
                if (Number($("#maxLength").val()) < 0 || Number($("#minLength").val()) < 0) {
                    text+= "- Make sure max length and min length are greater than or equal zero./";
                    $("#maxLength").addClass("emptyField");
                    $("#minLength").addClass("emptyField");
                }
                if (Number($("#maxLength").val()) < Number($("#minLength").val())) {
                    text+= "- Make sure max length is greater than min length./";
                    $("#maxLength").addClass("emptyField");
                    $("#minLength").addClass("emptyField");
                }
                if ($('#trailOrLead').val() !== "") {
                    var regex = new RegExp("^[a-zA-Z0-9]+$");
                    if (!$('#trailOrLead').val().match(regex)) {
                        text+= "- Make sure trail or lead character is alphanumeric./";
                        $('#trailOrLead').addClass("emptyField"); 
                    }
                }
                if (($("#maxLength").val()).toString().split(".").length > 1 ) {
                    text+= "- Make sure max length is integer./";
                    $("#maxLength").addClass("emptyField");
                }
                if (($("#minLength").val()).toString().split(".").length > 1) {
                    text+= "- Make sure min length is integer./";
                    $("#minLength").addClass("emptyField");
                } 
            break;

            case Formats.Numeric:
                    if (Number($("#max").val()) < Number($("#min").val())) {
                        text+= "- Make sure max is greater than min ./";
                        $("#min").addClass("emptyField");
                        $("#max").addClass("emptyField");
                    }
                    if ($("#decimal").val() < 0) {
                        text+= "- Make sure decimal value is greater or equal to zero./";
                        $("#decimal").addClass("emptyField");
                    }
                    if (($("#max").val()).toString().split(".").length > 1) {
                        if ($("#max").val().toString().split(".")[1].length >  $("#decimal").val()) {
                            text+= "- Make sure decimals in max field are equal or less than the decimal value./";
                            $("#max").addClass("emptyField");
                        }
                    }
                    if ($("#min").val().toString().split(".").length > 1) {
                        if ($("#min").val().toString().split(".")[1].length >  $("#decimal").val()) {
                            text+= "- Make sure decimals in min field are equal or less than the decimal value./";
                            $("#min").addClass("emptyField");
                        }
                    }
                break;

            case Formats.Boolean:
                if (this.state.boolMultiValue.length === 0) {
                    text+= "- Make sure to fill true inputs./";
                }
            break;
         }
        if (text.length > 0) {
            errorMessage = text;
            // this.setState({
            //     errorMessage: text
            // })
            
            return false;
        }
        window._profitIsleCloseModal('formatModal');
        return true;
    }

    getColumnBooleanValues(rowData,obj) {
        // toggleLoader(true, 'getColumnBooleanValues');

        var column = rowData[DEFINE_FIELDS.FIELD_NAME_FIELD];
        var query = {
            action: "getColumnBoolValues",
            fieldName: column,
            fileName: obj.props.subsection && obj.props.subsection !== "" ? obj.props.subsection : obj.state.selectedSubSection,
            section: obj.props.section ? obj.props.section :obj.state.selectedSection ,
            fileNumber :obj.props.definedFileNumber ? obj.props.definedFileNumber: obj.state.definedFileNumber
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{    
                if(response.status === 403) {
                    this.logout();                  
                }
                return response.json()})
            .then((data)=>{
                if(data && data.data){
                    var multiValues = [];
                    multiValues.push({label:MESSAGES.no_values_are_true, value: MESSAGES.no_values_are_true})
                    for (var e in data.data) {
                        multiValues.push({label:data.data[e].column, value:data.data[e].column});
                    }
                    if (obj.props.section) {
                        obj.props.setBoolValues(multiValues, rowData);
                    } else {
                        obj.setBoolValues(multiValues, rowData);
                    }
                }
                // toggleLoader(false, 'getColumnBooleanValues');
            }).catch((error)=>{
                alertAndLogError(error);
                // toggleLoader(false, 'getColumnBooleanValues');
            });
        }
    

    setBoolValues(options, rowData) {
        this.setState({
            boolValues: options,
        },function(){
            this.tableReferences[DEFINE_DATA].current.tabulator.scrollToRow(rowData[DEFINE_FIELDS.ROW_NUMBER_FIELD]);
        })
        boolValues = options
    }

    applyCellFormats(format) {
        var obj = this;
        var table = this.tableReferences[DEFINE_DATA].current.tabulator;

        if(obj.validateFormatsPopUP(format)) {
            var definedData = definition;
            switch (format) {
                case Formats.String:
                    definedData[cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]][DEFINE_FIELDS.MAX_LENGTH_FIELD] = $("#maxLength").val();
                    definedData[cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]][DEFINE_FIELDS.MIN_LENGTH_FIELD] = $("#minLength").val();
                    definedData[cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]][DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD] = $("#trailOrLead").val();
                    definedData[cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]][DEFINE_FIELDS.IS_TRAILING_FIELD] = isTrailingorLeading === "Trailing Characters" ? "true" : "false";
                break;
    
                case Formats.Numeric:
                    definedData[cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]][DEFINE_FIELDS.MAX_FIELD] = $("#max").val().charAt(0) === "." ? "0"+ $("#max").val() :  $("#max").val() ;
                    definedData[cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]][DEFINE_FIELDS.MIN_FIELD] = $("#min").val().charAt(0) === "." ? "0"+ $("#min").val() : $("#min").val() ;
                    definedData[cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]][DEFINE_FIELDS.DECIMAL_FIELD] = $("#decimal").val();
                break;
                case Formats.DateTime:
                    definedData[cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]][DEFINE_FIELDS.DATE_FORMAT_FIELD] = $("#dateFormat").val();
                break; 
                case Formats.Boolean:
                        // definedData[cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]][DEFINE_FIELDS.TRUE_INPUT_FIELD] = $("#trueInput").val();
                        // definedData[cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]][DEFINE_FIELDS.FALSE_INPUT_FIELD] = $("#falseInput").val();
                    var trueVals = [];
                    var falseVals = []
                    obj.state.boolValues.map(function(item){
                        if (obj.state.boolMultiValue.includes(item) && item["label"]!== MESSAGES.no_values_are_true) {
                            trueVals.push(item);
                        } else if (item["label"]!== MESSAGES.no_values_are_true) {
                            falseVals.push(item);
                        }
                    });
                    definedData[cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]][DEFINE_FIELDS.TRUE_INPUT_FIELD] = trueVals.map(function(elem){return elem.value;}).join(",");
                    definedData[cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]][DEFINE_FIELDS.FALSE_INPUT_FIELD] = falseVals.map(function(elem){return elem.value;}).join(",");
                    
                        obj.setState({
                        boolMultiValue: "",
                        boolValues: []
                    },function(){
                       obj.forceUpdate();
                        this.tableReferences[DEFINE_DATA].current.tabulator.scrollToRow(cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]);
                    })
                    break; 
   
            }
            errorMessage = "";

            table.replaceData(definedData);
            table.redraw();

            $("#trueInput").removeClass("emptyField");
            $("#falseInput").removeClass("emptyField");
            $("#max").removeClass("emptyField");
            $("#min").removeClass("emptyField");
            $("#maxLength").removeClass("emptyField");
            $("#minLength").removeClass("emptyField");
            $('#trailOrLead').removeClass("emptyField");
            $('#dateFormat').removeClass("emptyField");
        }

		//replacing the table data after the user changes anything
		this.compareDefinition(JSON.stringify(initialDefinition), JSON.stringify(table.getData()));
    }

    getClientPeriodTieOff(fileClick) {
        toggleLoader(true, 'getClientPeriodTieOff');
        this.setState({
            defineAsReadOnly: fileClick
        });

        var query = {
            action: "getClientPeriodTieOff",
            periodId: this.getPeriodId(this.state.selectedPeriod)
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{    
                if(response.status === 403) {
                    this.logout();                  
                }
                return response.json()})
            .then((data)=>{
                let tempStateObj = {
                    filledPandL: "false",
                    fileClick: fileClick
                }

                if(data.data.length !== 0) {
                    tempStateObj.pAndLData = data.data;
                    tempStateObj.filledPandL = "true";
                }

                this.setState(tempStateObj);

                window._profitIsleOpenModal('definePLFileModal');
                toggleLoader(false, 'getClientPeriodTieOff');
            }).catch((error)=>{
                alertAndLogError(error);
                toggleLoader(false, 'getClientPeriodTieOff');
            });

    }

    getRawFileList(callback) {
        let _this = this;
        this.state.getRawFileListReqSent = true;
       
        var query = {
            action: "getRawFileList"
        }

        let onThenCallback = (data) => {
            if(data && data.data){
                _this.fillAllData(data);
                if (callback) {
                    callback();
                }
            }
        } 
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getRawFileList",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: !callback ? true : false,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.upload_data.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.upload_data.requests_description.get_raw_file_list
        };
        fetchAPI(fetchOptions);

    }

    getSectionUploadedFiles(section, subSection, startPeriod, endPeriod) {
        var uploadedFilesFromBQ = [];
        var filesToReplace = [];
        this.state.rawFileListData.data.map(function(item) {
            if(DATA_DICTIONARY_ENUM[item[UPLOAD_FIELDS.UPLOADED_STATUS_FIELD]] !== DATA_DICTIONARY_ENUM.passed)
                return;
            if(section !== item[UPLOAD_FIELDS.FILE_TYPE])
                return;
            if(subSection !== undefined && subSection !== "" && subSection !== item[UPLOAD_FIELDS.FILE_SUBTYPE])
                return;

            if (item[UPLOAD_FIELDS.START_PERIOD] === startPeriod && item[UPLOAD_FIELDS.END_PERIOD] === endPeriod || (section === CROSSWALK && item[UPLOAD_FIELDS.FILE_TYPE] === CROSSWALK)) {
                filesToReplace.push({ value: item[UPLOAD_FIELDS.FILE_NUMBER_FIELD], label: item[UPLOAD_FIELDS.FILE_NAME_FIELD] + (item[UPLOAD_FIELDS.ALL_PERIODS] ? " - " + item[UPLOAD_FIELDS.ALL_PERIODS] : "") });
            }
            uploadedFilesFromBQ.push({ value: item[UPLOAD_FIELDS.FILE_NUMBER_FIELD], label: item[UPLOAD_FIELDS.FILE_NAME_FIELD] + (item[UPLOAD_FIELDS.ALL_PERIODS] ? " - " + item[UPLOAD_FIELDS.ALL_PERIODS] : "") });

        });

        if(uploadedFilesFromBQ.length){
            this.setState({
                disableNew: true,
                disableAppend: false,
                disableReplace: false,
                uploadedFilesFromBQ: uploadedFilesFromBQ,
                filesToReplace: filesToReplace
            });
            $("input[name=amendments][value='" + Amendments.Append + "']").prop('checked', true);
        } else{
            this.setState({
                disableNew: false,
                disableAppend: true,
                disableReplace: true,
                uploadedFilesFromBQ: []
            });
            $("input[name=amendments][value='" + Amendments.New + "']").prop('checked', true);
        }
    }

    getPeriodId(periodName) {
        let periodId = -1;
        this.state.clientPeriods.forEach(function(item) {
            if(item["value"] === periodName) {
                periodId = item["data-period_id"];
                return false;
            }
        });

        return periodId;
    }

    fillAllData(data) {
        var uploadedFileSubtype = {[ANCILLARY_FILES]: [], [MASTER_DATA]: []};
        var definedFileSubtype = {[ANCILLARY_FILES]: [], [MASTER_DATA]: []};
        var groupValues = {[ANCILLARY_FILES]: [], [MASTER_DATA]: []};
        let clientPeriodsMig = [];
        data.sections.forEach(function(row){
            if(!uploadedFileSubtype.hasOwnProperty(row["file_type"])) {
                uploadedFileSubtype[row["file_type"]] = [];
                definedFileSubtype[row["file_type"]] = [];
                groupValues[row["file_type"]] = [];
            }

            uploadedFileSubtype[row["file_type"]].push({ value: row["file_subtype"], label: row["display_file_subtype"], private: row["is_private"] });
            definedFileSubtype[row["file_type"]].push({ value: row["file_subtype"], label: row["display_file_subtype"] , id:row["raw_file_subtype_id"]});
            groupValues[row["file_type"]].push(row["display_file_subtype"]);
        });

        var selectedPeriodOriginal = this.state.selectedPeriod;
        var selectedPeriod = this.state.selectedPeriod;
        var selectedPeriodId = 0;
        var clientPeriodsWithUploadedFiles = [];
        var clientPeriods = [];
        data.periods.forEach(function(row){
            clientPeriodsMig.push({"period_name": row["period_name"],"client_period_id": row["client_period_id"],"quarter":row["quarter"], start_date:row["start_date"], end_date :row["end_date"]});
            if(row["count"] > 0 && row[FISCAL_YEAR.FIELDS.ACTIVE_STATUS] === FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE) {
                clientPeriodsWithUploadedFiles.push({ value: row["period_name"], label: row["period_name"], "data-period_id": row["client_period_id"], start_date:row["start_date"], end_date :row["end_date"] });
            }
            if (row[FISCAL_YEAR.FIELDS.ACTIVE_STATUS] === FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE) {
                clientPeriods.push({ value: row["period_name"], label: row["period_name"], "data-period_id": row["client_period_id"], start_date: row["start_date"] ,end_date: row["end_date"]});
            }
            if(row["latest"] > 0 && !selectedPeriod && row[FISCAL_YEAR.FIELDS.ACTIVE_STATUS] === FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE) {
                selectedPeriod = row["period_name"];
                selectedPeriodId = row["client_period_id"];
            }
        });
        if(!selectedPeriod) {
            selectedPeriod = data.periods[0]["period_name"];
            selectedPeriodId = data.periods[0]["client_period_id"];
        }
        if (!selectedPeriodId && selectedPeriod) {
            selectedPeriodId = data.periods.filter(e=>e["period_name"] ===  selectedPeriod)[0]["client_period_id"];
        }
        var currSectionInfo = data.sectionInfo[selectedPeriodId] ? data.sectionInfo[selectedPeriodId] : this.state.selectedPeriodInfo;       
        currSectionPeriodInfo = this.getPassedExpectedValues(currSectionInfo);
        currSectionInfo = this.getSectionStatus(currSectionInfo);
        this.setState({
            uploadedFileSubtype: uploadedFileSubtype,
            definedFileSubtype: definedFileSubtype,
            clientPeriodsWithUploadedFiles: clientPeriodsWithUploadedFiles,
            clientPeriods: clientPeriods,
            clientPeriodsMig: clientPeriodsMig,
            rawFileListData: data,
            selectedPeriod: selectedPeriod,
            selectedPeriodObj:{value:selectedPeriod , label: selectedPeriod, "data-period_id":selectedPeriodId},
            selectedPeriodInfo: currSectionInfo,
            selectedSectionPeriodInfo: currSectionPeriodInfo,
            infoMessages: data.infoMessages,
            groupValues: groupValues,
            allFileTypes: data.sections,
            sectionInfo: data.sectionInfo
        }, function() {
            if (!selectedPeriodOriginal || selectedPeriodOriginal === "") { // first time component will mount
                var option = {value:selectedPeriod, label:selectedPeriod, "data-period_id":selectedPeriodId};
                this.setChosenPeriod(option, true)
            }else {
                this.divideDataOverTypes(this.state.rawFileListData.data);
            }
            if(this.props.setChosenPeriod) this.props.setChosenPeriod(option);
            if(this.props.setPeriodOptions) this.props.setPeriodOptions(clientPeriodsWithUploadedFiles);
        });
    }

    getPassedExpectedValues(currSectionInfo) {
        for (var sec in currSectionPeriodInfo) {
            //resetting all sections
            currSectionPeriodInfo[sec]['expected'] = 0;
            currSectionPeriodInfo[sec]['received'] = 0;
            currSectionPeriodInfo[sec]['passed'] = 0;
            currSectionPeriodInfo[sec]['failed'] = 0;
        }

        for (var section in currSectionInfo) {
            for (var subsection in currSectionInfo[section]) {
                var currSubInfo = currSectionInfo[section][subsection];
                if(currSubInfo && currSectionPeriodInfo[section]) {
                    //readding the numbers of all subsections under each section
                    currSectionPeriodInfo[section].expected += currSubInfo.expected;
                    currSectionPeriodInfo[section].received += currSubInfo.received;
                    currSectionPeriodInfo[section].passed += currSubInfo.passed > currSubInfo.expected ? currSubInfo.expected : currSubInfo.passed ;
                    currSectionPeriodInfo[section].failed += currSubInfo.failed;
                }
            }
        }

        return currSectionPeriodInfo;
    }

    getSectionStatus(currSectionInfo) {
        if (!!currSectionInfo) {
            Object.keys(currSectionInfo).forEach(function eachKey(key) { 
                Object.keys(currSectionInfo[key]).forEach(function eachKey(key2) { 
                    if(currSectionInfo[key][key2]['passed'] >= currSectionInfo[key][key2]['expected']) {
                        currSectionInfo[key][key2]['status'] = "true";
                    }else {
                        currSectionInfo[key][key2]['status'] = "false";
                    }
                });  
            }); 
            return currSectionInfo; 
        }   
    }

    divideDataOverTypes(data) {
        var comp = this;

        //fetching current period object
        var currentPeriod = comp.state.rawFileListData.periods.find(function(el) { return el.period_name === comp.state.selectedPeriod });

        //getting all the files that have their start and end dates respectively smaller and greater than the chosen period's start and end periods
        var filteredData = data.filter(function(row) {
            return (new Date(currentPeriod.start_date_var) >= new Date(row.start_date) && new Date(currentPeriod.start_date_var) <= new Date(row.end_date)) 
            || (new Date(currentPeriod.end_date_var) >= new Date(row.start_date) && new Date(currentPeriod.end_date_var) <= new Date(row.end_date))
            || row.file_type === UPLOAD_SECTIONS.FIELDS.CROSSWALK;
        });

        //initialising sectionsData array and filling it with the filtered files
        var sectionsData = { [GENERAL_LEDGER]: [], [TRANSACTION_DATA]: [], [ANCILLARY_FILES]: [], [MASTER_DATA]: [], [P_AND_L]: [], [CROSSWALK]: [], [CURRENCY]:[] };
        for(var row in filteredData) {
            row = filteredData[row];
            if(!sectionsData.hasOwnProperty(row["file_type"])){
                sectionsData[row["file_type"]] = [];
            }
            sectionsData[row["file_type"]].push(row);
        }

        //checking if P & L has at least one not_defined file, used later to allow define or not
        var atLeastOnePassedFileForPandL = false;
        for(var current in sectionsData[P_AND_L]) {
            if (STATUS_ENUM[sectionsData[P_AND_L][current].status] === STATUS_ENUM.not_defined) {
                atLeastOnePassedFileForPandL = true;
                break;
            } 
        }

        comp.setState({
            sectionsData:sectionsData,
            generalLedgerData: sectionsData[GENERAL_LEDGER],
            transactionDataData: sectionsData[TRANSACTION_DATA],
            ancillaryFilesData: sectionsData[ANCILLARY_FILES],
            masterDataData: sectionsData[MASTER_DATA],
            currencyData: sectionsData[CURRENCY],
            pandlData: sectionsData[P_AND_L],
            cwData: sectionsData[CROSSWALK],
            atLeastOnePassedFileForPandL: atLeastOnePassedFileForPandL
        },function(){
            comp.forceUpdate(); // re-render after the data is filled
        });
    }

    checkValidUndefinedFiles(section, subSection) {
        var hasValidUndefinedFiles = false;
        var undefinedPeriods = [];
        var obj = this;
        var res ={};
        var query = {
            action: "checkValidUndefinedFiles",
            section: section,
            subSection: subSection,
            startPeriod: this.state.selectedUploadStartPeriod,
            endPeriod: this.state.selectedUploadEndPeriod
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      $.ajax({
            url: baseUrl+path, 
            async: false, 
            type: 'POST', 
            crossDomain:true, 
            xhrFields: { withCredentials: true }, 
            data:JSON.stringify(query),
            success:function(result, response, xhr){
                result = JSON.parse(result);
                res = {undefinedPeriods: result.undefinedPeriods, hasValidUndefinedFiles: result.hasValidUndefinedFiles};
            },
            error: function(error) {
                alertAndLogError(error);
            },
            complete: function(xhr, textStatus) {
                logoutIfUnauthenticated(xhr, obj);
            }
        });

        return res;
    }

    showUploadPopup(evt, section) {
        this.resetUploadPopupModal();
        var dataType = section;
        var displayType = capitaliseFirstLetterAfterChar(section).replaceAll("_", " ");

        // if([TRANSACTION_DATA, GENERAL_LEDGER].indexOf(section) !== -1) {
        //     var hasUndefinedUploadedFiles = this.checkValidUndefinedFiles(section, "");
        //     if(hasUndefinedUploadedFiles) {
        //         Popup.alert("Please define your last uploaded file before uploading a new one.");
        //         return;
        //     }
        // }
        
        toggleLoader(true, 'showUploadPopup');
        this.setState({
            selectedSection: section.toLowerCase(),
            selectedSubSection: "",
            selectedDataTypeDisplay: displayType,
            clickPosition: UPLOAD_DATA
        }, function(){ // case when default subsection should appear in drop down
            this.state.selectedSubSection = this.state.selectedSubSection === "" && this.state.uploadedFileSubtype[this.state.selectedSection] && this.state.uploadedFileSubtype[this.state.selectedSection].length === 1 ? this.state.uploadedFileSubtype[this.state.selectedSection][0].value : this.state.selectedSubSection;
            if (this.state.selectedSubSection) {
                var option = {value:this.state.uploadedFileSubtype[this.state.selectedSection][0].value};
                this.handleSubSectionChange(option, 1);
            }
        });
        
        toggleLoader(false, 'showUploadPopup');
        window._profitIsleOpenModal('uploadPopupModal');
    }

    showDeleteFilesPopup(subSection, section, file) {
       
        if($("#"+subSection).hasClass("disabled")) return; //do not proceed if the svg is disabled

        var fileDetails = [];
        var comp = this;
        
        if(!!file) {
            fileDetails.push(file);
        } else {
            $('#'+section.replace("\&","\\&").replace("&", "\\&").toLowerCase()).find('input:checkbox[id='+subSection+']:checked').closest(".tabulator-row").each(function(index, el) {
                var fileDetail = {};
                fileDetail[UPLOAD_FIELDS.RAW_FILE_ID_FIELD] = $(el).find("[tabulator-field="+ UPLOAD_FIELDS.RAW_FILE_ID_FIELD +"]").attr('title');
                fileDetail[UPLOAD_FIELDS.UPLOADED_STATUS_FIELD] = $(el).find("[tabulator-field="+ UPLOAD_FIELDS.UPLOADED_STATUS_FIELD +"]").attr('title');
                fileDetail[UPLOAD_FIELDS.FILE_NAME_FIELD] = $(el).find("[tabulator-field="+ UPLOAD_FIELDS.FILE_NAME_FIELD +"]").attr('title');
                fileDetail[UPLOAD_FIELDS.START_PERIOD]  =  comp.state.sectionsData[section].filter(e => e.raw_file_id === Number(fileDetail[UPLOAD_FIELDS.RAW_FILE_ID_FIELD]))[0][UPLOAD_FIELDS.START_PERIOD];
                fileDetail[UPLOAD_FIELDS.END_PERIOD] = comp.state.sectionsData[section].filter(e => e.raw_file_id === Number(fileDetail[UPLOAD_FIELDS.RAW_FILE_ID_FIELD]))[0][UPLOAD_FIELDS.END_PERIOD];
                fileDetails.push(fileDetail);
            });
        }
        
        var allSubSectionCheckboxes =  $('#'+section.replace("\&","\\&").replace("&", "\\&").toLowerCase()).find('input:checkbox[id='+subSection+']').closest(".tabulator-row").length;
        
        if(fileDetails.length ===allSubSectionCheckboxes){
            this.state.defaultDeleteMessage = MESSAGES.UPLOAD.all_selected_files_will_be_deleted;
        }
        else{
            this.state.defaultDeleteMessage = MESSAGES.UPLOAD.selected_files_will_be_deleted;
        }
        
        if(fileDetails.length < 1) {
            $('#'+section.replace("\&","\\&").replace("&", "\\&").toLowerCase()).find("div.tabulator-arrow").closest(".tabulator-row").click();
           
        }

        if(fileDetails.length < 1) {
            Popup.alert("Please select at least one file to delete.");
            return false;
        }
    
        fileDetails = fileDetails.map((item) => {
            
            if(item[UPLOAD_FIELDS.UPLOADED_STATUS_FIELD] === DATA_DICTIONARY_ENUM.passed){
                if(item[UPLOAD_FIELDS.START_PERIOD] !== item[UPLOAD_FIELDS.END_PERIOD]){
                    return (
                        <li style ={{color:"green",display:"list-item"}} key={item[UPLOAD_FIELDS.RAW_FILE_ID_FIELD]}>
                            {item[UPLOAD_FIELDS.FILE_NAME_FIELD]} from {item[UPLOAD_FIELDS.START_PERIOD]} to {item[UPLOAD_FIELDS.END_PERIOD]}
                        </li>
                    );
                }
                else{
                    return (
                    <li style ={{color:"green",display:"list-item"}} key={item[UPLOAD_FIELDS.RAW_FILE_ID_FIELD]}>
                        {item[UPLOAD_FIELDS.FILE_NAME_FIELD]}
                    </li>
                    );
                }
            }
            else{
                return (
                    <li style ={{color:"red",display:"list-item"}} key={item[UPLOAD_FIELDS.RAW_FILE_ID_FIELD]}>
                        {item[UPLOAD_FIELDS.FILE_NAME_FIELD] }
                    </li>
                    );
            }
        });
        
        this.setState({
            selectedSection: section,
            selectedSubSection: subSection,
            deleteOrReplaceMessage: this.state.defaultDeleteMessage,
            deleteReplaceCallback: this.deleteFiles,
            fileDetails: fileDetails,
            fileToDelete: file
        });   
        this.setDeleteReplaceDialogOpen(true);
    }

    deleteFiles(){
        toggleLoader(true, 'deleteFiles');
        var comp = this;
        var section = this.state.selectedSection;
        var subSection = this.state.selectedSubSection;
       
        var fileDetails = [];
        var rawFileSubTypeId = "";
        var fileIds = [];
        
        if(section === CROSSWALK) {
            let rData = this.state.fileToDelete === undefined ? this.state.crosswalkFile : this.state.fileToDelete;
            fileDetails = [rData];
        } else {
            $('#'+section.replace("&", "\\&").toLowerCase()).find('input[id='+subSection+']:checked').closest(".tabulator-row").each(function(index, el) {
                var fileDetail = {};
                fileDetail[UPLOAD_FIELDS.RAW_FILE_ID_FIELD] = $(el).find("[tabulator-field="+ UPLOAD_FIELDS.RAW_FILE_ID_FIELD +"]").attr('title');
                fileDetail[UPLOAD_FIELDS.FILE_NUMBER_FIELD] = $(el).find("[tabulator-field="+ UPLOAD_FIELDS.FILE_NUMBER_FIELD +"]").attr('title');
                fileDetail[UPLOAD_FIELDS.UPLOADED_STATUS_FIELD] = $(el).find("[tabulator-field="+ UPLOAD_FIELDS.UPLOADED_STATUS_FIELD +"]").attr('title');
                fileDetail[UPLOAD_FIELDS.FILE_NAME_FIELD] = $(el).find("[tabulator-field="+ UPLOAD_FIELDS.FILE_NAME_FIELD +"]").attr('title');
                rawFileSubTypeId = comp.state.sectionsData[comp.state.selectedSection].filter(e => e.raw_file_id === Number(fileDetail[UPLOAD_FIELDS.RAW_FILE_ID_FIELD]))[0]['raw_file_subtype_id'];
                fileDetails.push(fileDetail);
                fileIds.push(fileDetail[UPLOAD_FIELDS.RAW_FILE_ID_FIELD]);
            });
        }
        var chosenPassed = fileDetails.filter(item => item[UPLOAD_FIELDS.UPLOADED_STATUS_FIELD] === DATA_DICTIONARY_ENUM.passed).length;
        var data = { 
           action: "deleteFiles",
           fileDetails: fileDetails,
           section: section,
           subSection: subSection,
           rawFileSubTypeId: rawFileSubTypeId
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      var myUrl = baseUrl+path;
        $.ajax({
            url: myUrl, 
            async: true, 
            type: 'POST', 
            crossDomain:true, 
            xhrFields: { withCredentials: true }, 
            data:JSON.stringify(data),
            success:function(result, response, xhr){
                comp.setState({
                    fileIds: comp.state.fileIds.concat(fileIds)
                },function(){
                    var myObj = JSON.parse(result);
                    if(myObj.error){
                        Popup.alert(myObj.error);
                        return;
                    }
                    else{
                        this.state.selectedPeriodInfo[section][subSection].passed -= chosenPassed;
                        this.state.selectedSectionPeriodInfo[section].passed -= chosenPassed;
                        Popup.alert(myObj.message);
                        $('#'+section.replace("&", "\\&").toLowerCase()).find('input[id='+subSection+']:checked').closest(".tabulator-row").css('display','none');
                        $('#'+section.replace("&", "\\&").toLowerCase()).find('input[id='+subSection+']:checked').closest(".tabulator-row").prev().find(".tabulator-arrow").click();
                        
                        if(this.state.selectedPeriodInfo[section][subSection].passed === 0){
                            // this.state.rawFileListData.data = this.state.rawFileListData.data.filter(item => item.file_subtype !== subSection);
                            this.state.rawFileListData.data = this.state.rawFileListData.data.filter(item => fileDetails.filter(del => Number(del.raw_file_id) === item.raw_file_id).length === 0);
                        }
                        // comp.setChosenPeriod(comp.state.selectedPeriodObj);
                        if(section === CROSSWALK) {
                            this.getRawFileList();
                        } else {
                            this.setState(this.state);
                        }
                    }
                    
                });
            },
            error: function(error) {
                alertAndLogError(error);
            },
            complete: function(xhr, textStatus) {
                // comp.setChosenPeriod(comp.state.selectedPeriodObj);
                comp.forceUpdate();
                toggleLoader(false, 'deleteFiles');
                logoutIfUnauthenticated(xhr, comp);
            }
        });        
    }

    getIsCostCenterStateDefined() {
        var _this = this;
        // var callback = (data) => {
            // let isDefined = false;
            // if(data) {
            //     isDefined = parseBoolean(data[CONFIG_SETTINGS.COST_CENTER_STATE_DEFINED]);
            // }

            _this.setState({
                isCCStateDefined: _this.props.userSettingsState[CONFIG_SETTINGS.COST_CENTER_STATE_DEFINED]
            });
        // }
        
        // this.getClientSetting(CONFIG_SETTINGS.COST_CENTER_STATE_DEFINED, callback);
    }

    validateUploadedFile(fileName, fileId) {
        var obj = this;
        var truncateFlag = 0;
        var amendment = $("input[name='amendments']:checked").val();
        var delimiter = $("input[name='delimiter']:checked").val();
        if(delimiter === Delimiters.Other) {
            delimiter = $("#otherDelimiter").val();
        }
        var selectedValuesLength = this.state.multiValue.length;
        var uploadedFilesFromBQLength = this.state.uploadedFilesFromBQ.length;
        var encryption = this.state.encryption;
            
        if(amendment === Amendments.Replace && selectedValuesLength === uploadedFilesFromBQLength){
            truncateFlag = 1;
        }

        var query = {
            action: "validateUploadedFile",
            rawFileId: fileId,
            encryption: encryption,
            truncateFlag: truncateFlag,
            isHeaderAvailable: "true",      //to change later when we add the option to upload a file with no header
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      $.ajax({url: baseUrl+path, async: true, type: 'POST', crossDomain:true, xhrFields: { withCredentials: true }, data:JSON.stringify(query),
            success:function(result, response, xhr){
                var myObj = JSON.parse(result);
                obj.fillValidations(myObj, "", false);

                obj.setState({
                    uploadResponse: result,
                    uploadResponseStatus: myObj.status,
                    uploadResponseHeaders: myObj.headers,
                    uploadResponseColumns: myObj.columns,
                    uploadResponseCount: myObj.total_count ? myObj.total_count : "",
                    uploadResponseError: myObj.ERROR ? myObj.ERROR : "",
                    fileStatus: myObj.fileStatus,
                }, function(){
                    window._profitIsleCloseModal('validationModal');
                    window._profitIsleOpenModal('afterSuccessResponseModal');
                });
                $("#close-btn").attr("disabled", false);
                $("#uploadPopupModal .close").removeAttr("disabled");

                if(obj.state.selectedSection === GENERAL_LEDGER && amendment !== Amendments.New && STATUS_ENUM.pass === STATUS_ENUM[myObj.status]
                    && obj.state.isCCStateDefined === false) {
                    //fill transitions if file passed, and was appended under General Ledger
                    obj.fillTransitionCombinations();
                }

                obj.getRawFileList();
                obj.sendPipelineRequest(myObj.fileNameGCS, delimiter);
            },
            complete: function(xhr, textStatus) {
                logoutIfUnauthenticated(xhr, obj);
            }
        });
    }

    sendPipelineRequest(fileNameGCS, delimiter) {
        const onThenCallback = (data) => {
            console.log(data.result);
        };
        const onCompleteCallback = ()=>{
            console.log("sendPipelineRequest finished.")
        };

        let data = {
            fileNameGCS: fileNameGCS,
            delimiter: delimiter
        }

        const fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "sendPipelineRequest",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DataPipeline,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: data,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.onCompleteCallback]: onCompleteCallback,
            [FETCHAPI_PARAMS.email]: this.props.userSettingsState?.user?.email,
            [FETCHAPI_PARAMS.machine_name]: this.props.machine_name
        }
        this.fetchAPI(fetchOptions);
    }

    initiateFTPUpload() {
        let _this = this;
        _this.setState({
            pullSFTPDisabled: true
        },function(){
            const onThenCallback = (data) => {
                console.log(data.result);
            };
            const onCompleteCallback = ()=>{
                console.log("FTP Upload initiated.")
                _this.setstate({
                    pullSFTPDisabled: false
                })
            };
    
            let data = {
                action: "initiateFTPUpload"
            }
    
            const fetchOptions = {
                [FETCHAPI_PARAMS.funcName]: "initiateFTPUpload",
                [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
                [FETCHAPI_PARAMS.showLoader]: false,
                [FETCHAPI_PARAMS.path]: API_URL.DataPipeline,
                [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                [FETCHAPI_PARAMS.query]: data,
                [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
                [FETCHAPI_PARAMS.onCompleteCallback]: onCompleteCallback,
                [FETCHAPI_PARAMS.email]: this.props.userSettingsState?.user?.email,
                [FETCHAPI_PARAMS.machine_name]: this.props.machine_name
            }
            this.fetchAPI(fetchOptions);
        })
    }

    isFormValid(file) {
        $("#div-UploadFilePeriods .select-value").removeClass("emptyField");
        var selectedDelimiter = $("input[name='delimiter']:checked").val();
        var comp = this;
        var validated = true;
        if (comp.state.selectedSection !== CROSSWALK) {
            if(comp.state.selectedUploadStartPeriod === undefined || comp.state.selectedUploadEndPeriod === undefined) {
                $("#div-UploadFilePeriods .select-value").addClass("emptyField");
                validated =  false;
            }
            if(comp.state.selectedUploadStartPeriod && comp.state.selectedUploadEndPeriod) {
                var startPeriod = comp.state.rawFileListData.periods.find(function(el) { return el.period_name === comp.state.selectedUploadStartPeriod });
                var endPeriod = comp.state.rawFileListData.periods.find(function(el) { return el.period_name === comp.state.selectedUploadEndPeriod });
                var startPeriodStartDate =Math.round(new Date(startPeriod.start_date).getTime()/1000);
                var endPeriodEndDate = Math.round(new Date(endPeriod.end_date).getTime()/1000);
    
                if(endPeriodEndDate < startPeriodStartDate) {
                    $("#div-UploadFilePeriods .select-value").addClass("emptyField");
                    validated =  false;
                }
            }
        }
       

        if(comp.state.selectedSubSection === "") { // set default subsection value if drop down options length = 1
            comp.state.selectedSubSection = comp.state.uploadedFileSubtype[comp.state.selectedSection] && comp.state.uploadedFileSubtype[comp.state.selectedSection].length === 1 ? comp.state.uploadedFileSubtype[comp.state.selectedSection][0].value : "";
        }
        if(selectedDelimiter === Delimiters.Other) {
            var otherDelimiter = $("#otherDelimiter").val();
            if(otherDelimiter === "")
                return false;
        }
        if(comp.state.selectedSubSection === "") {
            $("#div-uploadedFileSubtype").addClass("emptyField")
            validated =  false;
        }
        var selectedAmendment = $("input[name='amendments']:checked").val();
        if(selectedAmendment === Amendments.Replace && (!comp.state.multiValue || comp.state.multiValue.length < 1)) {
            validated = false;
        }
        return validated;
    }

    uploadFile(){
        var comp = this;
        var file = $("#fs")[0].files[0];
        //const file = $("#fileUpload").find('input')[0].value;
        if (file && this.isFormValid(file)) {
            var selectedAmendment = $("input[name='amendments']:checked").val();
            if (selectedAmendment === Amendments.Replace) {
                var files = this.state.multiValue.map(function(item){
                    return item.label;
                });

                this.setState({
                    filesToReplace: files,
                    replacingFile: file,
                }, function() {                    
                    window._profitIsleOpenModal('replaceFilesModal');
                });
            } else if(!this.checkForReplaceWarning(file)) {
                comp.generateSignedURL(file);
            }
        } else if(!file) { 
            $("#fileSelectBox").addClass("emptyField");
            Popup.alert(MESSAGES.upload_file);
        } else{
            var elem = $("#div-UploadFilePeriods .select-value");

            if(comp.state.selectedSection !== CROSSWALK && comp.state.selectedUploadStartPeriod !== undefined && comp.state.selectedUploadEndPeriod !== undefined && (elem.hasClass("emptyField"))){
                Popup.alert(MESSAGES.invalid_periods_range);
            } else {
                Popup.alert(ALERT_FILL_MESSAGE);
            }
        }
    }

    checkForReplaceWarning(file) {
        let section = this.state.selectedSection;
        let subSection = this.state.selectedSubSection;
        let startPeriod = this.state.selectedUploadStartPeriod;
        let selectedUploadStartPeriod = this.state.selectedUploadStartPeriod;
        let oldFileSameSubsection = this.state.pandlData.find(function(file) {return file[UPLOAD_FIELDS.FILE_SUBTYPE] === subSection});   //old file that exists under the same subsection and period
        let oldFileSamePeriod = this.state.pandlData.find(function(file) {return file[UPLOAD_FIELDS.START_PERIOD] === selectedUploadStartPeriod});  //old file under same period
        //if it is P & L, and subSection and startPeriod are defined, and there are files for this period and subsection
        if(section === P_AND_L && subSection && subSection.length && startPeriod && startPeriod.length && oldFileSameSubsection && oldFileSamePeriod) {
            this.setState({
                deleteReplaceCallback: ()=>this.generateSignedURL(file),
                deleteOrReplaceMessage: this.state.defaultReplaceMessage,
                multiValue: [{ value: oldFileSamePeriod[UPLOAD_FIELDS.FILE_NUMBER_FIELD], label: oldFileSamePeriod[UPLOAD_FIELDS.FILE_NAME_FIELD] + " - " + oldFileSamePeriod[UPLOAD_FIELDS.ALL_PERIODS] }]
            });
            
            this.setDeleteReplaceDialogOpen(true);
            return true;
        }

        return false;
    }

    generateSignedURL(file){
        var comp = this;
        if (comp.props.userSettingsState?.user?.email === "null" || comp.props.userSettingsState?.user?.email === null) {
            this.logout();
            return;
        }
        //var validFilename = /^[a-z0-9_]+\.[^.]+$/i.test(file.name);
        $("#submitButtons").hide();
        $("#uploadProgress").show();
        $("#formFieldSet").attr("disabled", "disabled");
        $("#formFieldSet .Select").addClass("is-disabled");
        $("#uploadPopupModal .close").attr("disabled", "disabled");

        var fileName = file.name;
        var subSection = comp.state.selectedSubSection;
        var amendment = $("input[name='amendments']:checked").val();
        var delimiter = $("input[name='delimiter']:checked").val();
        if(delimiter === Delimiters.Other) {
            delimiter = $("#otherDelimiter").val();
        }
        var encryption = comp.state.encryption;
        
        var startPeriod = comp.state.rawFileListData.periods.find(function(el) { return el.period_name === comp.state.selectedUploadStartPeriod });
        var endPeriod = comp.state.rawFileListData.periods.find(function(el) { return el.period_name === comp.state.selectedUploadEndPeriod });
        
        var data = { 
            action: "generateSignedURL",
            fileName : fileName,
            fileType : file.type,
            section: comp.state.selectedSection, 
            subSection: subSection, 
            delimiter: delimiter,
            amendment: amendment,
            filesToReplace: '{"files":' +JSON.stringify(comp.state.multiValue) +'}',
            encryption: encryption,
            createdBy: comp.props.userSettingsState?.user?.email,
            startPeriod: startPeriod ? startPeriod.client_period_id : "",
            endPeriod: endPeriod ? endPeriod.client_period_id : "",
            
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      //var myUrl = baseUrl+"/CloudStorage";
        var myUrl = baseUrl+"/DataModeling";
        $.ajax({
            url: myUrl, 
            type: 'POST',
            async: false, 
            crossDomain:true, 
            xhrFields: { withCredentials: true }, 
            data:JSON.stringify(data), 
            // dataType: "json",
            // contentType: 'application/json',
            success:function(result, response, xhr){
                result = JSON.parse(result);
                var signedUrl = decodeURIComponent(result.url);
                comp.pushFileToGCS(file, signedUrl, result.rawFileName, result.rawFileId);
            },
            complete: function(xhr, textStatus) {
                logoutIfUnauthenticated(xhr, comp);
            }
        });
    }

    onloadstartHandler(evt) {
		var obj = this;
		obj.startedUpload = Date.now();
    }
    
	onloadHandler(evt) {
		var obj = this;
		obj.finishedUpload = Date.now();
    }
    
	onprogressHandler(evt) {
		var percent = evt.loaded/evt.total*100;
        var div = document.getElementById('progressBar');
        div.children[0].innerHTML = Math.round(percent) + "%";
        div.children[0].style.width = percent+"%";

        var currentDate = Date.now();
        var time = currentDate - this.state.fileStartedUploadTime;

        var uploadStatus = document.getElementById('upload-status');
        if(uploadStatus) {
            uploadStatus.innerHTML = 'Upload started. <br/>File Size: ' + formatFileSizeByByte(evt.total, 2);
            uploadStatus.innerHTML += ' (Remaining ' + formatFileSizeByByte((evt.total - evt.loaded), 2) + ', Speed: ' + formatFileSizeByByte(evt.loaded/(time/1000), 2) + '/Sec)';
        }
    }
    
    handleEncryptionChange(e){
        
        if(e){
            var comp = this;
            var  encryption = (e.target.value === "yes") ? "no" : "yes";
            comp.setState({
                 encryption:encryption
            });
         }
    }

    onreadystatechangeHandler(evt, fileName, fileId) {
		var obj = this;
		var status, text, readyState;
		try {
            readyState = evt.target.readyState;
            text = evt.target.responseText;
            status = evt.target.status;
		}
		catch(e) {
            return;
        }

		if (Number(readyState) === 4 && Number(status) === 200) {   //readyState = 4 means the request is complete
            var result = document.getElementById('result');
            result.className = "";
			result.innerHTML += 'Success!<br>';
            result.innerHTML += 'Upload took: ' + fancyTimeFormat((evt.currentTarget.upload.finishedUpload - evt.currentTarget.upload.startedUpload)/1000);
            var encryption = this.state.encryption
            $(".upload-cancel-button").text("Close");
            if(encryption === "no") {
                window._profitIsleOpenModal('validationModal');
                obj.validateUploadedFile(fileName, fileId);    
            } else {
                var toBeEncrypted = [];
                toBeEncrypted.push({fileId:fileId, fileName:fileName});
                this.setState({
                    toBeEncrypted: toBeEncrypted
                });
                $("#close-btn").attr("disabled", false);
                $("#uploadPopupModal .close").removeAttr("disabled");
                // obj.getRawFileList();
                Popup.alert("A notification will be sent once file is decrypted.");
                this.checkEncryptionProgress();
            }
        }
    }

    changeText() {
        $(".upload-cancel-button").text("Cancel");
    }

    configure(row) {
        if (row[DEFINE_FIELDS.CURRENCY_SUBTYPE_ID] && !row.fileType) {
            if (this.state.dataColumns.filter(e=>e[RAW_ITEMS.SUBTYPE_ID] === row[DEFINE_FIELDS.CURRENCY_SUBTYPE_ID]).length > 0) {
                row.file_type = this.state.dataColumns.filter(e=>e[RAW_ITEMS.SUBTYPE_ID] === row[DEFINE_FIELDS.CURRENCY_SUBTYPE_ID])[0][RAW_ITEMS.SUBTYPE_NAME]
            }
        } 
        this.setState({
            rowData: row,
            currencyDate: row[DEFINE_FIELDS.CURRENCY_DATE] ? {value:row[DEFINE_FIELDS.CURRENCY_DATE], label:row[DEFINE_FIELDS.CURRENCY_DATE]} : undefined,
            currencyColumn: {value: row[DEFINE_FIELDS.CURRENCY_FIELD_NAME], label:row[DEFINE_FIELDS.CURRENCY_FIELD_NAME], isStatic: row[DEFINE_FIELDS.STATIC]},
            currencySubSection: row[DEFINE_FIELDS.CURRENCY_SUBTYPE_ID] ? {raw_file_subtype_id: row[DEFINE_FIELDS.CURRENCY_SUBTYPE_ID], value: row.file_type, label:row.file_type} : undefined,
            showCurrencyDate: row[DEFINE_FIELDS.CURRENCY_DATE],
            currenciesMatchingData:[],
            matching_message: undefined,
            currencyColumns:[]
        },function(){
            if( row[DEFINE_FIELDS.CURRENCY_FIELD_NAME]) {
                this.fetchCurrencyColumns(this.state.currencySubSection);
                this.runMatchingCurrenciesAudit()
            }
            window._profitIsleOpenModal("configureModal");
            this.setPage(0);
        })

    }
/**
 * This function sends each 1 second a request to check if pending decryption files has been encrypted or not and 
 * it notifies the user once the encypted file has been encrypted
 */
    checkEncryptionProgress() {
        /* please leave this code commented for it might be used in the future */
        // var obj = this;
        // var query = {
        //     action: "getPendingDecryptionFiles",
        // }
        // var interval = setInterval(function() {
        //     fetch(`${baseUrl}${API_URL.DATA_MODELING}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
        //         .then((response)=>{
        //         return response.json();
        //         })
        //     .then((data)=>{
        //         var data = data.data;
        //         var toBeEncryptedFiles = obj.state.toBeEncrypted;
        //         var allEncrypted = 0;
        //         for(var e in toBeEncryptedFiles) {
        //              if (data.filter(elt=>elt["fileId"] === toBeEncryptedFiles[e].fileId).length === 0) {
        //                 allEncrypted++;
        //                 alert(toBeEncryptedFiles[e].fileName + " has been encrypted.");
        //              }
        //         }
        //         if (allEncrypted === toBeEncryptedFiles.length) {
        //             clearInterval(interval);
        //         }       
        //     })
        //     .catch((error)=>{
        //         alertAndLogError(error);
        //     });
        // }, 1000);

        var obj = this;
        var interval = setInterval(function() {
            obj.getRawFileList(function(){
                var data = obj.state.rawFileListData;
                var toBeEncryptedFiles = obj.state.toBeEncrypted;
                for(var e in toBeEncryptedFiles) {
                    var found = data.data.find(elt => elt["raw_file_id"] === toBeEncryptedFiles[e].fileId);
                    if (found){
                        obj.setState({
                            fileName: toBeEncryptedFiles[e].fileName
                         });
                        if (found[UPLOAD_FIELDS.UPLOADED_STATUS_FIELD].replace(/_/g,'') !== DATA_DICTIONARY_ENUM.pending_decryption.toLowerCase().replace(/ /g,'')) {
                            if (JSON.parse(found.response)["status"] && JSON.parse(found.response)["status"].replace(/_/g,'') === STATUS_ENUM.pass.toLowerCase().replace(/ /g,'')) {
                                obj.setState({
                                    decryptionMessage: " has been decrypted."
                                 });
                                 if (found["created_by"] === obj.props.userSettingsState?.user?.email) {
                                    obj.launchToast();
                                    toBeEncryptedFiles.splice(e,1); 
                                 }
                            }else if (found[UPLOAD_FIELDS.UPLOADED_STATUS_FIELD].replace(/_/g,'') === DATA_DICTIONARY_ENUM.failed_decrypt.toLowerCase().replace(/ /g,'')) {
                                obj.setState({
                                    decryptionMessage: " has failed decryption."
                                 });
                                 if (found["created_by"] === obj.props.userSettingsState?.user?.email) {
                                    obj.launchToast();
                                    toBeEncryptedFiles.splice(e,1); 
                                 }
                            }
                            
                        }
                     }
                }
                // $("#toast").removeClass("show");
                if (toBeEncryptedFiles.length === 0) {
                    clearInterval(interval);
                }       
            })
        }, 5000);
    }

/**
 * This function notifies the user that the encrypted file has been decrypted
 */
    launchToast() {
        var toastId = document.getElementById("toast")
        $("#toastUploadData").addClass("show");
        setTimeout(function(){
            $("#toastUploadData").removeClass("show");
        }, 5000);
    }

    pushFileToGCS(file, signedUrl, fileName, fileId) {
        this.state.fileStartedUploadTime = Date.now();
        var xhr = new XMLHttpRequest();
		// Set up events
		xhr.upload.addEventListener('loadstart', this.onloadstartHandler, false);
		xhr.upload.addEventListener('progress', this.onprogressHandler, false);
		xhr.upload.addEventListener('load', this.onloadHandler, false);        
        xhr.addEventListener('readystatechange', (e) => this.onreadystatechangeHandler(e, fileName, fileId), false);

		xhr.open('PUT', signedUrl, true);
        xhr.send(file);
        
        if($('#upload-status').length) {
            var uploadStatus = document.getElementById('upload-status');
            uploadStatus.innerHTML = 'Upload started. <br/>File Size: ' + formatFileSizeByByte(file.size, 2);    
        }
	}

    handleMultiChange(option) {
        this.setState(state => {
          return {
              multiValue: option,
              menuIsOpen: true
          };
        });
    }

    selectAll = () => {
        let multiValue = []
        let defaultSelectionName = this.state.defaultSelectionName;
        if(defaultSelectionName === _selectAll){
            multiValue = this.state.arrangedFiles.filter(e => !e.isDisabled);
            defaultSelectionName = _deselectAll;
        } else {
            multiValue = [];
            defaultSelectionName = _selectAll;
        }
        this.setState({
            menuIsOpen: true,
            multiValue:multiValue,
            defaultSelectionName:defaultSelectionName
        });
    }

    setChosenPeriod(option, mount) {
        if(option && option !== null) {
            var data = this.state.dataSummary;
            if(option.currentTarget || mount) {
                if (!mount) {
                    option = JSON.parse($(option.currentTarget).attr("data_option"));
                }
                for (var e in data) {
                    if (data[e][SUMMARY_FIELDS.PERIOD_ID] === option["data-period_id"]) {
                        data[e]["highlighted"] = "true";
                    }else {
                        data[e]["highlighted"] = "false";
                    }
                }
                if (this.tableReferences[DATA_SUMMARY]) {
                    this.tableReferences[DATA_SUMMARY].current.tabulator.replaceData(data);
                    this.tableReferences[DATA_SUMMARY].current.tabulator.scrollToRow(option["data-period_id"]);
                }
            }
            var currSectionInfo = this.state.rawFileListData.sectionInfo[option["data-period_id"]];
            var currSectionPeriodInfo = this.getPassedExpectedValues(currSectionInfo);
            var currSectionInfo = this.getSectionStatus(currSectionInfo);
        
            this.setState({
                selectedPeriod: option.value,
                selectedPeriodObj:option,
                selectedPeriodInfo: currSectionInfo,
                selectedSectionPeriodInfo: currSectionPeriodInfo
            }, function() {
                this.divideDataOverTypes(this.state.rawFileListData.data);
                
                this.forceUpdate();
            });
        }
    }

    setUploadStartPeriod(option) {
        if(option !== null) {
            this.setState({
                selectedUploadStartPeriod: option.value,
                selectedUploadEndPeriod: option.value,
                multiValue: []
            }, function () {
                $("#div-uploadedFilesFromBQ").addClass("hidden");
                if(this.state.selectedSection !== "" && this.state.selectedSubSection !== ""){
                    this.getSectionUploadedFiles(this.state.selectedSection, this.state.selectedSubSection, this.state.selectedUploadStartPeriod, this.state.selectedUploadEndPeriod);
                }
            }); 
        }
    }

    setUploadEndPeriod(option) {
        if(option !== null) {
            this.setState({
                selectedUploadEndPeriod: option.value,
                multiValue: []
            }, function () {
                $("#div-uploadedFilesFromBQ").addClass("hidden");
                if (this.state.selectedSection !== "" && this.state.selectedSubSection !== "") {
                    this.getSectionUploadedFiles(this.state.selectedSection, this.state.selectedSubSection, this.state.selectedUploadStartPeriod, this.state.selectedUploadEndPeriod);
                }
            }); 

        }
    }

    handleSubSectionChange(option, defaultFlag) {
        if(option !== null) {
            var periods = "";
            if(this.state.selectedSection !== CROSSWALK) {
                var result = this.checkValidUndefinedFiles(this.state.selectedSection, option.value);
                if(result.hasValidUndefinedFiles) {
                    if(result.undefinedPeriods.length > 0) {
                        for(var e in result.undefinedPeriods){
                            periods += Number(e) !== 0 && result.undefinedPeriods[e-1] !== result.undefinedPeriods[e]  ?  "-" : "";
                            periods += " " +  Number(e) !== 0 && result.undefinedPeriods[e-1] !== result.undefinedPeriods[e] ? result.undefinedPeriods[e] + " " : "" ;
                        }
                    }
                    Popup.alert("Please define your last uploaded file in period range: " + periods + "before uploading a new one.");
                    if (defaultFlag) {
                        window._profitIsleCloseModal('uploadPopupModal');          
                    }
                    return; 
                }
            }
            $("input[name=amendments]").prop('checked', false);
            $("#div-uploadedFilesFromBQ").addClass("hidden");
            $("#otherDelimiter").addClass("hidden");
            $("#otherDelimiter").val("");
            this.setState({
                selectedSubSection: option.value,
                uploadedFilesFromBQ: [],
                disableNew: false,
                disableAppend: true,
                disableReplace: true,
                multiValue: [],
                filesToReplace: []
            });
            $("input[name=amendments][value='" + Amendments.New + "']").prop('checked', true);
    
            if(option.value !== "" && option.value !== NEW_SECTION) {
                this.getSectionUploadedFiles(this.state.selectedSection, option.value, this.state.selectedUploadStartPeriod, this.state.selectedUploadEndPeriod);
            }
        }
    }

    getDataRowOfFieldName(tableData, requiredFieldName) {
        for (var row in tableData) {
            row = tableData[row];
   
            if(row[DEFINE_FIELDS.FIELD_NAME_FIELD].toLowerCase() === requiredFieldName.toLowerCase()) {
                return row;
            }
        }

        return "";
    }

    setValue(thisCell) {
        this.tableReferences[DEFINE_DATA].current.setValue();
        if(this.tableReferences[DEFINE_DATA].current.state.value === "BOOLEAN"){
            this.state.showBooleanAlertMessage = true;
        }
        else{
            this.state.showBooleanAlertMessage = false;
        }
        
    }

    getFileColumnIndex(data, col) {
        let retIndex = -1;
        data.map((item, index)=>{
            if(Object.keys(item).indexOf(col) !== -1) {
                retIndex = index;
                return;
            }
        });

        return retIndex;
    }

    /**
     * This function sets the column titles in the file as row values in the define Table
     * @param {*} allFileColumns 
     * @param {*} tableColumns 
     * @param {*} distinctValuesAndCount -- distinct values fetched from the API
     * @param {*} defineTableData -- definition data fetched from the API
     * @param {*} rawFileId 
     * @param {*} dataTypesFields -- data type options fetched from the API
     * @param {*} fileSamples -- file samples fetched from the API
     */
    setDefineFileColumnNames(allFileColumns, tableColumns, distinctValuesAndCount, defineTableData, rawFileId, dataTypesFields, fileSamples) {
        var tableData = [];
        var shouldReadFromDefineData = defineTableData.length > 0;
        for(var fileColumnIndex in allFileColumns) {   //iterate rows (file column names)
            var fileColumn = allFileColumns[fileColumnIndex].trim();
            var tempRow = {};
        
            for(var tableRow in tableColumns) {
                tableRow = tableColumns[tableRow];

                if(tableRow.field === DEFINE_FIELDS.FIELD_NAME_FIELD) {
                    tempRow[DEFINE_FIELDS.FIELD_NAME_FIELD] = fileColumn;
                } else if (tableRow.field === DEFINE_FIELDS.DISTINCT_VALUES_FIELD) {
                    let num = this.getDataRowOfFieldName(defineTableData, fileColumn)[DEFINE_FIELDS.DISTINCT_VALUES_FIELD];
                    let denom = this.getDataRowOfFieldName(defineTableData, fileColumn)[RAW_FILE.TOTAL_COUNT];
                    tempRow[DEFINE_FIELDS.DISTINCT_VALUES_FIELD] = num + "/" + denom;
                    if(!shouldReadFromDefineData || num === undefined) {
                        //read from distinct values if the file isn't defined
                        var fileColIndex = this.getFileColumnIndex(distinctValuesAndCount, fileColumn);
                        if(fileColIndex !== -1) {
                            num = distinctValuesAndCount[fileColIndex][fileColumn];
                            denom = distinctValuesAndCount[fileColIndex][RAW_FILE.TOTAL_COUNT];    
                            tempRow[DEFINE_FIELDS.DISTINCT_VALUES_FIELD] = num + "/" + denom;
                        } else {
                            tempRow[DEFINE_FIELDS.DISTINCT_VALUES_FIELD] = "";
                        }
                    }
                } else if (tableRow.field === DEFINE_FIELDS.SAMPLES_FIELD && !shouldReadFromDefineData) {
                    tempRow[tableRow.field] = fileSamples.filter(e=>e.field === fileColumn)[0].value;
                } else if(shouldReadFromDefineData) {
                    tempRow[tableRow.field] = this.getDataRowOfFieldName(defineTableData, fileColumn)[tableRow.field] || "";
                } else {
                    tempRow[tableRow.field] = "";
                }
            }
        
            tempRow[DEFINE_FIELDS.ROW_NUMBER_FIELD] = fileColumnIndex; //add rowIndex column in the table
            tempRow[UPLOAD_FIELDS.RAW_FILE_ID_FIELD] = rawFileId;

            tempRow[DEFINE_FIELDS.DATA_TYPE_FIELD] = tempRow[DEFINE_FIELDS.DATA_TYPE_FIELD] === "" ? dataTypesFields[0]["datatype"] : tempRow[DEFINE_FIELDS.DATA_TYPE_FIELD];
            tempRow[DEFINE_FIELDS.DATA_TYPE_ID_FIELD] = tempRow[DEFINE_FIELDS.DATA_TYPE_ID_FIELD] === "" ? dataTypesFields[0][DEFINE_FIELDS.DATA_TYPE_ID_FIELD]  : tempRow[DEFINE_FIELDS.DATA_TYPE_ID_FIELD];
            tempRow[DEFINE_FIELDS.DATA_FIELD] = tempRow[DEFINE_FIELDS.DATA_FIELD] === "" ? DEFAULT_DATA_VALUE : tempRow[DEFINE_FIELDS.DATA_FIELD];
            
            var row = this.getDataRowDataType(defineTableData,tempRow[DEFINE_FIELDS.DATA_TYPE_FIELD],tempRow[DEFINE_FIELDS.DATA_TYPE_ID_FIELD],tempRow[DEFINE_FIELDS.RAW_FILE_FIELD_ID_FIELD]);
            tempRow[DEFINE_FIELDS.MAX_FIELD] = row[DEFINE_FIELDS.MAX_FIELD] !== undefined ? row[DEFINE_FIELDS.MAX_FIELD].toString() : "*";
            tempRow[DEFINE_FIELDS.MIN_FIELD] = row[DEFINE_FIELDS.MIN_FIELD] !== undefined ? row[DEFINE_FIELDS.MIN_FIELD].toString() : "*";
            tempRow[DEFINE_FIELDS.MAX_LENGTH_FIELD] = row[DEFINE_FIELDS.MAX_LENGTH_FIELD] !== undefined ? row[DEFINE_FIELDS.MAX_LENGTH_FIELD].toString() : "0" ;
            tempRow[DEFINE_FIELDS.MIN_LENGTH_FIELD] = row[DEFINE_FIELDS.MIN_LENGTH_FIELD] !== undefined ? row[DEFINE_FIELDS.MIN_LENGTH_FIELD].toString() : "0";
            tempRow[DEFINE_FIELDS.DECIMAL_FIELD] = row[DEFINE_FIELDS.DECIMAL_FIELD] !== undefined ? row[DEFINE_FIELDS.DECIMAL_FIELD].toString() : "9";
            tempRow[DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD] = row[DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD] !== undefined ? row[DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD].toString() : "";
            tempRow[DEFINE_FIELDS.IS_TRAILING_FIELD] = row[DEFINE_FIELDS.IS_TRAILING_FIELD] !== undefined ?  row[DEFINE_FIELDS.IS_TRAILING_FIELD].toString() : "true";
            // tempRow[DEFINE_FIELDS.TRUE_INPUT_FIELD] = row[DEFINE_FIELDS.TRUE_INPUT_FIELD] !== undefined ? row[DEFINE_FIELDS.TRUE_INPUT_FIELD].toString() : "true";
            // tempRow[DEFINE_FIELDS.FALSE_INPUT_FIELD] = row[DEFINE_FIELDS.FALSE_INPUT_FIELD] !== undefined ? row[DEFINE_FIELDS.FALSE_INPUT_FIELD].toString() : "false";
            tempRow[DEFINE_FIELDS.TRUE_INPUT_FIELD] = row[DEFINE_FIELDS.TRUE_INPUT_FIELD] !== undefined ? row[DEFINE_FIELDS.TRUE_INPUT_FIELD].toString() : "";
            tempRow[DEFINE_FIELDS.FALSE_INPUT_FIELD] = row[DEFINE_FIELDS.FALSE_INPUT_FIELD] !== undefined ? row[DEFINE_FIELDS.FALSE_INPUT_FIELD].toString() : "";
            tempRow[DEFINE_FIELDS.DATE_FORMAT_FIELD] = row[DEFINE_FIELDS.DATE_FORMAT_FIELD] !== undefined ? row[DEFINE_FIELDS.DATE_FORMAT_FIELD].toString() : DEFAULT_DATE_FORMAT_UPLOAD;
            tempRow[DEFINE_FIELDS.FORMAT_FIELD] = row[DEFINE_FIELDS.FORMAT_FIELD] !== undefined ? row[DEFINE_FIELDS.FORMAT_FIELD].toString() : "(0-0)  **";
            tempRow[DEFINE_FIELDS.CURRENCY_DATE] = row[DEFINE_FIELDS.CURRENCY_DATE];// !== undefined ? row[DEFINE_FIELDS.FORMAT_FIELD].toString() : "(0-0)  **";
            tempRow[DEFINE_FIELDS.CURRENCY_FIELD_NAME] = row[DEFINE_FIELDS.CURRENCY_FIELD_NAME];
            tempRow[DEFINE_FIELDS.CURRENCY_SUBTYPE_ID] = row[DEFINE_FIELDS.CURRENCY_SUBTYPE_ID];
            tempRow[DEFINE_FIELDS.STATIC] = row[DEFINE_FIELDS.STATIC];
            tempRow[RAW_ITEMS.SUBTYPE_NAME] = row[RAW_ITEMS.SUBTYPE_NAME];
            tempRow[DEFINE_FIELDS.DEFINED_SUBTYPE_ID] = row[DEFINE_FIELDS.DEFINED_SUBTYPE_ID] ? row[DEFINE_FIELDS.DEFINED_SUBTYPE_ID].toString() : undefined;

            tableData.push(tempRow);
        }

        return tableData;
    }

    getDataRowDataType(tableData, datatype, dataypeId, rawFileFieldId) {
        for (var row in tableData) {
            row = tableData[row];
            if(row[DEFINE_FIELDS.DATA_TYPE_FIELD] === datatype && row[DEFINE_FIELDS.DATA_TYPE_ID_FIELD] === dataypeId && row[DEFINE_FIELDS.RAW_FILE_FIELD_ID_FIELD] === rawFileFieldId) {
                return row;
            }
        }
        return "";
    }

    getSelectedSections(tableData) {
        var selectedSections = [];
        for (var row in tableData) {
            row = tableData[row];
                selectedSections.push(row[DEFINE_FIELDS.DATA_FIELD]);
        }

        return selectedSections;
    }

    validatePrimaryKey(rowData) {
        //checking the ratio value of distinct value
        var distinctValue = rowData[DEFINE_FIELDS.DISTINCT_VALUES_FIELD];
        var res = distinctValue.split("/");
        var floorOfDistinctValue = Number(res[0])/Number(res[1]);

        //alerting the user that primary key columns should be unique
        if (floorOfDistinctValue !== 1) {
            Popup.alert("Primary Key column must have unique values");
            $(".define-submit").addClass("uk-button-disabled");
            return false;
        }

        return true;
    }

    handleRowDataChange(e, cell) {
        var unselectedOptions = copyObjectValues(mandatorySectionFields_uneditableVar);
        var selectedOptionsValues = copyObjectValues(mandatorySectionFields_selectedVar);
        var newSelectedValue = $(e.currentTarget).val();
        // var newSelectedValue = e.target.value;   //value that will replace the old one
        var oldSelectedValue = cell.getValue();             //value that will be replaced
        var rowData = cell.getRow().getData();
        var rowIndex = rowData[DEFINE_FIELDS.ROW_NUMBER_FIELD];   //extract the row index from the row data
        var optionIndex = -1;    //this index marks the index of the entity chosen from the dropdown

        //if primary key was chosen, validate it
        if(newSelectedValue === capitaliseFirstLetterAfterChar(PRIMARY_KEY).replace("_", " ") && this.state.selectedSection === TRANSACTION_DATA) {
            let validatedPK = this.validatePrimaryKey(rowData);
            if(!validatedPK) {
                $(e.currentTarget).val(DEFAULT_DATA_VALUE);
                newSelectedValue = DEFAULT_DATA_VALUE;
            }
        }

        if(newSelectedValue === capitaliseFirstLetterAfterChar(PRIMARY_KEY).replace("_", " ") && this.state.selectedSection === MASTER_DATA) {
            this.runMatchingKeysAudit(cell.getRow().getData());
        }

        if(newSelectedValue === capitaliseFirstLetterAfterChar(ENTRY_TYPE).replace("_", " ")) {
            this.getColumnBooleanValues(cell.getRow().getData(), this);
        }
        
        //remove the selected option from the list of options
        for (var option in unselectedOptions) {
            if(unselectedOptions[option].name === newSelectedValue) {
                optionIndex = option;   //save the newly selected option's index (this index is its index in the dropdown)
                break;
            }
        }

        var oldValueIndex = selectedOptionsValues.indexOf(oldSelectedValue);    //save the index of the old value (this index is its index in the list of selected options)
        var newValueIndex = selectedOptionsValues.indexOf(newSelectedValue);    //save the index of the new value (this index is its index in the list of selected options)

        if (oldValueIndex !== -1) {
            selectedOptionsValues[rowIndex] = "";   //remove the old value from the list of selected ones
        }

        if(newValueIndex === -1 && newSelectedValue !== DEFAULT_DATA_VALUE) {   //extra check
            selectedOptionsValues[rowIndex] = newSelectedValue;   //add the newly selected value to the list of selected ones, at the exact index of the cell row
        }
        
        var newDefineTableData = this.updateTableRow(cell, optionIndex);

        definition = newDefineTableData;
        mandatorySectionFieldsVar = unselectedOptions;
        mandatorySectionFields_selectedVar = selectedOptionsValues;
        
        //replacing the table data after the user changes anything
        var table = this.tableReferences[DEFINE_DATA].current.tabulator;

        table.replaceData(definition);
        table.redraw();

        this.compareDefinition(JSON.stringify(initialDefinition), JSON.stringify(table.getData()));
    }
    
    compareDefinition(oldDefinition, newDefinition) {
        if (oldDefinition === newDefinition) {
            //disable submit button
            $(".define-submit").addClass("uk-button-disabled");
        } else {
            //enable submit button
            // $(".define-submit").removeClass("uk-button-disabled");
        }
    }

    /**
     * This function updates the table row in the tableData object and
     * fills it with the corresponding "Data Type" and "Description"
     * values when the user changes the value of the "Data" dropdown
     * @param {*} cell 
     * @param {*} optionIndex 
     */
    updateTableRow(cell, optionIndex) {
        var defineTableData = copyObjectValues(definition);
        var rowIndex = cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD];   //save the index of the row to be edited
        var dataTypesKey = dataTypesFieldsVar;
        for (var row in defineTableData) {
            if (Number(row) === Number(rowIndex)) {     //edit the requested row
                if(optionIndex === -1) {
                    //if the option index didn't change (still = -1), means the user didn't 
                    //choose any of the mandatory fields so it must be the "Other" option
                    defineTableData[row][DEFINE_FIELDS.DATA_FIELD] = DEFAULT_DATA_VALUE;
                    defineTableData[row][DEFINE_FIELDS.DATA_TYPE_FIELD] = ""; //reset the value of the datatype after the data is set to "Other"
                    defineTableData[row][DEFINE_FIELDS.DATA_TYPE_ID_FIELD] = dataTypesKey[0][DEFINE_FIELDS.DATA_TYPE_ID_FIELD];; //reset the value of the datatypeId after the data is set to "Other"
                    defineTableData[row][DEFINE_FIELDS.DATA_TYPE_FIELD] = dataTypesKey[0]["datatype"]; //reset the value of the datatype after the data is set to "Other"
                    defineTableData[row][DEFINE_FIELDS.DATA_TYPE_ID_FIELD] = dataTypesKey[0][DEFINE_FIELDS.DATA_TYPE_ID_FIELD]; //reset the value of the datatypeId after the data is set to "Other"
                } else {
                    defineTableData[row][DEFINE_FIELDS.DATA_FIELD] = mandatorySectionFields_uneditableVar[optionIndex][DEFINE_FIELDS.DATA_FIELD];
                    defineTableData[row][DEFINE_FIELDS.DATA_TYPE_FIELD] = mandatorySectionFields_uneditableVar[optionIndex][DEFINE_FIELDS.DATA_TYPE_FIELD];
                    defineTableData[row][DEFINE_FIELDS.DESCRIPTION_FIELD] = mandatorySectionFields_uneditableVar[optionIndex][DEFINE_FIELDS.DESCRIPTION_FIELD];
                    defineTableData[row][DEFINE_FIELDS.DATA_TYPE_ID_FIELD] = mandatorySectionFields_uneditableVar[optionIndex][DEFINE_FIELDS.DATA_TYPE_ID_FIELD];
                }
            }
        }
        return defineTableData;
        
    }
 
    prepareHistoryPopup(section) {
        var obj = this;
        // toggleLoader(true, 'prepareHistoryPopup');
        var displayType = capitaliseFirstLetterAfterChar(section).replaceAll("_", " ");
        
        this.setState({
            selectedSection: section,
            selectedDataTypeDisplay: displayType,
            historyTableData: undefined
        }, function(){
            obj.setState(obj.state); 
        });

        window._profitIsleOpenModal('fileHistoryModal', function(){
            if(!obj.state.historyTableData) {
                obj.getHistoryData(section)
            }
        });
    }
   
    getHistoryData(section) {
        var obj= this;
        toggleLoader(true, 'getHistoryData');

        var query = {
            action: "getFileHistoryData",
            section: section
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      $.ajax({url: baseUrl+path, async: true, type: 'POST', crossDomain:true, xhrFields: { withCredentials: true }, data:JSON.stringify(query),
            success:function(result, response, xhr){
                var data = JSON.parse(result);
                if(data !== null && data.hasOwnProperty("ERROR")){
                    Popup.alert(data.ERROR);
                } else {
                    obj.setState({
                        historyTableData: data.data
                    }, function(){
                        obj.setState(obj.state);
                    });   
                }
                toggleLoader(false, 'getHistoryData');
            },
            complete: function(xhr, textStatus) {
                logoutIfUnauthenticated(xhr, obj);
            }
        });
    }

    resetDefineFileModal() {
        this.setDefineCrosswalkDialogOpen(false);
        $(".definePLFileTable").css('border', '0');
        this.getAllFileTypeColumns();
        if(this.linkRef) {
            this.linkRef.state.startPeriod = undefined;
            this.linkRef.state.endPeriod = undefined;
            this.linkRef.state.linksRef = [];
            this.linkRef.state.numberOfIterations = 0;
            this.linkRef.forceUpdate();
        }
        this.setState({
            defineTableData: [],
            defineDisplayType: "",
            defineAsReadOnly: false,
            pAndLData: [{
                name: 'Revenue',
                expected_value: "",
                confirmed_value: "",
            },{
                name: 'COGS',
                expected_value: "",
                confirmed_value: "",
            },{
                name: 'Profit',
                expected_value: "",
                confirmed_value: "",
            }],
            fileResponseHeaders: null,
            fileResponseColumns: null,
            fileResponse: null,
            auditMesage:"",
            boolMultiValue: "",
            multiValues:[],
            transactionFile: undefined,
            transactionFileId:undefined,
            transactionKey:undefined
        });

        definition = [];
        //replacing the table data after the user changes anything
        var table = this.tableReferences[DEFINE_DATA].current.tabulator;
        table.clearData();
        table.redraw();
    }

    prepareRawFileDefinition(tableType, subSection, rowData) {
        let _this = this;
        this.resetDefineFileModal();
        let tempState = {};
        let cb = ()=>{}

        if(!subSection) {
            subSection = "";
            //var table = this.tableReferences[tableType].current.tabulator;
        }
        if (rowData) {
            tempState.crosswalkFile = rowData;
        }
        // set default subsection value in define popup
        tempState.selectedSection = tableType;
        subSection = subSection === "" && this.state.definedFileSubtype[this.state.selectedSection] && this.state.definedFileSubtype[this.state.selectedSection].length === 1 ? this.state.definedFileSubtype[this.state.selectedSection][0].value : subSection;
        tempState.selectedSubSection = subSection ;     //no need for re-render

        if(tableType === P_AND_L) {
            if(this.state.atLeastOnePassedFileForPandL !== true) {
                Popup.alert(ALERT_DEFINE_MESSAGE);
            } else{
                this.getClientPeriodTieOff(false); 
            }
        } else if (tableType === CROSSWALK) {
            var uploadedCurr = this.state.cwData.filter(el=>el.file_type === CROSSWALK && el[UPLOAD_FIELDS.FILE_SUBTYPE] === subSection && DATA_DICTIONARY_ENUM[el[UPLOAD_FIELDS.UPLOADED_STATUS_FIELD]] === DATA_DICTIONARY_ENUM.passed);
            if (uploadedCurr && !rowData) {
                rowData = uploadedCurr[uploadedCurr.length-1];
                tempState.crosswalkFile = rowData;
            }
            tempState.defineDisplayType = rowData[UPLOAD_FIELDS.DISPLAY_FILE_SUBTYPE];
            tempState.raw_file_id = rowData.raw_file_id;
            this.setState(tempState)
            cb = function(){
                _this.fetchCrosswalkLinks(rowData);
                _this.setDefineCrosswalkDialogOpen(true);
            }
        } else {
            var defineTable = this.tableReferences[DEFINE_DATA].current.tabulator;
            var tableColumns = defineTable.getColumnDefinitions();

            if(subSection && subSection !== "") {
                $(".define-submit").addClass("uk-button-disabled");
                this.getRawFileDefinitionList(tableType, tableColumns, undefined,subSection);
            } else {
                if ( $('#'+tableType).find(".tabulator-table").children().length > 0 ) {
                    window._profitIsleOpenModal('defineFileModal');
                } else{
                    tempState.transactionKey = "";
                    tempState.transactionFile = "";
                    tempState.transactionFileId = "";
                    
                    cb = function(){
                        $("#dots_loader").removeClass("uk-display-flex");
                        $("#dots_loader").css('display','none');
                        _this.getAllFileTypeColumns();
                        Popup.alert(ALERT_DEFINE_MESSAGE);
                    }
                }
            }
        }

        this.setState(tempState, cb);
    }
    /**
     * 
     * define crosswalkd dialog functions
     */
    setDefineCrosswalkDialogOpen = (isOpen) => {
        let _this = this;
        _this.setState({
            openDefineCrosswalk: isOpen,
        });
    };

    defineCrosswalkDialogContent = () => {
        return(
            <div className="uk-modal-body">
                <div className="uk-flex uk-flex-between">
                    <div className="uk-flex-inline uk-flex-middle">
                        <h4 className="fs-20">{MESSAGES.UPLOAD.define_crosswalk}</h4>
                        <i className="fal fa-info-circle uk-margin-default-left" uk-tooltip={MESSAGES.UPLOAD.define_message_crosswalk}  title={MESSAGES.UPLOAD.define_message_crosswalk} />
                    </div>
                    </div>
                <div className="fs-14 uk-margin-small-top-bottom">{"Subcategory: " + this.state.defineDisplayType }</div>
                <div className="fs-14 uk-margin-medium-bottom">{!!this.state.crosswalkFile ? "File Name: " + this.state.crosswalkFile[UPLOAD_FIELDS.FILE_NAME_FIELD] : ""} </div>
                <div id="define-dropdown-table" className="uk-background-default uk-border">
                    <LinkDefinition ref={el=>this.linkRef=el} clientPeriods={this.state.clientPeriods} fileTypes={this.state.allFileTypes} dataColumns={this.state.dataColumns}
                    sectionInfo={this.state.sectionInfo || []} getSectionStatus={this.getSectionStatus} showMatchingEntityModal={this.showMatchingEntityModal} setMatchingEntityData={this.setMatchingEntityData}
                    crosswalkSubcategory={this.state.selectedSubSection} crosswalkFile={this.state.crosswalkFile} crosswalkColumns={this.state.crosswalkColumns} deleteFiles={this.deleteFiles} 
                    userAllowedSections = {this.props.userAllowedSections} setDeleteDialogOpen={this.setDeleteDialogOpen}/>
                </div>
            </div>
        );
    };
    
    defineCrosswalkDialogActions = () => {
        let hideDefineSubmit = this.state.selectedSubSection === undefined || this.state.selectedSubSection === "";
        return (
            <div className="uk-flex uk-flex-right uk-margin-small-top uk-margin-medium-right uk-margin-default-bottom uk-margin-medium-left">
                <Button  
                    id="cancel-define-data-btn"
                    label={MESSAGES.modal.buttons.cancel}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    data-dismiss="modal"
                    onBtnClick={this.resetDefineFileModal}
                />
                {!this.state.defineAsReadOnly && !hideDefineSubmit?
                        <Button 
                            id="submit-define-data-btn"
                            label={MESSAGES.modal.buttons.submit}
                            variant={BUTTON_VARIANT.PRIMARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}  
                            disabled={this.state.isFileDefined && this.state.selectedSection !== CROSSWALK }
                            className={"uk-margin-default-left define-submit"}
                            onBtnClick={this.defineCrosswalkFile}
                        />
                : ""}
            </div>
        );
    };
      
    /**
     * 
     * maching entity dialog functions 
     */

    setMatchingEntityDialogOpen= (isOpen) => {
        let _this = this;
        _this.setState({
            openMatchingEntityDialog: isOpen,
        });
    };

    setPageSize = (pageSize) => {
      if(Number(pageSize) === 5){
        $(".dialog-body").addClass("overflow_hidden")
      } else {
        $(".dialog-body").removeClass("overflow_hidden")
      }
    }

    matchingEntityDialogContent = () => {
        let _this = this;
        const { crosswalkEntities } = this.state;
        return(
            <div className="uk-modal-body">
                <div className="uk-flex uk-flex-between">
                    <div className="uk-flex-inline uk-flex-middle">
                        <h4 className="fs-20">Entity Matching Summary</h4>
                        <i className="fal fa-info-circle uk-margin-default-left" uk-tooltip={MESSAGES.UPLOAD.matchingEntity_title}/>
                    </div>
                </div>
                <div className="fs-14 uk-margin-small-top-bottom">{"Subcategory Column: " + this.state.subcategoryColumn}</div>
                <div className="fs-14 uk-margin-small-bottom">{"Crosswalk Column:" + this.state.crosswalkColumn}</div>
                <div className="crosswalk-table uk-border">
                    <div className="mapping-header uk-flex-between uk-padding-large-right">
                        <div className="text-link fs-12 uk-text-decoration-style-dashed" uk-tooltip={this.state.matching_tooltip}>{"Matching " + this.state.matching_message} </div>
                        <div className="text-grey fs-12">{"Data from " + this.state.selectedLinkStartPeriod + " to " + this.state.selectedLinkEndPeriod} </div>
                    </div>
                    <ReactTable
                        data={crosswalkEntities}
                        columns={[
                            {
                                Header: this.state.subcategoryColumn,
                                accessor: "Denominator",
                                /*Filterable: true,*/
                                Cell: row => (
                                    <div style={{textAlign:"center"}} id="fields">{row.original.Denominator}</div>
                                ),
                            },
                            {
                                Header: this.state.crosswalkColumn,
                                accessor: "Numerator",
                                Cell: row => (
                                    <div style={{textAlign:"center"}} className={(!row.original.Numerator || row.original.Numerator === "") ? "red" : ""} id="fields">
                                        {(row.original.Numerator === "" || !row.original.Numerator) ?  "No match" : row.original.Numerator}
                                    </div>
                                )
                            }
                        ]}
                        sortable ={true}
                        filterable={true}
                        defaultPageSize={5}
                        showPagination = {true}
                        defaultSorted={[{ id: 'Denominator', asc: true }]}
                        page={this.state.page}
                        onPageChange={page => this.setPage(page)}
                        onPageSizeChange = {pageSize => this.setPageSize(pageSize)}
                    />
                </div>
            </div>
        );
    };
    
    matchingEntityDialogActions = () => {
        return (
            <div className="uk-flex justify-content-end uk-padding-large-right uk-padding-small-bottom">
                <Button 
                    label={lang.modal.buttons.close}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}  
                    data-dismiss="modal"
                    onBtnClick={()=>this.setMatchingEntityDialogOpen(false)}
                />
            </div>
        );
    };


    /**
     * delete link dialog functions
     * 
     */

    setDeleteDialogOpen = (isOpen) => {
        let _this = this;
        _this.setState({
            openDeleteModal: isOpen,
        });
    };

    deleteDialogContent = () => {
        return <h5 className="fs-20">{lang.UPLOAD.delete_link_confirmation + lang.COMMON.sure_continue}</h5>;
    };
    
    deleteDialogActions = () => {
        return (
            <div className="uk-flex uk-flex-right uk-margin-small-top uk-margin-medium-right uk-margin-default-bottom uk-margin-medium-left">
                <Button  
                    id="cancel-define-data-btn"
                    label={lang.modal.buttons.no}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    data-dismiss="modal"
                    onBtnClick={()=>this.setDeleteDialogOpen(false)}
                />
                    <Button 
                        id="submit-define-data-btn"
                        label={lang.modal.buttons.yes}
                        variant={BUTTON_VARIANT.PRIMARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}  
                        className={"uk-margin-default-left define-submit"}
                        onBtnClick={this.linkRef?.hideCrosswalkLink}
                    />
            </div>
        );
    };

    /**
     * delete file dialog functions
     * 
     */

    setDeleteReplaceDialogOpen = (isOpen) => {
        let _this = this;
        _this.setState({
            openDeleteReplaceModal: isOpen,
        });
    };

    deleteReplaceDialogContent = () => {
        return<h5 className="uk-padding-large">{MESSAGES.UPLOAD.deleting} <span className="crosswalk-file-name">{this.state.fileDetails}</span> {this.state.deleteOrReplaceMessage}</h5>;
    };
    
    deleteReplaceDialogActions = () => {
        return (
            <div className="uk-flex uk-flex-right uk-margin-small-top uk-margin-medium-right uk-margin-default-bottom uk-margin-medium-left">
                <Button  
                    label={MESSAGES.modal.buttons.no}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    data-dismiss="modal"
                    className="uk-margin-default-right"
                    onBtnClick={()=>this.setDeleteReplaceDialogOpen(false)}
                />
                <Button 
                    label={MESSAGES.modal.buttons.yes}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}  
                    data-dismiss="modal"
                    onBtnClick={this.state.deleteReplaceCallback}
                />
            </div>
        );
      };

      /**
     * save crosswalk dialog functions
     * 
     */

    setSaveCrosswalkDialogOpen = (isOpen) => {
        let _this = this;
        _this.setState({
            openSaveCrosswalkModal: isOpen,
        });
    };

    deleteSaveCrosswalkContent = () => {
        return<h5 className="uk-padding-large">{this.state.saveCrosswalkMessage}</h5>;
    };
    
    deleteSaveCrosswalkActions = () => {
        return (
            <div className="uk-flex uk-flex-right uk-margin-small-top uk-margin-medium-right uk-margin-default-bottom uk-margin-medium-left">
                <Button 
                    label={MESSAGES.modal.buttons.ok}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}  
                    data-dismiss="modal"
                    onBtnClick={()=>this.setSaveCrosswalkDialogOpen(false)}
                />
            </div>
        );
      };

    fetchCrosswalkLinks(rowData) {
        let _this = this;
        var query = {
            action: "fetchCrosswalkLinks",
            subSection: this.state.selectedSubSection,
            rawFileId: rowData[UPLOAD_FIELDS.RAW_FILE_ID_FIELD],
        }

        let onThenCallback = (data) => {
            if(data.error && data.error.message) {
                return;
            }
            let tempState = {};
            if (data.links && data.links.length > 0) {
                tempState.links = data.links;
            }
            if (data.otherLinks && data.otherLinks.length > 0) {
                tempState.otherLinks = data.otherLinks;
            }
            if(data.uniqueCrosswalkColumns) {
                tempState.crosswalkColumns = {
                    id: shortid.generate(),     //setting id for fast comparison in child
                    columns: data.crosswalkColumns,      //non unique - for target
                    uniqueColumns: data.uniqueCrosswalkColumns  //unique - for source
                }
            }

            _this.setState(tempState,function(){
                this.linkRef.setLinks(data.links);
            })
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "fetchCrosswalkLinks",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]:  true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.upload_data.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.upload_data.requests_description.fetch_crosswalk_links
        };

        fetchAPI(fetchOptions);
    }

    saveCrosswalkLinks() {
        let _this = this;
        var query = {
            action: "saveCrosswalkLinks",
            crosswalkLinks: JSON.stringify(this.linkRef.getLinksData()),
            rawFileId: this.state.crosswalkFile[UPLOAD_FIELDS.RAW_FILE_ID_FIELD],
            subSection: this.state.selectedSubSection,
        }
     
        let onThenCallback = (data) => {
            let msg = MESSAGES.UPLOAD.links_saved_success;
            if(data.error  && data.error.message) {
                msg = data.error.message;
            }
            _this.getRawFileList();
            _this.setState({
                saveCrosswalkMessage: msg
            }, function(){
                _this.setSaveCrosswalkDialogOpen(true);
            })
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "saveCrosswalkLinks",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]:  true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.upload_data.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.upload_data.requests_description.save_crosswalk_links
        };

        fetchAPI(fetchOptions);
    }
    
    getRawFileDefinitionList(section, tableColumns, clickedRawFileId, subSection) {
        window._profitIsleOpenModal('defineFileModal');  
        var subSection = subSection && subSection !== "" ?  subSection :  this.state.selectedSubSection; 
        clickedRawFileId = clickedRawFileId || -1;
        toggleLoader(true, 'getRawFileDefinitionList');
        var obj = this;
        var query = {
            action: "getLastUploadedFileDefinitionList",
            section: section,
            rawFileId: clickedRawFileId,
            subSection: subSection,
            isNewArch:false
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
        .then((response) => {
            if(response.status === 403) {
                this.logout();
            }
            return response.json()})
        .then((data) => {
            if(data.error && data.error.message) {
                Popup.alert(data.error.message);
                window._profitIsleCloseModal('defineFileModal');            
                toggleLoader(false, 'getRawFileDefinitionList');
                return;
            }
            
            var defineAsReadOnly = false;
            var columnNames = JSON.parse(data.file.response).headers;
            var rawFileId = data.file[UPLOAD_FIELDS.RAW_FILE_ID_FIELD];
            var isFileDefined = [DATA_DICTIONARY_ENUM.passed, DATA_DICTIONARY_ENUM.failed_define, DATA_DICTIONARY_ENUM.failed_redefine].indexOf(DATA_DICTIONARY_ENUM[data.file[UPLOAD_FIELDS.UPLOADED_STATUS_FIELD]]) !== -1;
            var tempDefineTableData = data.fileDefinition;   //if the file is already defined, we have to fill its definition data in the table
            let cwColumnNames = tempDefineTableData.filter(col=>col[RAW_ITEMS.FIELD_NAME].toLowerCase().includes(PI_CW.toLowerCase()) && !columnNames.includes(col[RAW_ITEMS.FIELD_NAME].toLowerCase())).map(col=>{return col[RAW_ITEMS.FIELD_NAME]});
            columnNames = columnNames.concat(cwColumnNames);
            var distinctValuesAndCount = data.distinctValues;
            var mandatorySectionFields = data.mandatoryFields;
            var dataTypesFields = data.dataTypesFields;
            var fileResponse = data.fileResponse;
            var fileSamples = data.fileSamples || [];   //may exist (only if the file is not defined, else it will be part of fileDefintion)
            var fileResponseHeaders = JSON.parse(data.fileResponseHeaders);
            var fileResponseColumns = JSON.parse(data.fileResponseColumns);
            var transactionData = data.transactionData;
            var defineTableData = this.setDefineFileColumnNames(columnNames, tableColumns, distinctValuesAndCount, tempDefineTableData, rawFileId, dataTypesFields, fileSamples);
            var selectedMandatoryFields = this.getSelectedSections(defineTableData);

            var displayType = data.file.display_file_type;
            if(obj.state.selectedSubSection.length) {
                displayType += " - " + data.file.display_file_subtype;
            }    
            displayType += " (" + data.file.file_name + ")";

            if(clickedRawFileId !== -1) {
                defineAsReadOnly = true;
            }

            defineTableData.forEach(function(currentItem) {
                currentItem["id"] = Number(currentItem["row_number"]);
            });
            
            initialDefinition = copyObjectValues(defineTableData);
            definition = defineTableData;
            mandatorySectionFieldsVar = mandatorySectionFields;      //this state will be edited
            mandatorySectionFields_selectedVar = selectedMandatoryFields;
            mandatorySectionFields_uneditableVar = mandatorySectionFields;   //this state is not to be edited, will be a reference
            dataTypesFieldsVar = dataTypesFields;
            var transactionKey = undefined;
            var transactionFile = undefined;
            var transactionFileId = undefined;
            if ((this.state.selectedSection === MASTER_DATA || this.state.selectedSection === ANCILLARY_FILES) && subSection !== "") {
                if(transactionData.filter(e=>e["file_type"] === subSection).length > 0 ){
                    transactionKey = transactionData.filter(e => e["file_type"] === subSection && e.section === this.state.selectedSection)[0]["mapping_column"] ? transactionData.filter(e => e["file_type"] === subSection && e.section === this.state.selectedSection)[0]["mapping_column"] : transactionKey
                    transactionFileId = transactionData.filter(e => e["file_type"] === subSection && e.section === this.state.selectedSection)[0]["mapping_file_type_id"] ? transactionData.filter(e => e["file_type"] === subSection && e.section === this.state.selectedSection)[0]["mapping_file_type_id"] : ""
                    transactionFile = obj.state.dataColumns.filter(e => e["raw_file_subtype_id"] === transactionFileId).length > 0 ? this.state.dataColumns.filter(e => e["raw_file_subtype_id"] === transactionFileId)[0]["file_type"] : ""
                } 
            }
            var fileTypeObj = {subSection: transactionFile, column: transactionKey};
            this.setState({
                transactionData: transactionData,
                definedFileName: data.file.file_name,
                defineAsReadOnly: defineAsReadOnly,    //allow user to edit data
                defineDisplayType: displayType,
                isFileDefined: isFileDefined,
                fileResponse: fileResponse,
                fileResponseHeaders: fileResponseHeaders,
                fileResponseColumns: fileResponseColumns,
                transactionKey: transactionKey,
                transactionFile: transactionFile,
                transactionFileId: transactionFileId,
                mappingFileTypes: this.state.selectedSection === MASTER_DATA ? obj.state.mappingFileTypes.filter(e=>e["value"] !== obj.state.selectedSubSection ) :obj.state.mappingFileTypes,
                definedFileNumber: data.file.file_number,
            }, function(){
                $("#dots_loader").removeClass("uk-display-flex");
                $("#dots_loader").css('display','none');
                if (this.state.selectedSection === MASTER_DATA || this.state.selectedSection === ANCILLARY_FILES) {
                    this.getTransactionColumns(fileTypeObj);
                }
                this.getCurrencyPrerequistes();
            });

            // this.forceUpdate();     //to force an extra render to receive the mandatorySectionFields_selected prop

            toggleLoader(false, 'getRawFileDefinitionList');
        })
        .catch((error)=>{
            toggleLoader(false, 'getRawFileDefinitionList');
            alertAndLogError(error);
        });
    }

    getCurrencyPrerequistes() {
        var columns = this.state.dataColumns;
        var filesString = [];
        var currencySubsections = [];
        columns.map(function(item){
            if(!filesString.includes(item.display_file_type) && item.data_file_type === CURRENCY){
                currencySubsections.push({value:item.file_type, label:item.display_file_type , data_file_type:item.data_file_type , raw_file_subtype_id:item.raw_file_subtype_id});
                filesString.push(item.display_file_type);
            }
        });
        this.setState({
            currencySubSections: currencySubsections
        },function(){
            this.getDistinctCurrencyValues();
        })
    }

    fetchCurrencyColumns(option) {
        var columns = this.state.dataColumns;
        var columnsCurrency = copyObjectValues(this.state.dataColumns);
        var currencyDateColumnsOrg = copyObjectValues(this.state.dataColumns);
        var showCurrencyDate = false;
        var obj = this;
        var dataColumns = [];
        var displayColumns = [];
        var displayDateColumns = [];
        var currencyDateColumns = [];
        // columns of type string of the selected subsection file
        columns = columns.filter(e=>e.file_type === obj.state.selectedSubSection && obj.state.selectedSection === e.data_file_type && (e[RAW_ITEMS.FIELD_DATA_TYPE] === DATATYPE_ENUM.STRING.toUpperCase() || e[RAW_ITEMS.FIELD_DATA_TYPE] === ""));
        if (columns.length === 0) { // not defined file => columns wont be fetched so we get them from tableData
            columns = this.tableReferences[DEFINE_DATA].current.tabulator.getData().filter(e=>e[DEFINE_FIELDS.DATA_TYPE_FIELD] === DATATYPE_ENUM.STRING.toUpperCase() || e[DEFINE_FIELDS.DATA_TYPE_FIELD] === "" || !e[DEFINE_FIELDS.DATA_TYPE_FIELD])
        }
        columns.map(function(item){
            displayColumns.push({value:item[RAW_ITEMS.FIELD_NAME], label:item[RAW_ITEMS.FIELD_NAME] , data_file_type:item.data_file_type , raw_file_subtype_id:item.raw_file_subtype_id});
        });
        // checking if curreny file selected is defined be period or date
        var currencyColumns = columnsCurrency.filter(e=>e[RAW_ITEMS.NAME].toLowerCase() === CURRENCY_FIELDS.DATE.toLowerCase() && e[RAW_ITEMS.SUBTYPE_NAME] === option.value);
        if (currencyColumns && currencyColumns.length > 0) {
            showCurrencyDate  = true; // fetching columns of type datetime from selected subsection file
            currencyDateColumns = currencyDateColumnsOrg.filter(e=>e.file_type === obj.state.selectedSubSection && e[RAW_ITEMS.FIELD_DATA_TYPE] === DATATYPE_ENUM.DATETIME.toUpperCase());
            if (currencyDateColumns.length === 0 ) {
                currencyDateColumns = this.tableReferences[DEFINE_DATA].current.tabulator.getData().filter(e=>e[DEFINE_FIELDS.DATA_TYPE_FIELD] === DATATYPE_ENUM.DATETIME.toUpperCase())
                
            }
            currencyDateColumns.map(function(item){
                displayDateColumns.push({value:item[RAW_ITEMS.FIELD_NAME], label:item[RAW_ITEMS.FIELD_NAME] , data_file_type:item.data_file_type , raw_file_subtype_id:item.raw_file_subtype_id});
            });
        }
        var currencies = this.state.currencies.filter(e=>e.name === option.value);
        dataColumns.push({label:MESSAGES.UPLOAD.static_currency, value: MESSAGES.UPLOAD.static_currency , isDisabled:true});
        dataColumns = dataColumns.concat(currencies);
        dataColumns.push({label:MESSAGES.UPLOAD.currency_column, value: MESSAGES.UPLOAD.currency_column , isDisabled:true});
        dataColumns = dataColumns.concat(displayColumns);
        this.setState({
            currencyColumns: dataColumns,
            showCurrencyDate: showCurrencyDate,
            currencyDateColumns:displayDateColumns
        })
        
    }

    getDistinctCurrencyValues() {
        var obj = this;
        var query = {
            action: "getDistinctCurrencyValues",
            scenario_id: this.props.scenarioId,
            isNewArch: false
        }
        
        toggleLoader(true, 'getAllFileTypeColumns');
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      $.ajax({
            url: baseUrl+path,
            async: true,
            type: 'POST',
            crossDomain: true,
            xhrFields: { withCredentials: true },
            data: JSON.stringify(query),
            success: function (response, result, xhr) {
                response = JSON.parse(response);
                var currencies = [];
                response.data.map(function(item){
                    currencies.push({value:item.value, label: item.value, isStatic:true, name: item.name});
                });
                obj.setState({
                    currencies: currencies
                })
            },
            error: function(error) {
                alertAndLogError(error);
            },
            complete: function() {
                toggleLoader(false, 'getAllFileTypeColumns');
            }
        })
    }

    handleCurrencyChange(e, type) {
        if (type === RAW_ITEMS.FILE) {
            this.setState({
                currencySubSection: e,
                currencyColumn: undefined,
                currencyDate: undefined
            },function(){
                this.fetchCurrencyColumns(e);
            })
        } else if (type === RAW_ITEMS.FIELD_NAME) {
            this.setState({
                currencyColumn: e
            },function(){
                this.runMatchingCurrenciesAudit()
            })
        } else {
            this.setState({
                currencyDate: e
            })
        }
    }

    runMatchingCurrenciesAudit() {
        var obj = this;
        var query = {
            action: "runMatchingCurrenciesAudit",
            scenario_id: this.props.scenarioId,
            fileType: this.state.currencySubSection.value,
            fileTypeDenom: this.state.selectedSubSection,
            fieldName: this.state.currencyColumn.value,
            section: this.state.selectedSection,
            isStatic: this.state.currencyColumn.isStatic && this.state.currencyColumn.isStatic === true ||  this.state.currencyColumn.isStatic === "true" ? "true" : "false",
            period: this.state.selectedPeriod,
            fileNumber: this.state.definedFileNumber
        }
        
        toggleLoader(true, 'runMatchingCurrenciesAudit');
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      $.ajax({
            url: baseUrl+path,
            async: true,
            type: 'POST',
            crossDomain: true,
            xhrFields: { withCredentials: true },
            data: JSON.stringify(query),
            success: function (response, result, xhr) {
                response = JSON.parse(response);
                var message = response.currency_subcategory+"/"+response.transaction_currency;
                if (response.currency_subcategory === response.transaction_currency) {
                    $("#matchingCurrency").removeClass("red");
                }else{
                    $("#matchingCurrency").addClass("red");
                }
                obj.setState({
                    currenciesMatchingData: response.result,
                    matching_message: message,
                    tooltip_message: response.currency_subcategory + " of " + response.transaction_currency +
                     " ( "+ (Number(response.currency_subcategory) /Number(response.transaction_currency)) *100 +"% ) entities in " + capitaliseFirstLetterAfterChar(obj.state.selectedSubSection.replaceAll("_"," "))+ " currency match."
                })
            },
            error: function(error) {
                alertAndLogError(error);
            },
            complete: function() {
                toggleLoader(false, 'runMatchingCurrenciesAudit');
            }
        })
    }

    clearConfiguration(rowData, notclear) {
        var row = {};
        var data = this.tableReferences[DEFINE_DATA].current.tabulator.getData();
        for(var e in data) {
            if (notclear === undefined) {
                if (data[e][RAW_ITEMS.FIELD_NAME] === rowData[DEFINE_FIELDS.FIELD_NAME_FIELD]) {
                    data[e][DEFINE_FIELDS.CURRENCY_SUBTYPE_ID] = undefined;
                    data[e][DEFINE_FIELDS.CURRENCY_FIELD_NAME] = undefined;
                    data[e][DEFINE_FIELDS.STATIC] = undefined;
                    data[e][DEFINE_FIELDS.CURRENCY_DATE]= undefined;
                    data[e].currency_checked = false;
                    row = data[e];
                }
            }else if (data[e][RAW_ITEMS.FIELD_NAME] === rowData[DEFINE_FIELDS.FIELD_NAME_FIELD]) {
                data[e].currency_checked = true;
                row = data[e];
            }
        }
        // $(".define-submit").removeClass("uk-button-disabled");
        this.tableReferences[DEFINE_DATA].current.tabulator.replaceData(data);
        this.tableReferences[DEFINE_DATA].current.tabulator.scrollToRow(row[DEFINE_FIELDS.ROW_NUMBER_FIELD]);
    }

    getRowData() {
        this.setState({
            rData: (this.tableReferences[CROSSWALK]).getRowData()
        })
    }

    saveconfiguration() { 

        if (!this.state.currencyColumn || !this.state.currencyColumn.value|| !this.state.currencySubSection) {
            Popup.alert(MESSAGES.UPLOAD.fill_mandatoryFields);
            return false;
        }
        if (this.state.showCurrencyDate && !this.state.currencyDate) {
            Popup.alert(MESSAGES.UPLOAD.fill_mandatoryFields);
            return false;
        }
        var row = {};
        var obj = this;
        var data = this.tableReferences[DEFINE_DATA].current.tabulator.getData();
        for(var e in data) {
            if (data[e][RAW_ITEMS.FIELD_NAME] === obj.state.rowData[DEFINE_FIELDS.FIELD_NAME_FIELD]) {
                data[e][DEFINE_FIELDS.CURRENCY_SUBTYPE_ID] = !! this.state.currencySubSection ? this.state.currencySubSection[RAW_ITEMS.SUBTYPE_ID] : undefined;
                data[e][DEFINE_FIELDS.CURRENCY_FIELD_NAME] = !!this.state.currencyColumn ? this.state.currencyColumn.value :  undefined
                data[e][DEFINE_FIELDS.STATIC] = !!this.state.currencyColumn ?  this.state.currencyColumn.isStatic ? 'true' : 'false' :undefined;
                data[e][DEFINE_FIELDS.CURRENCY_DATE]= !!this.state.showCurrencyDate ?  this.state.currencyDate ? this.state.currencyDate.value : undefined : undefined;
                data[e][DEFINE_FIELDS.DEFINED_SUBTYPE_ID] = this.state.definedFileSubtype[this.state.selectedSection].filter(e=>e.value === obj.state.selectedSubSection)[0].id
                row = data[e];
            }
        }
        // $(".define-submit").removeClass("uk-button-disabled");
        definition = data;
        this.tableReferences[DEFINE_DATA].current.tabulator.replaceData(data);
        this.tableReferences[DEFINE_DATA].current.tabulator.scrollToRow(row[DEFINE_FIELDS.ROW_NUMBER_FIELD]);
        window._profitIsleCloseModal("configureModal");

    }

    setSelectedSubSection(cell) {
        var subSection = "";
        var displayType = cell.getRow().getData()["display_file_type"];
        if(cell.getTable().getGroups().length) {    //if table has groups, meaning is either ANCILLARY FILES or MASTER DATA
            subSection = cell.getRow().getData()["file_subtype"];
            displayType += " - " + cell.getRow().getData()["display_file_subtype"];
        }

        var section = cell.getTable().element.id;
        section = section;

        // this.state.selectedSubSection = subSection;
        // this.state.selectedSection = section;
        
        displayType += " (" + cell.getRow().getData()["file_name"] + ")";
        this.setState({
            selectedSubSection: subSection,
            selectedSection: section,
            defineDisplayType: displayType,
            defineAsReadOnly: true,     //define cannot be editable when opened from file name
        });
    }

    componentDidUpdate() {
        if(!this.state.getRawFileListReqSent) {
            this.getRawFileList();
        }
        // $("#history_data_table .tabulator-tableHolder").on("scroll", function(){
        //     $("*[id*=filter-dropdown-]:not('*[id*=filter-dropdown-cont]'):not('*[id*=filter-dropdown-button]').uk-open").hide();
        // });
            
        $('.expectedValues').on('copy paste cut', function(e) {
            e.preventDefault();
        });
        $('.confirmedValues').on('copy paste cut', function(e) {
            e.preventDefault();
        });   
    }
    
    componentDidMount() {
        //bind the local variable getRowGroupInfoAsHTML as the function of the same name in SectionFileInfo
        this.getRowGroupInfoAsHTML = this.fileInfoRef.current.getRowGroupInfoAsHTML.bind(this);
        this.getUploadDataSummary();
        this.getAllFileTypeColumns();
    }

    getAllFileTypeColumns() {
        var obj = this;
        var query = {
            action: "getAllFileTypeColumns",
            scenario_id: this.props.scenarioId,
            fileType: JSON.stringify(['master_data', 'transaction_data', 'ancillary_files','general_ledger', 'currency'])
        }
        
        toggleLoader(true, 'getAllFileTypeColumns');
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      $.ajax({
            url: baseUrl+path,
            async: true,
            type: 'POST',
            crossDomain: true,
            xhrFields: { withCredentials: true },
            data: JSON.stringify(query),
            success: function (response, result, xhr) {
                response = JSON.parse(response);
                var files = [];
                var filesString = [];
                response.data.map(function(item){
                if(!filesString.includes(item.display_file_type)){
                    files.push({value:item.file_type, label:item.display_file_type , data_file_type:item.data_file_type , raw_file_subtype_id:item.raw_file_subtype_id, mapping_column: item.mapping_column, mapping_file_type_id: item.mapping_file_type_id});
                    filesString.push(item.display_file_type);
                }
                });
                files.push({value:"empty-line", label:"" , data_file_type:"" , raw_file_subtype_id:""});
                obj.setState({
                    dataColumns: response.data,
                    mappingFileTypes: files,
                    arrangedFiles: arrangeFileNames(files,true)
                });
            },
            error: function(error) {
                alertAndLogError(error);
            },
            complete: function() {
                toggleLoader(false, 'getAllFileTypeColumns');
            }
        });
    }

    getTransactionColumns(fileTypeObj) {
        var _this = this;
        if (fileTypeObj.subSection === "empty-line") {
            this.setState({
                transactionColumns: []
            }); 
        }else{
            var dataType = definition.filter(e=>e["name"] === "Primary Key").length > 0 ? definition.filter(e=>e["name"] === "Primary Key")[0]["field_data_type"] : "";
            var data = this.state.dataColumns.filter(e=>e["file_type"] === fileTypeObj.subSection);
            let fields = [];
            data.map(function(item) { 
                if(item["field_data_type"].toUpperCase() === "STRING") {
                    fields.push({ 
                        label: item["raw_field_name"].replace("_pi_cw",PI_CW),
                        value: item["raw_field_name"].replace('pi_cw',PI_CW),
                        file_type: item["file_type"],
                        raw_file_subtype_id: item["raw_file_subtype_id"],
                        field_data_type:item["field_data_type"]
                    });
                }
            });
            this.setState({
                transactionColumns: fields
            },function(){
                _this.runMatchingKeysAudit();
            });
        }
    }

    getUploadDataSummary() {
        var obj = this;
        toggleLoader(true, 'getUploadDataSummary');
        var query = {
            action: "getUploadDataSummary"
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      $.ajax({url: baseUrl+path, async: true, type: 'POST', crossDomain:true, xhrFields: { withCredentials: true }, data:JSON.stringify(query),
            success:function(result, response, xhr){
                var response = JSON.parse(result);
                if(response.ERROR && response.ERROR.length) {
                    alertAndLogError();
                } else {
                    var data = response.data;
                    var completeUploads = 0;
                    var incompleteUploads = 0;
                    data.map(function(item){
                        if (item[SUMMARY_FIELDS.PENDING_FILES] === 0) {
                            completeUploads++;
                        } else {
                            incompleteUploads++;
                        }
                    });
                    obj.setState({
                        dataSummary: data,
                        completeUploads: completeUploads,
                        incompleteUploads: incompleteUploads
                    }, function () {
                        toggleLoader(false, 'getUploadDataSummary');
                    })
                }
            },
        });
    }

    runMatchingKeysAudit(primaryKey) {
        var obj = this;
        if (this.state.transactionKey === "" || this.state.transactionKey === undefined || this.state.transactionFile === "" || this.state.transactionFile === undefined
            || this.state.transactionFile === 'empty-line') {
                $("#dots_loader").removeClass("uk-display-flex");
                $("#dots_loader").css('display','none');
            return;
        }
        if (!primaryKey) {
            primaryKey = definition.filter(e=>e["name"] === "Primary Key").length > 0 ? definition.filter(e=>e["name"] === "Primary Key")[0]["raw_field_name"] : "";
        } else {
            primaryKey = primaryKey["raw_field_name"];
        }
        if (primaryKey === "" || !primaryKey) {
            return;
        }
        $("#dots_loader").addClass("uk-display-flex");
        // toggleLoader(true, 'runMatchingKeysAudit');
        var query = {
            action: "runMatchingKeysAudit",
            section : this.state.selectedSection,
            subSection: this.state.selectedSubSection,
            transactionKey: this.state.transactionKey,
            transactionFile: this.state.transactionFile,
            transactionFileId: this.state.transactionFileId,
            primaryKey: primaryKey,
            fileNumber: this.state.definedFileNumber
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      $.ajax({url: baseUrl+path, async: true, type: 'POST', crossDomain:true, xhrFields: { withCredentials: true }, data:JSON.stringify(query),
            success:function(result, response, xhr){
                var response = JSON.parse(result);
                if(response.ERROR && response.ERROR.length) {
                    alertAndLogError();
                    toggleLoader(false, 'runMatchingKeysAudit');
                } else {
                    var auditMesage = "Matching " + response.count +" of " + response.total;
                    obj.setState({
                        auditMesage :auditMesage
                    },function(){
                        $("#dots_loader").removeClass("uk-display-flex");
                        $("#dots_loader").css('display','none');
                        // toggleLoader(false, 'runMatchingKeysAudit');
                    });
                }
            },error:function(){
                $("#dots_loader").removeClass("uk-display-flex");
                $("#dots_loader").css('display','none')
                // toggleLoader(false, 'runMatchingKeysAudit');
            }
        });
    }

    validateJoinFile(fileType, subTypeId) {
        var _this = this;
        if (this.state.selectedSection !== MASTER_DATA)
            return true;
        if (fileType.data_file_type === TRANSACTION_DATA) // maped to transaction
            return true;
        if(fileType.mapping_column === null || !fileType.mapping_column || fileType.mapping_column === "") // not mapped
            return false;
        if(fileType.mapping_file_type_id === subTypeId) //mapped to the file we're mapping
            return false;
        var type = _this.state.mappingFileTypes.filter(e=>e.raw_file_subtype_id === fileType.mapping_file_type_id);
        if (type.length ===  0) {
            return false;
        }
        return this.validateJoinFile(type[0], subTypeId);
    }

    handleMappingFileChange(e) {
        var _this = this;
        var fileType = _this.state.mappingFileTypes.filter(elt=>elt.raw_file_subtype_id === e.raw_file_subtype_id);
        if (this.validateJoinFile(fileType[0],e.raw_file_subtype_id)) {
            if (e !== null) {
                $("#select-transactionFile").find('[class$="singleValue"]').text(e.label);
                $("#dots_loader").addClass("uk-display-flex");

                setTimeout(() => {
                    this.setState({
                        transactionKey: "",
                        auditMesage: "",
                        transactionFile: e.value,
                        transactionFileId: e.raw_file_subtype_id,
                    }, function () {
                        if (this.state.subSection !== "") {
                            // $(".define-submit").removeClass("uk-button-disabled");
                        }
                        _this.getTransactionColumns({ subSection: e.value });
                    });
                    $("#dots_loader").removeClass("uk-display-flex");
                    $("#dots_loader").css('display','none');
                }, 10);

            }
        } else{
            Popup.alert(MESSAGES.invalid_file);
        }
        
    }

    handleTransactionColumnChange(e) {
        var _this = this;
            if (e !== null) {
                $("#select-transactionColumn").find('[class$="singleValue"]').text(e.value);
                $("#select-transactionColumn").find('[class$="placeholder"]').text(e.value);
                $("#dots_loader").addClass("uk-display-flex");

                setTimeout(() => {
                    this.setState({
                        transactionKey: e.value,
                        auditMesage:""
                    },function(){
                        if (this.state.subSection !== "") {
                            // $(".define-submit").removeClass("uk-button-disabled");
                        }
                            setTimeout(() => {
                                _this.runMatchingKeysAudit();
                            }, 10);

                    });
                }, 20);

                $("#dots_loader").removeClass("uk-display-flex");
                $("#dots_loader").css('display','none');
            }
    }

    resetUploadPopupModal() {
        if (document.getElementById('fileName')){
            document.getElementById('fileName').textContent = MESSAGES.drag_and_drop_files;
        }
        $("#submitButtons").show();
        $("#uploadProgress").hide();
        $("#formFieldSet").removeAttr("disabled");
        $("#formFieldSet .Select").removeClass("is-disabled");
        $("#uploadPopupModal .close").removeAttr("disabled");
        $("#div-UploadFilePeriods .select-value").removeClass("emptyField");
        $("#div-uploadedFileSubtype").removeClass("emptyField");
        $("#fileSelectBox").removeClass("emptyField");
        var uploadStatus = document.getElementById("upload-status");
        uploadStatus.innerHTML = "";
        var div = document.getElementById("progressBar");
        div.children[0].innerHTML = "0%";
        div.children[0].style.width = "0%";
        var status = document.getElementById("result");
        status.innerHTML = "";
        var result = document.getElementById('result');
        result.className = "hidden";

        $("#upload-popup-form-")[0].reset();
        $("input[name=amendments]").prop('checked', false);
        $("input[name=amendments][value='" + Amendments.New + "']").prop('checked', true);
        $("#div-uploadedFilesFromBQ").addClass("hidden");
        $("#otherDelimiter").addClass("hidden");
        $("#close-btn").attr("disabled", true);
        this.state.multiValue = [];
        this.state.selectedUploadStartPeriod = undefined;
        this.state.selectedUploadEndPeriod = undefined;
        this.state.encryption = "yes";
        $(".switchBD").find("input[type='checkbox']").prop('checked',"yes")
    }
    
    updateSelectedAmendments() {
        var selectedAmendment = $("input[name='amendments']:checked").val();
        if(selectedAmendment === Amendments.Replace) {
            $("#div-uploadedFilesFromBQ").removeClass("hidden");
        } else {
            $("#div-uploadedFilesFromBQ").addClass("hidden");
        }
    }

    updateSelectedDelimiter() {
        var selectedDelimiter = $("input[name='delimiter']:checked").val();
        if(selectedDelimiter === Delimiters.Other) {
            $("#otherDelimiter").removeClass("hidden");
        } else {
            $("#otherDelimiter").addClass("hidden");
        }
    }

    getColumns(data) {
        return data.map(key => {
          return {
            Header: key,
            accessor: key
          };
        });
    }

    validateCurrencyPeriodDate() {
        if (this.state.selectedSection !== CURRENCY) {
            return true;
        }
        var tableData = this.tableReferences[DEFINE_DATA].current.tabulator.getData();
        var dateFound = tableData.filter(e=>e[DEFINE_FIELDS.DATA_FIELD] === CURRENCY_FIELDS.DATE);
        var periodFound = tableData.filter(e=>e[DEFINE_FIELDS.DATA_FIELD] === CURRENCY_FIELDS.PERIOD);
        if ((!dateFound && !periodFound)) {
            Popup.alert(MESSAGES.UPLOAD.define_period_or_date);
            return false;
        }else  if (dateFound && periodFound && dateFound.length> 0 && periodFound.length > 0) {
            Popup.alert(MESSAGES.UPLOAD.define_period_or_date);
            return false;
        } else if (dateFound && periodFound && dateFound.length === 0 && periodFound.length === 0){
            Popup.alert(MESSAGES.UPLOAD.define_period_or_date);
            return false;
        }else  {
            return true;
        }
        
    }

    validateConfigureColumns() {
        var obj = this;
        if (this.state.selectedSection === CURRENCY || this.state.selectedSection === P_AND_L || this.state.selectedSection === CROSSWALK) {
            return true;
        }
        var tableData = this.tableReferences[DEFINE_DATA].current.tabulator.getData();
        for (var e in tableData) {
            if ($("#chosenEntity-"+tableData[e][DEFINE_FIELDS.FIELD_NAME_FIELD]).prop('checked') && (!tableData[e][DEFINE_FIELDS.CURRENCY_SUBTYPE_ID] || !tableData[e][DEFINE_FIELDS.CURRENCY_FIELD_NAME])) {
                Popup.alert(MESSAGES.UPLOAD.missing_configuration);
                return false;
            }else if ($("#chosenEntity-"+tableData[e][DEFINE_FIELDS.FIELD_NAME_FIELD]).prop('checked') && tableData[e][DEFINE_FIELDS.STATIC] === 'false'  && tableData.filter(elt=>elt[RAW_ITEMS.FIELD_NAME].toLowerCase() === tableData[e][DEFINE_FIELDS.CURRENCY_FIELD_NAME].toLowerCase())[0][RAW_ITEMS.FIELD_DATA_TYPE] !== DATATYPE_ENUM.STRING.toUpperCase()) {
                Popup.alert(MESSAGES.UPLOAD.changed_type.replaceAll('{x}',obj.state.selectedSubSection).replaceAll('{y}',tableData[e][RAW_ITEMS.FIELD_DATA_TYPE]));
                return false;
            } else if ($("#chosenEntity-"+tableData[e][DEFINE_FIELDS.FIELD_NAME_FIELD]).prop('checked') && tableData[e][DEFINE_FIELDS.STATIC] === 'false' && tableData[e][DEFINE_FIELDS.CURRENCY_DATE]  && tableData.filter(elt=>elt[RAW_ITEMS.FIELD_NAME] === tableData[e][DEFINE_FIELDS.CURRENCY_DATE])[0][RAW_ITEMS.FIELD_DATA_TYPE] !== DATATYPE_ENUM.DATETIME.toUpperCase()) {
                Popup.alert(MESSAGES.UPLOAD.changed_type_datetime);
                return false;
            }
        }
        return true;
       
    }

    validateMandatorySectionFields() {
        if(!mandatorySectionFields_selectedVar) {
            return false;
        }

        var validated = true;
        var requiredFields = mandatorySectionFields_uneditableVar.filter(el => el.required === "t" || el.required === true); 
        for (var section in requiredFields) {
            section = requiredFields[section];
            if(mandatorySectionFields_selectedVar.indexOf(section.name) === -1) {
                validated = false;
                break;
            }
        }
        if ((this.state.transactionKey === "" || this.state.transactionKey === undefined) && this.state.selectedSection === MASTER_DATA) {
            validated = false;
        }
        if ((this.state.transactionKey === "" || this.state.transactionKey === undefined ) && this.state.selectedSection === ANCILLARY_FILES 
        && (this.state.transactionFile !== "" && this.state.transactionFile !== undefined && this.state.transactionFile !== "empty-line"  )) {
            validated = false;
        }
        var tableData = this.tableReferences[DEFINE_DATA].current.tabulator.getData();
        for (var e in tableData) {
            if (tableData[e][DEFINE_FIELDS.TRUE_INPUT_FIELD] === "" && tableData[e][DEFINE_FIELDS.FALSE_INPUT_FIELD] === "" && tableData[e][DEFINE_FIELDS.DATA_TYPE_FIELD] === Formats.Boolean) {
                Popup.alert(ALERT_BOOLEAN_MESSAGE);
                validated = 0;
            }
        }

        return validated;
    }

    confirmExpectedValues() {
        var obj = this;

        var revExp = $("#RevenueExpectedValues").val();
        var cogsExp = $("#COGSExpectedValues").val();
        var pExp = $("#ProfitExpectedValues").val();

        var revCon = $("#RevenueConfirmedValues").val();
        var cogsCon = $("#COGSConfirmedValues").val();
        var pCon = $("#ProfitConfirmedValues").val();

        if ((revExp !== revCon || revExp.trim() === "") || (cogsExp !== cogsCon || cogsExp.trim() === "") ||
            (pExp !== pCon || pExp.trim() === "")) {
            Popup.alert(ALERT_FILL_MESSAGE);
            $(".definePLFileTable").css('border', 'red '+ convertPxToViewport(2) +' solid');
        } else {
            obj.saveRawFileTieOff();
        }

    }

    defineFile() {
        var obj = this;
        if ($(".define-submit").hasClass('uk-button-disabled')){
            return;
        } else {
            var mandatoryFieldsValidated = this.validateMandatorySectionFields();
            var periodDateValidated = this.validateCurrencyPeriodDate()
            var configureValidated = this.validateConfigureColumns();
            if(mandatoryFieldsValidated === 0){
                return false;
            }
            if(!mandatoryFieldsValidated) {
                Popup.alert(ALERT_FILL_MESSAGE)
                return false;
            }
            if (!periodDateValidated) {
                return false;
            }
            if (!configureValidated) {
                return false;
            }
            this.validateDefinedFile();
        }
    }

    defineFileNewArch = () => {
        if ($(".define-submit").hasClass('uk-button-disabled')) {
            return;
        } else {
            let mandatoryFieldsValidated = this.validateMandatorySectionFields();
            let periodDateValidated = this.validateCurrencyPeriodDate()
            let configureValidated = this.validateConfigureColumns();
            if (mandatoryFieldsValidated === 0) {
                return false;
            }
            if (!mandatoryFieldsValidated) {
                Popup.alert(ALERT_FILL_MESSAGE)
                return false;
            }
            if (!periodDateValidated) {
                return false;
            }
            if (!configureValidated) {
                return false;
            }
            this.validateDefinedFileNewArch();
        }
    }

    defineCrosswalkFile() {
        this.validateCrosswalkFile();
    }

    validateCrosswalkFile(){
        var comp = this;
        var _in = false;
        var links = this.linkRef.state.linksRef;
        var startPeriod = this.linkRef.state.startPeriod;
        var endPeriod = this.linkRef.state.endPeriod;
        if (!startPeriod) {
            $("#select-link-start-period").find('div:first').addClass("uk-input-danger");
            return;
        } else if (!endPeriod) {
            $("#select-link-end-period").find('div:first').addClass("uk-input-danger");
            return;
        } else if(startPeriod && endPeriod && endPeriod !== PERIOD_ONWARD) {
            var startPeriodObj = comp.state.rawFileListData.periods.find(function(el) { return el.period_name === startPeriod });
            var endPeriodObj = comp.state.rawFileListData.periods.find(function(el) { return el.period_name === endPeriod });
            var startPeriodStartDate = Math.round(new Date(startPeriodObj.start_date).getTime()/1000);
            var endPeriodEndDate = Math.round(new Date(endPeriodObj.end_date).getTime()/1000);
            if(endPeriodEndDate < startPeriodStartDate) {
                $("#select-link-start-period").find('div:first').addClass("uk-input-danger");
                $("#select-link-end-period").find('div:first').addClass("uk-input-danger");
                Popup.alert(MESSAGES.UPLOAD.correct_periods);
                return;
            }
        }

        if(!links.length) {
            Popup.alert(MESSAGES.UPLOAD.no_links_defined);
            return;
        }
     
        for(var e in links) {
            if (links[e].state.row_status !== ROW_STATUS.VALUES.DELETED) {
                if(!links[e].linkObj[CROSSWALK_FIELDS.SOURCE_FIELD_NAME] || !links[e].linkObj[CROSSWALK_FIELDS.TARGET_FIELD_NAME]
                    || !links[e].linkObj[CROSSWALK_FIELDS.SOURCE_SUBTYPE] || !links[e].linkObj[CROSSWALK_FIELDS.TARGET_SUBTYPE]
                    || !links[e].linkObj[CROSSWALK_FIELDS.SOURCE_CROSSWALK_FIELD_NAME] || !links[e].linkObj[CROSSWALK_FIELDS.TARGET_CROSSWALK_FIELD_NAME]) {
                        Popup.alert(MESSAGES.UPLOAD.empty_drop_down);
                        return; // checking for empty dropdown
                } else if (links[e].linkObj[CROSSWALK_FIELDS.SOURCE_FIELD_NAME] === links[e].linkObj[CROSSWALK_FIELDS.TARGET_FIELD_NAME]
                    && links[e].linkObj[CROSSWALK_FIELDS.SOURCE_SUBTYPE] === links[e].linkObj[CROSSWALK_FIELDS.TARGET_SUBTYPE]) {
                    Popup.alert(MESSAGES.UPLOAD.same_source_target_file);
                        return; // checking for same source and target column
                }else if (links[e].linkObj[CROSSWALK_FIELDS.SOURCE_SUBTYPE_ID] === links[e].linkObj[CROSSWALK_FIELDS.TARGET_SUBTYPE_ID]) {
                    Popup.alert(MESSAGES.UPLOAD.same_source_target_subtype);
                        return; // checking for same source and target column
                } else if (links[e].linkObj[CROSSWALK_FIELDS.SOURCE_CROSSWALK_FIELD_NAME] === links[e].linkObj[CROSSWALK_FIELDS.TARGET_CROSSWALK_FIELD_NAME]) {
                    Popup.alert(MESSAGES.UPLOAD.same_source_target_crosswalk);
                        return; //checking for same source and target crosswalk column
                } else if (this.checkForIntersection(links[e], links).length > 0) {
                        var messages = this.checkForIntersection(links[e], links);
                        Popup.alert(MESSAGES.UPLOAD.same_source.replace('[xcolumnsx]',links[e].linkObj[CROSSWALK_FIELDS.SOURCE_FIELD_NAME]).replace('[xfilesx]',messages.join(",")));
                        return;
                }else if (links[e].linkObj[IS_PRIMARY]) {
                    _in = true;
                    var obj = this;
                    let callback = () => {}; 
                    callback = function(result) {
                       if(result === "false") { // checking for unique target values if source column is primary key 
                            Popup.alert(MESSAGES.UPLOAD.not_unique_target_column);
                            return;
                       }else{
                            obj.saveCrosswalkLinks();  
                            obj.setDefineCrosswalkDialogOpen(false)
                       }
                    }
                    links[e].runValidation(callback, links[e].linkObj);
                } 
            }
         
        }
        if(!_in) {
            this.setDefineCrosswalkDialogOpen(false)
            this.saveCrosswalkLinks();
        }
     
    }

    periodsIntersect(otherObj, obj) {
        let otherStartPeriod = this.state.clientPeriodsWithUploadedFiles.filter(el => el[PERIOD_ID] === otherObj[CROSSWALK_FIELDS.START_PERIOD_ID]);
        let otherEndPeriod = this.state.clientPeriodsWithUploadedFiles.filter(el=>el[PERIOD_ID] === otherObj[CROSSWALK_FIELDS.END_PERIOD_ID]);
        
        if (!otherStartPeriod.length || !otherStartPeriod || otherStartPeriod === null || !otherEndPeriod || otherEndPeriod === null ) {
            return false;
        }
        otherStartPeriod = otherStartPeriod[0].start_date;
        otherEndPeriod = otherEndPeriod[0] ? otherEndPeriod[0].end_date : new Date().toString();    //if not found, period isOnward

        var startPeriod = this.state.clientPeriodsWithUploadedFiles.filter(el=>el.value === obj.props.selectedStartPeriod)[0].start_date;
        var endPeriod = this.state.clientPeriodsWithUploadedFiles.filter(el=>el.value === obj.props.selectedEndPeriod);
        endPeriod = endPeriod[0] ? endPeriod[0].end_date : new Date().toString();    //if not found, period isOnward

        startPeriod = new Date(startPeriod);
        otherStartPeriod = new Date(otherStartPeriod);
        endPeriod = new Date(endPeriod);
        otherEndPeriod = new Date(otherEndPeriod);

        if (startPeriod <= otherStartPeriod && otherStartPeriod <= endPeriod 
            || otherStartPeriod <= endPeriod && endPeriod <= otherEndPeriod
            || startPeriod >= otherStartPeriod && endPeriod <= otherEndPeriod
            || otherStartPeriod >= startPeriod && otherEndPeriod <= endPeriod) {
            return true;
        }
        
        return false;
    }

    checkForIntersection(link, links) {
        var otherLinks = this.state.otherLinks;
        var messages = [];
        for (var e in otherLinks) {
            if (otherLinks[e][CROSSWALK_FIELDS.SOURCE_FIELD_NAME] === link.linkObj[CROSSWALK_FIELDS.SOURCE_FIELD_NAME] && 
                this.periodsIntersect(otherLinks[e], link) && 
                otherLinks[e][CROSSWALK_FIELDS.SOURCE_SUBTYPE_ID] === link.linkObj[CROSSWALK_FIELDS.SOURCE_SUBTYPE_ID] && 
                otherLinks[e][UPLOAD_FIELDS.RAW_FILE_ID_FIELD] !== link.linkObj[UPLOAD_FIELDS.RAW_FILE_ID_FIELD]) {
                    messages.push(otherLinks[e][CROSSWALK_FIELDS.FILE_NAME])
            }
        }
        var count = 0;
        for (var e in links) {
            if (links[e].state.row_status !== ROW_STATUS.VALUES.DELETED) {
                if (links[e].linkObj[CROSSWALK_FIELDS.SOURCE_FIELD_NAME] === link.linkObj[CROSSWALK_FIELDS.SOURCE_FIELD_NAME] && 
                    links[e].linkObj[CROSSWALK_FIELDS.SOURCE_SUBTYPE_ID] === link.linkObj[CROSSWALK_FIELDS.SOURCE_SUBTYPE_ID] && count!=0) {
                    messages.push(this.state.crosswalkFile[UPLOAD_FIELDS.FILE_NAME_FIELD]);
                } else if (links[e].linkObj[CROSSWALK_FIELDS.SOURCE_FIELD_NAME] === link.linkObj[CROSSWALK_FIELDS.SOURCE_FIELD_NAME] && 
                    links[e].linkObj[CROSSWALK_FIELDS.SOURCE_SUBTYPE_ID] === link.linkObj[CROSSWALK_FIELDS.SOURCE_SUBTYPE_ID] ) {
                    count++;
                }
            }
        }
        return messages;
    }

    validateDefinedFile() {
        var obj = this;
        toggleLoader(true, 'validateDefinedFile');

        //assign inside table - notice no "var"
        var table = this.tableReferences[DEFINE_DATA].current.tabulator;
        //you can then use it everywhere
        var defineTableData = table.getData();

        var query = {
            action: "validateDefinedFile",
            section: this.state.selectedSection, 
            subSection: this.state.selectedSubSection,
            fileName: this.state.definedFileName,
            tableTypeId: this.state.definedTableType,
            definedFields: JSON.stringify(defineTableData),
            transactionColumn: this.state.transactionKey,
            mappingFileId: this.state.transactionFileId           
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      $.ajax({url: baseUrl+path, async: true, type: 'POST', crossDomain:true, xhrFields: { withCredentials: true }, data:JSON.stringify(query),
            success:function(result, response, xhr){
                var response = JSON.parse(result);
                if(response.ERROR && response.ERROR.length) {
                    obj.setState({
                        afterDefineError: response.ERROR
                    });
                } else {
                    obj.setState({
                        afterDefineError: "",
                        transactionKey: "",
                        transactionFile: "",
                        transactionFileId: ""
                    },function(){
                        obj.getAllFileTypeColumns();
                    })

                    if(obj.state.selectedSection === GENERAL_LEDGER && obj.state.isCCStateDefined === false) {
                        //fill transitions if file passed, and was appended under General Ledger
                        obj.fillTransitionCombinations();
                    }
                }
                obj.fillValidations(response, "", true);
                obj.getRawFileList();
                obj.setState({
                    auditMesage: ""
                })
                window._profitIsleCloseModal('defineFileModal');            
                window._profitIsleOpenModal('afterDefineModal');
            },
            complete: function(xhr, textStatus) {
                logoutIfUnauthenticated(xhr, obj);
                toggleLoader(false, 'validateDefinedFile');
            }
        });
    }

    validateDefinedFileNewArch() {
        const obj = this;
        toggleLoader(true, 'validateDefinedFile');

        //assign inside table - notice no "var"
        const table = this.tableReferences[DEFINE_DATA].current.tabulator;
        //you can then use it everywhere
        const defineTableData = table.getData();

        const query = {
            action: "validateDefinedFileNewArch",
            section: this.state.selectedSection,
            subSection: this.state.selectedSubSection,
            fileName: this.state.definedFileName,
            tableTypeId: this.state.definedTableType,
            definedFields: JSON.stringify(defineTableData),
            transactionColumn: this.state.transactionKey,
            mappingFileId: this.state.transactionFileId
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      $.ajax({
            url: baseUrl + API_URL.DataPipeline, async: true, type: 'POST', crossDomain: true, xhrFields: {withCredentials: true}, data: JSON.stringify(query),
            success:function(result, response, xhr){
                var response = JSON.parse(result);
                if(response.ERROR && response.ERROR.length) {
                    obj.setState({
                        afterDefineError: response.ERROR
                    });
                } else {
                    obj.setState({
                        afterDefineError: "",
                        transactionKey: "",
                        transactionFile: "",
                        transactionFileId: ""
                    },function(){
                        obj.getAllFileTypeColumns();
                    })
                }
                obj.fillValidations(response, "", true);
                obj.getRawFileList();
                obj.setState({
                    auditMesage: ""
                })
                window._profitIsleCloseModal('defineFileModal');
                window._profitIsleOpenModal('afterDefineModal');
            },
            complete: function(xhr, textStatus) {
                logoutIfUnauthenticated(xhr, obj);
                toggleLoader(false, 'validateDefinedFile');
            }
        });
    }
    
    fillTransitionCombinations() {
        var obj = this;
        var query = {
            action: "fillTransitionCombinations"
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      $.ajax({url: baseUrl+path, async: true, type: 'POST', crossDomain:true, xhrFields: { withCredentials: true }, data:JSON.stringify(query),
            success:function(result, response, xhr) {
                if (result !== '') {
                    result = JSON.parse(result);
                    console.log("Finished inserting combinations, inserted " + result.data + " combinations.");
                }
            },
            error: function(error) {
                alertAndLogError(error);
            },
            complete: function(xhr, textStatus) {
                logoutIfUnauthenticated(xhr, obj);
            }
        });
    }
    
    setFileHeader(fileName, type) {
        this.setState({
            definedFileName: fileName,
            definedTableType: type
        });
    }

    updateUploadStatusResponse(response, flag){
        this.fillValidations(response, flag, false);
        this.setState({
			uploadResponseStatus: response.status ? response.status : "",
			uploadResponseHeaders: undefined,
			uploadResponseColumns: undefined,
			uploadResponseCount: response.total_count ? response.total_count : "",
            uploadResponseError: response.ERROR ? response.ERROR : "",
            fileStatus: response.fileStatus,
            clickPosition: flag
        });
        
        window._profitIsleOpenModal('afterSuccessResponseModal');
    }

    fillValidations(response, flag, rerender) {
        var uploadValidations = [];
        var uploadValidationsProgress = [];
        var updloadValidationsValue = "";

        if(response.validation) {
            response.validation.map((item, index) => {

                if (response.status !== DATA_DICTIONARY_ENUM.not_defined && response.status !== DATA_DICTIONARY_ENUM.failed_upload &&
                    response.status !== DATA_DICTIONARY_ENUM.failed_decrypt && response.status !== DATA_DICTIONARY_ENUM.pending_decryption &&
                    response.status !== DATA_DICTIONARY_ENUM.decrypting && response.status !== DATA_DICTIONARY_ENUM.decrypted &&
                    !(item.validation_message === "" && item.validation_status === "fail")) {
                        uploadValidations.push(item);    
                }
                
                uploadValidationsProgress.push(item);    

            });
        }

        if (flag === UPLOAD_FIELDS.PROGRESS_FIELD) {
            updloadValidationsValue = uploadValidationsProgress;
        } else {
            updloadValidationsValue = uploadValidations;
        }

        if(rerender) {
            this.setState({
                uploadValidations: updloadValidationsValue
            });
        } else {
            this.state.uploadValidations = updloadValidationsValue;
        }
    }

    onCheckBoxChecked(cell) {
        let section = cell.getTable().element.getAttribute("id").replace("&", "\\&");
        var sectionName = $("#" + section);
        let hasChecked = sectionName.find('input:checkbox[id='+cell.getRow().getData()[UPLOAD_FIELDS.FILE_SUBTYPE].replace("&", "\\&")+']:checked').length > 0;

        if(hasChecked) {
            $("[subType="+cell.getRow().getData()[UPLOAD_FIELDS.FILE_SUBTYPE].replace("&", "\\&")+"]").removeClass('disabled');
        } else {
            // $("[subType="+cell.getRow().getData()[UPLOAD_FIELDS.FILE_SUBTYPE]+"]").addClass('disabled');
        }        
    }

    toggleSection(e, section) {
        this.tableReferences[section].current.tabulator.setHeight(this.state.isSectionExpanded[section] ? 0 : 260); //expand the table

        //save that the section is expanded
        var isExpandedObj = copyObjectValues(this.state.isSectionExpanded);
        isExpandedObj[section] = !isExpandedObj[section];
        this.setState({
            isSectionExpanded: isExpandedObj
        });
    }

    resetBoolValues(){
        this.setState({
            boolMultiValue: "",
            multiValues:[]
        })
    }

    saveRawFileTieOff() {

        var revExp = $("#RevenueExpectedValues").val().replace('$','').replaceAll(',','');
        var cogsExp = $("#COGSExpectedValues").val().replace('$','').replaceAll(',','');
        var pExp = $("#ProfitExpectedValues").val().replace('$','').replaceAll(',','');

        toggleLoader(true, 'saveRawFileTieOff');
        var obj = this;

        var query = {
            action: "saveRawFileTieOff",
            periodId: this.getPeriodId(this.state.selectedPeriod),
            section: this.state.selectedSection, 
            revExpectedValue: revExp,
            cogsExpectedValue: cogsExp,
            profitExpectedValue: pExp,
            filledPandL: this.state.filledPandL
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
        .then((response)=>{
            return response.json()
        })   
        .then((data)=>{
            if(data !== null && !data.hasOwnProperty("ERROR")){
                Popup.alert(MESSAGES.UPLOAD.define_successful);
                $("#cancel-define-data-btn").click();
                toggleLoader(false, 'saveRawFileTieOff');
                obj.getRawFileList();
                obj.forceUpdate();
            }

        }).catch((error)=>{
            alertAndLogError(error);
        });
    
    }

     fileContainerChangeFile(e) {
		document.getElementById('fileSelectBox').classList.remove( 'fileContainerDragOver' );
		try {
			var droppedFiles = document.getElementById('fs').files;
			document.getElementById('fileName').textContent = droppedFiles[0].name;
		} catch (error) {;  }

		try {
			var aName = document.getElementById('fs').value;
			if (aName !== '') {
				document.getElementById('fileName').textContent = aName.split('fakepath').replace('//','');
			}
		} catch (error) {
			;
		}
	}
	
	 onDrop(e) {
		document.getElementById('fileSelectBox').classList.remove( 'fileContainerDragOver' );
		try {
			var droppedFiles = e.dataTransfer.files;
			document.getElementById('fileName').textContent = droppedFiles[0].name;
		} catch (error) { ; }
	}

	 dragOver(e) {
		document.getElementById('fileSelectBox').classList.add( 'fileContainerDragOver' );
		e.preventDefault();
		e.stopPropagation();
	}

	 leaveDrop(e) {
		document.getElementById('fileSelectBox').classList.remove( 'fileContainerDragOver' );
    }
    
    confirmReplacement(e) {
        const file = $("#fs")[0].files[0];
        if (file && !this.state.clicked) {
            e.preventDefault();
            this.setState({
                clicked: true
            },function(){
                window._profitIsleOpenModal("confirmReplaceFile");
            });
        }else {
            // document.getElementById('fileName').textContent = "";
            this.setState({
                clicked: false
            })
        }
    }

    setClicked() {
        this.setState({
            clicked: false
        })
    }

    setMatchingEntityData(data){
        this.setState({
            crosswalkEntities: data
        })
    }

    showMatchingEntityModal(index, isSource, linkObj){
        let _this = this;
        var tooltip_source_message = "";
        if (isSource) {
            var perc = formatValHTML((Number(linkObj[CROSSWALK_FIELDS.SOURCE_ENTITY].split("/")[0]) * 100 ) / Number(linkObj[CROSSWALK_FIELDS.SOURCE_ENTITY].split("/")[1]),FormatTypes.PERCENTAGE);
            tooltip_source_message = linkObj[CROSSWALK_FIELDS.SOURCE_ENTITY].split("/")[0] + " of " +linkObj[CROSSWALK_FIELDS.SOURCE_ENTITY].split("/")[1]+ " (" +perc + ") of entities in " +linkObj[CROSSWALK_FIELDS.SOURCE_FIELD_NAME] + " match";
        }else {
            var perc = formatValHTML((Number(linkObj[CROSSWALK_FIELDS.TARGET_ENTITY].split("/")[0]) * 100 ) / Number(linkObj[CROSSWALK_FIELDS.TARGET_ENTITY].split("/")[1]),FormatTypes.PERCENTAGE);
            tooltip_source_message = linkObj[CROSSWALK_FIELDS.TARGET_ENTITY].split("/")[0] + " of " +linkObj[CROSSWALK_FIELDS.TARGET_ENTITY].split("/")[1]+ " (" +perc + ") of entities in " +linkObj[CROSSWALK_FIELDS.TARGET_FIELD_NAME] + " match";
        }
        this.setState({
            subcategoryColumn: isSource ?linkObj[CROSSWALK_FIELDS.SOURCE_FIELD_NAME] 
                            :linkObj[CROSSWALK_FIELDS.TARGET_FIELD_NAME],
            crosswalkColumn: isSource ?linkObj[CROSSWALK_FIELDS.SOURCE_CROSSWALK_FIELD_NAME]
                            :linkObj[CROSSWALK_FIELDS.TARGET_CROSSWALK_FIELD_NAME],
            matching_message :isSource ?linkObj[CROSSWALK_FIELDS.SOURCE_ENTITY] :
                               linkObj[CROSSWALK_FIELDS.TARGET_ENTITY],
            matching_tooltip :tooltip_source_message,
            selectedLinkStartPeriod: this.linkRef.state.startPeriod,
            selectedLinkEndPeriod: this.linkRef.state.endPeriod,
            page: 0     //page 0 appears 1 on the UI
        },function(){
            _this.setMatchingEntityDialogOpen(true);
        })
    }

    setPage(pageNumber) {
        this.setState({
            page: pageNumber
        });
    }
    migrateData() {
        toggleLoader(true, 'migrateData');
        var query = {
            action: "migrateData",
            clientPeriods:JSON.stringify(this.state.clientPeriodsMig)
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${API_URL.DATA_MODELING}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
        .then((response)=>{
            if(response.status === 403) {
                this.logout();
            }
            return response.json()})
        .then((data)=>{
           
        }).catch((error)=>{
            alertAndLogError(error);
        }).then(()=>{
         toggleLoader(false, 'migrateData');
        });
    }
    executeExport = () => {
        let _this = this;
        if(_this.state.multiValue.length === 0){
            Popup.alert("You must choose at least one subtype.");
            return;
        }
        _this.setState({exportBySubtypeDisabled: true},()=>{
            this.exportBySubtype();
        })
       
    }
    exportBySubtype() {
        let _this  = this;
        let subTypesExtracted = copyObjectValues(this.state.multiValue);
        let subTypes = [];
        
        subTypesExtracted.map((item)=>{
            let obj = {};
            obj.subSection = item.value;
            obj.section = item.data_file_type;
            obj.subTypeId = item.raw_file_subtype_id;
            subTypes.push(obj);
        })

        setTimeout(() => {
            _this.setState({
                exportBySubtypeDisabled: false
            })
        }, 30000);
        var query = {
            action: "initiateDataSourceReupload",
            subTypes: JSON.stringify(subTypes)
            
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${API_URL.DATAPIPELINE}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
        .then((response)=>{
            if(response.status === 403) {
                this.logout();
            }
            return response.json()})
        .then((data)=>{
           
        }).catch((error)=>{
            alertAndLogError(error);
        }).then(()=>{
        
        });
    }

    migrateCostCenters=()=> {
        let _this = this;
        toggleLoader(true, 'migrateCostCenters');
        var query = {
            action: "generateCostCenterStates",
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${API_URL.MANAGE_COST_CENTER}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
        .then((response)=>{
            if(response.status === 403) {
                _this.logout();
            }
            return response.json()})
        .then((data)=>{
           
        }).catch((error)=>{
            alertAndLogError(error);
        }).then(()=>{
         toggleLoader(false, 'migrateCostCenters');
        });
    }

    openUserDownloadWindow() {
        $("#fs").trigger("click");
    }

    render() {
        const customStylesMultiSelect = {
            option: (styles) => ({
                ...styles,
                cursor: 'pointer',
                fontSize: convertPxToViewport(12),
                padding: convertPxToViewport(8)+' 0 '+' '+convertPxToViewport(8)+' '+convertPxToViewport(9)
            }),
            control: (provided) => ({
                ...provided,
                cursor: 'pointer',
                borderColor: '#dcdcdc',
                minHeight: convertPxToViewport(33),
                height: convertPxToViewport(33),
                fontSize: convertPxToViewport(12),
            }),
            valueContainer: (provided) => ({
                ...provided,
                height: convertPxToViewport(33),
                padding:  '0 '+convertPxToViewport(6),
                multiValue: () => ({
                    marginRight: convertPxToViewport(15),
                })
            }),
            input: (provided) => ({
                ...provided,
                margin: '0',
            }),
            indicatorSeparator: () => ({
                display: 'none',
            }),
            indicatorsContainer: (provided) => ({
                ...provided,
                height: convertPxToViewport(33),
            }),
            dropdownIndicator: (styles) => ({
                ...styles,
                paddingTop: convertPxToViewport(6),
                paddingBottom: convertPxToViewport(6),
            }),
            clearIndicator: (styles) => ({
                ...styles,
                paddingTop: convertPxToViewport(6),
                paddingBottom: convertPxToViewport(6),
            }),
            placeholder: defaultStyles => {
                return {
                    ...defaultStyles,
                    color: "#C6CCD7",
                    lineHeight: convertPxToViewport(12),
                };
            },
        };

        var obj = this;
        $('.tabulator-arrow').off("click");
        $('.tabulator-arrow').on("click", function(){
            $(this).parent().parent().css("margin-right",convertPxToViewport(1000));
        });
        const { pAndLData } = this.state;
        var summary_data = obj.state.dataSummary;
        var elementProgress = [];
        var className = "red";
        var option = null;
        if (summary_data && summary_data.length > 0) {
            for (var i=summary_data.length-1; i >=0; i--) {
                option = {value:summary_data[i][SUMMARY_FIELDS.PERIOD], label:summary_data[i][SUMMARY_FIELDS.PERIOD], "data-period_id": summary_data[i][SUMMARY_FIELDS.PERIOD_ID]};
                if (summary_data[i][SUMMARY_FIELDS.PENDING_FILES] > 0) {
                    className = "red-color";
                }else{
                    className = "greenText";
                }
                elementProgress.push(<div key={summary_data[i][SUMMARY_FIELDS.PERIOD_ID]}className={"summary-progress uk-small-padding uk-cursor-pointer "+ className} title={summary_data[i][SUMMARY_FIELDS.PERIOD]} data_option={JSON.stringify(option)} onClick={(e)=>{this.setChosenPeriod(e)}}></div>)
            }
        }
        var uploadResponseColumns = this.state.uploadResponseHeaders ? this.getColumns(this.state.uploadResponseHeaders) : this.state.uploadResponseColumns;
        var defineResponseColumns = this.state.fileResponseHeaders && this.state.fileResponseHeaders.length > 0 ? this.getColumns(this.state.fileResponseHeaders) : this.state.fileResponseColumns;
        
        // var isMultiSubSection = this.state.selectedSection ? this.tableReferences[this.state.selectedSection].current.tabulator.getGroups().length > 0 : false;
        var amendmentValue = $("input[name='amendments']:checked").val();
        var validationsTitle = "";
        if(STATUS_ENUM[this.state.uploadResponseStatus] === STATUS_ENUM.fail) {
            if(DATA_DICTIONARY_ENUM[this.state.fileStatus] === DATA_DICTIONARY_ENUM.failed_define) {
                validationsTitle = MESSAGES.UPLOAD.file_define_fail;
            } else if (DATA_DICTIONARY_ENUM[this.state.fileStatus] === DATA_DICTIONARY_ENUM.failed_upload) {
                validationsTitle = MESSAGES.UPLOAD.file_upload_fail
            }
        } else if(DATA_DICTIONARY_ENUM[this.state.fileStatus] === DATA_DICTIONARY_ENUM.passed) {
            validationsTitle = this.state.selectedSection !== CROSSWALK ? MESSAGES.UPLOAD.file_define_success : MESSAGES.UPLOAD.file_upload_success;
        } else {
            validationsTitle = this.state.section !== P_AND_L ? MESSAGES.UPLOAD.file_upload_success: MESSAGES.UPLOAD.file_upload_success_p_and_l;
        }
        
        var uploadValidations = [];
        this.state.uploadValidations.map((item, index) => {
            var currentStatus = STATUS_ENUM[item[RAW_FILE_VALIDATION.VALIDATION_STATUS]];
            if (STATUS_ENUM[item[RAW_FILE_VALIDATION.VALIDATION_STATUS]] !== DATA_DICTIONARY_ENUM.not_defined) {
                uploadValidations.push(
                    <li className="list-group-item" status={item[RAW_FILE_VALIDATION.VALIDATION_STATUS]} key={"key_" + index} >
                        {currentStatus === STATUS_ENUM.pass ?
                            <i className="fas fa-check uk-margin-small-right fa-lg" />
                            :
                            currentStatus === STATUS_ENUM.warning ?
                                <i className="fas fa-exclamation pi-text-yellow fa-lg uk-margin-small-right" />
                                :
                                item[RAW_FILE_VALIDATION.VALIDATION_MESSAGE] === "" ?
                                    <i className="far fa-clock uk-margin-small-right fa-lg" />
                                    :
                                    <i className="fal fa-times uk-margin-small-right fa-lg" />
                        }
                        {item[RAW_FILE_VALIDATION.VALIDATION_NAME]}
                        {
                            // [STATUS_ENUM.fail, STATUS_ENUM.warning].indexOf(STATUS_ENUM[item[RAW_FILE_VALIDATION.VALIDATION_STATUS]]) !== -1 ?
                            item[RAW_FILE_VALIDATION.VALIDATION_MESSAGE] !== item[RAW_FILE_VALIDATION.VALIDATION_STATUS] ?
                                <div className={currentStatus === STATUS_ENUM.fail ? "red fileValidationError" : "fileValidationError"}> {item[RAW_FILE_VALIDATION.VALIDATION_MESSAGE]}</div>
                                : ""
                        }
                    </li>
                );
            }
        });

        function bs_input_file() {
            $(".input-file").before(
                function () {
                    if (!$(this).prev().hasClass('input-ghost')) {
                        var element = $("<input type='file' class='input-ghost' style='visibility:hidden; height:0'>");
                        element.attr("name", $(this).attr("name"));
                        element.change(function () {
                            element.next(element).find('input').val((element.val()).split('\\').pop());
                            $("#fileUpload")[0].files = $(element)[0].files;
                        });
                        $(this).find("button.subtle--btn-secondary").click(function () {
                            element.click();
                        });
                        $(this).find("button.btn-reset").click(function () {
                            element.val(null);
                            $(this).parents(".input-file").find('input').val('');
                        });
                        $(this).find('input').css("cursor", "pointer");
                        $(this).find('input').mousedown(function () {
                            $(this).parents('.input-file').prev().click();
                            return false;
                        });
                        return element;
                    }
                }
            );
        }
        
        $(function () {
            bs_input_file();
        });
        var isMigrationAccessible = this.props.userAllowedSections ? getSectionExists(this.props.userAllowedSections, MENU_ITEM.FIELDS.TEMPORARY_DATA_MODELING): false;
        var isDefineFileAllowed = this.props.userAllowedSections ? getSectionExists(this.props.userAllowedSections, MENU_ITEM.FIELDS.DEFINE_FILE) : false;
        var hideDefineSubmit = this.state.selectedSubSection === undefined || this.state.selectedSubSection === "";

        var values = [];
            values.push(P_AND_L);
            values.push(GENERAL_LEDGER);
            values.push(TRANSACTION_DATA);
            values.push(MASTER_DATA);
            values.push(ANCILLARY_FILES);

        var object = [];
        for (var value in values) {
            var status = this.state.selectedSectionPeriodInfo[values[value]] && this.state.selectedSectionPeriodInfo[values[value]].passed >= this.state.selectedSectionPeriodInfo[values[value]].expected ? "fa-check-circle" : "fa-times-circle";
            object.push(<div key={values[value]+"key"}>
                <i className={"fas fa-3x "+status}></i>
                <div className="width-105" key={values[value]}>{capitaliseFirstLetterAfterChar(values[value]).replaceAll("_", " ")}</div>
            </div>);    
        }

        var upload_info_message = this.state.infoMessages && this.state.selectedSection ? this.state.infoMessages.filter(msg => msg.title === this.state.selectedSection && msg.feature_name === "upload").length > 0 ?  this.state.infoMessages.filter(msg => msg.title === this.state.selectedSection && msg.feature_name === "upload")[0].description : "" :"";
        var pandl_define_info_message = this.state.infoMessages && this.state.selectedSection ? this.state.infoMessages.filter(msg => msg.title === P_AND_L && msg.feature_name === "define").length > 0 ? this.state.infoMessages.filter(msg => msg.title === P_AND_L && msg.feature_name === "define")[0].description : "" :"";
        var define_info_message = this.state.infoMessages && this.state.selectedSection ? this.state.infoMessages.filter(msg => msg.title === this.state.selectedSection && msg.feature_name === "define").length > 0 ? this.state.infoMessages.filter(msg => msg.title === this.state.selectedSection && msg.feature_name === "define")[0].description : "" :"";
        var format_define_info_message = this.state.infoMessages && this.state.selectedSection ? this.state.infoMessages.filter(msg => msg.title === "format" && msg.feature_name === "define").length > 0 ? this.state.infoMessages.filter(msg => msg.title === "format" && msg.feature_name === "define")[0].description : "" :"";

        var uploadedFileSubtypeValueSelect = (this.state.selectedSubSection === "" || this.state.selectedSubSection === undefined)
            && this.state.uploadedFileSubtype[this.state.selectedSection] && this.state.uploadedFileSubtype[this.state.selectedSection].length === 1
            ? this.state.uploadedFileSubtype[this.state.selectedSection][0].value : this.state.selectedSubSection;
        
        //access for preview private files: true if has acces, false otherwise
        var previewPrivateFilesAccess = this.props.userAllowedSections && getSectionExists(this.props.userAllowedSections, ALL_WIDGETS.PREVIEW_PRIVATE_FILES);
        //public or private is the file: true if private, false if public
        var publicPrivateFiles = this.state.uploadedFileSubtype && this.state.uploadedFileSubtype[this.state.selectedSection] && this.state.uploadedFileSubtype[this.state.selectedSection].filter(e => e.value === this.state.selectedSubSection) && this.state.uploadedFileSubtype[this.state.selectedSection].filter(e => e.value === this.state.selectedSubSection)[0] && this.state.uploadedFileSubtype[this.state.selectedSection].filter(e => e.value === this.state.selectedSubSection)[0][UPLOAD_FIELDS.PRIVATE];
        const optionLabel = ({ value, label }) => (
            <div title={label} className="uk-text-overflow-ellipsis">{label}</div>
        ); 
        const optionLabelTwo = ({ label, isDisabled }) => (
            <div title={label} className={(isDisabled ? "option-group-header" : "")+ " option-padding"}>{label}</div>
        );
        const { currenciesMatchingData } = this.state;
        const { crosswalkEntities } = this.state;
        const bridgeOptions = ({title, label, isDisabled,value }) => (
            <div title={label?label:value} className={(isDisabled && title? "option-group-header option-padding" : isDisabled ? " disabled " : "") + " uk-text-overflow-ellipsis"}>
                {(label?label:value)}
            </div>
        );
        return (
            <div id={"upload_data_tables"}>
                <Popup />
                <section>
                    <div className="dm-parent-container">
                    <div id="toastUploadData" className="toast">
                        <div id="desc">{this.state.fileName && this.state.decryptionMessage ? this.state.fileName + this.state.decryptionMessage : ""}</div>
                    </div>
                        <div>
                            <div className="summary-data-table-container">
                                <div className="uk-display-flex justify-content-between w100">
                                    <div className="uk-display-flex uk-flex-middle">
                                        <h5 className="uk-margin-medium-left uk-text-bold uk-display-inline-flex">{DATA_SUMMARY}</h5>
                                        <i className="uk-margin-xsmall-left fal fa-info-circle uk-cursor-pointer"/>
                                        <div className="uk-display-flex uk-margin-medium-left">{elementProgress}</div>
                                        <div className="pl-50 uk-display-inline-block uk-line-height-medium fs-12 text-grey">{"Periods with complete uploads: "+this.state.completeUploads}</div>
                                        <div className="uk-margin-xmedium-left uk-display-inline-block uk-line-height-medium fs-12 text-grey">{"Periods with incomplete uploads: "+this.state.incompleteUploads}</div>
                                    </div>
                                    <div className="uk-float-left">
                                    <div>
                                        {isMigrationAccessible && 
                                        <div>
                                            <Button 
                                                label={"Export by Subtype"}
                                                variant={BUTTON_VARIANT.PRIMARY}
                                                size={SIZES.DEFAULT}
                                                type={BUTTON_TYPE.DEFAULT}  
                                                disabled={this.state.exportBySubtypeDisabled}
                                                style={{marginRight:'2vw',marginBottom:'1vw',position:'relative',right:'0vw'}}
                                                onBtnClick={()=>this.executeExport()}
                                            />
                                            <p style={{cursor:"pointer"}} onClick={this.selectAll}>{this.state.defaultSelectionName}</p>
                                        </div>
                                        } 
                                    </div>
                                     <div style = {{position:'relative',bottom:'0.5vw'}}>
                                            {isMigrationAccessible && 
                                                                    <CustomSelect
                                                                        id="select-uploadedFilesFromBQ"
                                                                        className="width-300"
                                                                        classNamePrefix="multiSelectNoWrap"
                                                                        name="uploadedFilesFromBQ"
                                                                        placeholder="Choose one or many subtypes"
                                                                        value={this.state.multiValue}
                                                                        options={this.state.arrangedFiles}
                                                                        onChange={this.handleMultiChange}
                                                                        isMulti   //the 'multi' prop allows it to be multi-select
                                                                        isSearchable
                                                                        isClearable={false}
                                                                        menuIsOpen={this.state.menuIsOpen}
                                                                        onMenuOpen={() => this.setState({ menuIsOpen: true })}
                                                                        onMenuClose={() => this.setState({ menuIsOpen: false })}
                                                                        closeMenuOnSelect={false}
                                                                        styles={customStylesMultiSelect}
                                                                        formatOptionLabel={optionLabelTwo}
                                                                    />
                                                                    // <DropDown //to remove comment and comment <CustomSelect
                                                                    //     id="select-uploadedFilesFromBQ"
                                                                    //     className="width-300"
                                                                    //     classNamePrefix="multiSelectNoWrap"
                                                                    //     name="uploadedFilesFromBQ"
                                                                    //     placeholder="Choose one or many subtypes"
                                                                    //     value={this.state.multiValue}
                                                                    //     options={this.state.arrangedFiles}
                                                                    //     onChange={this.handleMultiChange}
                                                                    //     isMulti   //the 'multi' prop allows it to be multi-select
                                                                    //     isSearchable
                                                                    //     isClearable={false}
                                                                    //     menuIsOpen={this.state.menuIsOpen}
                                                                    //     // onMenuOpen={() => this.setState({ menuIsOpen: true })}
                                                                    //     // onMenuClose={() => this.setState({ menuIsOpen: false })}
                                                                    //     closeMenuOnSelect={false}
                                                                    //     // styles={customStylesMultiSelect}
                                                                    //     formatOptionLabel={optionLabelTwo}
                                                                    //     type={DROPDOWN_TYPE.INPUT}
                                                                    // />
                                            }
                                    </div>
                                </div>
                                 
                            {/* <div className="uk-display-flex uk-flex-middle text-link uk-margin-medium-right uk-cursor-pointer fs-12" onClick={this.redirectToFileCensus}>File Census</div> */}
                            </div>
                                <UploadDataTable ref={this.tableReferences[DATA_SUMMARY]} id={DATA_SUMMARY} groupBy={""} table_type={DATA_SUMMARY} tableData={this.state.dataSummary} DATA_SUMMARY={DATA_SUMMARY} clientId={this.props.clientId} sectionInfo={DATA_SUMMARY} showDeleteFilesPopup={this.showDeleteFilesPopup} prepareHistoryPopup  setChosenPeriod={this.setChosenPeriod} isFileDefined={this.state.isFileDefined}/>
                            </div>
                            <div className="upload-data-table-container">
                                <SectionFileInfo  ref={this.fileInfoRef} sectionInfo={this.state.selectedSectionPeriodInfo[P_AND_L]} isDefineFileAllowed={isDefineFileAllowed} section={P_AND_L} showUploadPopup={this.showUploadPopup} 
                                showDeleteFilesPopup={this.showDeleteFilesPopup} prepareHistoryPopup={this.prepareHistoryPopup} toggleSection={this.toggleSection} isSectionExpanded={this.state.isSectionExpanded[P_AND_L]} 
                                selectedSection={this.state.selectedSection} isMigrationAccessible = {isMigrationAccessible}/>
                                <UploadDataTable id={P_AND_L} ref={this.fileInfoRef}  ref={this.tableReferences[P_AND_L]} subSections={this.state.definedFileSubtype[P_AND_L] || []} fileIds={this.state.fileIds} groupValues={this.state.groupValues[P_AND_L] || []} groupBy={"display_file_subtype"} table_type={UPLOAD_DATA} tableData={this.state.pandlData} sections={false} defaultRows={0} getClientPeriodTieOff={this.getClientPeriodTieOff} userAllowedSections={this.props.userAllowedSections} UPLOAD_DATA={UPLOAD_DATA} clientId={this.props.clientId} setFileHeader={this.setFileHeader} updateUploadStatusResponse={this.updateUploadStatusResponse} getFileColumnDistinctValues={this.getFileColumnDistinctValues} setSelectedSubSection={this.setSelectedSubSection} fileDelimiters={Delimiters} onCheckBoxChecked={this.onCheckBoxChecked} getRowGroupInfoAsHTML={this.getRowGroupInfoAsHTML} isSectionExpanded={this.state.isSectionExpanded? this.state.isSectionExpanded[P_AND_L] : false } dataTypesFields={dataTypesFieldsVar} defineData={definition} setFormatsElements={this.setFormatsElements} sectionInfo={this.state.selectedPeriodInfo ? this.state.selectedPeriodInfo[P_AND_L] :[]} showDeleteFilesPopup={this.showDeleteFilesPopup} isFileDefined={this.state.isFileDefined} prepareRawFileDefinition={this.prepareRawFileDefinition}  isDefineFileAllowed={isDefineFileAllowed} isMigrationAccessible = {isMigrationAccessible}/>
                            </div>
                            <div className="upload-data-table-container">
                                <SectionFileInfo sectionInfo={this.state.selectedSectionPeriodInfo[GENERAL_LEDGER]} isDefineFileAllowed={isDefineFileAllowed} section={GENERAL_LEDGER} showUploadPopup={this.showUploadPopup} 
                                showDeleteFilesPopup={this.showDeleteFilesPopup} prepareHistoryPopup={this.prepareHistoryPopup} toggleSection={this.toggleSection} isSectionExpanded={this.state.isSectionExpanded[GENERAL_LEDGER]} 
                                selectedSection={this.state.selectedSection} isMigrationAccessible = {isMigrationAccessible}/>
                                <UploadDataTable id={GENERAL_LEDGER} ref={this.tableReferences[GENERAL_LEDGER]} subSections={this.state.definedFileSubtype[GENERAL_LEDGER] || []} fileIds={this.state.fileIds} groupValues={this.state.groupValues[GENERAL_LEDGER] || []} groupBy={"display_file_subtype"} table_type={UPLOAD_DATA} tableData={this.state.generalLedgerData} sections={false} defaultRows={0} getRawFileDefinitionList={this.getRawFileDefinitionList} userAllowedSections={this.props.userAllowedSections} UPLOAD_DATA={UPLOAD_DATA} clientId={this.props.clientId} setFileHeader={this.setFileHeader} updateUploadStatusResponse={this.updateUploadStatusResponse} getFileColumnDistinctValues={this.getFileColumnDistinctValues} setSelectedSubSection={this.setSelectedSubSection} fileDelimiters={Delimiters} onCheckBoxChecked={this.onCheckBoxChecked} getRowGroupInfoAsHTML={this.getRowGroupInfoAsHTML} isSectionExpanded={this.state.isSectionExpanded ? this.state.isSectionExpanded[GENERAL_LEDGER] :[]} dataTypesFields={dataTypesFieldsVar} defineData={definition} setFormatsElements={this.setFormatsElements} sectionInfo={this.state.selectedPeriodInfo ? this.state.selectedPeriodInfo[GENERAL_LEDGER] : []} showDeleteFilesPopup={this.showDeleteFilesPopup} isFileDefined={this.state.isFileDefined} prepareRawFileDefinition={this.prepareRawFileDefinition}  isDefineFileAllowed={isDefineFileAllowed} isMigrationAccessible = {isMigrationAccessible}/>
                            </div>
                            <div className="upload-data-table-container">
                                <SectionFileInfo sectionInfo={this.state.selectedSectionPeriodInfo[TRANSACTION_DATA]} isDefineFileAllowed={isDefineFileAllowed} section={TRANSACTION_DATA} showUploadPopup={this.showUploadPopup} 
                                showDeleteFilesPopup={this.showDeleteFilesPopup} prepareHistoryPopup={this.prepareHistoryPopup} toggleSection={this.toggleSection} isSectionExpanded={this.state.isSectionExpanded[TRANSACTION_DATA]} 
                                selectedSection={this.state.selectedSection} isMigrationAccessible = {isMigrationAccessible}/>
                                <UploadDataTable id={TRANSACTION_DATA} ref={this.tableReferences[TRANSACTION_DATA]} subSections={this.state.definedFileSubtype[TRANSACTION_DATA] || []} fileIds={this.state.fileIds} groupValues={this.state.groupValues[TRANSACTION_DATA] || []} groupBy={"display_file_subtype"} table_type={UPLOAD_DATA} tableData={this.state.transactionDataData} sections={false} defaultRows={0} getRawFileDefinitionList={this.getRawFileDefinitionList} userAllowedSections={this.props.userAllowedSections} UPLOAD_DATA={UPLOAD_DATA} clientId={this.props.clientId} setFileHeader={this.setFileHeader} updateUploadStatusResponse={this.updateUploadStatusResponse} getFileColumnDistinctValues={this.getFileColumnDistinctValues} setSelectedSubSection={this.setSelectedSubSection} fileDelimiters={Delimiters} onCheckBoxChecked={this.onCheckBoxChecked} getRowGroupInfoAsHTML={this.getRowGroupInfoAsHTML} isSectionExpanded={this.state.isSectionExpanded ? this.state.isSectionExpanded[TRANSACTION_DATA] :[]} dataTypesFields={dataTypesFieldsVar} setFormatsElements= {this.setFormatsElements} sectionInfo={this.state.selectedPeriodInfo ? this.state.selectedPeriodInfo[TRANSACTION_DATA] :[]} showDeleteFilesPopup={this.showDeleteFilesPopup} isFileDefined={this.state.isFileDefined} prepareRawFileDefinition={this.prepareRawFileDefinition}  isDefineFileAllowed={isDefineFileAllowed} isMigrationAccessible = {isMigrationAccessible}/>
                            </div>
                            <div className="upload-data-table-container">
                                <SectionFileInfo sectionInfo={this.state.selectedSectionPeriodInfo[MASTER_DATA]} isDefineFileAllowed={isDefineFileAllowed} section={MASTER_DATA} showUploadPopup={this.showUploadPopup} 
                                showDeleteFilesPopup={this.showDeleteFilesPopup} prepareHistoryPopup={this.prepareHistoryPopup} toggleSection={this.toggleSection} isSectionExpanded={this.state.isSectionExpanded[MASTER_DATA]} 
                                selectedSection={this.state.selectedSection} isMigrationAccessible = {isMigrationAccessible}/>
                                <UploadDataTable id={MASTER_DATA} ref={this.tableReferences[MASTER_DATA]} subSections={this.state.definedFileSubtype[MASTER_DATA] || []} fileIds={this.state.fileIds}  groupValues={this.state.groupValues[MASTER_DATA] || []} groupBy={"display_file_subtype"} table_type={UPLOAD_DATA} tableData={this.state.masterDataData} sections={false} defaultRows={0} getRawFileDefinitionList={this.getRawFileDefinitionList} userAllowedSections={this.props.userAllowedSections} UPLOAD_DATA={UPLOAD_DATA} clientId={this.props.clientId} setFileHeader={this.setFileHeader} updateUploadStatusResponse={this.updateUploadStatusResponse} getFileColumnDistinctValues={this.getFileColumnDistinctValues} setSelectedSubSection={this.setSelectedSubSection} fileDelimiters={Delimiters} onCheckBoxChecked={this.onCheckBoxChecked} getRowGroupInfoAsHTML={this.getRowGroupInfoAsHTML} sectionInfo={this.state.selectedPeriodInfo ? this.state.selectedPeriodInfo[MASTER_DATA]:[]} isSectionExpanded={this.state.isSectionExpanded ? this.state.isSectionExpanded[MASTER_DATA] :[]} dataTypesFields={dataTypesFieldsVar} defineData={definition} setFormatsElements={this.setFormatsElements} sectionInfo={this.state.selectedPeriodInfo ? this.state.selectedPeriodInfo[MASTER_DATA] :[]} showDeleteFilesPopup={this.showDeleteFilesPopup} isFileDefined={this.state.isFileDefined} prepareRawFileDefinition={this.prepareRawFileDefinition}  isDefineFileAllowed={isDefineFileAllowed} isMigrationAccessible = {isMigrationAccessible}/>
                            </div>
                            <div className="upload-data-table-container">
                                <SectionFileInfo sectionInfo={this.state.selectedSectionPeriodInfo[ANCILLARY_FILES]} isDefineFileAllowed={isDefineFileAllowed} section={ANCILLARY_FILES} showUploadPopup={this.showUploadPopup} 
                                showDeleteFilesPopup={this.showDeleteFilesPopup} prepareHistoryPopup={this.prepareHistoryPopup} toggleSection={this.toggleSection} isSectionExpanded={this.state.isSectionExpanded[ANCILLARY_FILES]} 
                                selectedSection={this.state.selectedSection} isMigrationAccessible = {isMigrationAccessible}/>
                                <UploadDataTable id={ANCILLARY_FILES} ref={this.tableReferences[ANCILLARY_FILES]} subSections={this.state.definedFileSubtype[ANCILLARY_FILES] || []} fileIds={this.state.fileIds} groupValues={this.state.groupValues[ANCILLARY_FILES] || []} groupBy={"display_file_subtype"} table_type={UPLOAD_DATA} tableData={this.state.ancillaryFilesData} sections={false} defaultRows={0} getRawFileDefinitionList={this.getRawFileDefinitionList} userAllowedSections={this.props.userAllowedSections} UPLOAD_DATA={UPLOAD_DATA} clientId={this.props.clientId} setFileHeader={this.setFileHeader} updateUploadStatusResponse={this.updateUploadStatusResponse} getFileColumnDistinctValues={this.getFileColumnDistinctValues} setSelectedSubSection={this.setSelectedSubSection} fileDelimiters={Delimiters} onCheckBoxChecked={this.onCheckBoxChecked} getRowGroupInfoAsHTML={this.getRowGroupInfoAsHTML} sectionInfo={this.state.selectedPeriodInfo ? this.state.selectedPeriodInfo[ANCILLARY_FILES] :[]} isSectionExpanded={this.state.isSectionExpanded ? this.state.isSectionExpanded[ANCILLARY_FILES] :[]} dataTypesFields={dataTypesFieldsVar} defineData={definition} setFormatsElements={this.setFormatsElements} sectionInfo={this.state.selectedPeriodInfo ? this.state.selectedPeriodInfo[ANCILLARY_FILES] :[]} showDeleteFilesPopup={this.showDeleteFilesPopup} isFileDefined={this.state.isFileDefined} prepareRawFileDefinition={this.prepareRawFileDefinition}  isDefineFileAllowed={isDefineFileAllowed} isMigrationAccessible = {isMigrationAccessible}/>
                            </div>
                            <div className="upload-data-table-container">
                                <SectionFileInfo sectionInfo={this.state.selectedSectionPeriodInfo[CROSSWALK]} isDefineFileAllowed={isDefineFileAllowed} section={CROSSWALK} showUploadPopup={this.showUploadPopup} 
                                showDeleteFilesPopup={this.showDeleteFilesPopup} prepareHistoryPopup={this.prepareHistoryPopup} toggleSection={this.toggleSection} isSectionExpanded={this.state.isSectionExpanded[CROSSWALK]} 
                                selectedSection={this.state.selectedSection}/>
                                <UploadDataTable id={CROSSWALK} ref={this.tableReferences[CROSSWALK]} subSections={this.state.definedFileSubtype[CROSSWALK] || []} fileIds={this.state.fileIds} groupValues={this.state.groupValues[CROSSWALK] || []} groupBy={"display_file_subtype"} table_type={UPLOAD_DATA} tableData={this.state.cwData} sections={false} defaultRows={0} getRawFileDefinitionList={this.getRawFileDefinitionList} userAllowedSections={this.props.userAllowedSections} UPLOAD_DATA={UPLOAD_DATA} clientId={this.props.clientId} setFileHeader={this.setFileHeader} updateUploadStatusResponse={this.updateUploadStatusResponse} getFileColumnDistinctValues={this.getFileColumnDistinctValues} setSelectedSubSection={this.setSelectedSubSection} fileDelimiters={Delimiters} onCheckBoxChecked={this.onCheckBoxChecked} getRowGroupInfoAsHTML={this.getRowGroupInfoAsHTML} sectionInfo={this.state.selectedPeriodInfo ? this.state.selectedPeriodInfo[CROSSWALK] :[]} isSectionExpanded={this.state.isSectionExpanded ? this.state.isSectionExpanded[CROSSWALK] :[]} dataTypesFields={dataTypesFieldsVar} defineData={definition} setFormatsElements={this.setFormatsElements} sectionInfo={this.state.selectedPeriodInfo ? this.state.selectedPeriodInfo[CROSSWALK] :[]} showDeleteFilesPopup={this.showDeleteFilesPopup} isFileDefined={this.state.isFileDefined} prepareRawFileDefinition={this.prepareRawFileDefinition}  isDefineFileAllowed={isDefineFileAllowed}/>
                           </div>
                           <div className="upload-data-table-container">
                                <SectionFileInfo sectionInfo={this.state.selectedSectionPeriodInfo[CURRENCY]} isDefineFileAllowed={isDefineFileAllowed} section={CURRENCY} showUploadPopup={this.showUploadPopup} showDeleteFilesPopup={this.showDeleteFilesPopup} prepareHistoryPopup={this.prepareHistoryPopup} toggleSection={this.toggleSection} isSectionExpanded={this.state.isSectionExpanded[CURRENCY]} selectedSection={this.state.selectedSection} isMigrationAccessible = {isMigrationAccessible}/>
                                <UploadDataTable id={CURRENCY} ref={this.tableReferences[CURRENCY]} subSections={this.state.definedFileSubtype[CURRENCY] || []} fileIds={this.state.fileIds} groupValues={this.state.groupValues[CURRENCY] || []} groupBy={"display_file_subtype"} table_type={UPLOAD_DATA} tableData={this.state.currencyData} sections={false} defaultRows={0} getRawFileDefinitionList={this.getRawFileDefinitionList} userAllowedSections={this.props.userAllowedSections} UPLOAD_DATA={UPLOAD_DATA} clientId={this.props.clientId} setFileHeader={this.setFileHeader} updateUploadStatusResponse={this.updateUploadStatusResponse} getFileColumnDistinctValues={this.getFileColumnDistinctValues} setSelectedSubSection={this.setSelectedSubSection} fileDelimiters={Delimiters} onCheckBoxChecked={this.onCheckBoxChecked} getRowGroupInfoAsHTML={this.getRowGroupInfoAsHTML} sectionInfo={this.state.selectedPeriodInfo ? this.state.selectedPeriodInfo[CURRENCY] :[]} isSectionExpanded={this.state.isSectionExpanded ? this.state.isSectionExpanded[CURRENCY] :[]} dataTypesFields={dataTypesFieldsVar} defineData={definition} setFormatsElements={this.setFormatsElements} sectionInfo={this.state.selectedPeriodInfo ? this.state.selectedPeriodInfo[CURRENCY] :[]} showDeleteFilesPopup={this.showDeleteFilesPopup} isFileDefined={this.state.isFileDefined} prepareRawFileDefinition={this.prepareRawFileDefinition}  isDefineFileAllowed={isDefineFileAllowed} isMigrationAccessible = {isMigrationAccessible}/>
                           </div>
                        </div>
                    </div>
                </section>

                <div id="uploadPopupModal" className="modal fade" role="dialog">
				    <div className="modal-dialog modal-md">
					    <div className="modal-content uk-padding-small uk-padding-remove-bottom">
                            <button type="button" className="close-button uk-margin-small" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="fal fa-times" /></span>
                            </button>
                            <span className="uk-text-xlarge uk-margin-bottom uk-padding-small-left">Upload - {this.state.selectedDataTypeDisplay}</span>
                            <i className="fal fa-info-circle uk-margin-small uk-cursor-pointer" uk-tooltip={upload_info_message}></i>
                            <div className="modal-body">
                                <fieldset id="formFieldSet">
                                    <form id="upload-popup-form-">
                                        <div className="uk-flex uk-flex-column">
                                            <div className="fileContainer" id="fileSelectBox" 
                                                placeholder={this.state.selectedSection === CROSSWALK ? MESSAGES.UPLOAD.unique_entities : MESSAGES.UPLOAD.uploaded_file_gpg_extension} onDragOver={(event)=>{this.dragOver(event)}} onDragLeave={(event)=>{this.leaveDrop(event)}} onDrop={(event)=>{this.onDrop(event)}} onChange={(event)=>{this.fileContainerChangeFile(event)}}>
                                                <div className="fileContainerFileName" onDrop={(event)=>{this.onDrop(event)}} id="fileName">{MESSAGES.drag_and_drop_files}</div>
                                                <span className="input-group-btn uk-float-right uk-margin-large-right">
                                                    <button className="subtle--btn-secondary uk-padding-xsmall" type="button"><i className="fal fa-upload uk-margin-small-right"/>Upload File</button>
                                                </span>
                                                <input name="fs" id="fs" onClick={(e)=>{this.confirmReplacement(e)}} onChange={(event)=>{this.fileContainerChangeFile(event)}} type="file"/>
                                                <div className="uk-text-medium red placeHolder">{this.state.selectedSection === CROSSWALK ? MESSAGES.UPLOAD.encrypted_file_extension :MESSAGES.uploaded_file_gpg_extension}</div>
                                                <div className="uk-text-medium red placeHolder">{this.state.selectedSection === CROSSWALK ? MESSAGES.UPLOAD.unique_entities : ""}</div>
                                            </div>
                                        </div>
                                        <div className="uk-display-flex uk-flex-column uk-margin-xmedium-top uk-margin-small-bottom">
                                            <div className="uk-display-flex uk-flex-middle">
                                                <span className="fs-14 width-90">Encrypted file</span>
                                                        <SwitchToggle addedClassName="uk-display-flex" name="encryption" value={this.state.encryption} checked={this.state.encryption === "yes" ?  true : false} onChange={(e)=>{this.handleEncryptionChange(e)}} />
                                            </div>
                                            <div className="red uk-margin-small-bottom">{this.state.encryption === "no"? MESSAGES.UPLOAD.file_upload_encryption_warning : ""}</div>
                                        </div>
                                       
                                        <div id="div-UploadFilePeriods" className={(this.state.selectedSection === P_AND_L ? "" : "justify-content-between")+ " uk-flex"}>
                                       
                                            { this.state.selectedSection !== CROSSWALK ?
                                                <div className="uk-flex uk-flex-column select-value">
                                                    <span className="fs-14 uk-margin-xsmall-bottom">{this.state.selectedSection === P_AND_L ? "Period": "Start Period"} </span>
                                                    <CustomSelect
                                                        id="select-uploadPeriodStart"
                                                        className="width-125"
                                                        name="uploadedFilePeriodStart"
                                                        placeholder="Period"
                                                        value={findOptionByKey(this.state.clientPeriods, this.state.selectedUploadStartPeriod)}
                                                        options={this.state.clientPeriods}
                                                        onChange={this.setUploadStartPeriod}
                                                    />
                                                 </div>
                                            : ""}

                                                { this.state.selectedSection !== P_AND_L && this.state.selectedSection !== CROSSWALK ?
                                                    <div className="uk-flex uk-flex-column select-value">
                                                        <span className="fs-14 uk-margin-xsmall-bottom">End Period</span>
                                                        <CustomSelect
                                                            id="select-uploadPeriodEnd"
                                                            className="width-125"
                                                            name="uploadedFilePeriodEnd"
                                                            placeholder="Period"
                                                            value={findOptionByKey(this.state.clientPeriods, this.state.selectedUploadEndPeriod)}
                                                            options={this.state.clientPeriods}
                                                            onChange={this.setUploadEndPeriod}
                                                        />
                                                    </div>
                                                    : ""
                                                }
                                                <div className={(this.state.selectedSection === P_AND_L ? "uk-margin-medium-left" : "")+ " uk-flex uk-flex-column"} id="div-uploadedFileSubtype">
                                                    <span className="fs-14 uk-margin-xsmall-bottom">Subcategory</span>
                                                    <DropDown
                                                        id="select-uploadedFileSubtype"
                                                        className={[this.state.selectedSection === P_AND_L ? "": ""]+" drilling width-200"}
                                                        name="uploadedFileSubtype"
                                                        placeholder="Subcategory"
                                                        // value={this.state.selectedSubSection === "" || this.state.selectedSubSection === undefined ? this.state.uploadedFileSubtype[this.state.selectedSection][0] : this.state.selectedSubSection  }
                                                        value={findOptionByKey(this.state.uploadedFileSubtype[this.state.selectedSection], uploadedFileSubtypeValueSelect)}
                                                        options={this.state.uploadedFileSubtype[this.state.selectedSection] ? this.state.uploadedFileSubtype[this.state.selectedSection].filter(el => el.is_private !== true) : []} //to hide private files
                                                        onChange={this.handleSubSectionChange}
                                                        type={DROPDOWN_TYPE.INPUT}
                                                    />
                                                    <input type="text" id="newSectionInput" className="form-control hidden"/>
                                                </div>
                                            </div>
                                            <div className={this.state.selectedSection === P_AND_L ? "uk-hidden" : ""}>
                                                <div className="uk-flex uk-flex-middle uk-section-xsmall">
                                                    <span className="uk-text-xmedium width-100">Delimiter</span>
                                                    <div className="uk-display-flex">
                                                        <label className="uk-display-flex uk-flex-middle width-100 uk-cursor-pointer">
                                                            <input type="radio" className="radioButton uk-margin-remove-top uk-margin-small-right" name="delimiter" value={Delimiters.Comma} defaultChecked onClick={this.updateSelectedDelimiter}/>
                                                            <span className="uk-text-xmedium">Comma</span>
                                                        </label>
                                                        <label className="uk-display-flex uk-flex-middle width-100 uk-cursor-pointer">
                                                            <input type="radio" className="radioButton uk-margin-remove-top uk-margin-small-right" name="delimiter" value={Delimiters.Tab} onClick={this.updateSelectedDelimiter}/>
                                                            <span className="uk-text-xmedium">Tab</span>
                                                        </label>
                                                        <label className="uk-display-flex uk-flex-middle width-100 uk-cursor-pointer">
                                                            <input type="radio" className="radioButton uk-margin-remove-top uk-margin-small-right" name="delimiter" value={Delimiters.Pipe} onClick={this.updateSelectedDelimiter}/>
                                                            <span className="uk-text-xmedium">Pipe</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="uk-flex uk-flex-middle">
                                                    <span className="uk-text-xmedium width-100">Amendments</span>
                                                    <div className="uk-display-flex">
                                                        <label className="uk-display-flex uk-flex-middle width-100 uk-cursor-pointer">
                                                            <input type="radio" className="radioButton uk-margin-remove-top uk-margin-small-right" name="amendments" value={Amendments.New} disabled={this.state.selectedSection !== CROSSWALK ? this.state.disableNew : false} defaultChecked={true} onClick={this.updateSelectedAmendments}/>
                                                            <span className="uk-text-xmedium">New</span>
                                                        </label>
                                                        {this.state.selectedSection !== CROSSWALK ?
                                                            <label className="uk-display-flex uk-flex-middle width-100 uk-cursor-pointer">
                                                                {this.state.disableAppend 
                                                                    ? <input type="radio" className="radioButton uk-margin-remove-top uk-margin-small-right" name="amendments" value={Amendments.Append} disabled={this.state.disableAppend} onClick={this.updateSelectedAmendments} uk-tooltip={TOOLTIP_AMENDMENT_MESSAGE} />
                                                                    : <input type="radio" className="radioButton uk-margin-remove-top uk-margin-small-right" name="amendments" value={Amendments.Append} disabled={this.state.disableAppend} onClick={this.updateSelectedAmendments} />
                                                                }<span className="uk-text-xmedium">Append</span>
                                                            </label>
                                                        :""}
                                                        <div className="uk-display-flex uk-flex-middle">
                                                            <label className="uk-display-flex uk-flex-middle uk-cursor-pointer">
                                                            {this.state.disableAppend 
                                                                ? <input type="radio" className="radioButton uk-margin-remove-top uk-margin-small-right" name="amendments" value={Amendments.Replace} disabled={this.state.disableReplace} onClick={this.updateSelectedAmendments} uk-tooltip={TOOLTIP_AMENDMENT_MESSAGE} />
                                                                : <input type="radio" className="radioButton uk-margin-remove-top uk-margin-small-right" name="amendments" value={Amendments.Replace} disabled={this.state.disableReplace} onClick={this.updateSelectedAmendments} />
                                                            }<span className="uk-text-xmedium">Replace</span>

                                                            </label>
                                                            <div className="hidden uk-flex" id="div-uploadedFilesFromBQ">
                                                                <CustomSelect
                                                                    id="select-uploadedFilesFromBQ"
                                                                    className="width-150 drilling"
                                                                    name="uploadedFilesFromBQ"
                                                                    placeholder="Choose one or many files"
                                                                    value={this.state.multiValue}
                                                                    options={this.state.filesToReplace === undefined || this.state.filesToReplace === null ? "" : this.state.filesToReplace}
                                                                    onChange={this.handleMultiChange}
                                                                    isMulti   //the 'multi' prop allows it to be multi-select
                                                                    isSearchable
                                                                    menuIsOpen={this.state.menuIsOpen}
                                                                    onMenuOpen={() => this.setState({ menuIsOpen: true })}
                                                                    onMenuClose={() => this.setState({ menuIsOpen: false })}
                                                                    closeMenuOnSelect={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </fieldset>
                                    <div id="submitButtons" className="uk-flex justify-content-end uk-margin-medium-top">
                                        <Button 
                                            label={"Cancel"}
                                            variant={BUTTON_VARIANT.SECONDARY}
                                            size={SIZES.DEFAULT}
                                            type={BUTTON_TYPE.DEFAULT}
                                            data-dismiss="modal"
                                            className="d-inline-block"
                                        />
                                        <Button 
                                            label={"Submit"}
                                            variant={BUTTON_VARIANT.PRIMARY}
                                            size={SIZES.DEFAULT}
                                            type={BUTTON_TYPE.DEFAULT}  
                                            className="uk-margin-default-left"
                                            onBtnClick={this.uploadFile}
                                        />
                                    </div>
                                    <div id="uploadProgress" style={{display:'none'}}>
                                        <h4 className="uk-margin-small-bottom uk-text-small">Uploading File</h4>
                                        <div id="upload-status"></div>
                                        <div className="progress mt-mb-15" id="progressBar">
                                            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                                                0%
                                            </div>
                                        </div>
                                        <pre id="result"></pre>
                                        <div className="uk-flex justify-content-end uk-margin-default-top">
                                        <Button 
                                            id="close-btn"
                                            label={"Cancel"}
                                            variant={BUTTON_VARIANT.PRIMARY}
                                            size={SIZES.DEFAULT}
                                            type={BUTTON_TYPE.DEFAULT}  
                                            disabled={true}
                                            data-dismiss="modal"
                                            className="upload-cancel-button"
                                            onBtnClick={this.changeText}
                                        />
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
			    </div>
                <div id="configureModal" className="modal fade" role="dialog" style={{zIndex:"1062"}}>
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <button type="button" className="close-button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="fal fa-times" /></span>
                            </button>
                            <h4 className="uk-margin-medium uk-margin-remove-bottom">{MESSAGES.UPLOAD.configure_modal_title + this.state.rowData[DEFINE_FIELDS.FIELD_NAME_FIELD]}</h4>
                            <div className="uk-padding-large">
                                <div className="uk-display-flex uk-flex-bottom uk-margin-xmedium-bottom uk-flex-between">
                                    <div className="uk-flex-inline uk-flex-column">
                                        <label htmlFor="select-currencysubcategory" className="fs-14 width-150 uk-margin-xsmall-bottom">{MESSAGES.UPLOAD.currency_subcategory}</label>
                                        <CustomSelect
                                            id="select-currencysubcategory"
                                            className="width-150"
                                            name="currencySubcategory "
                                            placeholder="Select currency subcategory"
                                            value={findOptionByKey(this.state.currencySubSections, this.state.currencySubSection)}
                                            options={this.state.currencySubSections}
                                            onChange={(e)=>this.handleCurrencyChange(e, RAW_ITEMS.FILE)}
                                            classNamePrefix="currency-conversion-dropdown"
                                        />
                                    </div>
                                    <div className="uk-flex-inline uk-flex-column uk-padding-small-left">
                                        <label htmlFor="select-transactioncurrency" className="fs-14 width-150 word-wrap uk-margin-xsmall-bottom">{capitaliseFirstLetterAfterChar(this.state.selectedSubSection.replaceAll("_",' ')) + MESSAGES.UPLOAD.transaction_currency}</label>
                                        <CustomSelect
                                            id="select-transactioncurrency"
                                            className="width-150"
                                            name="transactionCurrency "
                                            placeholder={"Select "+ capitaliseFirstLetterAfterChar(this.state.selectedSubSection.replaceAll("_"," "))+ " currency "}
                                            value={findOptionByKey(this.state.currencyColumns, this.state.currencyColumn)}
                                            options={this.state.currencyColumns}
                                            onChange={(e)=>this.handleCurrencyChange(e, RAW_ITEMS.FIELD_NAME)}
                                            formatOptionLabel={bridgeOptions}
                                            classNamePrefix="currency-conversion-dropdown"
                                        />
                                    </div>
                                    {this.state.showCurrencyDate ?
                                        <div className="uk-flex-inline uk-flex-column uk-padding-small-left">
                                            <div className="uk-flex uk-flex-middle width-150">
                                                <label htmlFor="select-tranctiondate" className="fs-14 uk-margin-xsmall-bottom">{capitaliseFirstLetterAfterChar(this.state.selectedSubSection.replaceAll("_",' ')) + MESSAGES.UPLOAD.date_currency}</label>
                                                <i className="fal fa-info-circle uk-margin-xsmall-left" uk-tooltip={MESSAGES.UPLOAD.date_currency_tooltip} />
                                            </div>
                                            <CustomSelect
                                                id="select-tranctiondate"
                                                className="width-150"
                                                name="transactionDate "
                                                placeholder={"Select "+ capitaliseFirstLetterAfterChar(this.state.selectedSubSection.replaceAll("_"," "))+ " date "}
                                                value={findOptionByKey(this.state.currencyDateColumns, this.state.currencyDate)}
                                                options={this.state.currencyDateColumns}
                                                onChange={(e)=>this.handleCurrencyChange(e, RAW_ITEMS.FIELD_DATA_TYPE)}
                                                classNamePrefix="currency-conversion-dropdown"
                                            />
                                        </div>
                                        :
                                        ""
                                    }
                                    <div className="uk-flex-inline uk-flex-column uk-padding-small-left">
                                        <label htmlFor="conversion-currency-input" className="fs-14 width-150 uk-margin-xsmall-bottom">{MESSAGES.UPLOAD.conversion_currency}</label>
                                        <input id="conversion-currency-input" type="text"value={window._format?.currency_abbreviation} className="form-control width-150 uk-disabled uk-input-readonly"></input>
                                    </div>
                                </div>
                                <div className="crosswalk-table uk-border">
                                    <div className="mapping-header uk-flex-between uk-padding-large-right">
                                        <div className="uk-display-flex uk-flex-middle">
                                            <div className="fs-14 uk-text-bold">{MESSAGES.UPLOAD.entity_matching}</div>
                                            <i className="fal fa-info-circle uk-margin-xsmall-left" uk-tooltip={MESSAGES.UPLOAD.entity_matching_summary_tooltip_1 + capitaliseFirstLetterAfterChar(this.state.selectedSubSection.replaceAll("_",' ')) + MESSAGES.UPLOAD.entity_matching_summary_tooltip_2} />
                                        </div>
                                        <div id="matchingCurrency" className="text-link fs-14 uk-text-decoration-style-dashed" uk-tooltip={this.state.tooltip_message}>{this.state.matching_message ? "Matching " + this.state.matching_message : ""}</div>
                                    </div>
                                    <ReactTable
                                        data={currenciesMatchingData}
                                        columns={[
                                            {
                                                Header: capitaliseFirstLetterAfterChar(this.state.selectedSubSection.replaceAll("_"," ")) + " Currency",
                                                accessor: "transaction_currency",
                                                /*Filterable: true,*/
                                                Cell: row => (
                                                    <div style={{textAlign:"center"}} id="fields">{row.original.transaction_currency}</div>
                                                ),
                                            },
                                            {
                                                Header: "Currency Subcategory",
                                                accessor: "currency_subcategory",
                                                Cell: row => (
                                                    <div style={{textAlign:"center"}} className={(row.original.currency_subcategory === undefined || (row.original.currency_subcategory === "" && row.original.transaction_currency !== "") ||( row.original.currency_subcategory === 'NULL' && row.original.transaction_currency !== 'NULL')) ? "red" : ""} id="fields">
                                                        {((row.original.currency_subcategory === "" && row.original.transaction_currency !== "") || row.original.currency_subcategory === undefined||( row.original.currency_subcategory === 'NULL' && row.original.transaction_currency !== 'NULL')) ?  "No match" : row.original.currency_subcategory}
                                                    </div>
                                                )
                                            }
                                        ]}
                                        sortable ={true}
                                        filterable={true}
                                        defaultPageSize={5}
                                        showPagination = {true}
                                        defaultSorted={[{ id: 'transaction_currency', asc: true }]}
                                        page={this.state.page}
                                        onPageChange={page => this.setPage(page)}
                                    />
                                </div>
                            </div>
                            <div className="uk-display-flex uk-flex-right uk-padding-small-bottom uk-padding-large-right">
                                <Button 
                                    label={"Cancel"}
                                    variant={BUTTON_VARIANT.SECONDARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}
                                    data-dismiss="modal"
                                    className="uk-margin-default-right"
                                />
                                <Button 
                                    label={"Submit"}
                                    variant={BUTTON_VARIANT.PRIMARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}  
                                    onBtnClick={this.saveconfiguration}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div id="afterSuccessResponseModal" className="modal fade" role="dialog">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <button type="button" className="close-button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="fal fa-times" /></span>
                            </button>
                            <h4 className="uk-margin-medium uk-margin-remove-bottom"> {validationsTitle}</h4>
                            { this.state.uploadResponseError !== "" ? <h5 className="error pi-text-white-space-wrap uk-margin-medium-left uk-margin-remove-bottom"> {this.state.uploadResponseError} </h5> : ""}
                            <div className="modal-body pl-pr-30">
                                <ul id="ul-list-upload" className="list-group borderless">
                                    {uploadValidations}
                                </ul>
                            { STATUS_ENUM[this.state.uploadResponseStatus] === STATUS_ENUM.pass && this.state.uploadResponseColumns ?
                                <div className="success-upload-dialog">
                                    { this.state.uploadResponseCount !== "" && this.state.uploadResponseColumns ? <p className="sample-table-header">Sample table that has 5 lines out of {this.state.uploadResponseCount} </p> : ""}
                                    <ReactTable
                                        data={this.state.uploadResponseColumns}
                                        columns={uploadResponseColumns}
                                        className="-highlight"
                                        defaultPageSize={5}
                                        showPagination={false}
                                        sortable={true}
                                        resizable={false}
                                    />
                                </div>
                                : ""
                            }
                                <div className="uk-flex justify-content-end uk-margin-medium-top">
                                <Button 
                                    id="ok-pass-btn"
                                    label={amendmentValue === Amendments.New && isDefineFileAllowed && this.state.clickPosition !== UPLOAD_FIELDS.PROGRESS_FIELD && STATUS_ENUM[this.state.uploadResponseStatus] === STATUS_ENUM.pass ? GoToDefine : "Ok"}
                                    variant={BUTTON_VARIANT.PRIMARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}  
                                    data-dismiss="modal"
                                    onBtnClick={() => amendmentValue === Amendments.New && isDefineFileAllowed && this.state.clickPosition !== UPLOAD_FIELDS.PROGRESS_FIELD && STATUS_ENUM[this.state.uploadResponseStatus] === STATUS_ENUM.pass ? this.prepareRawFileDefinition(this.state.selectedSection, this.state.selectedSubSection) : false}
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="validationModal" className="modal fade" role="dialog">
                    <div className="modal-dialog modal-md">
                        <div className={"modal-content uk-padding-xmedium"}>
                            <h4 className="uk-margin-small-bottom">{MESSAGES.UPLOAD.file_being_validated}</h4>
                            <h4 className="uk-margin-small-bottom">{MESSAGES.UPLOAD.please_wait}</h4>
                        </div>
                        
                    </div>
                </div>
                <div id="confirmReplaceFile" className="modal fade" role="dialog">
                    <div className="modal-dialog modal-md">
                        <div className={"modal-content uk-padding-xmedium"}>
                            <h4 className="uk-margin-small-bottom">{MESSAGES.UPLOAD.file_replaced}</h4>
                            <div className="uk-flex justify-content-end uk-margin-default-right-left mrgt30">
                            <Button 
                                label={"No"}
                                variant={BUTTON_VARIANT.SECONDARY}
                                size={SIZES.DEFAULT}
                                type={BUTTON_TYPE.DEFAULT}
                                data-dismiss="modal"
                                className="uk-margin-default-right"
                                onBtnClick={this.setClicked}
                            />
                            <Button 
                                label={"Yes"}
                                variant={BUTTON_VARIANT.PRIMARY}
                                size={SIZES.DEFAULT}
                                type={BUTTON_TYPE.DEFAULT}  
                                data-dismiss="modal"
                                onBtnClick={this.openUserDownloadWindow}
                            />
                        </div>
                    </div>   
                </div>
                </div>
                <div id="afterDefineModal" className="modal fade" role="dialog">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="mrgb40">
                                <button type="button" className="close-button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><i className="fal fa-times" /></span>
                                </button>
                            </div>
                            <h5 className="uk-margin-large-top pi-text-white-space-pre padding-left-15px">{this.state.afterDefineError.length ? <span className="red">{this.state.afterDefineError}</span> : this.state.afterDefineMessage}</h5>
                            <div className="modal-body">
                                <ul id="ul-list-define" className="list-group borderless">
                                    {uploadValidations}
                                </ul>
                                <div className="uk-flex justify-content-end uk-margin-default-right-left">
                                    <Button 
                                        id="ok-pass-btn"
                                        label={"Ok"}
                                        variant={BUTTON_VARIANT.PRIMARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}  
                                        data-dismiss="modal"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="definePLFileModal" className="modal fade" role="dialog">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <button type="button" className="close-button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="fal fa-times" /></span>
                            </button>
                            <div className="uk-margin-medium-top uk-margin-medium-left">
                                <h4 className="fs-20">Define - {this.state.selectedSubSection ? capitaliseFirstLetterAfterChar(this.state.selectedSection).replaceAll("_", " ") : ""}<i className="fal fa-info-circle uk-margin-small fa-xs" uk-tooltip={pandl_define_info_message}></i></h4>
                                <h4>{"Period: "+this.state.selectedPeriod}</h4>
                            </div>
                            <div className="modal-body pl-pr-30">
                                <div className="p-and-l-data-container">
                                    <div className="uk-medium-text uk-margin-left" id="pl_text">{this.state.selectedSubSection ? capitaliseFirstLetterAfterChar(this.state.selectedSubSection).replaceAll("_", " ") : ""}</div>
                                    <div id="p_and_l_react_table">
                                        <ReactTable
                                            data={pAndLData}
                                            resizable={false}
                                            columns={[
                                                {
                                                Header: "Name",
                                                accessor: "name",
                                                },
                                                {
                                                Header: "Expected Values",
                                                accessor: "expected_value",
                                                Cell: row => (
                                                    <input className="expectedValues" disabled={obj.state.fileClick === true ? true : false} id={row.original.name.replace(" ","")+"ExpectedValues"} defaultValue={pAndLData[row.index]['expected_value'] !== null ? formatValString(pAndLData[row.index]['expected_value'], FormatTypes.AMOUNT) : ""}/>
                                                )},
                                                {
                                                Header: "Confirmed Values",
                                                accessor: "confirmed_value",
                                                Cell: row => (
                                                    <input className="confirmedValues" disabled={obj.state.fileClick === true ? true : false} id={row.original.name.replace(" ","")+"ConfirmedValues"} defaultValue={pAndLData[row.index]['expected_value'] !== null ? formatValString(pAndLData[row.index]['expected_value'], FormatTypes.AMOUNT) : ""}/>
                                                )}
                                            ]}
                                            minRows={4}
                                            sortable={false}
                                            showPagination={false}
                                            className="definePLFileTable"
                                        />
                                    </div>
                                </div>
                                <div className="uk-flex justify-content-end uk-margin-medium-top">
                                    <Button 
                                        id="cancel-define-data-btn"
                                        label={"Cancel"}
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        data-dismiss="modal"
                                        onBtnClick={this.resetDefineFileModal}
                                    />

                                    {
                                        !this.state.defineAsReadOnly && !hideDefineSubmit?
                                            <Button 
                                                id="submit-define-data-btn"
                                                label={"Submit"}
                                                variant={BUTTON_VARIANT.PRIMARY}
                                                size={SIZES.DEFAULT}
                                                type={BUTTON_TYPE.DEFAULT}  
                                                className="uk-margin-default-left"
                                                onBtnClick={this.confirmExpectedValues}
                                            />
                                            : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    id={"defineCrosswalkFileModal"}
                    openDialog={this.state.openDefineCrosswalk}
                    bodyContent={this.defineCrosswalkDialogContent}
                    dialogActions={this.defineCrosswalkDialogActions}
                    closeClick={() => this.setDefineCrosswalkDialogOpen(false)}
                    size={DIALOG_SIZE.XXLARGE}
                />
                <Modal
                    id={"matchingEntityModal"}
                    openDialog={this.state.openMatchingEntityDialog}
                    bodyContent={this.matchingEntityDialogContent}
                    dialogActions={this.matchingEntityDialogActions}
                    closeClick={() => this.setMatchingEntityDialogOpen(false)}
                    size={DIALOG_SIZE.XLARGE}
                    additionalBodyClassName={"overflow_hidden"}
                />
                <Modal
                    id={"deleteModal"}
                    openDialog={this.state.openDeleteModal}
                    bodyContent={this.deleteDialogContent}
                    dialogActions={this.deleteDialogActions}
                    closeClick={() => this.setDeleteDialogOpen(false)}
                    size={DIALOG_SIZE.SMALL}
                />
                <Modal
                    id={"deleteReplaceFilesModal"}
                    openDialog={this.state.openDeleteReplaceModal}
                    bodyContent={this.deleteReplaceDialogContent}
                    dialogActions={this.deleteReplaceDialogActions}
                    closeClick={() => this.setDeleteReplaceDialogOpen(false)}
                    size={DIALOG_SIZE.SMALL}
                />

                <Modal
                    id={"saveCrosswalkModal"}
                    openDialog={this.state.openSaveCrosswalkModal}
                    bodyContent={this.deleteSaveCrosswalkContent}
                    dialogActions={this.deleteSaveCrosswalkActions}
                    closeClick={() => this.setSaveCrosswalkDialogOpen(false)}
                    size={DIALOG_SIZE.SMALL}
                />

                <div id="defineFileModal" className="modal fade" role="dialog">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="mrgb40">
                                <button type="button" className="close-button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><i className="fal fa-times" /></span>
                                </button>
                            </div>
                            <h4 className="uk-margin-medium-left">Define - {this.state.defineDisplayType && this.state.defineDisplayType !== "" ? this.state.defineDisplayType.split("-")[0]: ""}<i className="fal fa-info-circle uk-margin-small fa-xs" uk-tooltip={define_info_message}></i></h4>
                            <h4 className="uk-margin-medium-left uk-margin-xsmall-bottom">{this.state.defineDisplayType && this.state.defineDisplayType !== "" && this.state.defineDisplayType.includes(")")? "File Name: " +capitaliseFirstLetterAfterChar(this.state.defineDisplayType.split("(")[1].replace(")","")).replaceAll("_", " "): ""}</h4>
                            <div className="modal-body">
                                <div id="define-dropdown-table">
                                {this.state.selectedSection === MASTER_DATA || this.state.selectedSection === ANCILLARY_FILES ?
                                    <div className="uk-flex uk-padding-small-left">
                                        <h6 className="fs-14 input-width-300 uk-margin-xsmall-bottom">{MESSAGES.UPLOAD.ancillary_join_data_file_name}</h6>
                                        <h6 className="fs-14 input-width-300 uk-padding-large-left uk-margin-xsmall-bottom">{MESSAGES.UPLOAD.ancillary_join_data_column}</h6>
                                    </div>
                                    :""}
                                    {this.state.selectedSection === MASTER_DATA || this.state.selectedSection === ANCILLARY_FILES ?
                                    <div className="uk-flex uk-padding-small-left">
                                        <CustomSelect
                                            id="select-transactionFile"
                                            className="input-width-300"
                                            name="transactionColumn "
                                            placeholder="Transaction/Master File Name"
                                            value={findOptionByKey(this.state.mappingFileTypes, this.state.transactionFile)}
                                            options={this.state.mappingFileTypes}
                                            onChange={(e)=>this.handleMappingFileChange(e)}
                                            isDisabled={this.state.defineAsReadOnly}
                                        />
                                        <CustomSelect
                                            id="select-transactionColumn"
                                            className="input-width-300 uk-margin-medium-left"
                                            name="transactionColumn "
                                            placeholder="Transaction/Master Column"
                                            value={findOptionByKey(this.state.transactionColumns, this.state.transactionKey)}
                                            options={this.state.transactionColumns}
                                            onChange={(e)=>this.handleTransactionColumnChange(e)}
                                            isDisabled={this.state.defineAsReadOnly}
                                            />
                                        {this.state.selectedSection === MASTER_DATA || this.state.selectedSection === ANCILLARY_FILES?
                                            <div className="uk-display-flex align-self-center uk-margin-medium-left auditMessage" id="dots_loader">
                                                <img src='/images/3dots.gif' className="height-20" />
                                            </div>
                                            : ""}
                                        {this.state.selectedSection === MASTER_DATA || this.state.selectedSection === ANCILLARY_FILES ?
                                            <div className="uk-display-flex uk-flex-middle uk-text-medium auditMessage uk-margin-medium-left">{this.state.auditMesage}</div>
                                            : ""}
                                    </div>
                                :""}
                                    <div className="upload-data-table-container">
                                        <div className="uk-text-xmedium uk-margin-left">{this.state.selectedSubSection ? capitaliseFirstLetterAfterChar(this.state.selectedSubSection.replaceAll("_", " ")) :""}</div>
                                        <UploadDataTable id={DEFINE_DATA + "_table"} ref={this.tableReferences[DEFINE_DATA]} initialDefinition={initialDefinition} compareDefinition={this.compareDefinition} table_type={DEFINE_DATA} tableData={definition} DEFINE_DATA={DEFINE_DATA} selectedFileColumnNames={this.state.selectedFileColumnNames} setFileHeader={this.setFileHeader}
                                        mandatorySectionFields={mandatorySectionFieldsVar} handleRowDataChange={this.handleRowDataChange} updateUploadStatusResponse={this.updateUploadStatusResponse} selectedSectionFields={mandatorySectionFields_selectedVar} defineAsReadOnly={this.state.defineAsReadOnly} isFileDefined={this.state.isFileDefined} dataTypesFields ={dataTypesFieldsVar}
                                        defineData={definition} setFormatsElements={this.setFormatsElements} setBoolValues={this.setBoolValues} getBoolValues={this.getColumnBooleanValues} section={this.state.selectedSection} subsection={this.state.selectedSubSection} definedFileNumber={this.state.definedFileNumber} configure={this.configure} clearConfiguration={this.clearConfiguration}/>
                                    </div>
                                </div>
                                { !publicPrivateFiles || (previewPrivateFilesAccess && publicPrivateFiles) ?
                                    <div id="define-sample-rows">
                                        {  this.state.fileResponse !== "" && this.state.fileResponse ?
                                            <div className="upload-data-table-container">
                                                <div className="uk-display-flex uk-flex-between uk-flex-middle uk-margin-xmedium-right-left">
                                                    <h5>{"Sample table that has 5 lines out of "+ formatValString(this.state.fileResponse.map.total_count, FormatTypes.BASIS_POINT)}</h5>
                                                    <div className="uk-text-xmedium text-link">Preview Files</div>
                                                </div>
                                                <ReactTable
                                                    data={this.state.fileResponseColumns}
                                                    columns={defineResponseColumns}
                                                    className="-striped -highlight"
                                                    defaultPageSize={5}
                                                    showPagination={false}
                                                    sortable={true}
                                                    resizable={false}
                                                />
                                            </div>
                                            : ""
                                        }
                                    </div> : ""
                                }
                                <div className="uk-flex justify-content-end uk-margin-default-right-left uk-margin-default-top">
                                    <Button  
                                        id="cancel-define-data-btn"
                                        label={MESSAGES.modal.buttons.cancel}
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        data-dismiss="modal"
                                        onBtnClick={this.resetDefineFileModal}
                                    />
                                    {
                                        !this.state.defineAsReadOnly && !hideDefineSubmit ?
                                            <Button
                                                id="submit-define-data-btn"
                                                label={"Submit"}
                                                variant={BUTTON_VARIANT.PRIMARY}
                                                size={SIZES.DEFAULT}
                                                type={BUTTON_TYPE.DEFAULT}  
                                                className={"uk-margin-default-left define-submit"}
                                                disabled={this.state.isFileDefined}
                                                onBtnClick={this.defineFile}
                                            />
                                            : ""
                                    }
                                    {
                                        !this.state.defineAsReadOnly && !hideDefineSubmit ?
                                            <Button 
                                                id="submit-define-data-btn-new-arch"
                                                label={"Submit New Arch"}
                                                variant={BUTTON_VARIANT.PRIMARY}
                                                size={SIZES.DEFAULT}
                                                type={BUTTON_TYPE.DEFAULT}  
                                                disabled
                                                className={"uk-margin-default-left define-submit"}
                                                onBtnClick={this.defineFileNewArch}
                                            />
                                        : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="fileHistoryModal" className="modal fade" role="dialog">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content uk-margin-remove">
                            <button type="button" className="close-button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="fal fa-times" /></span>
                            </button>
                            <h5 className="uk-margin-default-bottom uk-margin-medium-left uk-margin-medium-top fs-20">History - {this.state.selectedDataTypeDisplay}</h5>
                            <div className="modal-body pl-pr-30">
                                <UploadDataTable id={HISTORY_DATA + "_table"} ref={this.tableReferences[HISTORY_DATA]} table_type={HISTORY_DATA} tableData={this.state.historyTableData} HISTORY_DATA={HISTORY_DATA} selectedSection={this.state.selectedSection} dataTypesFields ={dataTypesFieldsVar} setFormatsElements={this.setFormatsElements}/>
                                <div className="uk-flex justify-content-end uk-margin-medium-top">
                                    <Button 
                                        id="close-btn"
                                        label={"Close"}
                                        variant={BUTTON_VARIANT.PRIMARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}  
                                        data-dismiss="modal"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                { this.state.filesToReplace ?
                    <div id="replaceFilesModal" className="modal fade" role="dialog">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="mrgb40">
                                    <button type="button" className="close-button" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true"><i className="fal fa-times" /></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <h5 className="uk-margin-small-bottom">{'Are you sure you want to substitute ' + this.state.filesToReplace.join(", ") + ' with ' + this.state.replacingFile.name + '?'}</h5>
                                    <div className="uk-flex justify-content-end uk-margin-default-right-left mrgt30">
                                        <Button  
                                            label={MESSAGES.modal.buttons.no}
                                            variant={BUTTON_VARIANT.SECONDARY}
                                            size={SIZES.DEFAULT}
                                            type={BUTTON_TYPE.DEFAULT}
                                            data-dismiss="modal"
                                            className="uk-margin-default-right"
                                        />
                                        <Button 
                                            label={MESSAGES.modal.buttons.yes}
                                            variant={BUTTON_VARIANT.PRIMARY}
                                            size={SIZES.DEFAULT}
                                            type={BUTTON_TYPE.DEFAULT}  
                                            data-dismiss="modal"
                                            onBtnClick={()=>this.generateSignedURL(this.state.replacingFile)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""
                }
                <div id="decryptFileModal" className="modal fade" role="dialog">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="mrgb40">
                                <button type="button" className="close-button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><i className="fal fa-times" /></span>
                                </button>
                            </div>
                            <div className="modal-body">
                            <h5 className="decrypt-popup-form-title uk-margin-bottom">Uploaded File needs to be decrypted!</h5>
                            <p className="uk-text-xmedium">The uploaded file will be decrypted by our team and we will get back to you once we’re done! <br/> Thank you for your patience!</p>
                                <div className="uk-flex justify-content-end uk-margin-default-right-left mrgt30">
                                <Button 
                                    label={"Ok"}
                                    variant={BUTTON_VARIANT.PRIMARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}  
                                    data-dismiss="modal"
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="confirmBoolModal" className="modal fade" role="dialog">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="mrgb40">
                                <button type="button" className="close-button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><i className="fal fa-times" /></span>
                                </button>
                            </div>
                            <div className="modal-body">
                            <p className="uk-text-xmedium">{MESSAGES.confirm_boolean_selection}</p>
                            <div className="uk-flex justify-content-between uk-margin-default-right-left uk-margin-default-top">
                                <Button  
                                    id="close-btn"
                                    label={MESSAGES.modal.buttons.cancel}
                                    variant={BUTTON_VARIANT.SECONDARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}
                                    data-dismiss="modal"
                                    className="uk-margin-default-right"
                                />
                                <Button 
                                    id="submit-format-data-btn"
                                    label={"Submit"}
                                    variant={BUTTON_VARIANT.PRIMARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}  
                                    onBtnClick={obj.setValue}
                                />
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="formatModal" className="modal fade" role="dialog">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="mrgb40">
                                <button type="button" className="close-button" data-dismiss="modal" aria-label="Close" onClick={()=>obj.resetBoolValues()}>
                                    <span aria-hidden="true"><i className="fal fa-times" /></span>
                                </button>
                            </div>
                            <h5 className="padding-left-15px uk-margin-remove uk-text-bold">Format<i className="fal fa-info-circle uk-margin-small fa-xs" uk-tooltip={format_define_info_message}></i></h5>
                            <div className="modal-body">
                                <div>
                                    {
                                        errorMessage !== "" ?
                                        errorMessage.split('/').map(function(item) {
                                            return(
                                                <div className="error">{item}</div>
                                            );
                                        })
                                    :""}
                                </div>

                                <div id="datatype-format" hidden className={Formats.String +" uk-display-inline datatype-format"}>
                                    <fieldset id="string-type" className="fieldset-border">
                                        <div className="uk-flex uk-flex-middle">
                                            <h6 className="uk-flex-column uk-width-1-2">Min Length</h6>
                                            <input type="number" id="minLength" min ="0" className="uk-width-1-2 form-control uk-margin-small-left" defaultValue={!currentDataTypesValues[DEFINE_FIELDS.MIN_LENGTH_FIELD] ? "" : formatValHTML(currentDataTypesValues[DEFINE_FIELDS.MIN_LENGTH_FIELD], FormatTypes.BASIS_POINT)}></input>
                                        </div>
                                        <div className="uk-flex uk-flex-middle">
                                            <h6 className="uk-flex-column uk-width-1-2">Max Length</h6>
                                            <input type="number" id="maxLength" min="0" className="uk-width-1-2 form-control uk-margin-small-left" defaultValue={!currentDataTypesValues[DEFINE_FIELDS.MAX_LENGTH_FIELD] ? "" : formatValHTML(currentDataTypesValues[DEFINE_FIELDS.MAX_LENGTH_FIELD], FormatTypes.BASIS_POINT)}></input>
                                        </div>

                                        <div className="uk-flex uk-flex-middle">
                                            <h6 className="uk-flex-column uk-width-1-2">Character Type</h6>
                                            <CustomSelect
                                                id="select-TrailingChar"
                                                className="uk-width-1-2"
                                                name="CharacterType"
                                                placeholder="Character Type"
                                                value={findOptionByKey(obj.state.charactersOptions, isTrailingorLeading)}
                                                options={obj.state.charactersOptions}
                                                onChange={obj.handleChangeIsTrailing}
                                            />
                                        </div>
                                        <div  className="uk-flex uk-flex-middle">
                                            <h6 className="uk-flex-column uk-width-1-2">Trailing/Leading Character</h6>
                                            <input type="text" id="trailOrLead" className="uk-width-1-2 form-control uk-margin-small-left" maxLength="1" defaultValue={!currentDataTypesValues[DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD] ? "" : currentDataTypesValues[DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD]}></input>
                                        </div>
                                    </fieldset>
                                    <div className="uk-flex justify-content-between uk-margin-default-right-left uk-margin-default-top">
                                        <Button  
                                            id="close-btn"
                                            label={MESSAGES.modal.buttons.cancel}
                                            variant={BUTTON_VARIANT.SECONDARY}
                                            size={SIZES.DEFAULT}
                                            type={BUTTON_TYPE.DEFAULT}
                                            data-dismiss="modal"
                                            className="uk-margin-default-right"
                                        />
                                        <Button 
                                            id="submit-format-data-btn"
                                            label={"Apply"}
                                            variant={BUTTON_VARIANT.PRIMARY}
                                            size={SIZES.DEFAULT}
                                            type={BUTTON_TYPE.DEFAULT}  
                                            data-dismiss="modal"
                                            onBtnClick={()=> obj.applyCellFormats(currentDataType)}
                                        />
                                    </div>
                                </div>

                                <div id="datatype-format" hidden className={Formats.Numeric +" uk-display-inline datatype-format"}>
                                    <fieldset id= "numeric-type" className="fieldset-border">
                                        <div className="uk-flex uk-flex-middle">
                                            <h6 className="uk-flex-column uk-width-1-2">Min</h6>
                                            <input type="number" id="min" className="form-control uk-width-1-2 uk-margin-small-left" defaultValue={!currentDataTypesValues[DEFINE_FIELDS.MIN_FIELD] ? "" : formatValHTML(currentDataTypesValues[DEFINE_FIELDS.MIN_FIELD], FormatTypes.BASIS_POINT)}></input>
                                        </div>
                                        <div className="uk-flex uk-flex-middle">
                                            <h6 className="uk-flex-column uk-width-1-2">Max</h6>
                                            <input type="number" id="max" className="form-control uk-width-1-2 uk-margin-small-left" defaultValue={!currentDataTypesValues[DEFINE_FIELDS.MAX_FIELD] ? "" : formatValHTML(currentDataTypesValues[DEFINE_FIELDS.MAX_FIELD], FormatTypes.BASIS_POINT)}></input>
                                        </div>

                                        <div className="uk-flex uk-flex-middle">
                                            <h6 className="uk-flex-column uk-width-1-2">Decimal</h6>
                                            <input type="number" id="decimal" min="0" onKeyUp={adjustNumberFieldToInteger} onChange={adjustNumberFieldToInteger}
                                                   className="form-control uk-width-1-2 uk-margin-small-left" defaultValue={currentDataTypesValues[DEFINE_FIELDS.DECIMAL_FIELD] === undefined ? currentDataTypesValues[DEFINE_FIELDS.DECIMAL_FIELD] : ""}/>
                                        </div>
                                    </fieldset>
                                    <div className="uk-flex justify-content-between uk-margin-default-right-left uk-margin-default-top">
                                        <Button  
                                            id="close-btn"
                                            label={MESSAGES.modal.buttons.cancel}
                                            variant={BUTTON_VARIANT.SECONDARY}
                                            size={SIZES.DEFAULT}
                                            type={BUTTON_TYPE.DEFAULT}
                                            data-dismiss="modal"
                                            className="uk-margin-default-right"
                                        />
                                        <Button 
                                            id="submit-format-data-btn"
                                            label={"Apply"}
                                            variant={BUTTON_VARIANT.PRIMARY}
                                            size={SIZES.DEFAULT}
                                            type={BUTTON_TYPE.DEFAULT}  
                                            onBtnClick={()=> obj.applyCellFormats(currentDataType)}
                                        />
                                    </div>
                                </div>

                                <div id="datatype-format" hidden className={Formats.DateTime +" uk-display-inline datatype-format"}>
                                    <fieldset id= "datetime-type" className="fieldset-border">
                                        <div className="uk-flex uk-flex-middle">
                                            <h6 className="uk-flex-column uk-width-1-2">Date Format</h6>
                                            <input type="text" id="dateFormat" min="0" className="form-control uk-width-1-2 uk-margin-small-left" defaultValue={!currentDataTypesValues[DEFINE_FIELDS.DATE_FORMAT_FIELD] || currentDataTypesValues[DEFINE_FIELDS.DATE_FORMAT_FIELD] === "" ? DEFAULT_DATE_FORMAT_UPLOAD : currentDataTypesValues[DEFINE_FIELDS.DATE_FORMAT_FIELD]}></input>
                                            <i id="help-image" className="far fa-question-circle fa-lg upload-data-fa uk-margin-xsmall" alt="Big Query help" onClick={()=>obj.directToGoogleHelp()}></i>
                                        </div>
                                    </fieldset>
                                    <div className="uk-flex justify-content-between uk-margin-default-right-left">
                                        <Button  
                                            id="close-btn"
                                            label={MESSAGES.modal.buttons.cancel}
                                            variant={BUTTON_VARIANT.SECONDARY}
                                            size={SIZES.DEFAULT}
                                            type={BUTTON_TYPE.DEFAULT}
                                            data-dismiss="modal"
                                            className="uk-margin-default-right"
                                        />
                                        <Button 
                                            id="submit-format-data-btn"
                                            label={"Apply"}
                                            variant={BUTTON_VARIANT.PRIMARY}
                                            size={SIZES.DEFAULT}
                                            type={BUTTON_TYPE.DEFAULT}  
                                            onBtnClick={()=> obj.applyCellFormats(currentDataType)}
                                        />
                                    </div>
                                </div>

                                <div id="datatype-format" hidden className={Formats.Boolean +" uk-display-inline datatype-format"}>
                                    <fieldset id="boolean-type" className="fieldset-border">
                                        <div className="uk-flex uk-flex-middle">
                                            <h6 id="trueInputLabel" className="uk-flex-column uk-width-1-4">Value if True</h6>
                                            <CustomSelect 
                                                id="select-format-boolean" 
                                                className="uk-margin-small-left uk-width-3-4"
                                                classNamePrefix="multiSelectNoWrap"
                                                name="Boolvalues"
                                                formatOptionLabel={optionLabel}
                                                placeholder="Boolean values"
                                                value={obj.state.boolMultiValue}
                                                options={obj.state.boolValues}
                                                isMulti 
                                                closeMenuOnSelect={false}
                                                onChange={obj.handleChangeBoolean}                                       
                                            /> 
                                        </div>
                                    </fieldset>
                                    <div className="uk-flex justify-content-between uk-margin-default-right-left">
                                        <Button  
                                            id="close-btn"
                                            label={MESSAGES.modal.buttons.cancel}
                                            variant={BUTTON_VARIANT.SECONDARY}
                                            size={SIZES.DEFAULT}
                                            type={BUTTON_TYPE.DEFAULT}
                                            data-dismiss="modal"
                                            className="uk-margin-default-right"
                                            onBtnClick={obj.resetBoolValues}
                                        />
                                        <Button 
                                            id="submit-format-data-btn"
                                            label={"Apply"}
                                            variant={BUTTON_VARIANT.PRIMARY}
                                            size={SIZES.DEFAULT}
                                            type={BUTTON_TYPE.DEFAULT}  
                                            onBtnClick={()=> obj.applyCellFormats(currentDataType)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
            </div>
		);

	}

}

export default UploadData;