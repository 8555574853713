import React, { Component } from 'react';
import Select from 'react-select';

import { toggleLoader,setLocalStorageValueByParameter } from '../class/common.js';
import { API_URL, CROSSWALK_FIELDS, Formats, FormatTypes, PERIOD_ONWARD, RAW_ITEMS, ROW_STATUS, UPLOAD_FIELDS, ALL_WIDGETS, SCENARIO_TYPE, FILE_CENSUS_FIELDS, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DROPDOWN_TYPE, UPLOAD_SECTIONS } from '../class/constants';
import { formatValHTML } from '../class/format';
import { convertPxToViewport } from '../class/formatting';
import { findOptionByKey ,getSectionExists} from '../class/utils.js';
import { arrangeFileNames } from '../sections/filter/FilterHelperFunctions.js';
import Button from '../newComponents/Button';
import DropDown from '../newComponents/DropDown';
import { generatePeriods, monthDiff } from '../class/date';
import { FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS } from '../class/networkUtils.js';
import { lang } from '../language/messages_en.js';

const $ = require('jquery');
const CROSSWALK = 'Crosswalk';
const P_L = 'P & L';
const PRIMARY_KEY = "primarykey";
const IS_PRIMARY = "is_primary";
const CURRENCY = "currency";


const baseUrl = process.env.REACT_APP_BASE_URL;
const path = API_URL.DATA_MODELING;

/**
 * Link
 * 
 * @author [Sarah Farjallah]
 */
class Link extends Component {
    constructor(props) {
        super(props);
        this.state = {
            [ROW_STATUS.FIELD]: !!this.props.linkObj && Object.keys(this.props.linkObj).length > 0 ? this.props.linkObj.state[ROW_STATUS.FIELD] : ROW_STATUS.VALUES.NEW,
            isSourceLoading: false,
            isTargetLoading: false,
            isOldArch: false,
        }

        this.deleteLink = this.deleteLink.bind(this);
        this.runMatchingEntityQuery = this.runMatchingEntityQuery.bind(this);
        this.runValidation = this.runValidation.bind(this);
        this.setData = this.setData.bind(this);
        this.getTimeRange = this.getTimeRange.bind(this);

        this.linkObj={
            [CROSSWALK_FIELDS.ID]: "",
            [CROSSWALK_FIELDS.SOURCE_SUBTYPE]: "",
            [CROSSWALK_FIELDS.SOURCE_FIELD_NAME]: "",
            [CROSSWALK_FIELDS.SOURCE_CROSSWALK_FIELD_NAME]: "",
            [CROSSWALK_FIELDS.TARGET_SUBTYPE]: "",
            [CROSSWALK_FIELDS.TARGET_FIELD_NAME]: "",
            [CROSSWALK_FIELDS.TARGET_CROSSWALK_FIELD_NAME]: "",
            [CROSSWALK_FIELDS.IS_OLD_ARCH]: false,
            [CROSSWALK_FIELDS.CROSSWALK_FILE_ID]: ""
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevState.cwColumns || prevProps.crosswalkColumns.id !== prevState.cwColsId) {
            this.setState({
                cwColsId: prevProps.crosswalkColumns.id,
                cwColumns: prevProps.crosswalkColumns.columns.map(col=>{
                    return {label: col, value: col}
                }),
                cwUniqueColumns: prevProps.crosswalkColumns.uniqueColumns.map(col=>{
                    return {label: col, value: col}
                })
            })
        }

        if (this.props.selectedStartPeriod !== prevProps.selectedStartPeriod || this.props.selectedEndPeriod !== prevProps.selectedEndPeriod) {      
            if (this.linkObj[CROSSWALK_FIELDS.SOURCE_CROSSWALK_FIELD_NAME] && this.linkObj[CROSSWALK_FIELDS.SOURCE_FIELD_NAME]) {
                this.setState({
                    isSourceLoading:true
                },function(){
                    this.runMatchingEntityQuery(prevProps.readMatchingNewArch, true, false, this.props.selectedStartPeriod, this.props.selectedEndPeriod);
                })
            }
            if (this.linkObj[CROSSWALK_FIELDS.TARGET_CROSSWALK_FIELD_NAME] && this.linkObj[CROSSWALK_FIELDS.TARGET_FIELD_NAME]) {
                this.setState({
                    isTargetLoading: true
                },function(){
                    this.runMatchingEntityQuery(prevProps.readMatchingNewArch, false, false, this.props.selectedStartPeriod, this.props.selectedEndPeriod);
                })
            }
        }
    }

    componentDidMount() {
        if (this.props.linkObj && Object.keys(this.props.linkObj).length > 0) {
            this.setState({
                isSourceLoading: true,
                isTargetLoading: true
            },function(){
                this.runMatchingEntityQuery(this.props.readMatchingNewArch, true, false);
                this.runMatchingEntityQuery(this.props.readMatchingNewArch, false, false);
            })
        }
        if(this.props.crosswalkColumns) {
            this.setState({
                cwColsId: this.props.crosswalkColumns.id,
                cwColumns: this.props.crosswalkColumns.columns.map(col=>{
                    return {label: col, value: col}
                }),
                cwUniqueColumns: this.props.crosswalkColumns.uniqueColumns.map(col=>{
                    return {label: col, value: col}
                })
            })
        }
    }

    showMatchingEntityModal(isSource) {
        if (isSource && !this.linkObj[CROSSWALK_FIELDS.SOURCE_ENTITY] && this.linkObj[CROSSWALK_FIELDS.SOURCE_ENTITY] !== "N/A") {
            return;
        }
        if(!isSource &&  !this.linkObj[CROSSWALK_FIELDS.TARGET_ENTITY] && this.linkObj[CROSSWALK_FIELDS.TARGET_ENTITY] !== "N/A") {
            return;
        }
        this.runMatchingEntityQuery(this.props.readMatchingNewArch, isSource, true);
        this.props.showMatchingEntityModal(this.props.index, isSource, this.linkObj);
    }

    getTimeRange(firstPeriod, lastPeriod) {
        var periods = this.props.clientPeriods;
        let periodsStr = "";
        var p1 = firstPeriod ? firstPeriod : this.props.selectedStartPeriod;
        var p2 = lastPeriod ? lastPeriod : this.props.selectedEndPeriod;
        if (!p1 || !p2) {
            return -1;
        }
        if (Number(p1.split("P")[0]) > Number(p2.split("P")[0])) {
            return -1;
        }else if (Number(p1.split("P")[0]) === Number(p2.split("P")[0])
            && Number(p1.split("P")[1]) > Number(p2.split("P")[1])) {
                return -1
        }
        if (p1 && p2) {
            if(p2 === PERIOD_ONWARD){
                let p1Index = periods.findIndex(item => item.value === p1);
                let p2Index = 0; //Latest period is at index 0
                periodsStr = periods.slice(p2Index,p1Index + 1).map(e=>e.value).join("','");
            } else {
                let p1StartDate = periods.filter(e=>e.value === p1).length > 0  ? new Date(periods.filter(e=>e.value === p1)[0].start_date) :"";
                let p2EndDate  = periods.filter(e=>e.value === p2).length > 0 ? new Date(periods.filter(e=>e.value === p2)[0].end_date):"";
                let periodsCount = monthDiff(p1StartDate, p2EndDate) + 1;
                periodsStr = generatePeriods(p1StartDate, periodsCount).join("','");
            }
        }
        return periodsStr;
    }

    prepareFileFields(typeName, subTypeName) {
        var columns = this.props.dataColumns.filter(el=>el[RAW_ITEMS.TYPE_NAME] === typeName && el[RAW_ITEMS.SUBTYPE_NAME] === subTypeName);
        var fields = [];
        for (var i in columns) {
            if (columns[i][RAW_ITEMS.FIELD_DATA_TYPE].toUpperCase() === Formats.String) {
                fields.push({value: columns[i][RAW_ITEMS.FIELD_NAME] , label:columns[i][RAW_ITEMS.FIELD_NAME], name:columns[i][RAW_ITEMS.DATA_FIELD]});
            }
        }
        return fields;
    }

    onChangeAttribute(e, type) {
        let tempState = {};
        this.linkObj[type] = e.value;
        
        if (type === CROSSWALK_FIELDS.SOURCE_SUBTYPE) { // change of source subcategory
            this.linkObj[CROSSWALK_FIELDS.SOURCE_COLUMNS] = this.prepareFileFields(e[RAW_ITEMS.SUBTYPE_NAME], e.value);
            this.linkObj[CROSSWALK_FIELDS.SOURCE_SUBTYPE_ID] = e[RAW_ITEMS.SUBTYPE_ID];
            this.linkObj[CROSSWALK_FIELDS.SOURCE_FILE_TYPE] = e[RAW_ITEMS.SUBTYPE_NAME];
            this.linkObj[CROSSWALK_FIELDS.SOURCE_FIELD_NAME] = undefined;
            
        }
        if (type === CROSSWALK_FIELDS.TARGET_SUBTYPE) { //change of target subcatergory
            this.linkObj[CROSSWALK_FIELDS.TARGET_COLUMNS] = this.prepareFileFields(e[RAW_ITEMS.SUBTYPE_NAME], e.value);
            this.linkObj[CROSSWALK_FIELDS.TARGET_SUBTYPE_ID] = e[RAW_ITEMS.SUBTYPE_ID];
            this.linkObj[CROSSWALK_FIELDS.TARGET_FILE_TYPE] = e[RAW_ITEMS.SUBTYPE_NAME];
            this.linkObj[CROSSWALK_FIELDS.TARGET_FIELD_NAME] =  undefined;
        }

        //change of source column or source crosswalk column
        if (type === CROSSWALK_FIELDS.SOURCE_FIELD_NAME || type === CROSSWALK_FIELDS.SOURCE_CROSSWALK_FIELD_NAME) {
            if(type === CROSSWALK_FIELDS.SOURCE_FIELD_NAME ) {
                if (e.name.toLowerCase().replace(/ /g,'') === PRIMARY_KEY || e.name.toLowerCase().replace(/ /g,'') === CURRENCY) {
                    this.linkObj[IS_PRIMARY] = true;
                }else{
                    this.linkObj[IS_PRIMARY] = false;
                }
            }
            if (this.linkObj[CROSSWALK_FIELDS.SOURCE_FIELD_NAME] && this.linkObj[CROSSWALK_FIELDS.SOURCE_CROSSWALK_FIELD_NAME]) {
                tempState.isSourceLoading = true;
                
                this.runMatchingEntityQuery(this.props.readMatchingNewArch, true, false);
               
            }
        }

        //change of source column or source crosswalk column
        if (type === CROSSWALK_FIELDS.TARGET_FIELD_NAME || type === CROSSWALK_FIELDS.TARGET_CROSSWALK_FIELD_NAME) {
            if (this.linkObj[CROSSWALK_FIELDS.TARGET_FIELD_NAME] && this.linkObj[CROSSWALK_FIELDS.TARGET_CROSSWALK_FIELD_NAME]) {
                tempState.isTargetLoading = true;
                this.runMatchingEntityQuery(this.props.readMatchingNewArch, false, false);
            }
            if (this.linkObj[CROSSWALK_FIELDS.SOURCE_FIELD_NAME] === PRIMARY_KEY) {
                this.runValidation();
            }
        }

        if(this.state[ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.NEW) {
            tempState[ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
        }
        this.setState(tempState);
    }

    runValidation(callback, linkObj) {
        linkObj = linkObj || this.linkObj;
        var query = {
            action: "runValidation",
            field: linkObj[CROSSWALK_FIELDS.TARGET_CROSSWALK_FIELD_NAME],
            subSection: this.props.crosswalkSubcategory,
        }
        
        let onThenCallback = (data) => {
            if (data.result) {
                this.linkObj[CROSSWALK_FIELDS.IS_UNIQUE] = data.result;
                if(callback) {
                 callback(data.result);
                }
             }
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "runValidation",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.upload_data.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.upload_data.requests_description.run_validation
        };

        fetchAPI(fetchOptions);
    }

    runMatchingEntityQuery(readFromNewArch, isSource, groupBy, periodS, periodE) {
        var obj = this;
        var periods = this.getTimeRange(periodS, periodE);
        if (periods === -1) {
            if (!groupBy) {
                if(isSource) {
                    obj.state.isSourceLoading = true;
                } else {
                    obj.state.isTargetLoading = true;
                }
            }
        }
        if (periods !== -1 && periods !== "" && Object.keys(this.linkObj).length > 0 && this.linkObj) {
            if (isSource && !groupBy) {
                $("#matchingSource_"+this.props.index).removeAttr('uk-tooltip');
                obj.state.isSourceLoading = true;
            }else if (!groupBy){
                $("#matchingTarget_"+this.props.index).removeAttr('uk-tooltip');
                obj.state.isTargetLoading = true;
            }
           
            var source = this.props.fileTypes.filter(el=>el[RAW_ITEMS.SUBTYPE_ID] === this.linkObj[CROSSWALK_FIELDS.SOURCE_SUBTYPE_ID]);
            var target = this.props.fileTypes.filter(el=>el[RAW_ITEMS.SUBTYPE_ID] === this.linkObj[CROSSWALK_FIELDS.TARGET_SUBTYPE_ID]);
            this.linkObj[CROSSWALK_FIELDS.IS_OLD_ARCH] = this.state.isOldArch;
            if(this.linkObj[CROSSWALK_FIELDS.CROSSWALK_FILE_ID] === "")
                this.linkObj[CROSSWALK_FIELDS.CROSSWALK_FILE_ID] = this.props.crosswalkFile[UPLOAD_FIELDS.RAW_FILE_ID_FIELD];
            if ((isSource && source && source.length > 0) || (!isSource && target && target.length > 0)) {
                var query = {
                    action: "runMatchingEntityQuery",
                    linkObj: this.linkObj,
                    isSource: isSource,
                    groupBy: groupBy,
                    fileType: !isSource ? this.props.fileTypes.filter(el=>el[RAW_ITEMS.SUBTYPE_ID] === this.linkObj[CROSSWALK_FIELDS.TARGET_SUBTYPE_ID])[0][RAW_ITEMS.SUBTYPE_NAME] :
                                this.props.fileTypes.filter(el=>el[RAW_ITEMS.SUBTYPE_ID] === this.linkObj[CROSSWALK_FIELDS.SOURCE_SUBTYPE_ID])[0][RAW_ITEMS.SUBTYPE_NAME],
                    crosswalkSubcategory: this.props.crosswalkSubcategory,
                    period: periods,
                    readMatchingNewArch: readFromNewArch
                }
       

                let onThenCallback = (data) => {
                    let tempState = {};
                    if(data.ERROR) {
                        if(isSource) {
                            obj.linkObj[CROSSWALK_FIELDS.SOURCE_ENTITY] = data.result;
                            tempState.isSourceLoading = false;
                        } else {
                            this.linkObj[CROSSWALK_FIELDS.TARGET_ENTITY] = data.result;
                            tempState.isTargetLoading = false;
                        }
                        obj.setState(tempState);
                    } else if (data.result || data.Numerator) {
                        if (!groupBy) {
                            if (isSource) {
                                obj.linkObj[CROSSWALK_FIELDS.SOURCE_ENTITY] = data.Numerator+"/"+data.Denominator;
                                if (data.count === data.total) {
                                    $("#matchingSource_"+obj.props.index).removeClass("red");
                                }else{
                                    $("#matchingSource_"+obj.props.index).addClass("red");
                                }
                                tempState.isSourceLoading = false;
                            } else{
                                obj.linkObj[CROSSWALK_FIELDS.TARGET_ENTITY] = data.Numerator+"/"+data.Denominator;
                                if (data.count === data.total) {
                                    $("#matchingTarget_"+obj.props.index).removeClass("red");
                                }else{
                                    $("#matchingTarget_"+obj.props.index).addClass("red");
                                }
                                tempState.isTargetLoading = false;
                            }
                        } else{
                            obj.props.setMatchingEntityData(data.result);
                        } 
                        obj.setState(tempState);
                    }
                }

                let fetchOptions = {
                    [FETCHAPI_PARAMS.funcName]: "runMatchingEntityQuery",
                    [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
                    [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
                    [FETCHAPI_PARAMS.showLoader]: groupBy ? true : false,
                    [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                    [FETCHAPI_PARAMS.query]: query,
                    [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
                    [FETCHAPI_PARAMS.screenName]:lang.observability.upload_data.screen_name,
                    [FETCHAPI_PARAMS.requestDescription]:lang.observability.upload_data.requests_description.run_matching_entity_query
                };

                fetchAPI(fetchOptions);
            }
        }
    }

    setData(data) {
        this.linkObj = data;
        this.linkObj[CROSSWALK_FIELDS.SOURCE_COLUMNS] = this.prepareFileFields(data[CROSSWALK_FIELDS.SOURCE_FILE_TYPE], data[CROSSWALK_FIELDS.SOURCE_SUBTYPE]);
        this.linkObj[CROSSWALK_FIELDS.TARGET_COLUMNS] = this.prepareFileFields(data[CROSSWALK_FIELDS.TARGET_FILE_TYPE], data[CROSSWALK_FIELDS.TARGET_SUBTYPE]);
        this.setState({
            [ROW_STATUS.FIELD]: ROW_STATUS.VALUES.OLD,
            isOld: true,     //this state doesn't change values on change attr, unlike rowStatus
            isOldArch: false
        });
    }

    deleteLink() {
        let callback = ()=>{
            this.setState({
                [ROW_STATUS.FIELD]: ROW_STATUS.VALUES.DELETED
            },function(){
                this.props.reload();
            });
        }
        this.props.deleteLink(this.props.index, callback);
    }

    onCheckboxChange = (e) => {
        this.setState({
            isOldArch: e.target.checked,
           [ROW_STATUS.FIELD]: ROW_STATUS.VALUES.EDITED
        });

        this.linkObj[CROSSWALK_FIELDS.IS_OLD_ARCH] = false;
        this.linkObj[ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED
    }

    render() {
        if(this.state[ROW_STATUS.FIELD] === ROW_STATUS.VALUES.DELETED) {
            return(<React.Fragment></React.Fragment>);
        }
        var disabledRow = this.state.isOld || false;
        var fileNamesWithTitles = arrangeFileNames(this.props.fileTypes.filter(e=>![UPLOAD_SECTIONS.FIELDS.INVENTORY_DATA,UPLOAD_SECTIONS.FIELDS.PRELIMINARY_DATA,UPLOAD_SECTIONS.FIELDS.CROSSWALK,UPLOAD_SECTIONS.FIELDS.P_AND_L,UPLOAD_SECTIONS.FIELDS.WAREHOUSE_INVENTORY_MOVEMENT,UPLOAD_SECTIONS.FIELDS.PLANOGRAM].includes(e[RAW_ITEMS.SUBTYPE_NAME])  && e[FILE_CENSUS_FIELDS.SUB_CATEGORY_MUDULE] === SCENARIO_TYPE.NORMAL));

        const optionLabel = ({ label, isDisabled }) => (
            <div title={label} className={(isDisabled ? "option-group-header" : "")+ " option-padding"}>{label}</div>
        )
        var tooltip_source_message = "";
        var tooltip_target_message = "";
        var redSourceClass = "";
        var redTargetClass = "";
        if (this.linkObj[CROSSWALK_FIELDS.SOURCE_ENTITY] && !this.state.isSourceLoading) {
            let perc = formatValHTML((Number(this.linkObj[CROSSWALK_FIELDS.SOURCE_ENTITY].split("/")[0]) * 100 ) / Number(this.linkObj[CROSSWALK_FIELDS.SOURCE_ENTITY].split("/")[1]),FormatTypes.PERCENTAGE);
            tooltip_source_message = this.linkObj[CROSSWALK_FIELDS.SOURCE_ENTITY].split("/")[0] + " of " + this.linkObj[CROSSWALK_FIELDS.SOURCE_ENTITY].split("/")[1]+ " (" +perc + ") of entities in " + this.linkObj[CROSSWALK_FIELDS.SOURCE_FIELD_NAME] + " match";
            redSourceClass = this.linkObj[CROSSWALK_FIELDS.SOURCE_ENTITY].split("/")[0] !== this.linkObj[CROSSWALK_FIELDS.SOURCE_ENTITY].split("/")[1] ? 'red' : ''
        }
        if (this.linkObj[CROSSWALK_FIELDS.TARGET_ENTITY] && !this.state.isTargetLoading) {
            let perc = formatValHTML((Number(this.linkObj[CROSSWALK_FIELDS.TARGET_ENTITY].split("/")[0]) * 100 ) / Number(this.linkObj[CROSSWALK_FIELDS.TARGET_ENTITY].split("/")[1]),FormatTypes.PERCENTAGE);
            tooltip_target_message = this.linkObj[CROSSWALK_FIELDS.TARGET_ENTITY].split("/")[0] + " of " + this.linkObj[CROSSWALK_FIELDS.TARGET_ENTITY].split("/")[1]+ " (" +perc + ") of entities in " + this.linkObj[CROSSWALK_FIELDS.TARGET_FIELD_NAME] + " match";
            redTargetClass = this.linkObj[CROSSWALK_FIELDS.TARGET_ENTITY].split("/")[0] !== this.linkObj[CROSSWALK_FIELDS.TARGET_ENTITY].split("/")[1] ? 'red' : ''
        }
        var cursorSource = "";
        var cursorTarget= "";
        if (this.linkObj[CROSSWALK_FIELDS.SOURCE_ENTITY]) {
            cursorSource = " uk-cursor-pointer";
        }
        if (this.linkObj[CROSSWALK_FIELDS.TARGET_ENTITY]) {
            cursorTarget = " uk-cursor-pointer";
        }
        // custom style for dropdowns
        const customStyles = {
            option: (styles, state) => ({
                ...styles,
                cursor: 'pointer',
                textAlign: "left",
            }),
            control: (styles) => ({
                ...styles,
                cursor: 'pointer',
                borderRadius: convertPxToViewport(4),
                borderWidth: convertPxToViewport(1),
            }),
        }
        return (
            <tr className="sourceTargetTd">
                <td className="sourceTargetTd uk-margin-small-top">
                    <Select
                        id="select-sourceFileType"
                        className="uk-margin-left uk-margin-small-right"
                        name="uploadedFilePeriodStart"
                        placeholder="Select subcategory"
                        value={findOptionByKey(this.props.fileTypes, this.linkObj[CROSSWALK_FIELDS.SOURCE_SUBTYPE])}
                        options={fileNamesWithTitles}
                        formatOptionLabel={optionLabel}
                        onChange={(e)=>this.onChangeAttribute(e, CROSSWALK_FIELDS.SOURCE_SUBTYPE)}
                        styles={customStyles}
                        isDisabled={disabledRow}
                    />
                    {/* <DropDown // to remove comment and comment <Select
                        id="select-sourceFileType"
                        className="uk-margin-left uk-margin-small-right"
                        name="uploadedFilePeriodStart"
                        placeholder="Select subcategory"
                        value={findOptionByKey(this.props.fileTypes, this.linkObj[CROSSWALK_FIELDS.SOURCE_SUBTYPE])}
                        options={fileNamesWithTitles}
                        formatOptionLabel={optionLabel}
                        onChange={(e)=>this.onChangeAttribute(e, CROSSWALK_FIELDS.SOURCE_SUBTYPE)}
                        type={DROPDOWN_TYPE.INPUT}
                        disabled={disabledRow}
                    /> */}
                </td>
                <td className="sourceTargetTd uk-margin-small-top">
                    <Select
                        id="select-sourceFileColumn"
                        className="uk-margin-left uk-margin-small-right"
                        name="uploadedFilePeriodStart"
                        placeholder="Select column"
                        value={findOptionByKey(this.linkObj[CROSSWALK_FIELDS.SOURCE_COLUMNS], this.linkObj[CROSSWALK_FIELDS.SOURCE_FIELD_NAME])}
                        options={this.linkObj[CROSSWALK_FIELDS.SOURCE_COLUMNS]}
                        onChange={(e)=>this.onChangeAttribute(e, CROSSWALK_FIELDS.SOURCE_FIELD_NAME)}
                        styles={customStyles}
                        isDisabled={disabledRow}
                    />
                    {/* <DropDown // to remove comment and comment <Select
                        id="select-sourceFileColumn"
                        className="uk-margin-left uk-margin-small-right"
                        name="uploadedFilePeriodStart"
                        placeholder="Select column"
                        value={findOptionByKey(this.linkObj[CROSSWALK_FIELDS.SOURCE_COLUMNS], this.linkObj[CROSSWALK_FIELDS.SOURCE_FIELD_NAME])}
                        options={this.linkObj[CROSSWALK_FIELDS.SOURCE_COLUMNS]}
                        onChange={(e)=>this.onChangeAttribute(e, CROSSWALK_FIELDS.SOURCE_FIELD_NAME)}
                        type={DROPDOWN_TYPE.INPUT}
                        disabled={disabledRow}
                    /> */}
                </td>
                <td className="sourceTargetTd uk-margin-small-top">
                    <Select
                        id="select-sourceCrosswalkColumn"
                        className="uk-margin-left uk-margin-small-right"
                        name="uploadedFilePeriodStart"
                        placeholder="Select column"
                        value={findOptionByKey(this.state.cwColumns, this.linkObj[CROSSWALK_FIELDS.SOURCE_CROSSWALK_FIELD_NAME])}
                        options={this.state.cwColumns || []}
                        onChange={(e)=>this.onChangeAttribute(e, CROSSWALK_FIELDS.SOURCE_CROSSWALK_FIELD_NAME)}
                        styles={customStyles}
                    />
                    {/* <DropDown // to remove comment and comment <Select
                        id="select-sourceCrosswalkColumn"
                        className="uk-margin-left uk-margin-small-right"
                        name="uploadedFilePeriodStart"
                        placeholder="Select column"
                        value={findOptionByKey(this.state.cwUniqueColumns, this.linkObj[CROSSWALK_FIELDS.SOURCE_CROSSWALK_FIELD_NAME])}
                        options={this.state.cwUniqueColumns || []}
                        onChange={(e)=>this.onChangeAttribute(e, CROSSWALK_FIELDS.SOURCE_CROSSWALK_FIELD_NAME)}
                        type={DROPDOWN_TYPE.INPUT}
                    /> */}
                </td>
                {this.state.isSourceLoading ? 
                <td className={"F  uk-margin-small-top uk-text-bold "+ redSourceClass + cursorSource} id={"matchingSource_"+this.props.index} >
                    <img alt="" src="/images/FhHRx.gif" style={{width:convertPxToViewport(15),height:convertPxToViewport(15)}}id="small_loader"/>
                    </td>
                :
                <td className={"F  uk-margin-small-top uk-text-bold "+ redSourceClass + cursorSource} id={"matchingSource_"+this.props.index} uk-tooltip={this.state.isSourceLoading ? "" : tooltip_source_message} onClick={(e)=>this.showMatchingEntityModal(true)}>
                    {this.linkObj[CROSSWALK_FIELDS.SOURCE_ENTITY]}
                </td>
                    }
                <td className="sourceTargetTd uk-margin-small-top">
                    <Select
                        id="select-targetFileType"
                        className="uk-margin-left uk-margin-small-right"
                        name="uploadedFilePeriodStart"
                        placeholder="Select subcategory"
                        value={findOptionByKey(this.props.fileTypes, this.linkObj[CROSSWALK_FIELDS.TARGET_SUBTYPE])}
                        options={fileNamesWithTitles}
                        formatOptionLabel={optionLabel}
                        onChange={(e)=>this.onChangeAttribute(e, CROSSWALK_FIELDS.TARGET_SUBTYPE)}
                        styles={customStyles}
                        isDisabled={disabledRow}
                    />
                    {/* <DropDown // to remove comment and comment <Select
                        id="select-targetFileType"
                        className="uk-margin-left uk-margin-small-right"
                        name="uploadedFilePeriodStart"
                        placeholder="Select subcategory"
                        value={findOptionByKey(this.props.fileTypes, this.linkObj[CROSSWALK_FIELDS.TARGET_SUBTYPE])}
                        options={fileNamesWithTitles}
                        formatOptionLabel={optionLabel}
                        onChange={(e)=>this.onChangeAttribute(e, CROSSWALK_FIELDS.TARGET_SUBTYPE)}
                        type={DROPDOWN_TYPE.INPUT}
                        disabled={disabledRow}
                    /> */}
                </td>
                <td className="sourceTargetTd uk-margin-small-top">
                    <Select
                        id="select-targetFileColumn"
                        className="uk-margin-left uk-margin-small-right"
                        name="uploadedFilePeriodStart"
                        placeholder="Select column"
                        value={findOptionByKey(this.linkObj[CROSSWALK_FIELDS.TARGET_COLUMNS], this.linkObj[CROSSWALK_FIELDS.TARGET_FIELD_NAME])}
                        options={this.linkObj[CROSSWALK_FIELDS.TARGET_COLUMNS]}
                        onChange={(e)=>this.onChangeAttribute(e, CROSSWALK_FIELDS.TARGET_FIELD_NAME)}
                        styles={customStyles}
                        isDisabled={disabledRow}
                    />
                    {/* <DropDown // to remove comment and comment <Select
                        id="select-targetFileColumn"
                        className="uk-margin-left uk-margin-small-right"
                        name="uploadedFilePeriodStart"
                        placeholder="Select column"
                        value={findOptionByKey(this.linkObj[CROSSWALK_FIELDS.TARGET_COLUMNS], this.linkObj[CROSSWALK_FIELDS.TARGET_FIELD_NAME])}
                        options={this.linkObj[CROSSWALK_FIELDS.TARGET_COLUMNS]}
                        onChange={(e)=>this.onChangeAttribute(e, CROSSWALK_FIELDS.TARGET_FIELD_NAME)}
                        type={DROPDOWN_TYPE.INPUT}
                        disabled={disabledRow}
                    /> */}
                </td>
                <td className="sourceTargetTd uk-margin-small-top">
                    <Select
                        id="select-targetCrosswalkColumn"
                        className="uk-margin-left uk-margin-small-right"
                        name="uploadedFilePeriodStart"
                        placeholder="Select column"
                        value={findOptionByKey(this.state.cwColumns, this.linkObj[CROSSWALK_FIELDS.TARGET_CROSSWALK_FIELD_NAME])}
                        options={this.state.cwColumns || []}
                        onChange={(e)=>this.onChangeAttribute(e, CROSSWALK_FIELDS.TARGET_CROSSWALK_FIELD_NAME)}
                        styles={customStyles}
                    />
                    {/* <DropDown // to remove comment and comment <Select
                        id="select-targetCrosswalkColumn"
                        className="uk-margin-left uk-margin-small-right"
                        name="uploadedFilePeriodStart"
                        placeholder="Select column"
                        value={findOptionByKey(this.state.cwColumns, this.linkObj[CROSSWALK_FIELDS.TARGET_CROSSWALK_FIELD_NAME])}
                        options={this.state.cwColumns || []}
                        onChange={(e)=>this.onChangeAttribute(e, CROSSWALK_FIELDS.TARGET_CROSSWALK_FIELD_NAME)}
                        type={DROPDOWN_TYPE.INPUT}
                    /> */}
                </td>
                {this.state.isTargetLoading ? 
                    <td className = {"sourceTargetTd uk-margin-small-top uk-cursor-pointer uk-text-bold "+redTargetClass + cursorTarget} id={"matchingTarget_"+this.props.index} >
                        <img alt="" src="/images/FhHRx.gif" style={{width:convertPxToViewport(15),height:convertPxToViewport(15)}}id="small_loader"/>
                    </td>
                    :
                    <td className = {"sourceTargetTd uk-margin-small-top uk-cursor-pointer uk-text-bold "+redTargetClass + cursorTarget} id={"matchingTarget_"+this.props.index} uk-tooltip={!this.state.isTargetLoading ? tooltip_target_message :"" } onClick={(e)=>this.showMatchingEntityModal(false)}>
                    {this.linkObj[CROSSWALK_FIELDS.TARGET_ENTITY]}
                    </td>
                }
               
                <td className="sourceTargetTd uk-margin-small-top">
                    <Button  
                        variant={BUTTON_VARIANT.TERTIARY}
                        size={SIZES.ICON}
                        type={BUTTON_TYPE.DEFAULT}
                        leftIcon={<i className="fal fa-trash-alt fa-lg"/>}
                        onBtnClick={this.deleteLink} 
                    />
                </td>
            </tr>
        );
    }
}

export default Link;