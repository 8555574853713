import React, { useImperativeHandle } from "react";
import { createRef } from "react";
import { forwardRef } from "react";
import { useRef } from "react";
import {
  ALL_REPORTS, ALL_WIDGETS,
  BUTTON_DROPDOWN_VARIANT,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  FILTER,
  HEADER_ELEMENT, SECTION,
  SEGMENTS_TITLES,
  SIZES
} from "../class/constants";
import { getTranslationFile } from "../class/utils";
import { lang } from "../language/messages_en";
import { getFilterBackgroundColor, getFilterDisplayClass } from "../sections/filter/FilterHelperFunctions";
import ManageColumns from "./manageColumns/ManageColumns";
import { getObjectAsArray } from "../class/jqueries";
import Button from "../newComponents/Button";
import { Segment } from "./Segment";
import ButtonDropdown from "../newComponents/ButtonDropdown";
import {linearizeHierarchy} from "../class/array";
import DrillButtonDropdown from "./drill/DrillButtonDropdown";

/**
 * This component is the expanding component (list) that is used in Landscape bubble chart and can be reused in other
 * screens that have a show/hide footer component or show/close footer component with half and full screen mode
 */
const ExpandingList = ({
  mainComponentId, // the main chart/component that is displayed when you access the screen 
  tabulatorId,
  isAdjustable, //can be minimized/maximized
  maximizeMinimizeClick,
  hasExport,
  exportParams,
  hasManageColumns,
  manageColumnsParams,
  manageColumnsParamsSelected,
  isClosable, // if isClosable = true, there will be a close icon instead of expand/collapse icon
  isExpanded,
  expandCollapseClick,
  filter, 
  hasStackDropdown,
  isFullScreen,
  closeClick,
  bodyComponent,
  title,
  addModified, // adds modified text next to title
  filterProps,
  defaultList,
  isActiveBubble,
  maximizeMinimizeChart,
  screenId,
  showManagecolumnsTwo,
  showFilter,
  isGeographyConcentration,
  selectedEntities,
  ...props
}, ref) => {
  const defaultManageColumnsRef = useRef();
  const selectedManageColumnsRef = useRef();
  const exportDefaultRef = useRef();
  const exportSelectedRef = useRef();
  const bubbleFilterDialRef = useRef();
  const manageColumnsDrillRef = useRef();
  const MESSAGES = getTranslationFile();

  const FILTER_TYPE_PROFITSTACK = FILTER.VALUES.TYPE.PROFIT_STACK;
  const _ct = MESSAGES.ui_filter.dropdowns.functions.contains.value;
  const _nct = MESSAGES.ui_filter.dropdowns.functions.not_contains.value;
  const _gt = MESSAGES.ui_filter.dropdowns.functions.greater.value;
  const _lt = MESSAGES.ui_filter.dropdowns.functions.less.value;
  const _ltoe = MESSAGES.ui_filter.dropdowns.functions.less_or_equalsNUM.value;
  const _gtoe = MESSAGES.ui_filter.dropdowns.functions.greater_or_equalsNUM.value;
  const _eq = MESSAGES.ui_filter.dropdowns.functions.equals.value;
  const _empty = MESSAGES.ui_filter.dropdowns.functions.empty.value;
  const _nempty = MESSAGES.ui_filter.dropdowns.functions.not_empty.value;
  const _isBetween = MESSAGES.ui_filter.dropdowns.functions.betweenNUM.value;
  const _startWith = MESSAGES.ui_filter.dropdowns.functions.start_with.value;
  const _endWith = MESSAGES.ui_filter.dropdowns.functions.end_with.value;
  const _field = FILTER.KEYS.FIELD;
  const _function = FILTER.KEYS.FUNCTION;
  const _entities = FILTER.KEYS.ENTITIES;
  const _logicalOperator = FILTER.KEYS.LOGICAL_OPERATOR;
  const _parenthesisBefore = FILTER.KEYS.PARENTHESIS_BEFORE;
  const _parenthesisAfter = FILTER.KEYS.PARENTHESIS_AFTER;
  useImperativeHandle(ref, () => ({
    getDefaultManageColumnsRef: () => {
      return defaultManageColumnsRef.current;
    },
    getSelectedManageColumnsRef: () => {
      return selectedManageColumnsRef.current;
    },
    getSelectedExportRef: () => {
      return exportSelectedRef.current;
    },
    getDefaultExportRef: () => {
      return exportDefaultRef.current;
    },
    bubbleFilterDialRef: () => {
      return bubbleFilterDialRef.current;
    },
    collapseClick: () => {
      let halfScreenClassName = "half-screen-list";
      let fullScreenClassName = "full-screen-list";

      $("#expanded-list-component").addClass("decreaseHeight");
      $("#expanding-component").removeClass("half-height");
      $("#expanding-component").removeClass("full-height");
      $("#expanding-component").addClass("chart-margin-top");
      $("#expanding-component").removeClass("chart-margin-bottom");
      $("#" + mainComponentId).removeClass("half-screen-main-component");
      $("#expanded-list-component #" + tabulatorId).removeClass(halfScreenClassName);  
      $("#expanded-list-component #" + tabulatorId).removeClass(fullScreenClassName);
    },
    expandClick: (changeCssOnly, forceRender) => {
      expandCollapse(changeCssOnly, forceRender);
    },
    renderFilterDisplay: () => {
      renderFilterDisplay()
    },
    removeFullScreenMode: () => {
      $("#" + mainComponentId).show();
    }
  }));

  /** Toggles manage columns board overlay */
  function onToggleManageColsBoard() {
    $("#overlay-defaultBubbleList").toggleClass("uk-hidden");
  }

  function onToggleManageColsDrillBoard() {
    $("#overlay-drill-bubble-list").toggleClass("uk-hidden");
  }

  function onToggleManageColsBoardSelectedBubble(){
    $("#overlay-selectedBubbleList").toggleClass("uk-hidden");
  }

  /**
   * Expands and collaps the footer component
   * @param changeCssOnly to only hide/show component using JQuery
   */
  function expandCollapse(changeCssOnly = false, forceRender) {
    if (expandCollapseClick && !changeCssOnly) {
      expandCollapseClick(forceRender);
    }

    let halfScreenClassName = "half-screen-list";
    let fullScreenClassName = "full-screen-list";

    $("#expanded-list-component").toggleClass("decreaseHeight");
    $("#expanding-component").toggleClass("chart-margin-top");
    $("#expanding-component").toggleClass("chart-margin-bottom");
    $("#expanding-component").toggleClass("half-height");
    $("#expanding-component").removeClass("full-height");
    $("#" + mainComponentId).toggleClass("half-screen-main-component");

    if (isFullScreen) {
      $("#" + mainComponentId).toggle();
      $("#expanded-list-component #" + tabulatorId).removeClass(fullScreenClassName);
    } else {
      $("#expanded-list-component #" + tabulatorId).toggleClass(halfScreenClassName);
    }
  }

  function closeList() {
    if (closeClick) { closeClick(); }
    // $("#expanding-component").toggleClass("uk-hidden");
  }

  /**
   * Set footer component to half or full screen mode
   */
  function minimizeMaximizeList() {
    if (maximizeMinimizeClick) { maximizeMinimizeClick(); }
    let halfScreenClassName = "half-screen-list";
    let fullScreenClassName = "full-screen-list";
    $("#expanding-component").toggleClass("full-height");
    $("#expanded-list-component #" + tabulatorId).toggleClass(fullScreenClassName);
    $("#expanded-list-component #" + tabulatorId).toggleClass(halfScreenClassName);
    $("#" + mainComponentId).toggle();
  }

  /**
   * If the component is closable, render the close icon
   * Else render the expand/collapse icon
   * @returns 
   */
  function renderExpandCloseIcon() {
    return (
      <div className="close_expand_button">
        {isClosable ? (
          <Button   
            variant={BUTTON_VARIANT.TERTIARY}
            size={SIZES.ICON}
            type={BUTTON_TYPE.DEFAULT}
            leftIcon={<i className="fal fa-times" />}
            className="close-button"
            aria-label="Close"
            onBtnClick={closeList}
        />
        ) : (
          <Button   
            variant={BUTTON_VARIANT.TERTIARY2}
            size={SIZES.ICON}
            type={BUTTON_TYPE.DEFAULT}
            leftIcon={<i className={"fs-16 far fa-chevron" + (isExpanded ? "-down" : "-up")} />}
            onBtnClick={() => expandCollapse()}
        />
        )}
      </div>
    );
  }

  /**
   * Returns the necessary icons needed in header
   * @returns 
   */
  function renderIconsInHeader() {
    return (
      <div className="uk-flex uk-flex-middle expand_compress_button">
        {renderExpandCloseIcon()}
        {isExpanded && isAdjustable &&
           <Button   
            variant={BUTTON_VARIANT.TERTIARY}
            size={SIZES.ICON}
            type={BUTTON_TYPE.DEFAULT}
            leftIcon={<i className={"fa-lg far fa-" + (!isFullScreen ? "expand" : "compress")} />}
            className="uk-margin-xsmall-left"
            onBtnClick={minimizeMaximizeList}
          />
        }
      </div>
    );
  }

  const fetchColumnsOnSave = () =>{
    if($("#defaultManageColumns").hasClass("uk-hidden") || isGeographyConcentration){
      defaultManageColumnsRef.current.fetchAll();
    }else{
      selectedManageColumnsRef.current.fetchAll();

    }
  }

  function renderDrillManageColumns(){
    return (
        <div className="noprint" key={"noprint"}>
          {(props.manageColsAccess && !Object.keys(props.manageColsAccess).every((k) => !props.manageColsAccess[k])) ?
              <ManageColumns ref={props.manageColumnsDrillRef}
                             key={HEADER_ELEMENT.ADD_COLUMNS + "_"}
                             resetDrillVectorType={props.pageComponent.resetDrillVectorType}
                             scenarioId={props.scenarioState?.scenario}
                             profitFormat={ALL_WIDGETS.FIELDS.LIST}
                             user={props?.userSettingsState.user}
                             onToggleBoard={onToggleManageColsDrillBoard}
                             onSelectDefaultProfile={props.pageComponent.selectProfile}
                             onSelectProfile={props.pageComponent.selectProfile}
                             onChangeProfileData={props.pageComponent.onApplyProfile}
                             manageColsAccess={props.manageColsAccess}
                             vectorObjects={props.vectorState?.vectorObjects}
                             vectorOptions={props.vectorState.vectorOptions}
                             updateDrillProfile={props.pageComponent.updateDrillProfile}
                             checkForLimitAccessMessage={props.checkForLimitAccessMessage}
                             vector={props.isDrilling && props?.history?.location?.state?.drillTier ? props.history.location.state.drillTier : props.vectorState.vectors[0]}
                             hideArrow={false}
                             id={"overlay-drill-bubble-list"}
                             isDrilling={props.isDrilling}
                             manageColumnsSelectionLimit={props.userSettingsState?.manageColumnsSelectionLimit}
                             setDrillTabulatorPageSize={props.pageComponent.setDrillTabulatorPageSize}
                             constraint={"16"}
                             updateManageColumnsProfile={props.updateManageColumnsProfile}
                             dispatch={props.dispatch}
                             characterSizeLimit={props.userSettingsState.characterSizeLimit}
                             useAppearanceList={props.userSettingsState.useAppearanceList}
                             setColumnWidthState={props.setColumnWidthState}
              /> : ""}
        </div>
    )
  }

  function renderManageColumns(){
          return (
          <>
          <div id="defaultManageColumns" className= {!!defaultList ? "uk-display-flex" : "uk-hidden"}>
            <ManageColumns
              ref={defaultManageColumnsRef}
              key={HEADER_ELEMENT.ADD_COLUMNS}
              scenarioId={manageColumnsParams.scenarioId}
              profitFormat={manageColumnsParams.profitFormat}
              approveBulkChange={manageColumnsParams.approveBulkChange}
              user={manageColumnsParams.user}
              onSelectProfile={manageColumnsParams.onSelectProfile}
              onSelectDefaultProfile={manageColumnsParams.onSelectDefaultProfile}
              onChangeProfileData={manageColumnsParams.onChangeProfileData}
              manageColsAccess={manageColumnsParams.manageColsAccess}
              vectorObjects={manageColumnsParams.vectorObjects}
              vector={manageColumnsParams.vector}
              sectionsProfilesAreValidFor={manageColumnsParams.sectionsProfilesAreValidFor}
              onToggleBoard={onToggleManageColsBoard}
              hideDropdown={manageColumnsParams.hideDropdown}
              vectorOptions={manageColumnsParams.vectorOptions}
              updateManageColumnsProfile={manageColumnsParams.updateManageColumnsProfile}
              dispatch={manageColumnsParams.dispatch}
              profileColumns={manageColumnsParams.profileColumns}
              customGroups={manageColumnsParams.customGroups}
              profiles={manageColumnsParams.profiles}
              stateProfiles={manageColumnsParams.stateProfiles}
              columnProfiles={manageColumnsParams.columnProfiles}
              manageColumnsSelectionLimit={manageColumnsParams.manageColumnsSelectionLimit}
              isDefaultProfileQT={manageColumnsParams.isDefaultProfileQT}
              fetchColumnsOnSave={fetchColumnsOnSave}
              isLandscapeBubble = {true}
              sendUniqueId={"defaultBubbleList"}
              isFromExpandingList={true}
              constraint={"7"}
              characterSizeLimit={manageColumnsParams?.characterSizeLimit}
              useAppearanceList={manageColumnsParams.useAppearanceList}
              setColumnWidthState={props.setColumnWidthState}
            />
          </div>
          {showManagecolumnsTwo ? 
          <div id="selectedManageColumns" className={!!defaultList ? "uk-hidden" : "uk-display-flex"}>
          <ManageColumns
            ref={selectedManageColumnsRef}
            key={HEADER_ELEMENT.ADD_COLUMNS}
            scenarioId={manageColumnsParamsSelected.scenarioId}
            profitFormat={manageColumnsParamsSelected.profitFormat}
            approveBulkChange={manageColumnsParamsSelected.approveBulkChange}
            user={manageColumnsParamsSelected.user}
            onSelectProfile={manageColumnsParamsSelected.onSelectProfile}
            onSelectDefaultProfile={manageColumnsParamsSelected.onSelectDefaultProfile}
            onChangeProfileData={manageColumnsParamsSelected.onChangeProfileData}
            manageColsAccess={manageColumnsParamsSelected.manageColsAccess}
            vectorObjects={manageColumnsParamsSelected.vectorObjects}
            vector={manageColumnsParamsSelected.vector}
            sectionsProfilesAreValidFor={manageColumnsParamsSelected.sectionsProfilesAreValidFor}
            onToggleBoard={onToggleManageColsBoardSelectedBubble}
            hideDropdown={manageColumnsParamsSelected.hideDropdown}
            vectorOptions={manageColumnsParamsSelected.vectorOptions}
            updateManageColumnsProfile={manageColumnsParamsSelected.updateManageColumnsProfile}
            dispatch={manageColumnsParamsSelected.dispatch}
            profileColumns={manageColumnsParamsSelected.profileColumns}
            customGroups={manageColumnsParamsSelected.customGroups}
            profiles={manageColumnsParamsSelected.profiles}
            stateProfiles={manageColumnsParamsSelected.stateProfiles}
            columnProfiles={manageColumnsParamsSelected.columnProfiles}
            manageColumnsSelectionLimit={manageColumnsParamsSelected.manageColumnsSelectionLimit}
            isDefaultProfileQT={false}
            selectedBubbleList = {true}
            fetchColumnsOnSave={fetchColumnsOnSave}
            isLandscapeBubble = {true}
            sendUniqueId={"selectedBubbleList"}
            isFromExpandingList={true}
            constraint={"6"}
            characterSizeLimit={manageColumnsParamsSelected.characterSizeLimit}
            useAppearanceList={manageColumnsParamsSelected.useAppearanceList}
            setColumnWidthState={props.setColumnWidthState}
        /></div>
          :""}
          
          </>
          )
       
    }

  const renderExportBody = () => {
    let configureClassName = "table-configure-dialog";
    return (
        <div id="configure-dropdown" style={{position: "absolute", right: "10.7vw", top:0}}>
          <div id="stack_table_export_dialog" className={configureClassName + " table-configure-dialog configure-container configure_body"}>
            {exportParams.options.map((option, index) => (
                <div className={"export_options_" + option.value} key={index} >
                  <div className="stacks_configure_title" onClick={() => {
                    option?.click();
                    defaultList ? exportDefaultRef.current.setConfigDropdownOpen(false) : exportSelectedRef.current.setConfigDropdownOpen(false)
                  }}>{option.label}</div>
                  <div className="export_description">{option.description}</div>
                  <div className="export_container_divider"></div>
                </div>
            ))}
          </div>
        </div>
    )
  }


  /**
   * Render header components like export, manage columns, stack dropdown, filter..
   * @returns 
   */
  function renderExpandedHeaderElements() {
    return (
      <div className={"add-columns-export-container gap_between_buttons " + (!isExpanded ? "hide" : "")} style={{position:"relative"}}>
        {hasExport && defaultList ?
            <ButtonDropdown
                id={"stacks-table-export-btn"}
                ref={exportDefaultRef}
                placeholder={lang.header.titles.xls_short}
                className="export-button-table uk-margin-small-left"
                // onBtnClick={this.showConfigureDialog}
                dropId={"stack_table_export_dialog"}
                variant={BUTTON_DROPDOWN_VARIANT.BUTTON}
                size={SIZES.SMALL}
                firstAttr={"#button-drop-stacks-table-export-btn"}
                secondAttr={"#stack_table_export_dialog"}
                renderContainerBody={renderExportBody}
                tooltip={lang.header.tooltips.export_format}
            />:
          hasExport ?
              <ButtonDropdown
                  id={"stacks-table-export-btn"}
                  ref={exportSelectedRef}
                  placeholder={lang.header.titles.xls_short}
                  className="export-button-table uk-margin-small-left"
                  // onBtnClick={this.showConfigureDialog}
                  dropId={"stack_table_export_dialog"}
                  variant={BUTTON_DROPDOWN_VARIANT.BUTTON}
                  size={SIZES.SMALL}
                  firstAttr={"#button-drop-stacks-table-export-btn"}
                  secondAttr={"#stack_table_export_dialog"}
                  renderContainerBody={renderExportBody}
                  tooltip={lang.header.tooltips.export_format}
              />
        : ""
        }

        {hasManageColumns && !props.isDrilling ?
          <>
            {renderManageColumns()}
          </>
          : <>{renderDrillManageColumns()}</>}
      </div>
    )
  }

  function renderBody() {
    return bodyComponent();
  }

  const renderFilterDisplay = () => {
    if (isActiveBubble || showFilter) {
      var allFilters = [];
      // var useOutsideFilter = comp.state.useOutsideFilter;
      // var loopedOverArr = useOutsideFilter ? comp.state.outsideFilter : comp.state.filterRefs;
      var loopedOverArr = typeof filterProps.filter === "string" ? JSON.parse(filterProps.filter) : filterProps.filter;
      // let exportQueryFilter = filterProps.savedFilterData[SF_NAME] ? filterProps.savedFilterData[SF_NAME] + " " : "";
      let filterFunction = "";
      let i = 0;
      loopedOverArr.map((filter, ind) => {
        switch (filter[_function]) {
          case _gt:
            filterFunction = ">";
            break;
          case _lt:
            filterFunction = "<";
            break;
          case _ltoe:
            filterFunction = "<=";
            break;
          case _gtoe:
            filterFunction = ">=";
            break;
          case _eq:
            filterFunction = "=";
            break;
          case _isBetween:
            filterFunction = "is between";
            break;
          case _startWith:
            filterFunction = "starts with";
            break;
          case _endWith:
            filterFunction = "ends with";
            break;
          case lang.ui_filter.dropdowns.functions.not_empty.value:
          case lang.ui_filter.dropdowns.functions.empty.value:
            filterFunction = getObjectAsArray(lang.ui_filter.dropdowns.functions, filter[_function], "value")[0].label;
            break;
          default:
            filterFunction = "";
            break;
        }
        let filterEntities = "";
        let star = [_ct, _nct].indexOf(filter[_function]) > -1 ? "*" : "";

        let operator = ind === 0 ? "" : filter[_logicalOperator];
        if (filter[_entities] && filter[_entities].length) {
          if (typeof filter[_entities] === "object") {   //array of selected values for vector filter
            filter[_entities].map((ent, i) => {
              filterEntities += (i > 0 ? ", " : "") + ent.value.toUpperCase();
            });
          } else {
            filterEntities = filter[_entities];     //value written in input for profit stack filter
          }
        }
        if (filter.type === FILTER_TYPE_PROFITSTACK && filter[_function] === _isBetween && filter) {
          filterEntities = filter[FILTER.KEYS.MIN] + " and " + filter[FILTER.KEYS.MAX];
        }
        let filterClass = getFilterDisplayClass(filter, false, false);
        let filterBackgroundColor = getFilterBackgroundColor(filter, false);
        let displayVector = filterProps.vectorOptions.filter(f => f.value === filter.vector)[0].label;
        let filterField = filter.type === FILTER_TYPE_PROFITSTACK ? linearizeHierarchy(filterProps.psLinesOptions, SECTION.CHILDREN)?.filter(f => f.value === filter.field)[0].label : filter[_field];
        let label;
        if ([SEGMENTS_TITLES.PROFIT_SEGMENTS.value, SEGMENTS_TITLES.PROFIT_TIERS.value].includes(filterField)) {
          let segment = new Segment();
          let filterEntitiesArr = filterEntities.split(",");
          let final = [];
          for (var e in filterEntitiesArr) {
            final.push(segment.getSegmentObject(filterEntitiesArr[e].replace(/ /g,''))?.label)
          }
         
          label =  final.join(",");
        }

        let text = star + displayVector + ":" + filterField + " " + filterFunction + " " + (label || filterEntities) + star;
        if (operator && loopedOverArr.length > 1) {
          allFilters.push(<span className={"pi-chip filtered-item-title"} key={"filter-display-key-v-" + i++} title={operator}>{operator}</span>);
        }
        filter[_parenthesisBefore].split("").forEach(entity => {
          allFilters.push(<span className={"filtered-item-title parenthesis"} key={"filter-display-key-v-" + i++} title={entity}>{filter.filter_row_type === FILTER.VALUES.FILTER_ROW_TYPE.FILTER && operator !== FILTER.VALUES.LOGICAL_OPERATOR.OR && ind === 0 ? entity + entity : entity}</span>);
        });

        let stylesObj = {};
        if(filterBackgroundColor){
          stylesObj.backgroundColor = filterBackgroundColor.color;
          stylesObj.color = filterBackgroundColor.textColor;

        }
        allFilters.push(
          <span style={stylesObj} className={filterClass + "filtered-item-title filter-header-el-" + FILTER.VALUES.TYPE.VECTOR} operator={operator} key={"filter-display-key-ps-" + i++} title={text}>{text}
          </span>
        );
       
        filter[_parenthesisAfter].split("").forEach(entity => {
          let lastHeaderFilterArr = loopedOverArr.filter(f => f.filter_row_type === "filter");
          lastHeaderFilterArr = lastHeaderFilterArr[lastHeaderFilterArr.length - 1];
          let addSecondParenthisis = JSON.stringify(lastHeaderFilterArr) === JSON.stringify(filter)
          allFilters.push(<span className={"filtered-item-title parenthesis"} key={"filter-display-key-v-" + i++} title={entity}>{addSecondParenthisis ? entity + entity : entity}</span>);
        });
      });
      return allFilters;
    }

  }

  /**
   * Returns header components
   * @returns 
   */
  function renderHeader() {
    let renderDrillButton = selectedEntities?.length >0  && selectedEntities.length <=  Number(props?.userSettingsState.drill_entity_selection_limit);
    return (
      <>
        <div className="uk-flex uk-flex-middle">
          {renderIconsInHeader()}
          <div className="uk-flex-inline" style={{alignItems: "center"}}>
            <span className="fs-14 filter-control-titles uk-margin-xsmall-left">{title}</span>
            {isExpanded && addModified &&
              <div className="uk-flex-inline uk-margin-left-negative">
                <span className="modified">{lang.manage_columns.titles.modified}</span>
                <i className="fs-12 fal fa-info-circle uk-margin-xsmall-left uk-margin-xsmall-top uk-cursor-pointer" uk-tooltip={lang.manage_columns.text.modified_stmt}></i>
              </div>
            }
            {renderDrillButton &&
                <DrillButtonDropdown vectors={props.vectorState?.vectorOptions?.filter(e => !e.isGroupTitle)}
                                     onOptionClick={props.onDrillOptionClick} disabled={props.isDrillDisabled}
                                     tooltipMessage={props.drillTooltip}/>
            }
          </div>
          {isExpanded && filterProps.filter ?
          <div id="filter-display-chip" className="filter-display-chip filter-display-filters disabled">
          {renderFilterDisplay()}
          </div>
          : ""}
        </div>
        {renderExpandedHeaderElements()}
      </>
    );
  }

  return (
    <div id="expanding-component" className="chart-margin-top" style={{height:'0%'}}>
      <div className="noprint uk-border drill-header-container uk-flex uk-flex-between ">
        {renderHeader()}
      </div>
      <div id={"expanded-list-component"} style={{height:'100%'}} className={"decreaseHeight " + screenId}>
        {renderBody()}
      </div>
    </div>
  );
}

export default forwardRef(ExpandingList);
