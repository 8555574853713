// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vectors_body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0;
    height: 100%
}

.vectors_container {
    display: flex;
    padding: 25px 10px 10px 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    height: 100%;
}

.vector-text{
    color: #333;
    font-family: Lato;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; 
}

.vector-component {
    display: flex;
    direction: column;
    flex-direction: column;
    row-gap: 6px;
    width: 50%;
}

.vector-row {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    justify-content: space-between;
    width: 100%
}

.italic-msg {
    font-style: italic;
    font-weight: 700;
    color: #A7A7A7;
  }`, "",{"version":3,"sources":["webpack://./src/components/supplyChainSetup/vectorsConfiguration.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,SAAS;IACT,SAAW;IACX;AACJ;;AAEA;IACI,aAAa;IACb,4BAA4B;IAC5B,sBAAsB;IACtB,uBAAuB;IACvB,SAAS;IACT,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,sBAAsB;IACtB,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;IACT,8BAA8B;IAC9B;AACJ;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;EAChB","sourcesContent":[".vectors_body {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 10px;\n    flex: 1 0 0;\n    height: 100%\n}\n\n.vectors_container {\n    display: flex;\n    padding: 25px 10px 10px 10px;\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 10px;\n    width: 100%;\n    height: 100%;\n}\n\n.vector-text{\n    color: #333;\n    font-family: Lato;\n    font-size: 13px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 120%; \n}\n\n.vector-component {\n    display: flex;\n    direction: column;\n    flex-direction: column;\n    row-gap: 6px;\n    width: 50%;\n}\n\n.vector-row {\n    display: flex;\n    align-items: flex-start;\n    gap: 20px;\n    justify-content: space-between;\n    width: 100%\n}\n\n.italic-msg {\n    font-style: italic;\n    font-weight: 700;\n    color: #A7A7A7;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
